import { HttpParams } from '@angular/common/http';
import { error } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Person } from '@metro-membership/data-access';
import { KeycloakService } from '@metro/ui-core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmailBody } from '../../../../../../../libs/data-access/src/lib/models/email';
import { PersonService } from '../../../data/services/person.service';
import { NotificationService } from '../../services/notification.service';

@Component({
	selector: 'metro-membership-choose-person',
	templateUrl: './choose-person.component.html',
	styleUrls: ['./choose-person.component.less']
})
export class ChoosePersonComponent implements OnDestroy {
	@Output() personChange: EventEmitter<Person> = new EventEmitter<Person>();
	searchControl: FormControl = new FormControl();
	unSubscribe = new Subject();
	showPersonForm = false;
	persons: Person[];
	@Input() chosenPerson = null as Person;
	@Input() extras = null;
	personForm : FormGroup;

	constructor(private personService: PersonService, private formBuilder: FormBuilder, private keycloak: KeycloakService,
							private notificationService: NotificationService) {
			this.getPersons();
			this.buildPersonForm();
			this.registerEvents();
		}

		buildPersonForm() {
			this.personForm = this.formBuilder.group({
				name: ['', Validators.required],
				surname:['', Validators.required],
				email: ['', Validators.compose([Validators.required, Validators.email])]
			});
		}

		checkPerson(event: boolean, person: Person) {
			this.chosenPerson = event ? person : null;
			this.personChange.next(this.chosenPerson);
		}

		ngOnDestroy() {
			this.unSubscribe.next();
			this.unSubscribe.complete();
		}

		registerEvents() {
			this.searchControl.valueChanges
			.pipe(takeUntil(this.unSubscribe))
			.subscribe((searchChanges: string) => {
				this.getPersons(searchChanges);
			});
		}

		getPersons(searchText?: string) {
			const searchPhrase: HttpParams = searchText ? new HttpParams()
			.set('filter', `name||cont||${searchText}`)
			.set('or', `surname||cont||${searchText}`): null;

			this.personService.fetchPersons(searchPhrase).subscribe(persons => {
				this.persons = persons;
			});
		}

		sendEmail(email: EmailBody) {
			this.personService.sendRegistrationEmail(email).subscribe(emailJson => {
			});
		}

		savePerson() {
			if(this.personForm.invalid) {
				return true;
			}
			const formData = this.personForm.getRawValue() as Person;
			this.personService.createPerson(formData).subscribe(personJson => {
				this.sendEmail({
					name: formData.name,
					url: this.keycloak.getKeycloakInstance().createRegisterUrl(),
					email: formData.email,
					resource: this.extras
				});
				this.getPersons();
				this.checkPerson(true, personJson);
			}, (error) => {
				this.notificationService.createBasicNotification('Sorry', error.error.error, 'error');
			})
		}
	}
