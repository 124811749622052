
export class AnalyticalInstrumentation  {
	instrumentationChromatography = false;
	instrumentationElementalAnalysis = false;
	instrumentationSpeciationAnalysis = false;
	instrumentationIsotopicAnalysis = false;
	instrumentationMolecularSpectroscopy = false;
	instrumentationNanoparticleAnalysis = false;
	instrumentationSurfaceAnalysis = false;
	instrumentationSolidSurfaceStateAnalysis = false;
	instrumentationSolidStructureStateAnalysis = false;
	instrumentationThermalAnalysis = false;
	instrumentationRheology = false;
	instrumentationSensoryAnalysis = false;
	instrumentationBiotechnology = false;
	instrumentationMicrobiology = false;
}

export class Chromatography {
	chromatographyGPC = false;
	chromatographyTLC = false;
	chromatographyPyGC = false;
	chromatographyGPCLALLS = false;
	chromatographyCE = false;
	chromatographyLC = false;
	chromatographyGC = false;
	chromatographyIC = false;
	chromatographySFC = false;
	chromatographyOther = false;
}

export class CeDetectors {
	ceDetectorsAerosolDetector = false;
	ceDetectorsCDD = false;
	ceDetectorsECD = false;
	ceDetectorsFLD = false;
	ceDetectorsMS = false;
	ceDetectorsMSMS = false;
	ceDetectorsRID = false;
	ceDetectorsUV = false;
	ceDetectorsMSn = false;
	ceDetectorsHRMS = false;
	ceDetectorsOther = false;
}

export class LcDetectors {
	lcDetectorsCDD = false;
	lcDetectorsECD = false;
	lcDetectorsFLD = false;
	lcDetectorsELSD = false;
	lcDetectorsDAD = false;
	lcDetectorsMS = false;
	lcDetectorsMSMS = false;
	lcDetectorsOrbitrap = false;
	lcDetectorsTOF = false;
	lcDetectorsQTOF = false;
	lcDetectorsRID = false;
	lcDetectorsUV = false;
	lcDetectorsMSn = false;
	lcDetectorsHRMS = false;
	lcDetectorsConductivity = false;
	lcDetectorsRadioFlow = false;
	lcDetectorsOther = false;
}

export class GcDetectors {
	gcDetectorsDID = false;
	gcDetectorsELCD = false;
	gcDetectorsECD = false;
	gcDetectorsFID = false;
	gcDetectorsFPD = false;
	gcDetectorsHID = false;
	gcDetectorsPID = false;
	gcDetectorsPDD = false;
	gcDetectorsTID = false;
	gcDetectorsMS = false;
	gcDetectorsMSMS = false;
	gcDetectorsMSn = false;
	gcDetectorsHRMS = false;
	gcDetectorsTCD = false;
	gcDetectorsOther = false;
}

export class ElementalAnalysis {
	elementalFAAS = false;
	elementalETAAS = false;
	elementalCVAAS = false;
	elementalHGAAS = false;
	elementalAMA = false;
	elementalICPAES = false;
	elementalLAICPAES = false;
	elementalICPMS = false;
	elementalLAICPMS = false;
	elementalICPMSMS = false;
	elementalHRICPMS = false;
	elementalXRF = false;
	elementalMicroXRF = false;
	elementalTXRF = false;
	elementalNAA = false;
	elementalOther = false;
}
export class SpeciationAnalysis {
	speciationHPLCICPAES = false;
	speciationHPLCICPMS = false;
	speciationHPLCAFS = false;
	speciationLCMS = false;
	speciationGCICPMS = false;
	speciationGCMS = false;
	speciationCEICPMS = false;
	speciationElectrochemistryTechniques = false;
	speciationOther = false;
}

export class IsotopicAnalysis {
	isotopicIRMS = false;
	isotopicMCICPMS = false;
	isotopicTandemMassSpectrometer = false;
	isotopicTIMS = false;
	isotopicNMR = false;
	isotopicNAA = false;
	isotopicOther = false;
}
export class MolecularAnalysis {
	molecularUVVis = false;
	molecularIR = false;
	molecularFTIR = false;
	molecularRamanSpectroscopy = false;
	molecularFluorescenceSpectroscopy = false;
	molecularEPR = false;
	molecularNMR = false;
	molecularOther = false;
}
export class NanoparticleAnalysis {
	nanoparticleA4FMALLS = false;
	nanoparticleA4FICPMS = false;
	nanoparticleSPICPMS = false;
	nanoparticleDLS = false;
	nanoparticleElectronMicroscopeTechniques = false;
	nanoparticleBET = false;
	nanoparticleELS = false;
	nanoparticleOther = false;
}
export class SurfaceAnalysis {
	surfaceAESSAM = false;
	surfaceAPFIM = false;
	surfaceAPMAXMA = false;
	surfaceFESEMEDX = false;
	surfaceSIMS = false;
	surfaceTDSMS = false;
	surfaceTOFSIMS = false;
	surfaceXPSESCA = false;
	surfaceLowLevelAlphaParticle = false;
	surfaceOther = false;
}
export class SolidSurfaceAnalysis {
	solidSurfaceAFM = false;
	solidSurfaceSEM = false;
	solidSurfaceTEM = false;
	solidSurfaceImageAnalysisSystem = false;
	solidSurfaceOther = false;
}
export class Microscopes {
	microscopesCM = false;
	microscopesFM = false;
	microscopesOM = false;
}

export class SolidStructureAnalysis {
	solidESR = false;
	solidNMR = false;
	solidRamanSpectrometer = false;
	solidXRD = false;
	solidMicroCT = false;
	solidPRTMS = false;
	solidOther = false;
}
export class ThermalAnalysis {
	thermalDEA = false;
	thermalDIL = false;
	thermalDMA = false;
	thermalDSC = false;
	thermalDTA = false;
	thermalEGA = false;
	thermalLFA = false;
	thermalTGA = false;
	thermalTMA = false;
	thermalOther = false;
}
export class Rheology {
	rheologyUtube = false;
	rheologyFallingSphere = false;
	rheologyRotationalViscosimeters = false;
	rheologyRheometers = false;
	rheologyOther = false;
}

export class SensorAnalysis {
	sensoryElectronicNose = false;
	sensoryElectronicTongue = false;
	sensoryGCO = false;
	sensoryElectronicVisualAnalyser = false;
	sensoryPanelTests = false;
	sensoryOther = false;
}

export class BiotechnologyMolecular {
	biotechnologyMolecularPCR = false;
	biotechnologyMolecularRTPCR = false;
	biotechnologyMolecularAPPCR = false;
	biotechnologyMolecularREPPCR = false;
	biotechnologyMolecularSequencer = false;
	biotechnologyMolecularDNAMicroarray = false;
	biotechnologyMolecularDGGE = false;
	biotechnologyMolecularRFLP = false;
	biotechnologyMolecularRibotyping = false;
	biotechnologyMolecularRAPD = false;
	biotechnologyMolecularPFGE = false;
	biotechnologyMolecularMEE = false;
	biotechnologyMolecularMLST = false;
	biotechnologyMolecularElectrophoreticTest = false;
	biotechnologyMolecularOther = false;
}

export class BiotechnologyBiosensors {
	biotechnologyBiosensorsOpticalBiosensors = false;
	biotechnologyBiosensorsPiezoelectricBiosensors = false;
	biotechnologyBiosensorsImmunosensors = false;
	biotechnologyBiosensorsElecthrochemicalBiosensors = false;
	biotechnologyBiosensorsOther = false;
}

export class BiotechnologyImmunoenzymatic {
	biotechnologyImmunoenzymaticELISA = false;
	biotechnologyImmunoenzymaticELFA = false;
	biotechnologyImmunoenzymaticOther = false;
}
export class BiotechnologyOthers {
	biotechnologyOthersMicrobialVolatile = false;
	biotechnologyOthersBioanalyser = false;
	biotechnologyOthersOther = false;
}

export class Microbiology {
	microbiologyAutoclaveSteriliser = false;
	microbiologyAntimicrobialSusceptibilityTest = false;
	microbiologyGramStrainer = false;
	microbiologyLiquidMediaPreparation = false;
	microbiologyPetriDishFiller = false;
	microbiologyColonyCounters = false;
	microbiologyCellCultureMedia = false;
	microbiologyCO2Incubator = false;
	microbiologyCompoundMicroscope = false;
	microbiologyMicrobialIdentificationSystem = false;
	microbiologyFlowCitometry = false;
	microbiologyGrowthChambers = false;
	microbiologyGerminationChambers = false;
	microbiologyOther = false;
}

export class Devices {
	devicesTechnicalBalance = false;
	devicesAnalyticalBalance = false;
	devicesOvenMuffleFurnace = false;
	devicesFridge4 = false;
	devicesFreezerMinus20 = false;
	devicesMinus80 = false;
	dedicatedCentrifugeUltracentrifuge = false;
	devicesHeatingBath = false;
	devicesUltrasoundBath = false;
	devicesIncubator = false;
	devicesMillerGrinder = false;
	devicesSiever = false;
	devicesMixer = false;
	devicesHomogeniser = false;
	devicesLyophilizer = false;
	devicesSprayDrier = false;
	devicesRotaryEvaporator = false;
	devicesSPME = false;
	devicesASE = false;
	devicesMicrowaveDigestionSystem = false;
	devicesMicrowaveExtractionSystem = false;
	devicesUltrawaveDigestionSystem = false;
	deviceLaserAblationSystem = false;
	devicesOther = false;
}
