<nz-table #table [nzData]="data?.data" [nzFrontPagination]="false" [nzTotal]="data?.total" [nzPageIndex]="data?.page" nzPageSize="10"
					(nzQueryParams)="changeQueryParams($event)">
	<thead>
	<tr>
		<th *ngFor="let header of headers" [nzColumnKey]="header.columnKey" [nzSortFn]="header.sortFn" [nzWidth]="header.width">
			{{ header.label }}
		</th>
	</tr>
	</thead>
	<tbody>
	<ng-container *ngFor="let data of table.data">
		<ng-template [ngTemplateOutlet]="trTemplate" [ngTemplateOutletContext]="{ $implicit: data }"></ng-template>
	</ng-container>
	</tbody>
</nz-table>
