export enum CountriesEnum {
	IT = 'Italy',
	RO = 'Romania',
	FR = 'France',
	PT = 'Portugal',
	ES = 'Spain',
	BE = 'Belgium',
	SI = 'Slovenia',
	GR = 'Greece',
	CZ = 'Czechia',
	MK = 'North Macedonia',
	HU = 'Hungary',
	NO = 'Norway',
	FI = 'Finland',
	TR = 'Turkey',
	DE = 'Germany',
	MD = 'Moldova',
	CH = 'Switzerland',
	NL = 'Netherlands'
}
export enum CountryCurrencyEnum {
	IT = 'EUR',
	RO = 'RON',
	FR = 'EUR',
	PT = 'EUR',
	ES = 'EUR',
	BE = 'EUR',
	SI = 'EUR',
	GR = 'EUR',
	CZ = 'CZK',
	MK = 'MKD',
	HU = 'HUF',
	NO = 'NOK',
	FI = 'EUR',
	TR = 'TRY',
	DE = 'EUR',
	MD = 'MDL',
	CH = 'CHF',
	NL = 'EUR'
}
