export const sector = [
	{
		isActive: false,
		number: 0,
		name: 'MATRIX_RMS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PROCESS_INTERMEDIATE',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PURE_SUBSTANCES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'SPIKED_RMS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'OTHER',
	},
]

export const pureSubst = [
	{
		isActive: false,
		number:0 ,
		name: 'SOLUTIONS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'POWDERS',
	},
]

export const procInterRms = [
	{
		isActive: false,
		number: 0,
		name: 'PROCESS_INTERMEDIATE_RM',
	},
]

export const matrixes = [
	{
		isActive: false,
		number: 0,
		name: 'FOODS_ANIMAL_ORIGIN',
	},
	{
		isActive: false,
		number: 0,
		name: 'FOODS_PLANT_ORIGIN',
	},
	{
		isActive: false,
		number: 0,
		name: 'PREPARED_FOOD_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'BEVERAGES_SUPPLEMENTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'ADDITIVES_SUPPLEMENTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'FOOD_CONTACT_MATERIAL',
	},
	{
		isActive: false,
		number: 0,
		name: 'AGRICULTURAL_NON_FOOD',
	},
	{
		isActive: false,
		number: 0,
		name: 'FEEDSTUFF',
	},
	{
		isActive: false,
		number: 0,
		name: 'ENVIRONMENTAL_MATRIXES',
	},
	{
		isActive: false,
		number: 0,
		name: 'MATRIX_OTHER',
	},
]

export const additivesSpecify  = [
	{
		isActive: false,
		number: 0,
		name: 'ADDITIVES_SUPLEMENTS_SPEC',
	},
]

export const feedStuffsSpecify  = [
	{
		isActive: false,
		number: 0,
		name: 'FEEDSTUFF_ADDITIVES_SUPPLEMENTS',
	},
]

export const foodsAnimal  = [
	{
		isActive: false,
		number: 0,
		name: 'MEATS_POULTRY_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'FISH_PRODUCT',
	},
	{
		isActive: false,
		number: 0,
		name: 'MILK_DIARY_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'HONEY_BEE_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'FATS_ANIMAL_ORIGIN',
	},
	{
		isActive: false,
		number: 0,
		name: 'FOOD_ANIMALS_OTHER',
	},
]

export const foodsPlant =[
	{
		isActive: false,
		number: 0,
		name: 'CEREALS_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'FRUIT_VEGETABLE',
	},
	{
		isActive: false,
		number: 0,
		name: 'OLIVES_OILS',
	},
	{
		isActive: false,
		number: 0,
		name: 'SUGAR_PLANTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'HERBS_SALT',
	},
	{
		isActive: false,
		number: 0,
		name: 'COCA_COFFE',
	},
	{
		isActive: false,
		number: 0,
		name: 'PLANTS_OTHER',
	},
]

export const beverages = [
	{
		isActive: false,
		number: 0,
		name: 'DRINK_WATER',
	},
	{
		isActive: false,
		number: 0,
		name: 'NON_ALCOHOLIC_BEVERAGES',
	},
	{
		isActive: false,
		number: 0,
		name: 'WINE',
	},
	{
		isActive: false,
		number: 0,
		name: 'BEER_CIDER',
	},
	{
		isActive: false,
		number: 0,
		name: 'ALCOHOLIC_BEVERAGES',
	},
	{
		isActive: false,
		number: 0,
		name: 'FRUIT_VEGETABLE_JUICES',
	},
	{
		isActive: false,
		number: 0,
		name: 'VINEGARS',
	},
	{
		isActive: false,
		number: 0,
		name: 'BEVERAGES_OTHER',
	},
]

export const fcm = [
	{
		isActive: false,
		number: 0,
		name: 'METALLIC_MATERIALS',
	},
	{
		isActive: false,
		number: 0,
		name: 'CERAMIC_MATERIALS',
	},
	{
		isActive: false,
		number: 0,
		name: 'PLASTIC_MATERIALS',
	},
	{
		isActive: false,
		number: 0,
		name: 'FCM_OTHER',
	},
]

export const agricultural = [
	{
		isActive: false,
		number: 0,
		name: 'PRODUCTS_TEXTILE_APPS',
	},
	{
		isActive: false,
		number: 0,
		name: 'TOBACCO',
	},
	{
		isActive: false,
		number: 0,
		name: 'OTHER_ARGICULTURAL',
	},
]

export const environmental = [
	{
		isActive: false,
		number: 0,
		name: 'SOILS_SEDIMENTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'GROUNDWATER',
	},
	{
		isActive: false,
		number: 0,
		name: 'FRESHWATER',
	},
	{
		isActive: false,
		number: 0,
		name: 'SEAWATER',
	},
	{
		isActive: false,
		number: 0,
		name: 'AIR_PARTICULATE_MATTER',
	},
	{
		isActive: false,
		number: 0,
		name: 'BIOLOGICAL_SAMPLES',
	},
	{
		isActive: false,
		number: 0,
		name: 'ENVIRONMENTAL_OTHER',
	},
]

export const prepareFoods = [
	{
		isActive: false,
		number: 0,
		name: 'CANNED_FRUITS',
	},
	{
		isActive: false,
		number: 0,
		name: 'BREAD_BAKERY',
	},
	{
		isActive: false,
		number: 0,
		name: 'PASTA',
	},
	{
		isActive: false,
		number: 0,
		name: 'SAUCES',
	},
	{
		isActive: false,
		number: 0,
		name: 'PREPARED_DISHES',
	},
	{
		isActive: false,
		number: 0,
		name: 'INSTANT_FOODS',
	},
	{
		isActive: false,
		number: 0,
		name: 'TOTAL_DIET',
	},
	{
		isActive: false,
		number: 0,
		name: 'PREPARED_FOOD_OTHER',
	},
]
