import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService as BaseConfigService } from '@metro/ui-core';
import { Config } from '../types/config';

@Injectable({
	providedIn: 'root'
})
export class ConfigService extends BaseConfigService<Config> {
	protected constructor(http: HttpClient) {
		super(http);
	}
}
