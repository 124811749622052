import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Facility, FacilityFinances } from '@metro-membership/data-access';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacilityService } from '../../data/services/facility.service';
import { CustomValidator } from '../../shared/validators/percentage.validator';
import { CountryCurrencyEnum } from '@metro-membership/data-access/enums';

@Component({
	selector: 'metro-membership-facility-finances',
	templateUrl: './facility-finances.page.html',
	styleUrls: ['./facility-finances.page.less']
})
export class FacilityFinancesPage {
	questionForm: FormGroup;
	unSubscribe = new Subject();
	facility: Facility;
	facilityQuestions: FacilityFinances;
	facilityId = '';
	showForm = false;

	currency: Set<string> = new Set();
	currencyList: string[] = [];

	constructor(
		private fb: FormBuilder,
		private facilityService: FacilityService,
		private activatedRoute: ActivatedRoute
		) {
			this.currencyList = Object.values(CountryCurrencyEnum);
			for (const curr of this.currencyList) {
				this.currency.add(curr);
			}

			this.activatedRoute.parent.params
			.pipe(takeUntil(this.unSubscribe))
			.subscribe(params => {
				this.facilityId = params.facilityId;
				this.buildForm();
				this.getQuestions();
			});
		}

		get isEditable() {
			return this.questionForm.enabled as boolean;
		}

		get maintenance() {
			return this.questionForm.get('maintenanceThanNet').value;
		}

		get consumables() {
			return this.questionForm.get('consumablesThanNet').value;
		}

		getQuestions() {
			this.facilityService.fetchFacilityFinances(parseInt(this.facilityId, 10)).subscribe(facilityFinances => {
				this.facilityQuestions = facilityFinances;
				if (this.facilityQuestions) {
					this.setForm();
				}
			});

		}

		onEdit() {
			this.questionForm.enable();
		}

		onSave() {
			if (!this.questionForm.valid) {
				return true;
			}
			if (this.facilityQuestions?.id) {
				this.editQuestions();
			} else {
				this.addQuestions();
			}
		}

		editQuestions() {
			const questionsBody = this.questionForm.getRawValue() as FacilityFinances;
			this.facilityService.editFacilityFinances(questionsBody, this.facilityQuestions.facilityId).subscribe(res => {
				this.questionForm.disable();
				this.getQuestions();
			});

		}

		addQuestions() {
			const questionsBody = this.questionForm.getRawValue() as FacilityFinances;
			this.facilityService.addFacilityFinances(questionsBody).subscribe(res => {
				this.questionForm.disable();
				this.getQuestions();
			});
		}

		buildForm() {
			this.questionForm = this.fb.group({
				facilityId: this.facilityId,
				maintenanceThanNet: true,
				consumablesThanNet: true,

				netBookValue: [null, Validators.compose([ Validators.required, CustomValidator.digitsValidator(), CustomValidator.dotValidator(), CustomValidator.fractionsValidator() ] ) ],
				netBookValueCurr:  ['EUR'],

				consumablesCosts: ['',Validators.compose([ Validators.required, CustomValidator.digitsValidator(), CustomValidator.dotValidator(), CustomValidator.fractionsValidator() ] ) ],
				maintenanceCosts: ['',Validators.compose([ Validators.required, CustomValidator.digitsValidator(), CustomValidator.dotValidator(), CustomValidator.fractionsValidator() ] ) ],

				maintenanceCostsCurr: ['EUR'],
				consumablesCostsCurr: ['EUR']
			});
			this.questionForm.disable();
			this.showForm = true;
		}

		setForm() {
			this.questionForm.patchValue({
				facilityId: this.facilityId ? this.facilityId : '',
				maintenanceThanNet:  this.facilityQuestions?.maintenanceCostsCurr === '%' ? false : true,
				consumablesThanNet: this.facilityQuestions?.consumablesCostsCurr === '%' ? false : true,

				netBookValue: this.facilityQuestions?.netBookValue ? this.facilityQuestions.netBookValue : '',
				netBookValueCurr:  this.facilityQuestions?.netBookValueCurr ? this.facilityQuestions.netBookValueCurr : 'EUR',

				maintenanceCosts: this.facilityQuestions?.maintenanceCosts ? this.facilityQuestions.maintenanceCosts: '',
				consumablesCosts: this.facilityQuestions?.consumablesCosts ? this.facilityQuestions.consumablesCosts : '',

				maintenanceCostsCurr:  this.facilityQuestions?.maintenanceCostsCurr ? this.facilityQuestions.maintenanceCostsCurr : 'EUR',
				consumablesCostsCurr: this.facilityQuestions?.consumablesCostsCurr ? this.facilityQuestions.consumablesCostsCurr : 'EUR',

			});
			this.registerEvents();

		}

		registerEvents() {
			this.questionForm.get('maintenanceThanNet').valueChanges.subscribe(res => {
				if (res) {
					this.questionForm.get('maintenanceCostsCurr').setValue('EUR');
				} else {
					this.questionForm.get('maintenanceCostsCurr').setValue('%');
				}
			});
			this.questionForm.get('consumablesThanNet').valueChanges.subscribe(res => {
				if (res) {
					this.questionForm.get('consumablesCostsCurr').setValue('EUR');
				} else {
					this.questionForm.get('consumablesCostsCurr').setValue('%');
				}
			});
		}
	}
