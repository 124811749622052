import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'metro-membership-not-found',
	templateUrl: './not-found.page.html',
	styleUrls: ['./not-found.page.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotFoundPage {
	@Input() title = 'Unassigned';
	@Input() subtitle = 'Sorry, no institute is assigned to you.';

	constructor(private location: Location) { }

	back() {
		this.location.back();
	}
}
