<form action="" class="mt-2" [formGroup]="upgradeForm" *ngIf="showForm">
	<div class="d-flex justify-content-between">
		<h1>Plans for upgrade</h1>
		<div>
			<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
				<div>EDIT</div>
			</button>

			<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" [ngClass]="{'disabled': upgradeForm.invalid }">
				<div>SAVE</div>
			</button>
		</div>
	</div>


	<div class="input-wrapper mt-5">

		<label nz-checkbox formControlName="renewal">
			<h4 class="mb-2">Plans for acquisition / renewal of the currently available equipment:</h4>
		</label>

	</div>

	<div class="input-wrapper mt-5">
		<h4 class="mb-2">New equipment to be acquired:</h4>
		<metro-form-input class="standard" type="text"
			ph="Comment" formControlName="newEquip"  [control]="upgradeForm.controls.newEquip"
		></metro-form-input>
	</div>


	<div class="input-wrapper mt-5" *ngIf="facility.type === facilityType.Primary_Production">

		<label nz-checkbox formControlName="newRealisation">
			<h4 class="mb-2">Plans for realisation of a new experimantal {{facilityTypeTranslations[facility.type.toUpperCase()]}} facility</h4>
		</label>

		<h4 class="mb-2">New facility to be realised:</h4>
		<metro-form-input class="standard" type="text"
			ph="Comment" formControlName="newFacility"  [control]="upgradeForm.controls.newFacility"
		></metro-form-input>

	</div>

	<div class="input-wrapper mt-5" *ngIf="facility.type === facilityType.Processing_And_Kitchen_Lab">

		<label nz-checkbox formControlName="newRealisation">
			<h4 class="mb-2">Plans for realisation of a new experimantal {{facilityTypeTranslations[facility.type.toUpperCase()]}} facility</h4>
		</label>

		<h4 class="mb-2">Processing line for:</h4>
		<metro-form-input class="standard" type="text"
			ph="Comment" formControlName="NewProcessingLine"  [control]="upgradeForm.controls.NewProcessingLine"
		></metro-form-input>

		<label nz-checkbox formControlName="NewKLRealisation" class="mt-5">
			<h4 class="mb-2">Plans for realisation of a new kitchen lab:</h4>
		</label>

		<h4 class="mb-2">Specification on the new planned kitchen lab:</h4>
		<metro-form-input class="standard" type="text"
			ph="Comment" formControlName="newKL"  [control]="upgradeForm.controls.newKL"
		></metro-form-input>

	</div>

	<div class="input-wrapper mt-5"  *ngIf="facility.type === facilityType.RM_Facility">
		<label nz-checkbox formControlName="newLine">
			<h4 class="mb-2">Plans for realization of a new production line</h4>
		</label>

		<h4 class="mb-2">Production line for:</h4>
		<metro-form-input class="standard" type="text"
			ph="Comment" formControlName="newLine2"  [control]="upgradeForm.controls.newLine2"
		></metro-form-input>
	</div>
</form>

