import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Facility, FacilityRi } from '@metro-membership/data-access';
import { FacilityTypeTranslations } from '@metro-membership/data-access/enums';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacilityService } from '../../data/services/facility.service';
import { CustomValidator } from '../../shared/validators/percentage.validator';

@Component({
  selector: 'metro-membership-facility-percent-ri',
  templateUrl: './facility-percent-ri.page.html',
  styleUrls: ['./facility-percent-ri.page.less']
})
export class FacilityPercentRiPage {
	facilityType = FacilityTypeTranslations;

	riForm: FormGroup;
	unSubscribe = new Subject();
	facility: Facility;
	facilityRi: FacilityRi;
	facilityId = '';
	showForm = false;

	constructor(private fb: FormBuilder,
		private facilityService: FacilityService,
		private activatedRoute: ActivatedRoute){
			this.activatedRoute.parent.params
			.pipe(takeUntil(this.unSubscribe))
			.subscribe(params => {
				this.facilityId = params.facilityId;
				this.buildForm();
				this.getPercentage();
			});
	}
	get isEditable() {
		return this.riForm.enabled as boolean;
	}
	onEdit() {
		this.riForm.enable();
	}

	onSave() {
		if (!this.riForm.valid) {
			return true;
		}
		if (this.facilityRi?.id) {
			this.editHrData();
		} else {
			this.addHrData();
		}
	}
	addHrData() {
		const riBody = this.riForm.getRawValue() as FacilityRi;
		this.facilityService.addFacilityRi(riBody).subscribe(riRespons => {
			this.facilityRi = riRespons;
			this.riForm.disable();
		})
	}
	editHrData() {
		const riBody = this.riForm.getRawValue() as FacilityRi;
		this.facilityService.editFacilityRi(riBody, riBody.id).subscribe(riRespons => {
			this.facilityRi = riRespons;
			this.riForm.disable();
		});

	}
	getPercentage() {
		const facilityId = parseInt(this.facilityId, 0)
		this.facilityService.fetchFacilityRi(facilityId).toPromise().then(facilitiesJson => {
			this.facilityRi = facilitiesJson;
			this.setForm();
		});
	}
	setForm() {
		this.riForm.patchValue({
			timeMachine: [this.facilityRi.timeMachine || null],
			addTimeMachine: [this.facilityRi.addTimeMachine || null],
		})
	}
	buildForm() {
		this.riForm = this.fb.group({
			facilityId: this.facilityId,
			timeMachine: [ null, Validators.compose([Validators.required, CustomValidator.digitsValidator(), CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
			addTimeMachine: [ null, Validators.compose([Validators.required])],
		});
		this.riForm.disable();
		this.showForm = true;
	}
}
