import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Facility, FacilityServiceEntity } from '@metro-membership/data-access';
import { debounceTime } from 'rxjs/operators';
import { FacilityService } from '../../../data/services/facility.service';
import { serviceDetails, tasksServices, stability, prepareRm} from './rm-services';
@Component({
	selector: 'metro-membership-rm-services',
	templateUrl: './rm-services.component.html',
	styleUrls: ['./rm-services.component.less']
})
export class RmServicesComponent implements OnInit {

	@Output() formChanges: EventEmitter<FacilityServiceEntity[]> = new EventEmitter<FacilityServiceEntity[]>();
	@Input() facility = { } as Facility;
	@Input() formGroup: FormGroup;
	showForm = false;
	facilityServices: FacilityServiceEntity;
	serviceDetails = serviceDetails;
	tasksServices = tasksServices;
	stability = stability;
	prepareRm = prepareRm;

	formLoaded = false;

	constructor(private formBuilder: FormBuilder, private facilityService: FacilityService) { }

	ngOnInit(): void {
		this.buildForm();
		this.fetchServices();
	}

	fetchServices() {
		this.facilityService.fetchFacilityServices(this.facility.id).subscribe(res => {
			this.facilityServices = res;
			this.setForm();
		}, () => { this.setForm() })
	}

	setForm() {
		this.formGroup.patchValue({
			preparationOfRm: this.facilityServices?.preparationOfRm || null,
			storage: this.facilityServices?.storage || null,
			managementInterlaboratoryStudies : this.facilityServices?.managementInterlaboratoryStudies || null,
			facilityInvolvedFeasibilityStudies: this.facilityServices?.facilityInvolvedFeasibilityStudies || null,
			facilityInvolvedProduction: this.facilityServices?.facilityInvolvedProduction || null,
			facilityInvolvedBigScaleProduction: this.facilityServices?.facilityInvolvedBigScaleProduction || null,
			facilityInvolvedStability: this.facilityServices?.facilityInvolvedStability || null,
			facilityInvolvedOther: this.facilityServices?.facilityInvolvedOther || null,
			facilityInvolvedOtherComment: this.facilityServices?.facilityInvolvedOtherComment || null,
			stabilityTestLuminous: this.facilityServices?.stabilityTestLuminous || null,
			stabilityTestThermal: this.facilityServices?.stabilityTestThermal || null,
			stabilityHumidityTest: this.facilityServices?.stabilityHumidityTest || null,
			stabilityOtherTest: this.facilityServices?.stabilityOtherTest || null,
			stabilityOtherTestComment: this.facilityServices?.stabilityOtherTestComment || null,

			ALInvolvedInRmCharacterisation: this.facilityServices?.ALInvolvedInRmCharacterisation || null,
			ALForHomogeneityStudies: this.facilityServices?.ALForHomogeneityStudies || null,

			analyticalTechnique: this.facilityServices?.analyticalTechnique || null,
			proceduresRmCertification: this.facilityServices?.proceduresRmCertification || null,
			proceduresRmCertificationComment: this.facilityServices?.proceduresRmCertificationComment || null,
			proceduresRmPreparation: this.facilityServices?.proceduresRmPreparation || null,
			proceduresRmPreparationComment: this.facilityServices?.proceduresRmPreparationComment || null,

			prepareRmSolid: this.facilityServices?.prepareRmSolid || null,
			prepareRmLiquid: this.facilityServices?.prepareRmLiquid || null,
			prepareRmGas: this.facilityServices?.prepareRmGas || null,

			solidRmObtainedWithDehydration: this.facilityServices?.solidRmObtainedWithDehydration || null,
			solidRmObtainedWithoutDehydration: this.facilityServices?.solidRmObtainedWithoutDehydration || null,

			beforeDehydration: this.facilityServices?.beforeDehydration || null,
			afterDehydration: this.facilityServices?.afterDehydration || null,

			productionScalePlantPerYear: this.facilityServices?.productionScalePlantPerYear || null,
			productionScalePlantPerYearComment: this.facilityServices?.productionScalePlantPerYearComment || null,

			productionScalePlantAliquots: this.facilityServices?.productionScalePlantAliquots || null,
			productionScalePlantAliquotsComment: this.facilityServices?.productionScalePlantAliquotsComment || null,

			productionScalePlantAliquotsDimension: this.facilityServices?.productionScalePlantAliquotsDimension || null,
			productionScalePlantAliquotsDimensionComment: [this.facilityServices?.productionScalePlantAliquotsDimensionComment || null],

			dedicatedProductionLines: this.facilityServices?.dedicatedProductionLines || null,

			linesSpecific2: this.facilityServices?.linesSpecific2 || null,
			linesSpecific: this.facilityServices?.linesSpecific || null,
			linesSpecificMatrixTypes: this.facilityServices?.linesSpecificMatrixTypes || null,

			liquidMaterials: this.facilityServices?.liquidMaterials || null,
			solidDryPowders: this.facilityServices?.solidDryPowders || null,
			solidDehydrated: this.facilityServices?.solidDehydrated || null,
		});
		this.formLoaded = true;
	}

	buildForm() {
		this.formGroup = this.formBuilder.group({
			facilityId: this.facility.id || null,
			preparationOfRm: '',
			storage: '',
			managementInterlaboratoryStudies : '',
			facilityInvolvedFeasibilityStudies: '',
			facilityInvolvedProduction: '',
			facilityInvolvedBigScaleProduction: '',
			facilityInvolvedStability: '',
			facilityInvolvedOther: null,
			facilityInvolvedOtherComment: null,
			stabilityTestLuminous: null,
			stabilityTestThermal: null,
			stabilityHumidityTest: null,
			stabilityOtherTest: null,
			stabilityOtherTestComment: null,

			ALInvolvedInRmCharacterisation: null,
			ALForHomogeneityStudies: null,

			analyticalTechnique: null,
			proceduresRmCertification: null,
			proceduresRmCertificationComment: null,
			proceduresRmPreparation: null,
			proceduresRmPreparationComment: null,

			prepareRmSolid: null,
			prepareRmLiquid: null,
			prepareRmGas: null,

			solidRmObtainedWithDehydration: null,
			solidRmObtainedWithoutDehydration: null,

			beforeDehydration: null,
			afterDehydration: null,

			productionScalePlantPerYear: null,
			productionScalePlantPerYearComment: null,

			productionScalePlantAliquots: null,
			productionScalePlantAliquotsComment: null,

			productionScalePlantAliquotsDimension: null,
			productionScalePlantAliquotsDimensionComment: null,

			dedicatedProductionLines: null,
			linesSpecific2: null,
			linesSpecific: null,
			linesSpecificMatrixTypes: null,

			liquidMaterials: null,
			solidDryPowders: null,
			solidDehydrated: null

		});
		this.showForm = true;
		this.formGroup.valueChanges
		.pipe(debounceTime(500))
		.subscribe(changes => {
			this.formChanges.next(changes);
		});
	}

	changeDisability(control: AbstractControl){
		if(!this.formLoaded) {
			return true;
		}

		if(control.disabled){
			control.enable();
		} else {
			control.disable();
		}
	}

}
