import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class QualityFormsService {

  constructor(private formBuilder: FormBuilder) {

  }

	facilityServicesForm(facilityId: string) {
		return this.formBuilder.group({
			facilityId:facilityId,
			samplePreparationStorage: null,
			foodCompositionCharacterization: null,
			inorganicContaminant: null,
			organicContaminants: null,
			chemicalBiologicalMarkersProfiles: null,
			microbiologicalAnalysis: null,
			allergenTesting: null,
			geneticAnalyses: null,
			characterizationFCMMigrationTests: null,
			analysisFoodAdditivesSupplements: null,
			developmentSensorsDevices: null,
			agroecoSystemCharacterisation: null,
			testing: null,
			status: null,
			preparationOfRm: null,
			storage: null,
			managementInterlaboratoryStudies : null,
		})

	}

	facilityQualityForm(facilityId: string): FormGroup {
		return this.formBuilder.group({
			facilityId: facilityId,
			qualityManagerSystemQuestionStatus: [null, Validators.required],
			qualityManagerSystemQuestionSubStatus: null,
			qualityManagerSystemQuestion: null,
			qualityManagerSystemQuestionComment: null,

			accreditationSubStatus: null,
			sustainableFood: null,
			accreditationStatus: [null, Validators.required],
			accreditationISO15189: null,
			accreditationISO17025: null,
			accreditationISO17020: null,
			accreditationISO17043: null,
			accreditationISO17065: null,
			accreditationISO17034: null,
			accreditationOther: null,
			accreditationOtherComment:null,

			guidanceEurachemTraceability: null,
			guidanceEurachemQuantify: null,
			guidanceISO30: null,
			guidanceISO31: null,
			guidanceISO32: null,
			guidanceISO33: null,
			guidanceISO34: null,
			guidanceISO35: null,
			guidanceISO80: null,
			guidanceOther: null,
			guidanceOtherComment:  null,

			accreditedCalibration: null,
			accreditedTestingLab: null,
			accreditedRM: null,
			accreditedProficiencyTest: null,
			accreditedOther: null,
			accreditedOtherComment:  null,

			serviceAccreditationStatus: null,

			certificationStatus: [null, Validators.required],
			certificationSubStatus: null,
			certificationISO9000: null,
			certificationISO14001: null,
			certificationionOther: null,
			certificationOtherComment: null,

			qualityControlActivities: [null, Validators.required],

			SOPSubStatus: null,
			SOPStatus: [null, Validators.required],
			SOPWorkInstruction: null,
			SOPIntroductionLabEq: null,
			SOPCalibrationProcedure: null,
			SOPManagementLabEq: null,
			SOPResearchLabs: null,
			SOPOther: null,
			SOPOtherComment: null,

			personnelTrainingStatus: [null, Validators.required],
			personnelTrainingQuality: null,
			personnelTrainingSecurity: null,
			personnelTrainingTechnical: null,
			personnelTrainingUpdate: null,
			personnelTrainingInstruments: null,
			personnelTrainingTempSuper: null,
			personnelTrainingPerSuper: null,
			personnelTrainingQuiz: null,
			personnelTrainingOther: null,
			personnelTrainingOtherComment: null,

			linkedResearchStatus: [null, Validators.required],
			linkedResearchTeaching: null,
			linkedResearchStatusOther: null,
			linkedResearchCostumers: null,
			linkedResearchOther: null,
			linkedResearchOtherComment: null,

			qualityAssuranceStatus: [null, Validators.required],
			qualityAssuranceSubStatus: null,
			qualityAssuranceManual: null,
			qualityAssuranceProcedure: null,
			qualityAssuranceValidation: null,
			qualityAssuranceControl: null,
			qualityAssuranceQualification: null,
			qualityAssuranceTraining: null,
			qualityAssuranceSuperVision: null,
			qualityAssuranceRingTests: null,
			qualityAssuranceCertRm: null,
			qualityAssuranceAnalyticalStand: null,
			qualityAssuranceBlanks: null,
			qualityAssuranceSurrogates: null,
			qualityAssuranceInstrumental: null,
			qualityAssuranceIndependentStand: null,
			qualityAssuranceRepeatability: null,
			qualityAssuranceRecovery: null,
			qualityAssuranceCalibration: null,
			qualityAssuranceOther: null,
			qualityAssuranceOtherComment: null,

			qms: null,
			qmsStatus: [null, Validators.required],
			qmsSubStatus: null,

		});
	}

  instituteQualityForm(instituteId: string): FormGroup {
    return this.formBuilder.group({
      instituteId: instituteId,
      qualityManagerSystemQuestionStatus:[ null, Validators.required ],
      qualityManagerSystemQuestionSubStatus: null,
      qualityManagerSystemQuestion: null,
      qualityManagerSystemQuestionComment: null,

      instituteCertificationStatus:[ null, Validators.required ],
      instituteCertificationISO9000: null,
      instituteCertificationISO14001: null,
      instituteCertificationOther: null,
      instituteCertificationOtherComment: null,
      instituteCertificationSubStatus: null,

      certificationCostsGeneralCost: null,
      certificationCostsFacilityBudget: null,
      certificationCostsOther: null,
      certificationCostsOtherComment: null,

      servicesExternalUsersStatus: [null, Validators.required],

      externalUsersSatisfactionSurvey: null,
      externalUsersSatisfactionMeeting: null,
      externalUsersSatisfactionOther: null,
      externalUsersSatisfactionOtherComment: null,

      handlingCustomerComplaintsStatus: null,
      handlingCustomerComplaintsSubStatus: null,

      applyingOperatingProcedureStatus: [null, Validators.required],
      applyingOperatingProcedureSubStatus: null,

      researchActivitiesStatus: [null, Validators.required],
      researchActivities: null,

      researchInfrastructuresStatus: [null, Validators.required],
      researchInfrastructures: null,

      researchDocumentationStatus: [null, Validators.required],
      researchDocumentationManagementSystem: null,
      researchDocumentationOperationalProcedure: null,
      researchDocumentationResearchActivities: null,
      researchDocumentationReportFellowships: null,
      researchDocumentationOther: null,
      researchDocumentationOtherComment: null,

      sopStatus: [null, Validators.required],
      sopPolicy: null,
      sopConfidentiality: null,
      sopConferences: null,
      sopPapers: null,
      sopTV: null,
      sopVisits: null,
      sopPartners: null,
      sopOpenAccess: null,
      sopAgroFoodSector: null,
      sopMeeting: null,
      sopWebConf: null,
      sopWorkShops: null,
      sopHomePageSite: null,
      sopTechnicalReports: null,
      sopPatent: null,
      sopOther: null,
      sopOtherComment: null,
      sopRecord:null,
      sopRecordStatus:[null, Validators.required],
      sopRecordSubStatus:null,
      audiTrailStatus: [null, Validators.required],
      audiTrailSubStatus:null,
      qms:null,
      qmsStatus: [null, Validators.required],
      qmsSubStatus: null,

    });
  }
}
