import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'metro-membership-under-development',
  templateUrl: './under-development.page.html',
  styleUrls: ['./under-development.page.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnderDevelopmentPage {
	@Input() showButton = true;

	constructor(private location: Location) { }

	back() {
		this.location.back();
	}
}
