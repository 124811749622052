<form action="" class="mt-2" [formGroup]="questionForm" *ngIf="showForm">
	<div class="d-flex justify-content-between">
		<h1>{{institute?.shortName }} - Institute financial data</h1>
		<div>
			<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
				<div>EDIT</div>
			</button>

			<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" [ngClass]="{'disabled': questionForm.invalid }">
				<div>SAVE</div>
			</button>
		</div>
	</div>

	<div class="title mt-12">Average monthly personnel costs (including monthly contributions and taxes) for staff category to be involved in METROFOOD-RI:</div>
	<div class="description">(Please fill in the personnel costs including any contributions and taxes, so you have to provide the average gross salaries per month grouped by the main categories: scientists,  lab technicians and temporary staff (we’ll take care then to perform the relevant calculation considering the FTE dedicated to METROFOOD-RI per each facility). You have the option to provide the related costs whether in your local currency or in euro (if you keep the accounting in euro, as well) by checking the currency from the drop down list. You can obtain the requested data from your accounting department.) </div>
	<div class="mt-8">Management</div>

	<div class="input-wrapper mt-2">
		<metro-form-input class="standard" type="text" unit="thousands"
		ph="Amount" formControlName="personnelManagmentCosts"  [control]="questionForm.controls.personnelManagmentCosts"
		></metro-form-input>
		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.personnelManagmentCosts.disabled}" >
			<nz-select  class="select" formControlName="personnelManagmentCostsCurr">
				<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
			</nz-select>
		</div>
	</div>


	<div class="mt-8">Scientists</div>

	<div class="input-wrapper mt-2">
		<metro-form-input class="standard" type="text" unit="thousands"
		ph="Amount" formControlName="personnelScientistsCosts"  [control]="questionForm.controls.personnelScientistsCosts"
		></metro-form-input>
		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.personnelScientistsCosts.disabled}" >
			<nz-select  class="select" formControlName="personnelScientistsCostsCurr">
				<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
			</nz-select>
		</div>
	</div>

	<div class="mt-8">Lab technicians</div>
	<div class="input-wrapper mt-2">
		<metro-form-input class="standard" type="text" unit="thousands"
		ph="Amount" formControlName="personnelLabCosts"  [control]="questionForm.controls.personnelLabCosts"
		></metro-form-input>
		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.personnelLabCosts.disabled}" >
			<nz-select  class="select" formControlName="personnelLabCostsCurr">
				<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
			</nz-select>
		</div>
	</div>

	<div class="mt-8">Temporary staff</div>
	<div class="input-wrapper mt-2">
		<metro-form-input class="standard" type="text" unit="thousands"
		ph="Amount" formControlName="personnelTempStaffCosts"  [control]="questionForm.controls.personnelTempStaffCosts"
		></metro-form-input>
		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.personnelTempStaffCosts.disabled}" >
			<nz-select  class="select" formControlName="personnelTempStaffCostsCurr">
				<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
			</nz-select>
		</div>
	</div>


	<div class="mt-8">Administrative staff</div>

	<div class="input-wrapper mt-2">
		<metro-form-input class="standard" type="text" unit="thousands"
		ph="Amount" formControlName="personnelAdminCosts"  [control]="questionForm.controls.personnelAdminCosts"
		></metro-form-input>
		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.personnelAdminCosts.disabled}" >
			<nz-select  class="select" formControlName="personnelAdminCostsCurr">
				<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
			</nz-select>
		</div>
	</div>

	<div class="mt-8">IT staff</div>

	<div class="input-wrapper mt-2">
		<metro-form-input class="standard" type="text" unit="thousands"
		ph="Amount" formControlName="ItStaffCosts"  [control]="questionForm.controls.ItStaffCosts"
		></metro-form-input>
		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.ItStaffCosts.disabled}" >
			<nz-select  class="select" formControlName="ItStaffCostsCurr">
				<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
			</nz-select>
		</div>
	</div>

</form>

<div class="description mt-5">Please note that only the PI, Finances Data Manager and Deputy can see Finances link and edit this section.</div>

