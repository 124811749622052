import { AbstractControl, Validators } from "@angular/forms";

export function isControlEmpty(val){
	return (val === undefined || val == null ) ? null : val;
}


export function setStandardControlsValidators(mainControl: AbstractControl, trueControl?: AbstractControl, falseControl?: AbstractControl) {
	mainControl.valueChanges.subscribe(change => {
		if(change) {
			if(trueControl) { trueControl.setValidators(Validators.required) }
			if(falseControl) {
				falseControl.clearValidators();
				falseControl.updateValueAndValidity();
			}
		} else {
			if(falseControl) { falseControl.setValidators(Validators.required) }
			if(trueControl) {
				trueControl.clearValidators();
				trueControl.updateValueAndValidity();
			}
		}
	});
}
