import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';

@Injectable({
	providedIn: 'root'
})
export class FacilityEquipmentService {
	constructor(
		private formBuilder: FormBuilder
	) { }

	primaryProduction(): FormGroup {
		return this.formBuilder.group({
			experimentalFieldsSeeds: null,
			experimentalFieldsPlants: null,
			experimentalFieldsFeedstuffs: null,
			experimentalFieldsLivestock: null,
			experimentalFieldsBees: null,
			experimentalFieldsAquaculture: null,
			experimentalFieldsAlgae: null,
			experimentalFieldsOther: null,
			experimentalFacilityOpenFields: null,
			experimentalFacilityOpenFieldsComment: null,
			experimentalFacilityGreenhouses: null,
			experimentalFacilityGreenhousesComment: null,
			experimentalFacilityGrowth: null,
			experimentalFacilityGrowthComment: null,
			experimentalFacilityOther: null,
			experimentalFacilityOtherComment: null,
			openFieldscultivableHa: null,
			openFieldsSeparated: null,
			soilType: null,
			irrigationSystem: null,
			plantsTypeArableCrops: null,
			plantsTypeArableCropsComment: null,
			plantsTypeAnnualCrops: null,
			plantsTypeAnnualCropsComment: null,
			plantsTypeAromaticPlants: null,
			plantsTypeAromaticPlantsComment: null,
			plantsTypePerennialCrops: null,
			plantsTypePerennialCropsComment: null,
			plantsTypeOther: null,
			plantsTypeOtherComment: null,
			perennialCropsImplantedPomeFruits: null,
			perennialCropsImplantedPomeFruitsComment: null,
			perennialCropsImplantedOliveTrees: null,
			perennialCropsImplantedOliveTreesComment: null,
			perennialCropsImplantedCitrus: null,
			perennialCropsImplantedCitrusComment: null,
			perennialCropsImplantedVines: null,
			perennialCropsImplantedVinesComment: null,
			perennialCropsImplantedOther: null,
			perennialCropsImplantedOtherComment: null,
			greenhousesCultivableMeters: null,
			greenhousesSeparatedGreenhouses: null,
			metersCultivableGreenhouseMin: null,
			metersCultivableGreenhouseMax: null,
			metersCultivableGreenhouseAverage: null,
			shapeGable: null,
			shapeFlatArch: null,
			ShapeRaisedDome: null,
			ShapeSawtooth: null,
			ShapeTunnel: null,
			ShapeOther: null,
			StructureSingleSpan: null,
			StructureMultiSpan: null,
			StructureShadeHouse: null,
			StructureScreenHouse: null,
			StructureCropTopStructure: null,
			StructureOther: null,
			TechnologyLowTech: null,
			TechnologyMediumTech: null,
			TechnologyHighTech: null,
			HeatingBoiler: null,
			HeatingHotWaterTubes: null,
			HeatingWithSteamPipes: null,
			HeatingUnitHeaters: null,
			HeatingInfraredHeaters: null,
			HeatingSolar: null,
			HeatingNoSystem: null,
			HeatingOther: null,
			CoolingVentilation: null,
			CoolingEvaporation: null,
			CoolingShadeCloths: null,
			CoolingCirculation: null,
			CoolingNoSystem: null,
			CoolingOther: null,
			CultivationSoil: null,
			CultivationOtherMedia: null,
			CultivationTypeOther: null,
			EnvironmentalTemperature: null,
			EnvironmentalRelativeHumidity: null,
			EnvironmentalLightIntensity: null,
			EnvironmentalQualityLight: null,
			EnvironmentalOther: null,
			ApplicationAgronomy: null,
			ApplicationBotany: null,
			ApplicationPlantPathology: null,
			ApplicationBiotechnology: null,
			ApplicationOther: null,
			growthChambersTotalMeters: null,
			growthChambersSeparated: null,
			TypeCultureChamber: null,
			TypeCultureChamberDimension: null,
			TypeGerminationChamber: null,
			TypeGerminationChamberDimension: null,
			TypeTallPlantsChamber: null,
			TypeTallPlantsChamberDimension: null,
			TypeSmallPlantsChamber: null,
			TypeSmallPlantsChamberDimension: null,
			TypeLowTemperatureChamber: null,
			TypeLowTemperatureChamberDimension: null,
			TypeOther: null,
			TypeOtherDimension: null,
			CultivationTypeAgronomy: null,
			CultivationTypeBotany: null,
			CultivationTypePlantPathology: null,
			CultivationTypePestControl: null,
			// CultivationTypeOther: null,
			CultivationTypeSoilCultivation: null,
			CultivationTypeOtherMedia: null,
			CultivationTypeOtherMediaComment: null,
			DedicatedEquipmentWeatherStation: null,
			DedicatedEquipmentWeatherStationComment: null,
			DedicatedEquipmentInternalTemperature: null,
			DedicatedEquipmentInternalTemperatureComment: null,
			DedicatedEquipmentPhotosynthesis: null,
			DedicatedEquipmentPhotosynthesisComment: null,
			DedicatedEquipmentRoot: null,
			DedicatedEquipmentRootComment: null,
			DedicatedEquipmentLeafSpectroscopy: null,
			DedicatedEquipmentLeafSpectroscopyComment: null,
			DedicatedEquipmentSensors: null,
			DedicatedEquipmentSensorsComment: null,
			DedicatedEquipmentOther: null,
			DedicatedEquipmentComment: null,
			equipmentMachinerySoilCultivation: null,
			equipmentMachinerySoilCultivationComment: null,
			equipmentMachineryPlanting: null,
			equipmentMachineryPlantingComment: null,
			equipmentMachineryFertilising: null,
			equipmentMachineryFertilisingComment: null,
			equipmentMachineryPestControl: null,
			equipmentMachineryPestControlComment: null,
			equipmentMachineryIrrigation: null,
			equipmentMachineryIrrigationComment: null,
			equipmentMachinerySorting: null,
			equipmentMachinerySortingComment: null,
			equipmentMachineryHarvesting: null,
			equipmentMachineryHarvestingComment: null,
			equipmentMachineryLoading: null,
			equipmentMachineryLoadingComment: null,
			equipmentMachineryPostHarvesting: null,
			equipmentMachineryPostHarvestingComment: null,
			equipmentMachineryPrecisionFarming: null,
			equipmentMachineryPrecisionFarmingComment: null,
			equipmentMachineryVerticalFarming: null,
			equipmentMachineryVerticalFarmingComment: null,
			equipmentMachineryOther: null,
			equipmentMachineryOtherComment: null,
			livestockBreedingCattle: null,
			livestockBreedingCattleMin: null,
			livestockBreedingCattleMax: null,
			livestockBreedingCattleOther: null,
			livestockBreedingBuffaloes: null,
			livestockBreedingBuffaloesMin: null,
			livestockBreedingBuffaloesMax: null,
			livestockBreedingBuffaloesOther: null,
			livestockBreedingSheeps: null,
			livestockBreedingSheepsMin: null,
			ConcerningLivestockBreedingSheepsMax: null,
			livestockBreedingSheepsOther: null,
			livestockBreedingGoats: null,
			livestockBreedingGoatsMin: null,
			livestockBreedingGoatsMax: null,
			livestockBreedingGoatsOther: null,
			livestockBreedingPigs: null,
			livestockBreedingPigsMin: null,
			livestockBreedingPigsMax: null,
			livestockBreedingPigsOther: null,
			livestockBreedingHorses: null,
			livestockBreedingHorsesMin: null,
			livestockBreedingHorsesMax: null,
			livestockBreedingHorsesOther: null,
			livestockBreedingPoultry: null,
			livestockBreedingPoultryMin: null,
			livestockBreedingPoultryMax: null,
			livestockBreedingPoultryOther: null,
			livestockBreedingRabbits: null,
			livestockBreedingRabbitsMin: null,
			livestockBreedingRabbitsMax: null,
			livestockBreedingRabbitsOther: null,
			livestockBreedingOther: null,
			livestockBreedingOtherMin: null,
			livestockBreedingOtherMax: null,
			livestockBreedingOtherOther: null,
			livestockMachineryDryFeeder: null,
			livestockMachineryDryFeederComment: null,
			livestockMachineryWetFeeder: null,
			livestockMachineryWetFeederComment: null,
			livestockMachineryCattleCrush: null,
			livestockMachineryCattleCrushComment: null,
			livestockMachineryHeadlocks: null,
			livestockMachineryHeadlocksComment: null,
			livestockMachineryStablePartitions: null,
			livestockMachineryStablePartitionsComment: null,
			livestockMachineryFreeStall: null,
			livestockMachineryFreeStallComment: null,
			livestockMachineryGestationStall: null,
			livestockMachineryGestationStallComment: null,
			livestockMachineryFreeStandingAreas: null,
			livestockMachineryFreeStandingAreasComment: null,
			livestockMachineryRoundYards: null,
			livestockMachineryRoundYardsComment: null,
			livestockMachineryHeadRolls: null,
			livestockMachineryHeadRollsComment: null,
			livestockMachineryMilkingMachine: null,
			livestockMachineryMilkingMachineComment: null,
			livestockMachineryEggCollectors: null,
			livestockMachineryEggCollectorsComment: null,
			livestockMachineryOther: null,
			livestockMachineryOtherComment: null,
			equipmentSituWeatherStation: null,
			equipmentSituWeatherStationComment: null,
			equipmentSituInternalTemperature: null,
			equipmentSituInternalTemperatureComment: null,
			equipmentSituSensors: null,
			equipmentSituSensorsComment: null,
			equipmentSituOther: null,
			equipmentSituOtherComment: null,
			concerningAquacultureFish: null,
			concerningAquacultureFishMin: null,
			concerningAquacultureFishMax: null,
			concerningAquacultureFishOther: null,
			concerningAquacultureCrustaceans: null,
			concerningAquacultureCrustaceansMin: null,
			concerningAquacultureCrustaceansMax: null,
			concerningAquacultureCrustaceansOther: null,
			concerningAquacultureMolluscs: null,
			concerningAquacultureMolluscsMin: null,
			concerningAquacultureMolluscsMax: null,
			concerningAquacultureMolluscsOther: null,
			concerningAquacultureSeaweed: null,
			concerningAquacultureSeaweedMin: null,
			concerningAquacultureSeaweedMax: null,
			concerningAquacultureSeaweedOther: null,
			measureEquipmentSituWeatherStation: null,
			measureEquipmentSituWeatherStationComment: null,
			measureEquipmentSituInternalTemperature: null,
			measureEquipmentSituInternalTemperatureComment: null,
			measureEquipmentSituPh: null,
			measureEquipmentSituPhComment: null,
			measureEquipmentSituPe: null,
			measureEquipmentSituPeComment: null,
			measureEquipmentSituConductivity: null,
			measureEquipmentSituConductivityComment: null,
			measureEquipmentSituSensors: null,
			measureEquipmentSituSensorsComment: null,
			measureEquipmentSituOther: null,
			measureEquipmentSituOtherComment: null,
			species: null,
			macroMicroAlgae: null,
			openPondsCircularPond: null,
			openPondsCircularPondNumber: null,
			openPondsRacewayPond: null,
			openPondsRacewayPondNumber: null,
			openPondsInclinedCascade: null,
			openPondsInclinedCascadeNumber: null,
			openPondsTank: null,
			openPondsTankNumber: null,
			photobioreactorsTubular: null,
			photobioreactorsTubularNumber: null,
			photobioreactorsTubularBubble: null,
			photobioreactorsTubularBubbleNumber: null,
			photobioreactorsHorizontal: null,
			photobioreactorsHorizontalNumber: null,
			photobioreactorsVertical: null,
			photobioreactorsVerticalNumber: null,
			photobioreactorsFlatPlate: null,
			photobioreactorsFlatPlateNumber: null,
			photobioreactorsOther: null,
			photobioreactorsOtherNumber: null,
			linkDedicatedControlOptimisationSystems: null,
			linkFoodMatrixesCharacterisationLabs: null,
			linkNone: null,
		})
	}

	rmForm(facility: Facility): FormGroup {
		return this.formBuilder.group({
			id: facility.rmEquipment?.id,
			facilityId: facility.id,
			sterilizationPTFE: null,
			sterilizationPTFEComment: null,
			sterilizationAutoclaveSterilizer: null,
			sterilizationAutoclaveSterilizerComment: null,
			sterilizationRadiosterilization: null,
			sterilizationRadiosterilizationComment: null,
			sterilizationSubBoiling: null,
			sterilizationSubBoilingComment: null,
			sterilizationOther: null,
			sterilizationOtherComment: null,
			balancesTechnical: null,
			balancesTechnicalComment: null,
			balancesAnalytical: null,
			balancesAnalyticalComment: null,
			balancesMicroBalances: null,
			balancesMicroBalancesComment: null,
			balancesUltraMicro: null,
			balancesUltraMicroComment: null,
			balancesCalibrationMasses: null,
			balancesCalibrationMassesComment: null,
			homogenizationCentrifuge: null,
			homogenizationCentrifugeComment: null,
			homogenizationFiltration: null,
			homogenizationFiltrationComment: null,
			homogenizationGrinder: null,
			homogenizationGrinderComment: null,
			homogenizationMixer: null,
			homogenizationMixerComment: null,
			homogenizationShaker: null,
			homogenizationShakerComment: null,
			homogenizationSiever: null,
			homogenizationSieverComment: null,
			homogenizationUltrasoundBath: null,
			homogenizationUltrasoundBathComment: null,
			homogenizationRotaryEvaporator: null,
			homogenizationRotaryEvaporatorComment: null,
			homogenizationOven: null,
			homogenizationOvenComment: null,
			homogenizationFreeze: null,
			homogenizationFreezeComment: null,
			homogenizationOther: null,
			homogenizationOtherComment: null,
			homogenizedMin: null,
			homogenizedMax: null,
			sampleDosingValve: null,
			sampleDosingValveComment: null,
			sampleFeeder: null,
			sampleFeederComment: null,
			sampleVialFillingMachine: null,
			sampleVialFillingMachineComment: null,
			sampleSealingMachine: null,
			sampleSealingMachineComment: null,
			sampleNitrogenSealer: null,
			sampleNitrogenSealerComment: null,
			sampleStopperingMachine: null,
			sampleStopperingMachineComment: null,
			samplePelletPresser: null,
			samplePelletPresserComment: null,
			sampleVacuumChamber: null,
			sampleVacuumChamberComment: null,
			sampleLyophilizedStoppering: null,
			sampleLyophilizedStopperingComment: null,
			sampleLabelPrinter: null,
			sampleLabelPrinterComment: null,
			sampleAutomaticLabeler: null,
			sampleAutomaticLabelerComment: null,
			sampleOther: null,
			sampleOtherComment: null,
			instrumentsOven: null,
			instrumentsOvenComment: null,
			instrumentsFridge7: null,
			instrumentsFridge7Comment: null,
			instrumentsMinusFreezer20: null,
			instrumentsMinusFreezer20Comment: null,
			instrumentsMinus40: null,
			instrumentsMinus40Comment: null,
			instrumentsFreezerMinus80: null,
			instrumentsFreezerMinus80Comment: null,
			instrumentsClimaticChamber: null,
			instrumentsClimaticChamberComment: null,
			instrumentsCycleTemperature: null,
			instrumentsCycleTemperatureComment: null,
			instrumentsTemperatureHumidity: null,
			instrumentsTemperatureHumidityComment: null,
			instrumentsDesiccator: null,
			instrumentsDesiccatorComment: null,
			instrumentsSolarBox: null,
			instrumentsSolarBoxComment: null,
			instrumentsOther: null,
			instrumentsOtherComment: null,
			waterActivity: null,
			waterActivityComment: null,
			waterActivityTotalContent: null,
			waterActivityTotalContentComment: null,
			waterActivityParticleSize: null,
			waterActivityParticleSizeComment: null,
			storageHowRoomTemperature: null,
			storageHowRoomTemperatureComment: null,
			storageHowRefrigeratedTemperature: null,
			storageHowRefrigeratedTemperatureComment: null,
			storageHowUnderVacuum: null,
			storageHowUnderVacuumComment: null,
			storageHowInertGas: null,
			storageHowInertGasComment: null,
			storageHowSterilized: null,
			storageHowSterilizedComment: null,
			storageHowInDark: null,
			storageHowInDarkComment: null,
			storageHowGlassContainers: null,
			storageHowGlassContainersComment: null,
			storageHowPlasticContainers: null,
			storageHowPlasticContainersComment: null,
			storageHowOther: null,
			storageHowOtherComment: null
		})
	}

	analyticalForm(facility: Facility): FormGroup {
		return this.formBuilder.group({
			id: facility.analyticalEquipment?.id,
			facilityId: facility.id,
			instrumentationChromatography: null,
			instrumentationElementalAnalysis: null,
			instrumentationSpeciationAnalysis: null,
			instrumentationIsotopicAnalysis: null,
			instrumentationMolecularSpectroscopy: null,
			instrumentationNanoparticleAnalysis: null,
			instrumentationSurfaceAnalysis: null,
			instrumentationSolidSurfaceStateAnalysis: null,
			instrumentationSolidStructureStateAnalysis: null,
			instrumentationThermalAnalysis: null,
			instrumentationRheology: null,
			instrumentationSensoryAnalysis: null,
			instrumentationBiotechnology: null,
			instrumentationMicrobiology: null,
			chromatographyGPC: null,
			chromatographyGPCComment: null,
			chromatographyTLC: null,
			chromatographyTLCComment: null,
			chromatographyPyGC: null,
			chromatographyPyGCComment: null,
			chromatographyGPCLALLS: null,
			chromatographyGPCLALLSComment: null,
			chromatographyCE: null,
			chromatographyCEComment: null,
			chromatographyLC: null,
			chromatographyLCComment: null,
			chromatographyGC: null,
			chromatographyGCComment: null,
			chromatographyIC: null,
			chromatographyICComment: null,
			chromatographySFC: null,
			chromatographySFCComment: null,
			chromatographyOther: null,
			chromatographyOtherComment: null,
			ceDetectorsAerosolDetector: null,
			ceDetectorsAerosolDetectorComment: null,
			ceDetectorsCDD: null,
			ceDetectorsCDDComment: null,
			ceDetectorsECD: null,
			ceDetectorsECDComment: null,
			ceDetectorsFLD: null,
			ceDetectorsFLDComment: null,
			ceDetectorsMS: null,
			ceDetectorsMSComment: null,
			ceDetectorsMSMS: null,
			ceDetectorsMSMSComment: null,
			ceDetectorsRID: null,
			ceDetectorsRIDComment: null,
			ceDetectorsUV: null,
			ceDetectorsUVComment: null,
			ceDetectorsMSn: null,
			ceDetectorsMSnComment: null,
			ceDetectorsHRMS: null,
			ceDetectorsHRMSComment: null,
			ceDetectorsOther: null,
			ceDetectorsOtherComment: null,
			lcDetectorsCDD: null,
			lcDetectorsCDDComment: null,
			lcDetectorsECD: null,
			lcDetectorsECDComment: null,
			lcDetectorsFLD: null,
			lcDetectorsFLDComment: null,
			lcDetectorsELSD: null,
			lcDetectorsELSDComment: null,
			lcDetectorsDAD: null,
			lcDetectorsDADComment: null,
			lcDetectorsMS: null,
			lcDetectorsMSComment: null,
			lcDetectorsMSMS: null,
			lcDetectorsMSMSComment: null,
			lcDetectorsOrbitrap: null,
			lcDetectorsOrbitrapComment: null,
			lcDetectorsTOF: null,
			lcDetectorsTOFComment: null,
			lcDetectorsQTOF: null,
			lcDetectorsQTOFComment: null,
			lcDetectorsRID: null,
			lcDetectorsRIDComment: null,
			lcDetectorsUV: null,
			lcDetectorsUVComment: null,
			lcDetectorsMSn: null,
			lcDetectorsMSnComment: null,
			lcDetectorsHRMS: null,
			lcDetectorsHRMSComment: null,
			lcDetectorsConductivity: null,
			lcDetectorsConductivityComment: null,
			lcDetectorsRadioFlow: null,
			lcDetectorsRadioFlowComment: null,
			lcDetectorsOther: null,
			lcDetectorsOtherComment: null,
			gcDetectorsDID: null,
			gcDetectorsDIDComment: null,
			gcDetectorsELCD: null,
			gcDetectorsELCDComment: null,
			gcDetectorsECD: null,
			gcDetectorsECDComment: null,
			gcDetectorsFID: null,
			gcDetectorsFIDComment: null,
			gcDetectorsFPD: null,
			gcDetectorsFPDComment: null,
			gcDetectorsHID: null,
			gcDetectorsHIDComment: null,
			gcDetectorsPID: null,
			gcDetectorsPIDComment: null,
			gcDetectorsPDD: null,
			gcDetectorsPDDComment: null,
			gcDetectorsTID: null,
			gcDetectorsTIDComment: null,
			gcDetectorsMS: null,
			gcDetectorsMSComment: null,
			gcDetectorsMSMS: null,
			gcDetectorsMSMSComment: null,
			gcDetectorsMSn: null,
			gcDetectorsMSnComment: null,
			gcDetectorsHRMS: null,
			gcDetectorsHRMSComment: null,
			gcDetectorsTCD: null,
			gcDetectorsTCDComment: null,
			gcDetectorsOther: null,
			gcDetectorsOtherComment: null,
			elementalFAAS: null,
			elementalFAASComment: null,
			elementalETAAS: null,
			elementalETAASComment: null,
			elementalCVAAS: null,
			elementalCVAASComment: null,
			elementalHGAAS: null,
			elementalHGAASComment: null,
			elementalAMA: null,
			elementalAMAComment: null,
			elementalICPAES: null,
			elementalICPAESComment: null,
			elementalLAICPAES: null,
			elementalLAICPAESComment: null,
			elementalICPMS: null,
			elementalICPMSComment: null,
			elementalLAICPMS: null,
			elementalLAICPMSComment: null,
			elementalICPMSMS: null,
			elementalICPMSMSComment: null,
			elementalHRICPMS: null,
			elementalHRICPMSComment: null,
			elementalXRF: null,
			elementalXRFComment: null,
			elementalMicroXRF: null,
			elementalMicroXRFComment: null,
			elementalTXRF: null,
			elementalTXRFComment: null,
			elementalNAA: null,
			elementalNAAComment: null,
			elementalOther: null,
			elementalOtherComment: null,
			speciationHPLCICPAES: null,
			speciationHPLCICPAESComment: null,
			speciationHPLCICPMS: null,
			speciationHPLCICPMSComment: null,
			speciationHPLCAFS: null,
			speciationHPLCAFSComment: null,
			speciationLCMS: null,
			speciationLCMSComment: null,
			speciationGCICPMS: null,
			speciationGCICPMSComment: null,
			speciationGCMS: null,
			speciationGCMSComment: null,
			speciationCEICPMS: null,
			speciationCEICPMSComment: null,
			speciationElectrochemistryTechniques: null,
			speciationElectrochemistryTechniquesComment: null,
			speciationOther: null,
			speciationOtherComment: null,
			isotopicIRMS: null,
			isotopicIRMSComment: null,
			isotopicMCICPMS: null,
			isotopicMCICPMSComment: null,
			isotopicTandemMassSpectrometer: null,
			isotopicTandemMassSpectrometerComment: null,
			isotopicTIMS: null,
			isotopicTIMSComment: null,
			isotopicNMR: null,
			isotopicNMRComment: null,
			isotopicNAA: null,
			isotopicNAAComment: null,
			isotopicOther: null,
			isotopicOtherComment: null,
			molecularUVVis: null,
			molecularUVVisComment: null,
			molecularIR: null,
			molecularIRComment: null,
			molecularFTIR: null,
			molecularFTIRComment: null,
			molecularRamanSpectroscopy: null,
			molecularRamanSpectroscopyComment: null,
			molecularFluorescenceSpectroscopy: null,
			molecularFluorescenceSpectroscopyComment: null,
			molecularEPR: null,
			molecularEPRComment: null,
			molecularNMR: null,
			molecularNMRComment: null,
			molecularOther: null,
			molecularOtherComment: null,
			nanoparticleA4FMALLS: null,
			nanoparticleA4FMALLSComment: null,
			nanoparticleA4FICPMS: null,
			nanoparticleA4FICPMSComment: null,
			nanoparticleSPICPMS: null,
			nanoparticleSPICPMSComment: null,
			nanoparticleDLS: null,
			nanoparticleDLSComment: null,
			nanoparticleElectronMicroscopeTechniques: null,
			nanoparticleElectronMicroscopeTechniquesComment: null,
			nanoparticleBET: null,
			nanoparticleBETComment: null,
			nanoparticleELS: null,
			nanoparticleELSComment: null,
			nanoparticleOther: null,
			nanoparticleOtherComment: null,
			surfaceAESSAM: null,
			surfaceAESSAMComment: null,
			surfaceAPFIM: null,
			surfaceAPFIMComment: null,
			surfaceAPMAXMA: null,
			surfaceAPMAXMAComment: null,
			surfaceFESEMEDX: null,
			surfaceFESEMEDXComment: null,
			surfaceSIMS: null,
			surfaceSIMSComment: null,
			surfaceTDSMS: null,
			surfaceTDSMSComment: null,
			surfaceTOFSIMS: null,
			surfaceTOFSIMSComment: null,
			surfaceXPSESCA: null,
			surfaceXPSESCAComment: null,
			surfaceLowLevelAlphaParticle: null,
			surfaceLowLevelAlphaParticleComment: null,
			surfaceOther: null,
			surfaceOtherComment: null,
			solidSurfaceAFM: null,
			solidSurfaceAFMComment: null,
			solidSurfaceSEM: null,
			solidSurfaceSEMComment: null,
			solidSurfaceTEM: null,
			solidSurfaceTEMComment: null,
			solidSurfaceImageAnalysisSystem: null,
			solidSurfaceImageAnalysisSystemComment: null,
			solidSurfaceOther: null,
			solidSurfaceOtherComment: null,
			microscopesCM: null,
			microscopesFM: null,
			microscopesOM: null,
			solidESR: null,
			solidESRComment: null,
			solidNMR: null,
			solidNMRComment: null,
			solidRamanSpectrometer: null,
			solidRamanSpectrometerComment: null,
			solidXRD: null,
			solidXRDComment: null,
			solidMicroCT: null,
			solidMicroCTComment: null,
			solidPRTMS: null,
			solidPRTMSComment: null,
			solidOther: null,
			solidOtherComment: null,
			thermalDEA: null,
			thermalDEAComment: null,
			thermalDIL: null,
			thermalDILComment: null,
			thermalDMA: null,
			thermalDMAComment: null,
			thermalDSC: null,
			thermalDSCComment: null,
			thermalDTA: null,
			thermalDTAComment: null,
			thermalEGA: null,
			thermalEGAComment: null,
			thermalLFA: null,
			thermalLFAComment: null,
			thermalTGA: null,
			thermalTGAComment: null,
			thermalTMA: null,
			thermalTMAComment: null,
			thermalOther: null,
			thermalOtherComment: null,
			rheologyUtube: null,
			rheologyUtubeComment: null,
			rheologyFallingSphere: null,
			rheologyFallingSphereComment: null,
			rheologyRotationalViscosimeters: null,
			rheologyRotationalViscosimetersComment: null,
			rheologyRheometers: null,
			rheologyRheometersComment: null,
			rheologyOther: null,
			rheologyOtherComment: null,
			sensoryElectronicNose: null,
			sensoryElectronicNoseComment: null,
			sensoryElectronicTongue: null,
			sensoryElectronicTongueComment: null,
			sensoryGCO: null,
			sensoryGCOComment: null,
			sensoryElectronicVisualAnalyser: null,
			sensoryElectronicVisualAnalyserComment: null,
			sensoryPanelTests: null,
			sensoryPanelTestsComment: null,
			sensoryOther: null,
			sensoryOtherComment: null,
			biotechnologyMolecularPCR: null,
			biotechnologyMolecularPCRComment: null,
			biotechnologyMolecularRTPCR: null,
			biotechnologyMolecularRTPCRComment: null,
			biotechnologyMolecularAPPCR: null,
			biotechnologyMolecularAPPCRComment: null,
			biotechnologyMolecularREPPCR: null,
			biotechnologyMolecularREPPCRComment: null,
			biotechnologyMolecularSequencer: null,
			biotechnologyMolecularSequencerComment: null,
			biotechnologyMolecularDNAMicroarray: null,
			biotechnologyMolecularDNAMicroarrayComment: null,
			biotechnologyMolecularDGGE: null,
			biotechnologyMolecularDGGEComment: null,
			biotechnologyMolecularRFLP: null,
			biotechnologyMolecularRFLPComment: null,
			biotechnologyMolecularRibotyping: null,
			biotechnologyMolecularRibotypingComment: null,
			biotechnologyMolecularRAPD: null,
			biotechnologyMolecularRAPDComment: null,
			biotechnologyMolecularPFGE: null,
			biotechnologyMolecularPFGEComment: null,
			biotechnologyMolecularMEE: null,
			biotechnologyMolecularMEEComment: null,
			biotechnologyMolecularMLST: null,
			biotechnologyMolecularMLSTComment: null,
			biotechnologyMolecularElectrophoreticTest: null,
			biotechnologyMolecularElectrophoreticTestComment: null,
			biotechnologyMolecularOther: null,
			biotechnologyMolecularOtherComment: null,
			biotechnologyBiosensorsOpticalBiosensors: null,
			biotechnologyBiosensorsOpticalBiosensorsComment: null,
			biotechnologyBiosensorsPiezoelectricBiosensors: null,
			biotechnologyBiosensorsPiezoelectricBiosensorsComment: null,
			biotechnologyBiosensorsImmunosensors: null,
			biotechnologyBiosensorsImmunosensorsComment: null,
			biotechnologyBiosensorsElecthrochemicalBiosensors: null,
			biotechnologyBiosensorsElecthrochemicalBiosensorsComment: null,
			biotechnologyBiosensorsOther: null,
			biotechnologyBiosensorsOtherComment: null,
			biotechnologyImmunoenzymaticELISA: null,
			biotechnologyImmunoenzymaticELISAComment: null,
			biotechnologyImmunoenzymaticELFA: null,
			biotechnologyImmunoenzymaticELFAComment: null,
			biotechnologyImmunoenzymaticOther: null,
			biotechnologyImmunoenzymaticOtherComment: null,
			biotechnologyOthersMicrobialVolatile: null,
			biotechnologyOthersMicrobialVolatileComment: null,
			biotechnologyOthersBioanalyser: null,
			biotechnologyOthersBioanalyserComment: null,
			biotechnologyOthersOther: null,
			biotechnologyOthersOtherComment: null,
			microbiologyAutoclaveSteriliser: null,
			microbiologyAutoclaveSteriliserComment: null,
			microbiologyAntimicrobialSusceptibilityTest: null,
			microbiologyAntimicrobialSusceptibilityTestComment: null,
			microbiologyGramStrainer: null,
			microbiologyGramStrainerComment: null,
			microbiologyLiquidMediaPreparation: null,
			microbiologyLiquidMediaPreparationComment: null,
			microbiologyPetriDishFiller: null,
			microbiologyPetriDishFillerComment: null,
			microbiologyColonyCounters: null,
			microbiologyColonyCountersComment: null,
			microbiologyCellCultureMedia: null,
			microbiologyCellCultureMediaComment: null,
			microbiologyCO2Incubator: null,
			microbiologyCO2IncubatorComment: null,
			microbiologyCompoundMicroscope: null,
			microbiologyCompoundMicroscopeComment: null,
			microbiologyMicrobialIdentificationSystem: null,
			microbiologyMicrobialIdentificationSystemComment: null,
			microbiologyFlowCitometry: null,
			microbiologyFlowCitometryComment: null,
			microbiologyGrowthChambers: null,
			microbiologyGrowthChambersComment: null,
			microbiologyGerminationChambers: null,
			microbiologyGerminationChambersComment: null,
			microbiologyOther: null,
			microbiologyOtherComment: null,
			devicesTechnicalBalance: null,
			devicesAnalyticalBalance: null,
			devicesOvenMuffleFurnace: null,
			devicesFridge4: null,
			devicesFreezerMinus20: null,
			devicesMinus80: null,
			dedicatedCentrifugeUltracentrifuge: null,
			devicesHeatingBath: null,
			devicesUltrasoundBath: null,
			devicesIncubator: null,
			devicesMillerGrinder: null,
			devicesSiever: null,
			devicesMixer: null,
			devicesHomogeniser: null,
			devicesLyophilizer: null,
			devicesSprayDrier: null,
			devicesRotaryEvaporator: null,
			devicesSPME: null,
			devicesASE: null,
			devicesMicrowaveDigestionSystem: null,
			devicesMicrowaveExtractionSystem: null,
			devicesUltrawaveDigestionSystem: null,
			deviceLaserAblationSystem: null,
			devicesOther: null
		})


	}


}
