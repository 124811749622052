<nz-table *ngIf="showForm"
[nzFrontPagination]="false"
[nzData]="formGroup.controls">
<thead>
	<tr>
		<th nzWidth="40%" nzAlign="center"></th>
		<th nzWidth="60%" nzAlign="center">
			<tr style="display: block;">
				<th style="display: inline-block; width: 25%;" nzAlign="center">YES</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">Potentially</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">Planned 1-5 years</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">NO</th>
			</tr>
		</th>
	</tr>
</thead>
<tbody>

	<tr class="pointer" *ngFor="let item of sector.controls; index as i" [formGroup]="item">

		<td nzWidth="40%">
			{{sectorCategories[item.value.name]}}
		</td>
		<td nzWidth="60%" nzAlign="center">
			<nz-radio-group formControlName="status" style="width: 100%;" >
				<td nzAlign="center" style="display: inline-block; width: 25%;">
					<label nz-radio nzValue="YES"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="POTENTIALLY"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="PLANNED"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="NO"></label>
				</td>
			</nz-radio-group>
		</td>
	</tr>
</tbody>

</nz-table>

<div class="relative mb-15">
	<h3>For food safety, indicate the categories you work with</h3>
	<div *ngFor="let item of safety.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === safety.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For toxic and potentially toxic elements (please specify here)</h3>
	<div *ngFor="let item of elements.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>
	</div>
</div>

<div class="relative mb-15">
	<h3>Hydrocarbons (please specify here)</h3>
	<div *ngFor="let item of hydrocarbons.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<nz-textarea-count [nzMaxCharacterCount]="300" [formGroup]="item" class="w-100">
			<textarea rows="4" formControlName="comment" nz-input></textarea>
		</nz-textarea-count>
	</div>
</div>

<div class="relative mb-15">
	<h3>Process contaminants (please specify here)</h3>
	<div *ngFor="let item of processContam.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === processContam.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>Environmental contaminants (please specify here)</h3>
	<div *ngFor="let item of environmContam.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === environmContam.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
		[control]="item.controls.comment">
	</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>Additives and supplements (please specify here)</h3>
	<div *ngFor="let item of additivesSpecify.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<nz-textarea-count [nzMaxCharacterCount]="300" [formGroup]="item">
			<textarea rows="4" formControlName="comment" nz-input></textarea>
		</nz-textarea-count>
	</div>
</div>

<div class="relative mb-15">
	<h3>For food quality, indicate the categories you work with</h3>
	<div *ngFor="let item of quality.controls; index as i" [formGroup]="item" class="d-flex justify-content-between w-100">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>
		<metro-form-input *ngIf="i === quality.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
		[control]="item.controls.comment">
	</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For food authenticity/traceability, indicate the categories you work with</h3>
	<div *ngFor="let item of authenticity.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === authenticity.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For - omics analyses</h3>
	<div *ngFor="let item of omics.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === omics.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For nutrition, indicate the categories you work with</h3>
	<div *ngFor="let item of nutrition.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>
		<metro-form-input *ngIf="i === nutrition.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For agroecosystem characterisation, indicate the categories you work with</h3>
	<div *ngFor="let item of agroeco.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === agroeco.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For characterisation of materials, indicate the categories you work with</h3>
	<div *ngFor="let item of minerals.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === minerals.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>Indicate the matrixes you work with</h3>
	<div *ngFor="let item of matrixes.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === matrixes.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For foods of plant origin, indicate the sub-categories you work with</h3>
	<div *ngFor="let item of plants.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === plants.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For beverages and vinegars, indicate the sub-categories you work with</h3>
	<div *ngFor="let item of beverages.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === beverages.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For prepared (ready-to-eat), indicate the sub-categories you work with</h3>
	<div *ngFor="let item of preparedFoods.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === preparedFoods.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For foods of animal origin, indicate the sub-categories you work with</h3>
	<div *ngFor="let item of foodAnimal.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>
	</div>
</div>

