import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	constructor(private notification: NzNotificationService) {}

	createBasicNotification(title: string, description: string, customClass: string) {
		return this.notification.blank(
			`${title}`,
			`${description}`,
			{
				nzClass: customClass,
				nzDuration: 5000
			}
		);
	}
}
