
<form action="" *ngIf="showForms">
	<div class="relative mb-15">
		<h3>4.1 Preparation of food for processing</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="foodProcessingPreparation.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of foodProcessingPreparation.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input nz-input type="text"
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.2 Thermal processing</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="thermalProcessing.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of thermalProcessing.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.3 Evaporation and dehydratation</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="evaporationDehydratation.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of evaporationDehydratation.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.4 Freezing</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="freezing.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of freezing.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.4 Freezing</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="freezing.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of freezing.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.5 High pressure processing</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="pressureProcessing.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of pressureProcessing.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.6 Innovative technologies for food processing</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="foodProcessingPreparation.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of foodProcessingPreparation.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.7 Baking, extrusion and frying</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="bakingExtrusion.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of bakingExtrusion.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.8 Separation and extraction</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="separationExtraction.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of separationExtraction.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.10 Extraction technologies</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="separationExtraction.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of separationExtraction.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.11 Packaging</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="packing.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>Capacity</th>
				<th>Other</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of packing.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="capacity"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="other"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.12 Food preparation / Kitchen labs.</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="foodPreparation.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>type</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of foodPreparation.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number">
					</td>
					<td>
						<input type="text" nz-input
							formControlName="type"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.12.2 Cooking</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="cooking.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>type</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of cooking.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="type"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>

	<div class="relative mb-15">
		<h3>4.12.3 Storage</h3>
		<nz-table
			[nzFrontPagination]="false"
			[nzData]="storage.controls">
			<thead>
			<tr>
				<th>Subcategory</th>
				<th>Number</th>
				<th>type</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let item of storage.controls; index as i"  [formGroup]="item">
				<tr class="pointer pr-20	pl-20">
					<td>
							{{item.get('product').value}}
					</td>
					<td>
						<input type="text" nz-input
							formControlName="number"  >
					</td>
					<td>
						<input type="text" nz-input
							formControlName="type"  >
					</td>
				</tr>
			</ng-container>
			</tbody>
		</nz-table>
	</div>
</form>

