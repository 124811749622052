import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { FacilityService } from '../../../../data/services/facility.service';

@Component({
	selector: 'metro-membership-facility-modal',
	templateUrl: './facility-modal.component.html',
	styleUrls: ['./facility-modal.component.less']
})
export class FacilityModalComponent implements OnInit {
	@Input() isVisible = false;
	@Input() instituteId: string;
	unSubscribe = new Subject();
	facilityForm: FormGroup;
	radioValue = 'rm';

	constructor(
		private formBuilder: FormBuilder,
		private modal: NzModalRef
	) {}

	ngOnInit() {
		this.facilityForm = this.formBuilder.group({
			facilityName: ['', Validators.required],
			instituteId: this.instituteId,
			type: ''
		});
	}
	handleOk(): void {
		this.saveFacility();
	}

	handleCancel(): void {
		this.modal.destroy();
	}

	saveFacility(): void {
		const facilityFormData = this.facilityForm.getRawValue();
		// facilityFormData.type = this.radioValue;
		this.modal.destroy(facilityFormData);

	}

}
