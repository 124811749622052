<form action="" [formGroup]="facilitySiteForm" *ngIf="facility?.id && showForm">
	<nz-tabset nzType="card">
		<nz-tab [nzTitle]="'Site details'">
			<div class="mb-7">
				<metro-form-input class="standard" type="text" ph="Street" formControlName="street"
				label="Street"
				[control]="facilitySiteForm.controls.street"></metro-form-input>
			</div>
			<div class="mb-7">
				<metro-form-input class="standard" type="text" ph="Zip code" formControlName="zipCode"
				label="Zip Code"
				[control]="facilitySiteForm.controls.zipCode"></metro-form-input>
			</div>
			<div class="mb-7">
				<metro-form-input class="standard" type="text" ph="City" formControlName="city"
				label="City"
				[control]="facilitySiteForm.controls.city"></metro-form-input>
			</div>
			<div class="mb-7">
				<metro-form-input class="standard" type="text" ph="Square" formControlName="square" *ngIf="facility.type === 'analytical' || facility.type === 'rm'"
				label="Square"
				[control]="facilitySiteForm.controls.square"></metro-form-input>
			</div>
			<div class="mb-7">
				<metro-form-input class="standard" type="text" ph="Details" formControlName="spec" *ngIf="facility.type !== 'analytical'"
				label="Details"
				[control]="facilitySiteForm.controls.spec"></metro-form-input>
			</div>
		</nz-tab>
		<nz-tab [nzTitle]="'Site Person'" *ngIf="facility.type === 'process_kitchen' || facility.type === 'primary'">
<!--			<metro-membership-choose-person-->
<!--			(personChange)="handlePersonChange($event)"-->
<!--			[chosenPersonId] = "site?.personId"-->
<!--			></metro-membership-choose-person>-->
		</nz-tab>
	</nz-tabset>
</form>

<ng-template [nzModalFooter] >
	<button nz-button (click)="handleOk()" [ngClass]="{'disabled': facilitySiteForm.invalid}">Save</button>
	<button nz-button (click)="handleCancel()">cancel</button>
</ng-template>
