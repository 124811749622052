import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRolesPresets } from '@metro-membership/util';
import { RoleGuard } from '../core/guards/role.guard';
import { FacilityDetailsPage } from './facility-details/facility-details.page';
import { FacilityEquipmentPage } from './facility-equipment/facility-equipment.page';
import { FacilityFinancesPage } from './facility-finances/facility-finances.page';
import { FacilityHrPage } from './facility-hr/facility-hr.page';
import { FacilityPeoplePage } from './facility-people/facility-people.page';
import { FacilityPercentRiPage } from './facility-percent-ri/facility-percent-ri.page';
import { FacilityQualityPage } from './facility-quality/facility-quality.page';
import { FacilitySectorPage } from './facility-sector/facility-sector.page';
import { FacilityServicesPage } from './facility-services/facility-services.page';
import { FacilityUpgradePlansPage } from './facility-upgrade-plans/facility-upgrade-plans.page';
import { FacilityPage } from './facility/facility.page';

export const facilityRoutes: Routes = [
	{
		path: 'facilities/:facilityId',
		component: FacilityPage,
		data: { breadcrumb: ':facility' },
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'details'
			},
			{
				path: 'details',
				component: FacilityDetailsPage,
				data: { breadcrumb: '' }
			},
			{
				path: 'people',
				component: FacilityPeoplePage,
				data: { breadcrumb: 'People' }
			},
			{
				path: 'sector',
				component: FacilitySectorPage,
				data: { breadcrumb: 'Sector' }
			},
			{
				path: 'finances',
				component: FacilityFinancesPage,
				canActivate: [RoleGuard],
				data: {
					breadcrumb: 'Finances',
					appRoles: AuthRolesPresets.isFinancialManagerOfInstituteOrFacility
				}
			},
			{
				path: 'services',
				component: FacilityServicesPage,
				data: { breadcrumb: 'Services',
				appRoles: AuthRolesPresets.isManagerOfFacilityOrInstitute }
			},
			{
				path: 'hr',
				component: FacilityHrPage,
				data: { breadcrumb: 'HR',
				appRoles: AuthRolesPresets.isManagerOfFacilityOrInstitute }
			},
			{
				path: 'equipment',
				component: FacilityEquipmentPage,
				data: { breadcrumb: 'Equipment' }
			},
			{
				path: 'plans',
				component: FacilityUpgradePlansPage,
				data: { breadcrumb: 'Plans for upgrade',
				appRoles: AuthRolesPresets.isManagerOfFacilityOrInstitute }
			},
			{
				path: 'ri',
				component: FacilityPercentRiPage,
				data: { breadcrumb: '% to RI',
				appRoles: AuthRolesPresets.isManagerOfFacilityOrInstitute
			}
			},
			{
				path: 'quality',
				component: FacilityQualityPage,
				canActivate: [RoleGuard],
				data: { breadcrumb: 'Quality',
				appRoles: AuthRolesPresets.isQualityManagerOfInstituteOrFacility
			}
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(facilityRoutes)],
	exports: [RouterModule]
})
export class FacilityRoutingModule {}
