<nz-table *ngIf="showForm"
[nzFrontPagination]="false"
[nzData]="formGroup.controls">
<thead>
	<tr>
		<th nzWidth="40%" nzAlign="center"></th>
		<th nzWidth="60%" nzAlign="center">
			<tr style="display: block;">
				<th style="display: inline-block; width: 25%;" nzAlign="center">YES</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">Potentially</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">Planned 1-5 years</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">NO</th>
			</tr>
		</th>
	</tr>
</thead>
<tbody>

	<tr class="pointer" *ngFor="let item of sector.controls; index as i" [formGroup]="item">

		<td nzWidth="40%">
			{{sectorCategories[item.value.name]}}
		</td>
		<td nzWidth="60%" nzAlign="center">
			<nz-radio-group formControlName="status" style="width: 100%;" >
				<td nzAlign="center" style="display: inline-block; width: 25%;">
					<label nz-radio nzValue="YES"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="POTENTIALLY"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="PLANNED"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="NO"></label>
				</td>
			</nz-radio-group>
		</td>
	</tr>
</tbody>

</nz-table>

<div class="relative mb-15">
	<h1>For food safety, indicate the categories you work with</h1>
	<div *ngFor="let item of food.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
		[control]="item.controls.comment">
	</metro-form-input>
	</div>
</div>


<div class="relative mb-15">
	<nz-table
		[nzFrontPagination]="false"
		[nzData]="true">
		<thead>
		<tr>
			<th nzWidth="20%"></th>
			<th zWidth="40%">processed amount</th>
			<th zWidth="40%">production rate</th>
		</tr>
		</thead>
		<tbody>
			<tr class="pointer pb-5">
				<td nzWidth="20%">
					Min
				</td>
				<td nzWidth="40%" *ngFor="let item of amountMin.controls; index as i" [formGroup]="item">
					<metro-form-input class="standard" type="text" formControlName="comment"
					[control]="item.controls.comment" ph="Amount"
				></metro-form-input>
				</td>
			</tr>

			<tr class="pointer pb-5">
				<td nzWidth="20%">
					Max
				</td>
				<td nzWidth="40%" *ngFor="let item of amountMax.controls; index as i" [formGroup]="item">
					<metro-form-input class="standard" type="text" formControlName="comment"
					[control]="item.controls.comment" ph="Amount"
				></metro-form-input>
				</td>
			</tr>

			<tr class="pointer pb-5">
				<td nzWidth="20%">
					Average
				</td>
				<td nzWidth="40%" *ngFor="let item of amountAverage.controls; index as i" [formGroup]="item">
					<metro-form-input class="standard" type="text" formControlName="comment"
					[control]="item.controls.comment" ph="Amount"
				></metro-form-input>
				</td>
			</tr>

			<tr class="pointer pb-5">
				<td nzWidth="20%">
					measurement unit
				</td>
				<td nzWidth="40%" *ngFor="let item of amountUnit.controls; index as i" [formGroup]="item">
					<metro-form-input class="standard" type="text" formControlName="comment"
					[control]="item.controls.comment" ph="Amount"
				></metro-form-input>
				</td>
			</tr>
		</tbody>
	</nz-table>
</div>
