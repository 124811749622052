<div class="d-flex justify-content-between">
	<h1 class="mt-2 mb-5">HR</h1>
	<div>
			<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" >
				<span>SAVE</span>
				<i nz-icon nzType="icons:edit" class="ml-4"></i>
			</button>

			<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable" >
				<span>EDIT</span>
			</button>
	</div>
</div>
<div>
	<form action="" [formGroup]="hrForm" *ngIf="showForm">

		<div class="relative mb-15">
			<nz-table
				[nzFrontPagination]="false"
				[nzData]="hrForm.controls">
				<thead>
				<tr>
					<th nzWidth="20%"></th>
					<th zWidth="20%">n° min</th>
					<th zWidth="20%">n° max</th>
					<th zWidth="20%">FTE min</th>
					<th zWidth="20%">FTE max</th>
				</tr>
				</thead>
				<tbody>
					<tr class="pointer pb-5">
						<td nzWidth="20%">
							Scientist (Permanent Position)
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workingScientistsMin"  [control]="hrForm.controls.workingScientistsMin"
						></metro-form-input>
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workingScientistsMax"  [control]="hrForm.controls.workingScientistsMax"
						></metro-form-input>
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workingScientistsMinFTE"  [control]="hrForm.controls.workingScientistsMinFTE"
						></metro-form-input>
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workingScientistsMaxFTE"  [control]="hrForm.controls.workingScientistsMaxFTE"
						></metro-form-input>
						</td>
					</tr>
					<tr class="pointer pr-20	pl-20">
						<td nzWidth="20%">
							Lab technicians (Permanent Position)
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workingLabTechniciansMin"  [control]="hrForm.controls.workingLabTechniciansMin"
						></metro-form-input>
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workingLabTechniciansMax"  [control]="hrForm.controls.workingLabTechniciansMax"
						></metro-form-input>
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workingLabTechniciansMinFTE"  [control]="hrForm.controls.workingLabTechniciansMinFTE"
						></metro-form-input>
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workingLabTechniciansMaxFTE"  [control]="hrForm.controls.workingLabTechniciansMaxFTE"
						></metro-form-input>
						</td>
					</tr>
					<tr class="pointer pr-20	pl-20">
						<td nzWidth="20%">
							Temporary staff (Research assistants, fellows, PhD students)
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workinTempStaffMin"  [control]="hrForm.controls.workinTempStaffMin"
						></metro-form-input>
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workinTempStaffMax"  [control]="hrForm.controls.workinTempStaffMax"
						></metro-form-input>
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workinTempStaffMinFTE"  [control]="hrForm.controls.workinTempStaffMinFTE"
						></metro-form-input>
						</td>
						<td nzWidth="20%">
							<metro-form-input class="standard" type="text"
								ph="Amount" formControlName="workinTempStaffMaxFTE"  [control]="hrForm.controls.workinTempStaffMaxFTE"
						></metro-form-input>
						</td>
					</tr>
				</tbody>
			</nz-table>
		</div>

		<div class="mt-15"> FTE dedicated to METROFOOD-RI [Scientists (PP) - FTE]:</div>
		<metro-form-input class="standard" type="text"
		ph="Amount" formControlName="metroFoodFTEScientists"  [control]="hrForm.controls.metroFoodFTEScientists"
	></metro-form-input>

		<div class="mt-15"> FTE dedicated to METROFOOD-RI [Lab technicians (PP) - FTE]:</div>
		<metro-form-input class="standard" type="text"
		ph="Amount" formControlName="metroFoodFTELabTechnicans"  [control]="hrForm.controls.metroFoodFTELabTechnicans"
	></metro-form-input>

		<div class="mt-15"> FTE dedicated to METROFOOD-RI [Temporary staff - FTE]</div>

		<metro-form-input class="standard" type="text"
		ph="Amount" formControlName="metroFoodFTTempStaff"  [control]="hrForm.controls.metroFoodFTTempStaff"
	></metro-form-input>
	</form>

</div>

