import { Component, OnInit } from '@angular/core';
import { Person } from '@metro-membership/data-access';
import { UserService } from '../../core/services/user.service';

@Component({
	selector: 'metro-membership-my-institutes',
	templateUrl: './my-institutes.page.html',
	styleUrls: ['./my-institutes.page.less']
})
export class MyInstitutesPage implements OnInit {
	user: Person;

	constructor(private userService: UserService) {}

	ngOnInit(): void {
		this.user = this.userService.user.getValue();
	}
}
