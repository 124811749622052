import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Facility, FacilityQuality, FacilityQualityServices, FacilityServiceEntity } from '@metro-membership/data-access';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacilityService } from '../../data/services/facility.service';
import { personnelQualities, qualityAssurance, sop, guidance, accreditation } from './facility-quality-answers';
import { analyticalServicesEnum } from '../facility-services/analytical/analytical';
import { FacilityType, ServiceStatuses } from '@metro-membership/data-access/enums';
import { Validators } from '@angular/forms';
import { QualityFormsService } from '../../data/services/quality.service';
// Review: Quality to check
@Component({
	selector: 'metro-membership-facility-quality',
	templateUrl: './facility-quality.page.html',
	styleUrls: ['./facility-quality.page.less']
})
export class FacilityQualityPage implements OnDestroy {
	personnelQualities = personnelQualities;
	qualityAssurance = qualityAssurance;
	sop = sop;
	accreditation = accreditation;
	guidance = guidance;
	questionForm: FormGroup;
	showForm = false;
	unSubscribe = new Subject();
	formValidSubs = new Subject();
	facilityId = '';
	facility: Facility;
	facilityQualityData: FacilityQuality;
	facilityServices = { } as FacilityServiceEntity;
	facilityServicesForm: FormGroup;
	analyticalServicesTranslations = analyticalServicesEnum;
	facilityTypes = FacilityType;

	constructor(
		private activatedRoute: ActivatedRoute,
		private facilityService: FacilityService,
		private qualityService : QualityFormsService
		) {

			this.activatedRoute.parent.params
			.pipe(takeUntil(this.unSubscribe))
			.subscribe(params => {
				this.facilityId = params.facilityId;
				this.getFacility();
			});
		}

		ngOnDestroy() {
			this.unSubscribe.next();
			this.unSubscribe.complete();
		}

		get isEditable(): boolean {
			return this.questionForm.enabled as boolean;
		}

		get facilityServicesArray(): string[] {
			const checkboxControls = [];
			for (const key in this.facilityServices) {
				checkboxControls.push(key)
			}
			return checkboxControls;
		}
		getFacilityQuality() {
			this.formValidSubs.next();
			this.formValidSubs.complete();

			const params = new HttpParams({
				fromObject: { join: ['services'] }
			});
			const id = parseInt(this.facilityId)
			this.facilityService.fetchFacilityQuality(id, params).subscribe(facility => {
				this.facilityQualityData = facility;
				this.buildForm();
			}, (error) => {
				this.buildForm();
			});
		}

		getFacility() {
			this.facilityService.fetchFacility(this.facilityId).subscribe(facility => {
				this.facility = facility;
				this.getFacilityQuality();
				if(this.facility.type === this.facilityTypes.Analytical_Lab) {
					this.getServices();
				}
			});
		}

		buildForm() {
			this.questionForm = this.qualityService.facilityQualityForm(this.facilityId);
			this.questionForm.patchValue(this.facilityQualityData);

			this.questionForm.disable();
			this.showForm = true;

			this.registerValidators();

		}

		getServices() {
			this.facilityService.fetchFacilityServices(this.facility.id).subscribe(services => {
				const keys = Object.keys(services);
				const filtered = keys.filter(serviceKey => services[serviceKey] === ServiceStatuses.YES);
				filtered.forEach(element => {
					this.facilityServices[element] = this.facilityQualityData?.services?.[element] || null;
				});
				this.buildServicesForm();
			})
		}

		buildServicesForm() {
			this.facilityServicesForm = this.qualityService.facilityServicesForm(this.facilityId);

			this.facilityServicesForm.patchValue(this.facilityServices);
			this.disableQualityServiceForm();

		}

		disableQualityServiceForm() {
			if(this.facilityServicesForm) {
				this.facilityServicesForm.disable();
			}
		}

		onSave() {

			if (!this.questionForm.valid) {
				return true;
			}
			this.questionForm.disable();
			this.disableQualityServiceForm();

			if (this.facilityQualityData) {
				this.editQuestions();
			} else {
				this.addQuestions();
			}
		}

		editQuestions() {
			const formData = this.questionForm.getRawValue() as FacilityQuality;
			this.facilityService.editFacilityQuality(formData, this.facility.id).subscribe(res => {
				this.onSaveQualityService();
				this.getFacilityQuality();
			});
		}

		addQuestions() {
			const formData = this.questionForm.getRawValue() as FacilityQuality;
			this.facilityService.addFacilityQuality(formData).subscribe(res => {
				this.onSaveQualityService();
				this.getFacilityQuality();
			});
		}

		onSaveQualityService() {
			if(!this.facilityServicesForm) {
				return true;
			}

			if (this.facilityQualityData?.services) {
				this.editQualityService();
			} else {
				this.addQualityService();
			}
		}

		addQualityService() {
			const formData = this.facilityServicesForm?.getRawValue() as FacilityQualityServices;
			formData.qualityId = this.facilityQualityData?.id
				this.facilityService.addFacilityQualityServices(formData).subscribe(res => {

			});
		}

		editQualityService() {
			const formData = this.facilityServicesForm?.getRawValue() as FacilityQualityServices;
			formData.qualityId = this.facilityQualityData?.id
				this.facilityService.editFacilityQualityServices(formData, this.facilityQualityData?.id).subscribe(res => {
		});
	}

		registerValidators() {
			const  qualityManagerSystemQuestionStatus = this.questionForm.get('qualityManagerSystemQuestionStatus');
			const  qualityManagerSystemQuestion = this.questionForm.get('qualityManagerSystemQuestion');
			const  qualityManagerSystemQuestionSubStatus = this.questionForm.get('qualityManagerSystemQuestionSubStatus');

			const accreditationStatus = this.questionForm.get('accreditationStatus');
			const accreditationSubStatus = this.questionForm.get('accreditationSubStatus');
			const sustainableFood = this.questionForm.get('sustainableFood');

			const certificationStatus = this.questionForm.get('certificationStatus');
			const certificationSubStatus = this.questionForm.get('certificationSubStatus');

			const SOPSubStatus = this.questionForm.get('SOPSubStatus');
			const SOPStatus = this.questionForm.get('SOPStatus');

			const qualityAssuranceStatus = this.questionForm.get('qualityAssuranceStatus');
			const qualityAssuranceSubStatus = this.questionForm.get('qualityAssuranceSubStatus');

			const qms = this.questionForm.get('qms');
			const qmsStatus = this.questionForm.get('qmsStatus');

			this.setStandardControlsValidators(qualityManagerSystemQuestionStatus,qualityManagerSystemQuestion, qualityManagerSystemQuestionSubStatus);
			this.setStandardControlsValidators(accreditationStatus, sustainableFood, accreditationSubStatus);
			this.setStandardControlsValidators(certificationStatus, null, certificationSubStatus);
			this.setStandardControlsValidators(SOPStatus, null, SOPSubStatus);
			this.setStandardControlsValidators(qualityAssuranceStatus, null, qualityAssuranceSubStatus);
			this.setStandardControlsValidators(qmsStatus, qms, null);
		}

		onEdit() {
			this.questionForm.enable();
			if(this.facilityServicesForm) {
				this.facilityServicesForm.enable();
			}
		}

		setStandardControlsValidators(mainControl: AbstractControl, trueControl?: AbstractControl, falseControl?: AbstractControl) {
			mainControl.valueChanges
			.pipe(takeUntil(this.formValidSubs))
			.subscribe(change => {
				if(change) {
					if(trueControl) {
						trueControl.setValidators(Validators.required)
					}
					if(falseControl) {
						falseControl.clearValidators();
						falseControl.updateValueAndValidity();
					}
				} else {
					if(falseControl) { falseControl.setValidators(Validators.required) }
					if(trueControl) {
						trueControl.clearValidators();
						trueControl.updateValueAndValidity();
					}
				}
			});
		}

	}
