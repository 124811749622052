export * from './lib/enums/person-role';
export * from './lib/enums/facility-type';
export * from './lib/enums/countries';
export * from './lib/enums/facility-services';
export * from './lib/enums/facility-sectors';
export * from './lib/enums/facility-equipments';
export * from './lib/enums/insitute-quality';
export * from './lib/enums/resource-types';

export enum RadioDecisions {
	YES = 'YES',
	NO = 'NO',
	DONT_KNOW = 'DONT_KNOW',
	NOT_APPLY = 'NOT_APPLY'
}
