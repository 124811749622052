import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Facility, FacilitySite } from '@metro-membership/data-access';
import { FacilityType } from '@metro-membership/data-access/enums';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';

@Component({
	selector: 'metro-facility-site',
	templateUrl: './site-modal.component.html',
	styleUrls: ['./site-modal.component.less']
})
export class FacilitySiteComponent implements OnInit {
	unSubscribe = new Subject();
	facility: Facility;
	site: FacilitySite;
	facilitySiteForm: FormGroup;
	showForm = false;
	constructor(private modal: NzModalRef, private formBuilder: FormBuilder) {

	}
	ngOnInit() {
		this.buildForm();
	}
	buildForm() {
		this.facilitySiteForm = this.formBuilder.group({
			id: [this.site?.id || null ],
			facilityId: this.facility.id || null,
			street: [this.site?.street || '',  Validators.compose([Validators.required])],
			zipCode: [this.site?.zipCode || '', Validators.compose([Validators.required])],
			city: [this.site?.city || '', Validators.compose([Validators.required])],
			square: [this.site?.square || ''],
			personId: [this.site?.personId || null],
			spec:[ this.site?.spec ||  '']
		});
		this.setValidation();
		this.showForm = true;
	}
	handlePersonChange(personId: string) {
		this.facilitySiteForm.get('personId').setValue(personId);
	}

	setValidation() {
		const square = this.facilitySiteForm.get('square') as FormControl;
		const personId = this.facilitySiteForm.get('personId') as FormControl;
		const spec = this.facilitySiteForm.get('spec') as FormControl;

		if (this.facility.type === FacilityType.Analytical_Lab) {
			square.setValidators(Validators.required)
		} else if (this.facility.type === FacilityType.RM_Facility) {
			square.setValidators(Validators.required);
			spec.setValidators(Validators.required);
		} else if(this.facility.type === FacilityType.Processing_And_Kitchen_Lab) {
			spec.setValidators(Validators.required);
			personId.setValidators(Validators.required);
		} else {
			spec.setValidators(Validators.required);
			personId.setValidators(Validators.required);
		}
	}

	handleCancel() {
		this.modal.destroy();
	}

	handleOk() {
		this.modal.destroy({ data: this.facilitySiteForm.getRawValue() });
	}

}
