<metro-membership-loader></metro-membership-loader>

<metro-header [user]="{ username: username, avatarUrl: null }"></metro-header>
<metro-membership-top-menu></metro-membership-top-menu>

<div class="container">
	<router-outlet></router-outlet>
</div>

<metro-footer [appVersion]="appVersion"></metro-footer>
