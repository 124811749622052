import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InstituteResources } from '@metro-membership/data-access';
import { ResourceTypes } from '@metro-membership/data-access/enums';
import { Subject, Subscription } from 'rxjs';
import { InstituteService } from '../../data/services/institute.service';

@Component({
  selector: 'metro-membership-resources-additional',
  templateUrl: './resources-additional.component.html',
  styleUrls: ['./resources-additional.component.less']
})
export class ResourcesAdditionalComponent {
	isEditable = false;
	unSubscribe = new Subject();
	instituteId = '';
	subscriptions: Subscription[] = [];
	resourcesForm: FormGroup;
	resourceTypes = ResourceTypes;
	instituteResources: InstituteResources;
	showForm = false;
	showEditPanel = false;
	constructor(
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private instituteService: InstituteService) {
		this.subscriptions.push(
		activatedRoute.params.subscribe(params => {
			this.instituteId = params.instituteId;
			this.buildForm();
			this.getResource(params.resourceId);
		}));
	}

	buildForm() {
		this.resourcesForm = this.formBuilder.group({
			name: null,
			type: null,
			side: null,
			instituteId: null,
			personId: null,
			softwareOntologies: null,
			softwareType: null,
			softwareTypeComment: null,
			softwareExportFormat: null,
			softwareExportFormatComment: null,
			softwareImportFormat: null,
			softwareImportFormatComment: null,
			hardwareType: null,
			hardwareTypeComment: null,
			hardwareServersNumber: null,
			hardwareDescription: null,
			databaseSize: null,
			datasetConfidentialInformation: null,
			datasetConfidentialInformationComment: null,
			databaseStructure: null,
			databaseStructureComment: null,
			databaseOntologies: null,
			dataSampleProvided: null,
			databaseImportedFormat: null,
			databaseImportedFormatComment:null,
			databaseExportedFormat: null,
			databaseExportedFormatComment: null

		});
		this.showForm = true;
		this.resourcesForm.disable();
	}

	getResource(resourceId: number) {
		const params = new HttpParams({
			fromObject: { join: ['person', 'institute'], instituteId: this.instituteId || null }
		});

		this.instituteService.fetchResource(resourceId, params).subscribe(resourceJson => {
			this.instituteResources = resourceJson;
			this.showEditPanel = ( this.instituteResources.type === this.resourceTypes.DATABASE ||
				this.instituteResources.type === this.resourceTypes.HARDWARE ||
				this.instituteResources.type === this.resourceTypes.SOFTWARE );
			this.setForm();
		});
	}

	setForm() {
		this.resourcesForm.patchValue(this.instituteResources);

	}

	onEdit() {
		this.resourcesForm.enable();
		this.isEditable = true;
	}

	onSave() {
		const params = new HttpParams({
			fromObject: {
				instituteId: this.instituteId || null
			}
		});
		const formData = this.resourcesForm.getRawValue();
		this.instituteService.editResource(formData, this.instituteResources.id, params).subscribe(res => {
			this.resourcesForm.disable();
			this.isEditable = false;
		})
	}


}
