<metro-membership-breadcrumbs></metro-membership-breadcrumbs>

<form nz-form nzLayout="vertical" [formGroup]="searchForm" (ngSubmit)="formSubmit()" class="pt-4 pb-8 px-10 mb-14">
	<div nz-row nzGutter="30">
		<div nz-col nzSpan="8">
			<nz-form-item>
				<nz-form-label>Institute name</nz-form-label>
				<nz-form-control>
					<nz-input-group [nzSuffix]="nameClearTpl" nzAddOnBeforeIcon="icons:library">
						<input nz-input placeholder="Institute name" formControlName="name" />
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<ng-template #nameClearTpl>
				<i nz-icon class="ant-input-clear-icon" nzTheme="outline" nzType="close-circle" *ngIf="searchForm.get('name').value"
					 (click)="searchForm.get('name').reset()"></i>
			</ng-template>
		</div>

		<div nz-col nzSpan="8">
			<nz-form-item>
				<nz-form-label>Country</nz-form-label>
				<nz-form-control>
					<nz-input-group [nzSuffix]="countryClearTpl" nzAddOnBeforeIcon="icons:map-marker">
						<input nz-input placeholder="Country" formControlName="country" />
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<ng-template #countryClearTpl>
				<i nz-icon class="ant-input-clear-icon" nzTheme="outline" nzType="close-circle" *ngIf="searchForm.get('country').value"
					 (click)="searchForm.get('country').reset()"></i>
			</ng-template>
		</div>

		<div nz-col nzSpan="8">
			<nz-checkbox-wrapper (nzOnChange)="searchForm.get('with').setValue($event)">
				<div nz-row [nzGutter]="[24, 0]" class="mt-9">
					<div nz-col nzSpan="12">
						<label nz-checkbox nzValue="physical" [nzChecked]="isChecked('with', 'physical')">
							With physical facilities
						</label>
					</div>
					<div nz-col nzSpan="12">
						<label nz-checkbox nzValue="e-resources" [nzChecked]="isChecked('with', 'e-resources')">
							With e-resources
						</label>
					</div>
				</div>
			</nz-checkbox-wrapper>
		</div>

		<div nz-col nzSpan="8">
			<nz-select [nzMaxTagCount]="1" [nzMaxTagPlaceholder]="tagPlaceHolder" [nzCustomTemplate]="multipleTemplate" [nzAllowClear]="true"
								 [nzShowArrow]="true" nzMode="multiple" nzPlaceHolder="Filter institutes by type" formControlName="type" class="w-100">
				<nz-option nzLabel="EU/National Reference Laboratory" nzValue="item1"></nz-option>
				<nz-option nzLabel="National Metrology Institute" nzValue="item2"></nz-option>
				<nz-option nzLabel="Designated Institute" nzValue="item3"></nz-option>
				<nz-option nzLabel="EFSA Art. 36 Institute" nzValue="item4"></nz-option>
				<nz-option nzLabel="with International or National harmonisation / standardisation Body" nzValue="item5"></nz-option>
			</nz-select>
			<ng-template #multipleTemplate let-selected>
				Filter institutes by type: {{ selected.nzLabel }}
			</ng-template>
			<ng-template #tagPlaceHolder let-selectedList>, {{ selectedList.length }} more </ng-template>
		</div>

		<div nz-col nzSpan="8">
			<label class="mr-2">Show node representative only</label>
			<nz-switch formControlName="isNodeRep" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
			<ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
			<ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
		</div>
	</div>

	<div nz-row class="mt-6">
		<div nz-col nzSpan="24" class="text-center">
			<button nz-button nzType="primary" class="my-2 mx-2">Find<i nz-icon nzType="search"></i></button>
			<button nz-button (click)="searchForm.reset({ type: [], with: [] })" type="button" class="my-2 mx-2">Clear All</button>
		</div>
	</div>
</form>

<metro-membership-paginated-table [headers]="tableHeaders" [data]="institutes | async" [params]="filterParams" (paramsChange)="makeQuery($event)">
	<ng-template #trTemplate let-data>
		<tr>
			<td><a routerLink="/institutes/{{ data.id }}">{{ data.shortName }}</a></td>
			<td>{{ data.country }}</td>
			<td>
				<span class="mr-2" nz-typography nzCopyable [nzContent]="data.personEmail"></span>
			</td>
			<td>
				<!-- <a href="{{ data.url }}" target="_blank">
					<span class="mr-2">{{ data.url }}</span>
					<i nz-icon nzType="link"></i>
				</a> -->
			</td>
		</tr>
	</ng-template>
</metro-membership-paginated-table>
