<form action="" class="mt-2" [formGroup]="questionForm" *ngIf="showForm">
	<div class="d-flex justify-content-between">
		<h1>{{institute?.shortName }} - Institute Quality data</h1>
		<div>
			<button class=" d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
				<div>EDIT</div>
			</button>

			<button class=" d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" [ngClass]="{'disabled': (questionForm.invalid || !isCertified || !isSopChecked) }">
				<div>SAVE</div>
			</button>
		</div>
	</div>


	<div class="relative mb-15">
		<h3 class="mb-3">1. Is within your institution a person appointed as responsible for the quality management system (such a person is often called quality assurance manager, quality coordinator etc.)?</h3>

			<nz-radio-group formControlName="qualityManagerSystemQuestionStatus" >
				<label nz-radio [nzValue]="true">YES</label>
				<label nz-radio [nzValue]="false">NO</label>
			</nz-radio-group>

      <div *ngIf="questionForm.controls.qualityManagerSystemQuestionStatus.invalid" class="invalid-message">
        required
      </div>

			<div *ngIf="questionForm.controls.qualityManagerSystemQuestionStatus.value" class="mt-3">
				<h3 class="mb-3">what is his/her position in the organization?</h3>

				<nz-radio-group class="d-flex flex-column" formControlName="qualityManagerSystemQuestion">

					<label nz-radio nzValue="MANAGEMENT_TEAM_MEMBER">Member of the management team reporting to the director</label>
					<label nz-radio nzValue="OTHER">Other</label>

          <metro-form-input *ngIf="questionForm.controls.qualityManagerSystemQuestion.value === 'OTHER'"
            class="standard mt-3 w-100" type="text" ph="Position name" formControlName="qualityManagerSystemQuestionComment"
            [control]="questionForm.controls.qualityManagerSystemQuestionComment">
          </metro-form-input>

          <div *ngIf="questionForm.controls.qualityManagerSystemQuestion.invalid" class="invalid-message">
            required
          </div>

			</nz-radio-group>


		</div>
		<div *ngIf="questionForm.controls.qualityManagerSystemQuestionStatus.value === false" class="mt-3">
			<h3 class="mb-3">will someone be recruited in the near future (by 2023)?</h3>
			<nz-radio-group formControlName="qualityManagerSystemQuestionSubStatus" class="d-flex">

				<label nz-radio [nzValue]="true">YES</label>
				<label nz-radio [nzValue]="false">NO</label>

			</nz-radio-group>
      <div *ngIf="questionForm.controls.qualityManagerSystemQuestionSubStatus.invalid" class="invalid-message">
        required
      </div>
		</div>
</div>

<div class="relative mb-15">
	<h3 class="mb-3">2. Is your institution certified?</h3>
	<div>
		<nz-radio-group formControlName="instituteCertificationStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.instituteCertificationStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>
		<div *ngIf="questionForm.controls.instituteCertificationStatus.value" class="mt-3 mb-3">
			<h3>what is the scope of the certification?</h3>

			<div class="d-flex justify-content-between flex-column">
				<label nz-checkbox formControlName="instituteCertificationISO9000">
					<div class="title">ISO 9000/9001</div>
				</label>

				<label nz-checkbox formControlName="instituteCertificationISO14001">
					<div class="title">ISO 14001</div>
				</label>

				<label nz-checkbox formControlName="instituteCertificationOther">
					<div class="title">Other (please specify in the comment box below)</div>
				</label>

				<metro-form-input *ngIf="questionForm.controls.instituteCertificationOther.value"
				class="standard mt-3 w-100" type="text" ph="Other" formControlName="instituteCertificationOtherComment"
				[control]="questionForm.controls.instituteCertificationOtherComment">
			</metro-form-input>

      <div *ngIf="!isCertified" class="invalid-message">choose Certs </div>
		</div>
	</div>

	<div *ngIf="questionForm.controls.instituteCertificationStatus.value === false" class="mt-3 mb-3">
		<h3>Is it planned in the near future (by 2023)?</h3>
		<nz-radio-group formControlName="instituteCertificationSubStatus" class="d-flex">

			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.instituteCertificationSubStatus.invalid" class="invalid-message">
        required
      </div>

		</nz-radio-group>
	</div>
</div>
</div>

<div class="relative mb-15" *ngIf="questionForm.controls.instituteCertificationStatus.value">
	<h3 class="mb-3">3. How are costs associated with accreditation/certification accounted for?</h3>
		<div class="d-flex justify-content-between flex-column">
			<label nz-checkbox formControlName="certificationCostsGeneralCost">
				<div class="title">As general cost (institution level)</div>
			</label>

			<label nz-checkbox formControlName="certificationCostsFacilityBudget">
				<div class="title">As facility budget</div>
			</label>

      <label nz-checkbox formControlName="certificationCostsOther">
				<div class="title">Other</div>
			</label>

      <metro-form-input *ngIf="questionForm.controls.certificationCostsOther.value"
      class="standard mt-3 w-100" type="text" ph="Other" formControlName="certificationCostsOtherComment"
      [control]="questionForm.controls.certificationCostsOtherComment">
    </metro-form-input>
		</div>
</div>

<div class="relative mb-15">
	<h3 class="mb-3">4. Do you provide services to external users?</h3>
	<div>
		<nz-radio-group formControlName="servicesExternalUsersStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.servicesExternalUsersStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>
	</div>
</div>
<ng-container *ngIf="questionForm.controls.servicesExternalUsersStatus.value">

	<div class="relative mb-15">
		<h3 class="mb-3">5. How do you evaluate satisfaction of external users and stakeholders?</h3>
		<div>
			<div class="d-flex justify-content-between flex-column">
				<label nz-checkbox formControlName="externalUsersSatisfactionSurvey">
					<div class="title">Client satisfaction survey</div>
				</label>

				<label nz-checkbox formControlName="externalUsersSatisfactionMeeting">
					<div class="title">Stakeholder meeting</div>
				</label>

				<label nz-checkbox formControlName="externalUsersSatisfactionOther">
					<div class="title">Other (please specify in the comment box below)</div>
				</label>

				<metro-form-input *ngIf="questionForm.controls.externalUsersSatisfactionOther.value"
				class="standard mt-3 w-100" type="text" ph="Other" formControlName="externalUsersSatisfactionOtherComment"
				[control]="questionForm.controls.externalUsersSatisfactionOtherComment">
			</metro-form-input>
		</div>
	</div>
</div>
<div class="relative mb-15">
	<h3 class="mb-3">6. Does your institution have a procedure for handling customer complaints?</h3>
	<div>
		<nz-radio-group formControlName="handlingCustomerComplaintsStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.handlingCustomerComplaintsStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>

		<div *ngIf="questionForm.controls.handlingCustomerComplaintsStatus.value === false" class="mt-3">
			<h3 class="mb-3">Is it planned in the near future (by 2023)?</h3>
			<nz-radio-group formControlName="handlingCustomerComplaintsSubStatus" class="d-flex">

				<label nz-radio [nzValue]="true">YES</label>
				<label nz-radio [nzValue]="false">NO</label>
        <div *ngIf="questionForm.controls.handlingCustomerComplaintsSubStatus.invalid" class="invalid-message">
          required
        </div>
			</nz-radio-group>
		</div>
	</div>
</div>

</ng-container>

<div class="relative mb-15">
	<h3 class="mb-3">7. Does your institution apply a standard operating procedure describing the contingency plan in the event of partial or total failure of equipment ( to be used in METROFOOD-RI)?</h3>
	<div>
		<nz-radio-group formControlName="applyingOperatingProcedureStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.applyingOperatingProcedureStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>

		<div *ngIf="questionForm.controls.applyingOperatingProcedureStatus.value === false" class="mb-3 mt-3">
			<h3>would you need guidance for the implementation?</h3>
			<nz-radio-group formControlName="applyingOperatingProcedureSubStatus" class="d-flex">

				<label nz-radio [nzValue]="true">YES</label>
				<label nz-radio [nzValue]="false">NO</label>
        <div *ngIf="questionForm.controls.applyingOperatingProcedureSubStatus.invalid" class="invalid-message">
          required
        </div>
			</nz-radio-group>
		</div>
	</div>
</div>

<div class="relative mb-15">
	<h3 class="mb-3">8. Does your institution have an updated work plan related with research activities?</h3>
	<div >
		<nz-radio-group formControlName="researchActivitiesStatus" >
			<label nz-radio nzValue="YES">YES</label>
			<label nz-radio nzValue="NO">NO</label>
      <label nz-radio nzValue="NOAVAILIABLE">Yes, institution have an updated work plan related with research activities, but it in not publicy avaliable</label>
      <div *ngIf="questionForm.controls.researchActivitiesStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>

		<div *ngIf="questionForm.controls.researchActivitiesStatus.value === 'YES'">
			<h3 class="mb-3 mt-3">if possible, please provide a table of contents.</h3>

			<div class="">

				<nz-upload
				[nzHeaders]="{ authorization: 'authorization-text' }"
				(nzChange)="handleChange($event)"
				>
				<button nz-button><i nz-icon nzType="upload"></i>Click to Upload</button>
			</nz-upload>

		</div>

	</div>
</div>
</div>

<div class="relative mb-15">
	<h3 class="mb-3">9. Does your institution have any internal/external link with the quality management of other research infrastructures?</h3>
	<div class="mb-3">
		<nz-radio-group formControlName="researchInfrastructuresStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.researchInfrastructuresStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>

		<div *ngIf="questionForm.controls.researchInfrastructuresStatus.value">
			<h3 class="mt-3">Please identify the Research Infrastructure(s).</h3>

			<div class="d-flex justify-content-between">
				<metro-form-input
				class="standard mt-3 w-100" type="text" ph="Other" formControlName="researchInfrastructures"
				[control]="questionForm.controls.researchInfrastructures">
			</metro-form-input>
		</div>
	</div>
</div>
</div>

<div class="relative mb-15">
	<h3 class="mb-3">10. Does your institution have any quality documentation system for research activities?</h3>
	<div>
		<nz-radio-group formControlName="researchDocumentationStatus" >
			<label nz-radio nzValue="YES">YES</label>
			<label nz-radio nzValue="NO">NO</label>
      <label nz-radio nzValue="NOAVAILIABLE">Yes, institution have any quality documentation system for research activities, but it in not publicy avaliable</label>
      <div *ngIf="questionForm.controls.researchDocumentationStatus.invalid" class="invalid-message">required</div>
    </nz-radio-group>

		<div *ngIf="questionForm.controls.researchDocumentationStatus.value === 'YES' " class="d-flex justify-content-between flex-column mt-3">

			<label nz-checkbox formControlName="researchDocumentationManagementSystem">
				<div class="title">Internal project management system</div>
			</label>
			<label nz-checkbox formControlName="researchDocumentationOperationalProcedure">
				<div class="title">Operational Procedure for Design (Research) and Development</div>
			</label>
			<label nz-checkbox formControlName="researchDocumentationResearchActivities">
				<div class="title">Annual report of research activities</div>
			</label>
			<label nz-checkbox formControlName="researchDocumentationReportFellowships">
				<div class="title">Annual report of fellowships</div>
			</label>
			<label nz-checkbox formControlName="researchDocumentationOther">
				<div class="title">Other (please specify in the comment box below)</div>
			</label>

      <metro-form-input *ngIf="questionForm.controls.researchDocumentationOther.value"
        class="standard mt-3 w-100" type="text" ph="Other" formControlName="researchDocumentationOtherComment"
        [control]="questionForm.controls.researchDocumentationOtherComment">
      </metro-form-input>

      <h3 class="mt-3 mb-3">if possible, please provide a table of content.</h3>

			<div class="">

				<nz-upload
				[nzHeaders]="{ authorization: 'authorization-text' }"
				(nzChange)="handleChange($event)"
				>
				<button nz-button><i nz-icon nzType="upload"></i>Click to Upload</button>
			</nz-upload>

		</div>
	</div>
</div>
</div>

<div class="relative mb-15">
	<h3 class="mb-3">11.	Does your institution have any SOPs for dissemination and exploitation of your results and communication with other organizations related with research activities?</h3>
	<div>
		<nz-radio-group formControlName="sopStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.sopStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>

		<div class="d-flex justify-content-between flex-column" *ngIf="questionForm.controls.sopStatus.value">
			<h3 class="mt-3 mb-3">please specify.</h3>
			<label nz-checkbox formControlName="sopPolicy">
				<div class="title">Policy for dissemination depends on the research project and is generally set in the research or grant agreement</div>
			</label>
			<label nz-checkbox formControlName="sopConfidentiality">
				<div class="title">Confidentiality when requested</div>
			</label>
			<label nz-checkbox formControlName="sopConferences">
				<div class="title">Conferences</div>
			</label>
			<label nz-checkbox formControlName="sopPapers">
				<div class="title">Scientific papers</div>
			</label>
			<label nz-checkbox formControlName="sopTV">
				<div class="title">TV communication</div>
			</label>
			<label nz-checkbox formControlName="sopVisits">
				<div class="title">Scientific visits </div>
			</label>
			<label nz-checkbox formControlName="sopPartners">
				<div class="title">Presentation to the industrial partners</div>
			</label>
			<label nz-checkbox formControlName="sopOpenAccess">
				<div class="title">Open access</div>
			</label>
			<label nz-checkbox formControlName="sopAgroFoodSector">
				<div class="title">Plans and activities for cooperation with agro-food sector</div>
			</label>
			<label nz-checkbox formControlName="sopMeeting">
				<div class="title">Meeting</div>
			</label>
			<label nz-checkbox formControlName="sopWebConf">
				<div class="title">Web conference</div>
			</label>
			<label nz-checkbox formControlName="sopWorkShops">
				<div class="title">Workshops seminars</div>
			</label>
			<label nz-checkbox formControlName="sopHomePageSite">
				<div class="title">Home page site </div>
			</label>
			<label nz-checkbox formControlName="sopTechnicalReports">
				<div class="title">Technical and scientific reports</div>
			</label>
			<label nz-checkbox formControlName="sopPatent">
				<div class="title">Patent</div>
			</label>
			<label nz-checkbox formControlName="sopOther">
				<div class="title">Other (please specify in the comment box below) </div>
			</label>

			<metro-form-input *ngIf="questionForm.controls.sopOther.value"
			class="standard mt-3 w-100" type="text" ph="Other" formControlName="sopOtherComment"
			[control]="questionForm.controls.sopOtherComment">
		</metro-form-input>
    <div *ngIf="!isSopChecked" class="invalid-message">choose SOPs </div>
	</div>
</div>
</div>

<div class="relative mb-15">
	<h3 class="mb-3" >12.	Does your institution have any SOPs for record keeping and document control related with research activities?</h3>
	<div>
		<nz-radio-group formControlName="sopRecordStatus" >
			<label nz-radio nzValue="YES">YES</label>
			<label nz-radio nzValue="NO">NO</label>
      <label nz-radio nzValue="NOAVAILIABLE">Yes, institution does have any SOPs for record keeping and document control related with research activities, but it in not publicy avaliable</label>
      <div *ngIf="questionForm.controls.sopRecordStatus.invalid" class="invalid-message">required</div>
    </nz-radio-group>

		<div *ngIf="questionForm.controls.sopRecordStatus.value ==='YES'">
			<h3>if possible, please provide a table of content.</h3>

			<div class="d-flex justify-content-between">
				<div class="">

					<nz-upload
					[nzHeaders]="{ authorization: 'authorization-text' }"
					(nzChange)="handleChange($event)"
					>
					<button nz-button><i nz-icon nzType="upload"></i>Click to Upload</button>
				</nz-upload>

			</div>
		</div>
	</div>

	<div *ngIf="questionForm.controls.sopRecordStatus.value === 'NO' " class="mt-3">
		<h3 class="mb-3">is it planned in the near feature (by 2023)</h3>
		<nz-radio-group formControlName="sopRecordSubStatus" class="d-flex">

			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.sopRecordStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>
	</div>
</div>
</div>

<div class="relative mb-15">
	<h3 class="mb-3">13.Regarding research activities,	does your institution maintain an audit-trail that permits a suitably trained and experienced third party to repeat procedures in a future date?
	</h3>
	<div>

		<nz-radio-group formControlName="audiTrailStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.audiTrailStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>

		<div *ngIf="questionForm.controls.audiTrailStatus.value === false">
			<h3 class="mt-3 mb-3">do you think it should be required?</h3>
			<nz-radio-group formControlName="audiTrailSubStatus" class="d-flex">

				<label nz-radio [nzValue]="true">YES</label>
				<label nz-radio [nzValue]="false">NO</label>
        <div *ngIf="questionForm.controls.audiTrailSubStatus.invalid" class="invalid-message">required</div>
			</nz-radio-group>
		</div>
	</div>
</div>

<div class="relative mb-15">
	<h3>14.	Can you recommend Quality Management System (QMS) aspects that are not covered by this questionnaire but could be usefully implemented in METROFOOD-RI, in the current or future situation?
	</h3>
	<div>

		<nz-radio-group formControlName="qmsStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
      <div *ngIf="questionForm.controls.qmsStatus.invalid" class="invalid-message">required</div>
		</nz-radio-group>

		<div *ngIf="questionForm.controls.qmsStatus.value">
			<h3>please specify these requirements aspects briefly.</h3>

			<div class="d-flex justify-content-between">
				<metro-form-input
				class="standard mt-3 w-100" type="text" ph="Other" formControlName="qms"
				[control]="questionForm.controls.qms">
			</metro-form-input>
		</div>
	</div>

</div>
</div>

</form>
