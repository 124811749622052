import { Injectable } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, UrlSegment } from '@angular/router';
import { BreadcrumbOption } from 'ng-zorro-antd/breadcrumb';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { BreadcrumbPart } from './breadcrumb-part';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
	breadcrumbParts: BehaviorSubject<Record<string, BreadcrumbPart>> = new BehaviorSubject<Record<string, BreadcrumbPart>>({});

	putBreadcrumbPart(type: string, part: BreadcrumbPart) {
		return this.breadcrumbParts.pipe(
			first()
		).subscribe(parts => {
			parts[type] = part;
			this.breadcrumbParts.next(parts);
		})
	}

	getBreadcrumbs(route: ActivatedRoute): BreadcrumbOption[] {
		const breadcrumbs = this.getBreadcrumbsRecursive(route);
		const breadcrumb: BreadcrumbOption = {
			label: 'Home',
			params: {},
			url: ''
		};
		return [breadcrumb, ...breadcrumbs];
	}

	private getBreadcrumbsRecursive(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbOption[] = []): BreadcrumbOption[] {
		const children: ActivatedRoute[] = route.children;
		if (children.length === 0) {
			return breadcrumbs;
		}

		for (const child of children) {
			if (child.outlet === PRIMARY_OUTLET) {
				const routeUrl = this.getRouteUrl(child.snapshot.url);
				const nextUrl = routeUrl ? url + `/${routeUrl}` : url;
				let breadcrumbLabel = child.snapshot.data.breadcrumb;

				if (routeUrl && breadcrumbLabel) {
					if (breadcrumbLabel[0] === ':') {
						const label = breadcrumbLabel.slice(1);
						const parts = this.breadcrumbParts.getValue();
						breadcrumbLabel = parts[label]?.value || label[0].toUpperCase() + label.slice(1);
					}

					const breadcrumb: BreadcrumbOption = {
						label: breadcrumbLabel,
						params: child.snapshot.params,
						url: nextUrl
					};
					breadcrumbs.push(breadcrumb);
				}

				return this.getBreadcrumbsRecursive(child, nextUrl, breadcrumbs);
			}
		}

		return breadcrumbs;
	}

	private getRouteUrl(url: UrlSegment[]): string {
		return url.map(segment => segment.path)
			.filter(path => path)
			.join('/');
	}
}
