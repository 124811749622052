import {
	AfterViewInit,
	Component,
	ElementRef,
	forwardRef,
	Input,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => FormInputComponent),
	multi: true,
};

const enum MetricEnum {
	CURRENCY = 'CURR',
	PERCENTAGE = '%'
};

@Component({
	selector: 'metro-form-input',
	templateUrl: './form-input.component.html',
	styleUrls: ['./form-input.component.less'],
	encapsulation: ViewEncapsulation.None,
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class FormInputComponent implements ControlValueAccessor, AfterViewInit, OnInit  {
	@Input() label = '';
	@Input() unit = null;
	@Input() metric: MetricEnum;
	@Input() ph = '';
	@Input() type = '';
	@Input() class = '';
	@Input() control: FormControl = new FormControl();

	private innerValue: any = '';
	@ViewChild('input') input: ElementRef;
	onTouched: any = () => {};

	constructor() {}

	ngOnInit() {
		if (this.control.value) {
			this.innerValue = this.control.value;
		} else {
			this.innerValue = '';
		}
	}

	ngAfterViewInit() {
		this.control.valueChanges.subscribe((changes) => {
			if (this.control.value === '' || this.control.value === null || this.control.value === undefined) {
				this.innerValue = '';
				this.input.nativeElement.value = '';
			}
		});
	}
	onChange(e: any, value: any) {
		this.innerValue =  value
		this.propagateChange(this.innerValue);
		if (e.key !== 'Enter') {
			this.control.markAsUntouched();
		}
	}
	handleFocusOut() {
		const valueLength = this.innerValue?.length;
		const lastChar = this.innerValue.charAt(valueLength - 1)
		if(lastChar === ',') {
			this.innerValue = this.innerValue + '00';
			this.propagateChange(this.innerValue);
		}
	}

	get value(): any {
		return this.innerValue;
	}
	set value(v: any) {
		if (v !== this.innerValue) {
			this.innerValue = v;
		}
	}

	propagateChange = (_: any) => {};

	writeValue(value: any) {
		this.innerValue = value;
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	getErrorMessage() {
		return this.control.hasError('required')
		? 'This field cannot be empty.'
		: this.control.hasError('digits')
		? 'numbers only'
		: this.control.hasError('percentageValue')
		? 'This entry can contain numbers between 0-100 only'
		: this.control.hasError('dot')
		? 'This entry can contain commas only'
		: this.control.hasError('fractions-2')
		? 'This entry can contain only two numbers after comma'
		: '';
	}

	isValid() {
		return this.control.invalid;
	}

}
