export const sector = [
	{
		isActive: false,
		number: 0,
		name: 'AGRICULTURAL_FIELDS',
	},
	{
		isActive: false,
		number: 0,
		name: 'GREENHOUSES',
	},
	{
		isActive: false,
		number: 0,
		name: 'LIVESTOCK_BREEDING',
	},
	{
		isActive: false,
		number: 0,
		name: 'AQUACULTURE',
	},
	{
		isActive: false,
		number: 0,
		name: 'AGRICULTURAL_BIOENERGY_PROD',
	},
	{
		isActive: false,
		number: 0,
		name: 'BIOTECHNOLOGICAL_PRODUCTION_FOODS',
	},
	{
		isActive: false,
		number: 0,
		name: 'SECTOR_OTHER',
	}
];

export const endProducts = [
	{
		isActive: false,
		number: 0,
		name: 'FRUTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'VEGETABLES',
	},
	{
		isActive: false,
		number: 0,
		name: 'FISH_SHELLFISH',
	},
	{
		isActive: false,
		number: 0,
		name: 'MEAT',
	},
	{
		isActive: false,
		number: 0,
		name: 'MILK_DIARY_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'HONEY_BEE_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'BEVERAGES',
	},
	{
		isActive: false,
		number: 0,
		name: 'MACRO_MICRO_ALGAE',
	},
	{
		isActive: false,
		number: 0,
		name: 'NON_FOOD_AGRICULTURAL',
	},
	{
		isActive: false,
		number: 0,
		name: 'END_PRODUCTS_OTHER',
	}
];

export const useRM = [
	{
		isActive: false,
		number:  0,
		name: 'USE_RM',
	},
]

export const amount = [
	{
		isActive: false,
		number:  0,
		name: 'PRODUCED_RATE_AMOUNT',
	},
]
