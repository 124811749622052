export const analyticalServices = [
	{ formKey: 'samplePreparationStorage', label: 'Sampling, sample preparation and storage' },
	{ formKey: 'foodCompositionCharacterization', label: 'Food composition and characterization' },
	{ formKey: 'inorganicContaminant', label: 'Inorganic contaminants' },
	{ formKey: 'organicContaminants', label: 'Organic contaminants' },
	{ formKey: 'chemicalBiologicalMarkersProfiles', label: 'Chemical and biological markers and profiles' },
	{ formKey: 'microbiologicalAnalysis', label: 'Microbiological analysis' },
	{ formKey: 'allergenTesting', label: 'Allergen testing' },
	{ formKey: 'geneticAnalyses', label: 'Genetic analyses' },
	{ formKey: 'characterizationFCMMigrationTests', label: 'Analyses related to food contact materials (characterization of FCM, migration tests, etc.)' },
	{ formKey: 'analysisFoodAdditivesSupplements', label: 'Analysis of food additives and supplements' },
	{ formKey: 'developmentSensorsDevices', label: 'Development of sensors and devices (ex.: development of sensors for applications in food; sensors for in-situ, on-line and in-line analyses, etc.)' },
	{ formKey: 'agroecoSystemCharacterisation', label: 'Agroecosystem characterisation (ex.: analysis of soil, water, air pollutants; bioavailability studies; qualification of the environment of production; etc.)' },
	{ formKey: 'testing', label: 'Testing (ex.: rehological, leaching, etc.)' },

]
export enum analyticalServicesEnum {
	samplePreparationStorage = 'Sampling, sample preparation and storage',
	foodCompositionCharacterization = 'Food composition and characterization',
	inorganicContaminant = 'Inorganic contaminants',
	organicContaminants = 'Organic contaminants',
	chemicalBiologicalMarkersProfiles = 'Chemical and biological markers and profiles',
	microbiologicalAnalysis = 'Microbiological analysis',
	allergenTesting = 'Allergen testing',
	geneticAnalyses = 'Genetic analyses',
	characterizationFCMMigrationTests = 'Analyses related to food contact materials (characterization of FCM, migration tests, etc.)',
	analysisFoodAdditivesSupplements = 'Analysis of food additives and supplements',
	developmentSensorsDevices = 'Development of sensors and devices (ex.: development of sensors for applications in food; sensors for in-situ, on-line and in-line analyses, etc.)',
	agroecoSystemCharacterisation = 'Agroecosystem characterisation (ex.: analysis of soil, water, air pollutants; bioavailability studies; qualification of the environment of production; etc.)',
	testing = 'Testing (ex.: rehological, leaching, etc.)'
}
