<div class="mt-2 d-flex justify-content-end" *ngIf="showEditPanel">
	<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
		<span>EDIT</span>
		<i nz-icon nzType="icons:edit" class="ml-4"></i>
	</button>
	<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable">
		<span>SAVE</span>
	</button>
</div>

<ng-container *ngIf="showForm">
	<div [ngSwitch]="instituteResources?.type">
		<metro-membership-resources-software *ngSwitchCase="resourceTypes.SOFTWARE" [resourcesForm] = "resourcesForm"></metro-membership-resources-software>
		<metro-membership-resources-hardware *ngSwitchCase="resourceTypes.HARDWARE" [resourcesForm] = "resourcesForm"></metro-membership-resources-hardware>
		<metro-membership-resources-database *ngSwitchCase="resourceTypes.DATABASE" [resourcesForm] = "resourcesForm"></metro-membership-resources-database>
		<div class="title" *ngSwitchDefault>There is no additional questions.</div>
	</div>
</ng-container>
