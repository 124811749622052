import { NgModule } from '@angular/core';
import { DefaultModule } from '../default/default.module';
import { SharedModule } from '../shared/shared.module';
import { FacilityRoutingModule } from './facility-routing.module';
import { FacilityDetailsPage } from './facility-details/facility-details.page';
import { FacilityEquipmentPage } from './facility-equipment/facility-equipment.page';
import { FacilityFinancesPage } from './facility-finances/facility-finances.page';
import { FacilityHrPage } from './facility-hr/facility-hr.page';
import { FacilityPeoplePage } from './facility-people/facility-people.page';
import { FacilitySectorPage } from './facility-sector/facility-sector.page';
import { FacilityServicesPage } from './facility-services/facility-services.page';
import { FacilityUpgradePlansPage } from './facility-upgrade-plans/facility-upgrade-plans.page';
import { FacilityPage } from './facility/facility.page';
import { FacilitySiteComponent } from './facility-details/site-modal/site-modal.component';
import { KitchenLabsEquipmentComponent } from './facility-equipment/kitchen-labs-equipment/kitchen-labs-equipment.component';
import { RmPlantsComponent } from './facility-equipment/rm-plants/rm-plants.component';
import { AnalyticalComponent } from './facility-equipment/analytical/analytical.component';
import { AnalyticalServiceComponent } from './facility-services/analytical/analytical-services.component';
import { RmServicesComponent } from './facility-services/rm-services/rm-services.component';
import { FacilityPercentRiPage } from './facility-percent-ri/facility-percent-ri.page';
import { ProductionComponent } from './facility-equipment/production/production.component';
import { SectorProductionComponent } from './facility-sector/sector-production/sector-production.component';
import { SectorRmComponent } from './facility-sector/sector-rm/sector-rm.component';
import { SectorAnalyticalComponent } from './facility-sector/sector-analytical/sector-analytical.component';
import { SectorKitchenComponent } from './facility-sector/sector-kitchen/sector-kitchen.component';
import { FacilityQualityPage } from './facility-quality/facility-quality.page';

@NgModule({
	declarations: [
		FacilityDetailsPage,
		FacilityEquipmentPage,
		FacilityFinancesPage,
		FacilityHrPage,
		FacilityPeoplePage,
		FacilityPercentRiPage,
		FacilitySectorPage,
		FacilityServicesPage,
		FacilityUpgradePlansPage,
		FacilityPage,
		FacilitySiteComponent,
		KitchenLabsEquipmentComponent,
		RmPlantsComponent,
		AnalyticalComponent,
		AnalyticalServiceComponent,
		RmServicesComponent,
		ProductionComponent,
		SectorProductionComponent,
		SectorRmComponent,
		SectorAnalyticalComponent,
		SectorKitchenComponent,
		FacilityQualityPage
	],
	imports: [
		SharedModule,
		DefaultModule, // Temporary entry, until under-development is removed ;)
		FacilityRoutingModule
	]
})
export class FacilityModule {}
