import { APP_INITIALIZER } from '@angular/core';
import { initializeKeycloak, KeycloakService } from '@metro/ui-core';
import { environment } from '../../environments/environment';
import { UserService } from './services/user.service';
import { ConfigService } from './services/config.service';

export const appInitializerProvider = {
	provide: APP_INITIALIZER,
	useFactory: initializeApp,
	deps: [ConfigService, UserService, KeycloakService],
	multi: true
}

function initializeApp(config: ConfigService, userService: UserService, keycloak: KeycloakService) {
	return async () => {
		await config.loadFiles('/assets/config.json');
		await initializeKeycloak({ keycloak, config }, {
			enableLogging: !environment.production,
			loadProfile: true,
			forceLogin: true
		});

		await userService.getAndStoreUser(keycloak.getKeycloakInstance().subject,
			keycloak.getKeycloakInstance().profile.email ).toPromise();
		userService.keycloakRoles = keycloak.getUserRoles();

		const authGM = userService.getCurrentAuthGrantManager();
		if (!await authGM.hasAppRole('consortium_member') && !await authGM.isSystemAdmin()) {
			// setTimeout((time) => {
			// 	location.reload()
			// }, 2000)
			// throw new Error('Not a consortium member');
		}

	}
}

