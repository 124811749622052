export enum SectorStatuses {
	YES = "YES",
	NO = "NO",
	POTENTIALLY = "POTENTIALLY",
	PLANNED = "PLANNED"
}

export enum SectorCategories {
	OTHER = 'OTHER',
	SECTOR_OTHER = 'SECTOR_OTHER',
	FOOD_SAFETY = 'FOOD_SAFETY',
	FOOD_QUALITY = 'FOOD_QUALITY',
	FOOD_AUTHENTICITY = 'FOOD_AUTHENTICITY',
	NUTRITION = 'NUTRITION',
	AGROECOSYSTEM_CHAR = 'AGROECOSYSTEM_CHAR',
	CHARACTERISATION_MATERIAL = 'CHARACTERISATION_MATERIAL',
	TOXIC_ELEMENT = 'TOXIC_ELEMENT',
	MYCOTOXINS = 'MYCOTOXINS',
	PLANT_ALKALOIDS = 'PLANT_ALKALOIDS',
	SAFETY_OTHER_TOXINS = 'SAFETY_OTHER_TOXINS',
	PESTICIDES = 'PESTICIDES',
	VETERINARY_DRUG = 'VETERINARY_DRUG',
	VOC = 'VOC' ,
	POPS = 'POPS',
	PHENOLS = 'PHENOLS',
	MELAMINE = 'MELAMINE',
	NITRATES = 'NITRATES',
	PRECHLORATES = 'PRECHLORATES',
	ALLERGENS = 'ALLERGENS',
	ADDITIVES = 'ADDITIVES' ,
	GMO = 'GMO',
	AMINES = 'AMINES',
	BIOCIDES = 'BIOCIDES',
	HORMONES = 'HORMONES',
	HYDROCARBONS = 'HYDROCARBONS',
	HALOGENATED_CONTAMINANTS = 'HALOGENATED_CONTAMINANTS',
	MOH = 'MOH',
	PROCESS_CONTAMINANTS = 'PROCESS_CONTAMINANTS',
	ENVIRONMENTAL_CONTAMINANTS = 'ENVIRONMENTAL_CONTAMINANTS',
	FOOD_CONTACT_MATERIAL_MIGRATION = 'FOOD_CONTACT_MATERIAL_MIGRATION',
	NANOPARTICLES = 'NANOPARTICLES',
	MICROPLASTICS = 'MICROPLASTICS',
	PATHOGENIC_MICRO = 'PATHOGENIC_MICRO',
	PARASITES = 'PARASITES',
	PRIONS = 'PRIONS',
	VIRUSES = 'VIRUSES',
	RADIOACTIVITY = 'RADIOACTIVITY',
	SAFETY_OTHER = 'SAFETY_OTHER',
	TOTAL_CONTENT = 'TOTAL_CONTENT',
	BIOAVAILABILITY = 'BIOAVAILABILITY',
	SPECIATION = 'SPECIATION',
	OTHER_TOXINES = 'OTHER_TOXINES',
	HYDROCARBONS_OTHER = 'HYDROCARBONS_OTHER',
	ACRYLAMIDE = 'ACRYLAMIDE',
	FURANS = 'FURANS',
	MCDP_ESTERS = 'MCDP_ESTERS',
	PROCESS_CONTAM_OTHER = 'PROCESS_CONTAM_OTHER',
	PAHS = 'PAHS',
	PCB= 'PCB',
	PBDE_BFR= 'PBDE_BFR',
	PCDD_PCDF= 'PCDD_PCDF',
	PFAS= 'PFAS',
	CHLORINATED_PARAFFINS = 'CHLORINATED_PARAFFINS',
	ORGANOLEPTIC_PROPERTIES = 'ORGANOLEPTIC_PROPERTIES',
	PHYSICAL_CHEMICAL_ANALYSIS = 'PHYSICAL_CHEMICAL_ANALYSIS',
	BIOACTIVE_COMPOUNDS = 'BIOACTIVE_COMPOUNDS',
	MICROBIOLOGICAL_ANALYSIS = 'MICROBIOLOGICAL_ANALYSIS',
	IN_VITRO_BIOACTIVITY = 'IN_VITRO_BIOACTIVITY',
	SENSORY_ANALYSIS = 'SENSORY_ANALYSIS',
	ISOTOPES_LIGHT_ELEMENTS = 'ISOTOPES_LIGHT_ELEMENTS',
	ISOTOPES_HEAVY_ELEMENTS = 'ISOTOPES_HEAVY_ELEMENTS',
	RARE_EARTH_ELEMENTS = 'RARE_EARTH_ELEMENTS',
	ELEMENTAL_PROFILES = 'ELEMENTAL_PROFILES',
	OMNICS_ANALYSE = 'OMNICS_ANALYSE',
	GENETIC_MARKERS = 'GENETIC_MARKERS',
	ADULTERATION = 'ADULTERATION',
	PROTEOMICS = 'PROTEOMICS',
	LIPIDOICS = 'LIPIDOICS',
	METABOLOMICA = 'METABOLOMICA',
	GENOMICS = 'GENOMICS',
	MULTI_OMNICS = 'MULTI_OMNICS',
	FIBER = 'FIBER',
	LIPIDS_CONTENT = 'LIPIDS_CONTENT',
	FATTY_ACIDS = 'FATTY_ACIDS',
	DRY_MATTER = 'DRY_MATTER',
	VITAMINS = 'VITAMINS',
	CARBOHYDRATES ='CARBOHYDRATES',
	PROTEINS = 'PROTEINS',
	WATER = 'WATER',
	MINERALS = 'MINERALS',
	SURFACE_GROUNDWATER = 'SURFACE_GROUNDWATER',
	SOILS_SEDIMENTS = 'SOILS_SEDIMENTS',
	BIOAVAILABILITY_STUDIES = 'BIOAVAILABILITY_STUDIES',
	AIR_POLLUTION = 'AIR_POLLUTION' ,
	WET_DRY_DEPOSITIONS = 'WET_DRY_DEPOSITIONS',
	BIOINDICATORS = 'BIOINDICATORS',
	METALLIC_MATERIALS = 'METALLIC_MATERIALS',
	CERAMIC_MATERIALS = 'CERAMIC_MATERIALS',
	PLASTIC_MATERIALS = 'PLASTIC_MATERIALS',
	GLASS_MATERIALS = 'GLASS_MATERIALS',
	PAPER_MATERIALS = 'PAPER_MATERIALS',
	RECYCLED_MATERIALS = 'RECYCLED_MATERIALS',
	WOOD_PLANTS_MATERIALS = 'WOOD_PLANTS_MATERIALS',
	FOOD_OF_ANIMALS = 'FOOD_OF_ANIMALS',
	FOOD_OF_PLANTS = 'FOOD_OF_PLANTS',
	FOOD_PREPARED_PRODUCTS = 'FOOD_PREPARED_PRODUCTS',
	BEVERAGES_VINEGARS = 'BEVERAGES_VINEGARS',
	ADDITIVES_SUPPLEMENTS = 'ADDITIVES_SUPPLEMENTS',
	ADDITIVES_SUPLEMENTS_SPEC = 'ADDITIVES_SUPLEMENTS_SPEC',
	CEREALS = 'CEREALS',
	FRUIT_VEGETABLES ='FRUIT_VEGETABLES',
	OLIVES_OILS ='OLIVES_OILS',
	SUGAR_PLANTS = 'SUGAR_PLANTS',
	HERBS_SALT ='HERBS_SALT',
	COCA_COFFE ='COCA_COFFE',
	DRINK_WATER = 'DRINK_WATER',
	NON_ALCOHOLIC_BEVERAGES = 'NON_ALCOHOLIC_BEVERAGES',
	WINE = 'WINE',
	BEER_CIDER = 'BEER_CIDER',
	BEVERAGES = 'BEVERAGES',
	ALCOHOLIC_BEVERAGES = 'ALCOHOLIC_BEVERAGES',
	FRUIT_VEGETABLE_JUICES = 'FRUIT_VEGETABLE_JUICES',
	VINEGARS = 'VINEGARS',
	CANNED_FRUITS = 'CANNED_FRUITS',
	BREAD_BAKERY = 'BREAD_BAKERY',
	PASTA = 'PASTA',
	SAUCES = 'SAUCES',
	PREPARED_DISHES ='PREPARED_DISHES',
	INSTANT_FOODS ='INSTANT_FOODS',
	TOTAL_DIET = 'TOTAL_DIET',
	PREPARED_FOOD_OTHER = 'PREPARED_FOOD_OTHER',
	MEATS_POLUTRY_PRODUCTS = 'MEATS_POLUTRY_PRODUCTS',
	FISH_PRODUCTS = 'FISH_PRODUCTS',
	MILK_DIARY = 'MILK_DIARY',
	HONEY_BEE = 'HONEY_BEE',
	FATS_ANIMAL = 'FATS_ANIMAL',
	FOOD_PROCESSING = 'FOOD_PROCESSING',
	FOOD_STORAGE = 'FOOD_STORAGE',
	FOOD_PACKING = 'FOOD_PACKING',
	FOOD_PREPARATION = 'FOOD_PREPARATION',
	TREATMENT_REDUCTION = 'TREATMENT_REDUCTION',
	PRODUCTION_AIDS_FOOD = 'PRODUCTION_AIDS_FOOD',
	FRUTS = 'FRUTS',
	VEGETABLES = 'VEGETABLES',
	LEGUMES = 'LEGUMES',
	MEAT_PRODUCTS='MEAT_PRODUCTS',
	FISH_SEAFOOD = 'FISH_SEAFOOD',
	CONFECTIONARY = 'CONFECTIONARY',
	READY_TO_EAT_MEAT = 'READY_TO_EAT_MEAT',
	FEEDSTUFFS = 'FEEDSTUFFS',
	NON_FOOD_AGRICULTURAL = 'NON_FOOD_AGRICULTURAL',
	NNON_FOOD_AGRICULTURAL = 'NNON_FOOD_AGRICULTURAL',
	FOOD_BY_PRODUCTS = 'FOOD_BY_PRODUCTS',
	FOOD_WASTES = 'FOOD_WASTES',
	MATRIX_RMS = 'MATRIX_RMS',
	PROCESS_INTERMEDIATE = 'PROCESS_INTERMEDIATE',
	PURE_SUBSTANCES = 'PURE_SUBSTANCES',
	SPIKED_RMS = 'SPIKED_RMS',
	SOLUTIONS = 'SOLUTIONS',
	POWDERS = 'POWDERS',
	FOODS_ANIMAL_ORIGIN = 'FOODS_ANIMAL_ORIGIN',
	FOODS_PLANT_ORIGIN = 'FOODS_PLANT_ORIGIN',
	PREPARED_FOOD_PRODUCTS = 'PREPARED_FOOD_PRODUCTS',
	BEVERAGES_SUPPLEMENTS = 'BEVERAGES_SUPPLEMENTS',
	FOOD_CONTACT_MATERIAL = 'FOOD_CONTACT_MATERIAL',
	AGRICULTURAL_NON_FOOD = 'AGRICULTURAL_NON_FOOD',
	FEEDSTUFF = 'FEEDSTUFF',
	ENVIRONMENTAL_MATRIXES = 'ENVIRONMENTAL_MATRIXES',
	MEATS_POULTRY_PRODUCTS = 'MEATS_POULTRY_PRODUCTS',
	FISH_PRODUCT = 'FISH_PRODUCT',
	MILK_DIARY_PRODUCTS = 'MILK_DIARY_PRODUCTS',
	HONEY_BEE_PRODUCTS = 'HONEY_BEE_PRODUCTS',
	FATS_ANIMAL_ORIGIN = 'FATS_ANIMAL_ORIGIN',
	CEREALS_PRODUCTS = 'CEREALS_PRODUCTS',
	FRUIT_VEGETABLE = 'FRUIT_VEGETABLE',
	OLIVES_OILS_FATS = 'OLIVES_OILS_FATS',
	PLANTS_OTHER = 'PLANTS_OTHER',
	FCM_OTHER = 'FCM_OTHER',
	PRODUCTS_TEXTILE_APPS = 'PRODUCTS_TEXTILE_APPS',
	TOBACCO = 'TOBACCO',
	OTHER_ARGICULTURAL = 'OTHER_ARGICULTURAL',
	GROUNDWATER = 'GROUNDWATER',
	FRESHWATER = 'FRESHWATER',
	SEAWATER = 'SEAWATER',
	AIR_PARTICULATE_MATTER = 'AIR_PARTICULATE_MATTER',
	BIOLOGICAL_SAMPLES = 'BIOLOGICAL_SAMPLES',
	SUGARS = 'SUGARS',
	AGRICULTURAL_FIELDS = 'AGRICULTURAL_FIELDS',
	GREENHOUSES = 'GREENHOUSES',
	LIVESTOCK_BREEDING = 'LIVESTOCK_BREEDING',
	AQUACULTURE = 'AQUACULTURE',
	AGRICULTURAL_BIOENERGY_PROD = 'AGRICULTURAL_BIOENERGY_PROD',
	BIOTECHNOLOGICAL_PRODUCTION_FOODS = 'BIOTECHNOLOGICAL_PRODUCTION_FOODS',
	EXPERIMENTAL_FIELD_OTHER = 'EXPERIMENTAL_FIELD_OTHER',
	FISH_SHELLFISH = 'FISH_SHELLFISH',
	MEAT = 'MEAT',
	MACRO_MICRO_ALGAE = 'MACRO_MICRO_ALGAE',
	NON_FOOD_AGRICULTURAL_PRODUCT_OTHER = 'NON_FOOD_AGRICULTURAL_PRODUCT',
	USE_GROW_RAW_MATERIALS_PRODUCTION = 'USE_GROW_RAW_MATERIALS_PRODUCTION',
	PRODUCED_RATE_AMOUND = 'PRODUCED_RATE_AMOUND',
	USE_RM = 'USE_RM',
	FOODS_OTHER = 'FOODS_OTHER',
	END_PRODUCTS_OTHER = 'END_PRODUCTS_OTHER',
	PRODUCED_RATE_AMOUNT = 'PRODUCED_RATE_AMOUNT',
	ENVIRONMENTAL_OTHER = 'ENVIRONMENTAL_OTHER',
	FOOD_ANIMALS_OTHER = 'FOOD_ANIMALS_OTHER',
	BEVERAGES_OTHER = 'BEVERAGES_OTHER',
	MATRIX_OTHER = 'MATRIX_OTHER',
	FEEDSTUFF_ADDITIVES_SUPPLEMENTS ='FEEDSTUFF_ADDITIVES_SUPPLEMENTS',
	ADDITIVES_SUPPLEMENTS_MATRIX = 'ADDITIVES_SUPPLEMENTS_MATRIX',
	ENVIRONM_CONTAM_OTHER = 'ENVIRONM_CONTAM_OTHER',
	QUALITY_OTHER = 'QUALITY_OTHER',
	AUTHENTICITY_OTHER = 'AUTHENTICITY_OTHER',
	NUTRITION_OTHER = 'NUTRITION_OTHER',
	AGRO_ECO_OTHER = 'AGRO_ECO_OTHER',
	PROCESSED_AMOUNT_MIN = 'PROCESSED_AMOUNT_MIN',
	PRODUCTION_RATE_MIN = 'PRODUCTION_RATE_MIN',
	PRODUCTION_RATE_MAX = 'PRODUCTION_RATE_MAX',
	PROCESSED_AMOUNT_MAX = 'PROCESSED_AMOUNT_MAX',
	PROCESSED_AMOUNT_AVERAGE = 'PROCESSED_AMOUNT_AVERAGE',
	PRODUCTION_RATE_AVERAGE = 'PRODUCTION_RATE_AVERAGE',
	PROCESSED_AMOUNT_MEASUREMENT_UNIT = 'PROCESSED_AMOUNT_MEASUREMENT_UNIT',
	PRODUCTION_RATE_MEASUREMENT_UNIT = 'PRODUCTION_RATE_MEASUREMENT_UNIT',
	TOXIC_ELEMENTS_OTHER = 'TOXIC_ELEMENTS_OTHER',
}

export enum SectorCategoriesTranslations {
	SAFETY_OTHER = 'Other',
	TOXIC_ELEMENTS_OTHER = 'Other',
	AUTHENTICITY_OTHER = 'Other',
	OMNICS_OTHER = 'Other',
	AGRO_ECO_OTHER = 'Other',
	NUTRITION_OTHER = 'Other',
	QUALITY_OTHER = 'Other',
	PROCESS_CONTAM_OTHER = 'Other',
	ENVIRONM_CONTAM_OTHER = 'Other',
	USE_RM = 'Use RM',
	NON_FOOD_AGRICULTURAL_PRODUCT_OTHER = 'Other (please specify)',
	MACRO_MICRO_ALGAE = 'Macro- and micro-algae',
	FISH_SHELLFISH = 'Fish and shellfish',
	EXPERIMENTAL_FIELD_OTHER = 'Other (please specify)',
	BIOTECHNOLOGICAL_PRODUCTION_FOODS = 'Biotechnological production of foods/ingredients',
	AGRICULTURAL_BIOENERGY_PROD = 'Agricultural (by)products for bioenergy production',
	LIVESTOCK_BREEDING = 'Livestock breeding',
	AQUACULTURE = 'Aquaculture',
	GREENHOUSES = 'Greenhouses',
	AGRICULTURAL_FIELDS = 'Agricultural fields',
	OTHER = 'OTHER',
	FOODS_OTHER = 'Other',
	SECTOR_OTHER = 'Other',
	FOOD_SAFETY = 'Food safey',
	FOOD_QUALITY = 'Food quality',
	FOOD_AUTHENTICITY = 'Food authenticity/traceability',
	NUTRITION = 'Nutrition',
	AGROECOSYSTEM_CHAR = 'Agroecosystem characterisation / Environmental analysis',
	CHARACTERISATION_MATERIAL = 'Characterisation of materials ',
	TOXIC_ELEMENT = 'Toxic and potentially toxic elements',
	MYCOTOXINS = 'Mycotoxins',
	PLANT_ALKALOIDS = 'Plant alkaloids',
	SAFETY_OTHER_TOXINS = 'Other toxins (marine, fresh water, bacterial, etc.)',
	PESTICIDES = 'Pesticides',
	VETERINARY_DRUG = 'Veterinary drug residues',
	VOC = 'VOC' ,
	POPS = 'POPs',
	PHENOLS = 'Phenols',
	MELAMINE = 'Melamine',
	NITRATES = 'Nitrates',
	PRECHLORATES = '(Per)chlorates',
	ALLERGENS = 'Allergens',
	ADDITIVES = 'Additives' ,
	GMO = 'GMO',
	AMINES = 'Amines',
	BIOCIDES = 'Biocides',
	HORMONES = 'Hormones',
	HYDROCARBONS = 'Hydrocarbons',
	HALOGENATED_CONTAMINANTS = 'Halogenated contaminants',
	MOH = 'MOH',
	PROCESS_CONTAMINANTS = 'Process contaminants',
	ENVIRONMENTAL_CONTAMINANTS = 'Environmental contaminants',
	FOOD_CONTACT_MATERIAL_MIGRATION = 'Food contact materials - migration',
	NANOPARTICLES = 'Nanoparticles',
	MICROPLASTICS = 'Microplastics',
	PATHOGENIC_MICRO = 'Pathogenic micro-organisms',
	PARASITES = 'Parasites',
	PRIONS = 'Prions',
	VIRUSES = 'Viruses',
	RADIOACTIVITY = 'Radioactivity',
	TOTAL_CONTENT = 'Total content',
	BIOAVAILABILITY = 'Bioavailability',
	SPECIATION = 'Speciation',
	OTHER_TOXINES = 'Other toxines',
	HYDROCARBONS_OTHER = 'Other Hydrocarbons',
	ACRYLAMIDE = 'Acrylamide',
	FURANS = 'Furans',
	MCDP_ESTERS = '3-MCDP and MCDP esters',
	PAHS = 'PAHs',
	PCB= 'PCB',
	PBDE_BFR= 'PBDE and other BFRs',
	PCDD_PCDF= 'PCDDs and PCDFs',
	PFAS= 'PFAS',
	CHLORINATED_PARAFFINS = 'Chlorinated paraffins',
	ORGANOLEPTIC_PROPERTIES = 'Organoleptic properties (texture, appearance, flavor, etc.)',
	PHYSICAL_CHEMICAL_ANALYSIS = 'Physical-chemical analysis (dry matter, water content, free fatty acids, etc.)',
	BIOACTIVE_COMPOUNDS = 'Bioactive compounds (flavonoids, carotenoids, polyphenols, etc.)',
	MICROBIOLOGICAL_ANALYSIS = '(Micro)biological analysis',
	IN_VITRO_BIOACTIVITY = 'In vitro bioactivity or antioxidant activity',
	SENSORY_ANALYSIS = 'Sensory analysis',
	ISOTOPES_LIGHT_ELEMENTS = 'Isotopes of light elements',
	ISOTOPES_HEAVY_ELEMENTS = 'Isotopes of heavy elements',
	RARE_EARTH_ELEMENTS = 'Rare Earth Elements (REE)',
	ELEMENTAL_PROFILES = 'Elemental profiles (different from REE)',
	OMNICS_ANALYSE = '-Omics analyses',
	GENETIC_MARKERS = 'Genetic markers',
	ADULTERATION = 'Adulteration',
	PROTEOMICS = 'Proteomics',
	LIPIDOICS = 'Lipidoics',
	METABOLOMICA = 'Metabolomica',
	GENOMICS = 'Genomics',
	MULTI_OMNICS = 'Multi-omics',
	FIBER = 'Fiber',
	LIPIDS_CONTENT = 'Lipids content',
	FATTY_ACIDS = 'Fatty acids',
	DRY_MATTER = 'Dry matter',
	VITAMINS = 'Vitamins',
	CARBOHYDRATES ='Carbohydrates',
	SUGARS = 'Sugars (glucose, fructose, etc.)',
	PROTEINS = 'Proteins and amino-acids',
	WATER = 'Water',
	MINERALS = 'Minerals',
	SURFACE_GROUNDWATER = 'Surface and groundwater',
	SOILS_SEDIMENTS = 'Soils and sediments',
	BIOAVAILABILITY_STUDIES = 'Bioavailability studies',
	AIR_POLLUTION = 'Air pollution' ,
	WET_DRY_DEPOSITIONS = 'Wet & Dry depositions',
	BIOINDICATORS = 'Bioindicators',
	METALLIC_MATERIALS = 'Metallic materials',
	CERAMIC_MATERIALS = 'Ceramic materials and composites',
	PLASTIC_MATERIALS = 'Plastic materials',
	GLASS_MATERIALS = 'Glass materials',
	PAPER_MATERIALS = 'Paper-based materials',
	RECYCLED_MATERIALS = 'Recycled materials',
	WOOD_PLANTS_MATERIALS = 'Wood and plant materials',
	FOOD_OF_ANIMALS = 'Foods of animal origin',
	FOOD_OF_PLANTS = 'Foods of plant origin',
	FOOD_PREPARED_PRODUCTS = 'Prepared (ready-to-eat) food products and total diet',
	BEVERAGES_VINEGARS = 'Beverages and vinegars',
	ADDITIVES_SUPPLEMENTS = 'Additives and supplements',
	ADDITIVES_SUPPLEMENTS_MATRIX = 'Additives and supplements',
	CEREALS = 'Cereals and cereal products',
	FRUIT_VEGETABLES ='Fruit and vegetables',
	OLIVES_OILS ='Olives, oils and fats of vegetable origin',
	SUGAR_PLANTS = 'Sugar and sugar plants',
	HERBS_SALT ='Herbs, salt and spices',
	COCA_COFFE ='Cocoa, coffee, tea and derivatives',
	DRINK_WATER = 'Drinking water',
	NON_ALCOHOLIC_BEVERAGES = 'Non-alcoholic beverages (milk beverages excluded)',
	WINE = 'Wine',
	BEER_CIDER = 'Beer and cider',
	BEVERAGES = 'Beverages',
	ALCOHOLIC_BEVERAGES = 'Alcoholic beverages (wine and beer excluded)',
	FRUIT_VEGETABLE_JUICES = 'Fruit and vegetable juices, extracts',
	VINEGARS = 'Vinegars',
	CANNED_FRUITS = 'Canned fruits, creams and confectionary products',
	BREAD_BAKERY = 'Bread and bakery products',
	PASTA = 'Pasta (fresh and dried)',
	SAUCES = 'Sauces, dressings and preserves of animal and vegetable origin',
	PREPARED_DISHES ='Prepared dishes and instant dishes',
	INSTANT_FOODS ='Infant foods (milk excluded)',
	TOTAL_DIET = 'Total diet',
	PREPARED_FOOD_OTHER = 'Other',
	MEATS_POLUTRY_PRODUCTS = 'Meats and poultry products',
	FISH_PRODUCTS = 'Fish products',

	HONEY_BEE = 'Honey and bee products',
	FATS_ANIMAL = 'Fats of animal origin',
	FOOD_PROCESSING = 'Food processing (pilot plants, innovative technologies, mild technologies)',
	FOOD_STORAGE = 'Food storage (technologies and facilities)',
	FOOD_PACKING = 'Food packaging (materials and technologies, active and smart packaging)',
	FOOD_PREPARATION = 'Food preparation ( cooking, storage, appliance, utensils, tableware, cookware)',
	TREATMENT_REDUCTION = 'Treatment/reduction of food losses and waste (recycling, by-products, etc.)',
	PRODUCTION_AIDS_FOOD = 'Production of aids in food production/storage (agrochemicals, sanitizers, antimicriobials, starter cultures, food additives)',
	FRUTS = 'Fruits',
	FRUITS = 'Fruits',
	VEGETABLES = 'Vegetables',
	LEGUMES = 'Legumes',
	MILK_DAIRY = 'Milk and dairy products',
	MEAT_PRODUCTS='Meat and meat products',
	MEAT='Meat',
	FISH_SEAFOOD = 'Fish and seafood',
	CONFECTIONARY = 'Confectionary',
	READY_TO_EAT_MEAT = 'Ready-to-eat meals',
	NON_FOOD_AGRICULTURAL = 'Non-food agricultural products',
	FOOD_BY_PRODUCTS = 'Food by-products',
	FOOD_WASTES = 'Food wastes',
	MATRIX_RMS = 'MATRIX-RMs',
	PROCESS_INTERMEDIATE = 'PROCESS INTERMEDIATE RMs (ex. Intermediates obtained by extraction, or other pre-treatment)',
	PURE_SUBSTANCES = 'PURE SUBSTANCES FOR CALIBRATION',
	SPIKED_RMS = 'SPIKED-RMs (Matrix-RMs or process intermediates spiked with calibrated solutions/pure substances)',
	SOLUTIONS = 'solutions',
	POWDERS = 'powders',
	FOODS_ANIMAL_ORIGIN = 'Foods of animal origin',
	FOODS_PLANT_ORIGIN = 'Foods of plant origin',
	PREPARED_FOOD_PRODUCTS = 'Prepared (ready-to-eat) food products and total diet',
	BEVERAGES_SUPPLEMENTS = 'Beverages and vinegars',
	FOOD_CONTACT_MATERIAL = 'Food contact materials',
	AGRICULTURAL_NON_FOOD = 'Agricultural non-food products',
	FEEDSTUFF = 'Feedstuffs',
	ENVIRONMENTAL_MATRIXES = 'Environmental matrixes',
	MEATS_POULTRY_PRODUCTS = 'Meats and poultry products',
	FISH_PRODUCT = 'Fish products',
	MILK_DIARY_PRODUCTS = 'Milk and dairy products',
	HONEY_BEE_PRODUCTS = 'Honey and bee products',
	FATS_ANIMAL_ORIGIN = 'Fats of animal origin',
	CEREALS_PRODUCTS = 'Cereals and cereal products',
	FRUIT_VEGETABLE = 'Fruit and vegetables',
	OLIVES_OILS_FATS = 'Olives, oils and fats of vegetable origin',
	PLANTS_OTHER = 'Other',
	FCM_OTHER = 'Other',
	PRODUCTS_TEXTILE_APPS = 'Products for textile applications',
	TOBACCO = 'Tobacco',
	OTHER_ARGICULTURAL = 'Other',
	GROUNDWATER = 'Groundwater',
	FRESHWATER = 'Freshwater',
	SEAWATER = 'Seawater',
	AIR_PARTICULATE_MATTER = 'Air and particulate matter',
	BIOLOGICAL_SAMPLES = 'Biological samples',
	END_PRODUCTS_OTHER = 'Other',
	ENVIRONMENTAL_OTHER = 'Other',
	FOOD_ANIMALS_OTHER = 'Other',
	BEVERAGES_OTHER = 'Other',
	MATRIX_OTHER = 'Other'
}
