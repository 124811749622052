import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';
import { debounce, debounceTime } from 'rxjs/operators';
import { foodDevelopment, storage, cooking, foodPreparation, packing, extractionTechnologies, separationExtraction, bakingExtrusion, innovativeFoodProcessing, pressureProcessing, freezing, evaporationDehydratation, thermalProcessing, foodProcessingPreparation  } from './processing_kitchen_labs';
@Component({
  selector: 'metro-membership-kitchen-labs-equipment',
  templateUrl: './kitchen-labs-equipment.component.html',
  styleUrls: ['./kitchen-labs-equipment.component.less']
})
export class KitchenLabsEquipmentComponent implements OnInit {
	@Output() kitchenForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	@Input() facility = { } as Facility;
	@Input() kitchenForms: FormGroup;
	showForms = false;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {}

}
