import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { PaginatedTableComponent } from './components/paginated-table/paginated-table.component';
import { MapLayoutComponent } from './components/map-layout/map-layout.component';
import { FacilityTypeComponent } from './components/facility-type/facility-type.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { PersonInfoComponent } from './components/person-info/person-info.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { PersonModalComponent } from './modals/person-modal/person-modal.component';
import { ChoosePersonComponent } from './components/choose-person/choose-person.component';
import { NotFoundPage } from './components/not-found/not-found-page';

import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { FacilityModalComponent } from '../institute/node-view/modals/facility-modal/facility-modal.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { FormTextComponent } from './components/form-text/form-text.component';

const reexportedModules = [
	CommonModule,
	ReactiveFormsModule,
	FormsModule,
	NzTableModule,
	NzFormModule,
	NzInputModule,
	NzCheckboxModule,
	NzRadioModule,
	NzSelectModule,
	NzSwitchModule,
	NzButtonModule,
	NzIconModule,
	NzResultModule,
	NzTabsModule,
	NzAvatarModule,
	NzModalModule,
	NzNotificationModule,
	NzMenuModule,
	NzTypographyModule,
	NzCollapseModule,
	NzUploadModule
];

const exportedComponents = [
	BreadcrumbsComponent,
	PaginatedTableComponent,
	MapLayoutComponent,
	FacilityTypeComponent,
	SideMenuComponent,
	PersonInfoComponent,
	FormInputComponent,
	FormTextComponent,
	PersonModalComponent,
	FacilityModalComponent,
	ChoosePersonComponent,
	NotFoundPage
]

@NgModule({
  declarations: [
		...exportedComponents
	],
  imports: [
		...reexportedModules,
		NzBreadCrumbModule,
		RouterModule
  ],
	exports: [
		...exportedComponents,
		...reexportedModules,
	]
})
export class SharedModule { }
