import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FacilityPersonRole, InstitutePersonRole, Person } from '@metro-membership/data-access';

@Component({
  selector: 'metro-membership-person-info',
  templateUrl: './person-info.component.html',
  styleUrls: ['./person-info.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonInfoComponent {
	@Input() person = null as Person;
	@Input() role = null as InstitutePersonRole | FacilityPersonRole;
	@Input() biggerSpacing = false;
	@Input() showDelete = false;
  @Input() public deleteFcn: (param:any) => void;

	deleteRole() {
		if(!this.role) {
			return true;
		}
		this.deleteFcn(this.role)
	}

}
