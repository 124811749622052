import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HeaderClickHandlerService } from '@metro/ui';
import { AuthGuard, KeycloakAngularModule, UrlInterceptor } from '@metro/ui-core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { appInitializerProvider } from './app-initializer';
import { LoaderComponent } from './components/loader/loader.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ConfigService } from './services/config.service';

@NgModule({
	declarations: [
		TopMenuComponent,
		LoaderComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		HttpClientModule,
		KeycloakAngularModule,
		NzMenuModule,
		NzIconModule
	],
	providers: [
		appInitializerProvider,
		AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UrlInterceptor,
			deps: [ConfigService],
			multi: true
		},
		HeaderClickHandlerService
	],
	exports: [
		TopMenuComponent,
		LoaderComponent
	]
})
export class CoreModule { }
