import { FormControl } from '@angular/forms';

export class CustomValidator {
	static fractionsValidator() {
    const FRACTION_REGEXP = new RegExp( /^[0-9][0-9\s]*[,]*[.]*([0-9]{0,2})?$/ );

		return (input: FormControl) => {

			if (FRACTION_REGEXP.test(input.value)) {
				return null;
			}
			return {
				'fractions-2': true
			};
		};
  }
	static digitsValidator() {
    const DECIMAL_NUMBERS_REGEXP = new RegExp( /^[0-9][0-9\s]*[,]*[.]*([0-9]+)?$/ );

		return (input: FormControl) => {

			if (DECIMAL_NUMBERS_REGEXP.test(input.value)) {
				return null;
			}
			return {
				digits: true
			};
		};
  }
	static dotValidator() {
		const DOT_REGEXP = new RegExp( /^[0-9][0-9\s]*[,]*([0-9]+)?$/ );

		return (input: FormControl) => {

			if (DOT_REGEXP.test(input.value)) {
				return null;
			}
			return {
				dot: true
			};
		};
	}
	static percentageValidator(input: FormControl) {
		const max = 100;
		const min = 0;
		const NUMBERS_REGEXP = /^[0-9][0-9]*[,]*[.]*([0-9]+)?$/;
		const val: number = parseFloat(input.value.replace(',', '.'));

		if ((val <= max ) && (val >= min ) && NUMBERS_REGEXP.test(input.value)) {
			return null;
		}

		return {
			percentageValue : true
		};
	}
}
