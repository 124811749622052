import { HttpParams } from '@angular/common/http';
import { Component} from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Institute, InstituteQuality } from '@metro-membership/data-access';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InstituteService } from '../../data/services/institute.service';
import { QualityFormsService } from '../../data/services/quality.service';
import { NotificationService } from '../../shared/services/notification.service';
import { UploadService } from '../../shared/services/upload.service';

@Component({
  selector: 'metro-membership-institute-quality',
  templateUrl: './institute-quality.page.html',
  styleUrls: ['./institute-quality.page.less']
})
export class InstituteQualityPage {
  questionForm: FormGroup;
  showForm = false;
  unSubscribe = new Subject();
  instituteId = '';
  institute: Institute;
  instituteQuality: InstituteQuality[];

  isSopChecked = true;
  isStakeholdersChecked = true;
  isCertified = true;

  constructor(
    private instituteService: InstituteService,
    private activatedRoute: ActivatedRoute,
    private uploadService: UploadService,
    private qualityFormService: QualityFormsService,
		private notificationService: NotificationService
    ) {

      this.activatedRoute.parent.params
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(params => {
        this.instituteId = params.instituteId;
        this.getInstitute();
      });
    }

    get isEditable(): boolean {
      return this.questionForm.enabled as boolean;
    }

    getInstitute() {
      const params = new HttpParams({
        fromObject: { join: ['qualities', 'qualities.answers'] }
      });
      this.instituteService.fetchInstitute(this.instituteId, params).subscribe(institute => {
        this.institute = institute;
        this.buildForm();
      });
    }

    buildForm() {
      this.questionForm = this.qualityFormService.instituteQualityForm(this.instituteId);

      this.questionForm.patchValue(this.institute?.qualities);
      this.questionForm.disable();
      this.showForm = true;
      this.registerValidationChanges();
    }

    onSave() {
      if (!this.questionForm.valid) {
        return true;
      }
      if (this.institute.qualities) {
        this.editQuestions();
      } else {
        this.addQuestions();
      }
      this.questionForm.disable();
    }

    editQuestions() {
      const formData = this.questionForm.getRawValue() as InstituteQuality;
      this.instituteService.editInstituteQuality(formData, this.institute.id).subscribe(res => {
        this.getInstitute();
      }, error => {
				this.notificationService.createBasicNotification('Sorry', 'Editing error.', 'error')
			});
    }

    addQuestions() {
      const formData = this.questionForm.getRawValue() as InstituteQuality;
      this.instituteService.addInstituteQuality(formData).subscribe(res => {
        this.getInstitute();
      }, error => {
				this.notificationService.createBasicNotification('Sorry', 'Adding error.', 'error')
			});
    }
    handleChange = (item: any) => {
      const formData = new FormData();
      formData.append(item.name, item.file as any);
      this.uploadService.uploadFile(formData).subscribe(
        (res: any) => {
          item.onSuccess(item.file);
        },
        (err) => {
          item.onError(err, item.file);
        });
      }


      onEdit() {
        this.questionForm.enable();
      }

      registerValidationChanges() {
        const qualityManagerSystemQuestionStatus = this.questionForm.get('qualityManagerSystemQuestionStatus');
        const qualityManagerSystemQuestion = this.questionForm.get('qualityManagerSystemQuestion');
        const qualityManagerSystemQuestionSubStatus = this.questionForm.get('qualityManagerSystemQuestionSubStatus');

        const servicesExternalUsersStatus = this.questionForm.get('servicesExternalUsersStatus');

        const handlingCustomerComplaintsStatus = this.questionForm.get('handlingCustomerComplaintsStatus');
        const handlingCustomerComplaintsSubStatus = this.questionForm.get('handlingCustomerComplaintsSubStatus');

        const applyingOperatingProcedureStatus = this.questionForm.get('applyingOperatingProcedureStatus');
        const applyingOperatingProcedureSubStatus = this.questionForm.get('applyingOperatingProcedureSubStatus');

        const audiTrailStatus = this.questionForm.get('audiTrailStatus');
        const audiTrailSubStatus = this.questionForm.get('audiTrailSubStatus');

        const qmsStatus = this.questionForm.get('qmsStatus');
        const qms = this.questionForm.get('qms');

        this.certValidations();
        this.sopValidations();

        this.setStandardControlsValidators(qualityManagerSystemQuestionStatus, qualityManagerSystemQuestion,
          qualityManagerSystemQuestionSubStatus);
        this.setStandardControlsValidators(applyingOperatingProcedureStatus, null ,applyingOperatingProcedureSubStatus);
        this.setStandardControlsValidators(audiTrailStatus, null ,audiTrailSubStatus);
        this.setStandardControlsValidators(qmsStatus, qms, null);

        servicesExternalUsersStatus.valueChanges.subscribe(change => {
          if(change) {
            this.questionForm.get('handlingCustomerComplaintsStatus').setValidators(Validators.required)
            this.setStandardControlsValidators(handlingCustomerComplaintsStatus, null ,handlingCustomerComplaintsSubStatus);
          } else {
            handlingCustomerComplaintsStatus.clearValidators();
            handlingCustomerComplaintsSubStatus.clearValidators()
          }
          this.questionForm.updateValueAndValidity();
        });

      }

      sopValidations() {
        let isSopSubs: Subscription[] = [];

        const sopStatus =this.questionForm.get('sopStatus');
        const sopControls = [
          this.questionForm.get('sopPolicy'), this.questionForm.get('sopConfidentiality'), this.questionForm.get('sopConferences'),
          this.questionForm.get('sopPapers'), this.questionForm.get('sopPapers'),  this.questionForm.get('sopTV'),
          this.questionForm.get('sopVisits'), this.questionForm.get('sopPartners'), this.questionForm.get('sopOpenAccess'),
          this.questionForm.get('sopAgroFoodSector'), this.questionForm.get('sopMeeting'), this.questionForm.get('sopWorkShops'),
          this.questionForm.get('sopWebConf'), this.questionForm.get('sopHomePageSite'), this.questionForm.get('sopTechnicalReports'),
          this.questionForm.get('sopPatent'), this.questionForm.get('sopOther')
        ];

        sopStatus.valueChanges.subscribe(changes => {
          if(changes) {
            this.isSopChecked = false;
            isSopSubs = this.setCheckBoxValidators(sopControls, 'isSopChecked')
          } else {
            this.isSopChecked = true;
            isSopSubs.forEach(sub => sub.unsubscribe());
            sopControls.forEach(cert => cert.setValue(false));
          }
        });
      }

      certValidations() {
        let isCertifiedSub: Subscription[] = [];
        const instituteCertificationStatus = this.questionForm.get('instituteCertificationStatus');
        const instituteCertificationSubStatus = this.questionForm.get('instituteCertificationSubStatus');
        const certControls = [
          this.questionForm.get('instituteCertificationISO9000'),
          this.questionForm.get('instituteCertificationISO14001'),
          this.questionForm.get('instituteCertificationOther')
        ];

        instituteCertificationStatus.valueChanges.subscribe(changes => {
          if(changes) {
            this.isCertified = false;
            instituteCertificationSubStatus.clearValidators();
            instituteCertificationSubStatus.updateValueAndValidity();
            isCertifiedSub = this.setCheckBoxValidators(certControls, 'isCertified');
          } else {
            this.isCertified = true;
            instituteCertificationSubStatus.setValidators(Validators.required);
            instituteCertificationSubStatus.updateValueAndValidity();
            isCertifiedSub.forEach(sub => sub.unsubscribe());
            certControls.forEach(cert => cert.setValue(false));
          }
        });
      }

      setCheckBoxValidators(checkboxes: AbstractControl[], assignParam: string ) {
        return checkboxes.map(control => {
          return control.valueChanges.subscribe(() => {
            const sopDataArray = checkboxes.filter(changedControls => changedControls.value);
            this[assignParam] = sopDataArray?.length > 0  ? true : false;
          });
        });
      }

      setStandardControlsValidators(mainControl: AbstractControl, trueControl?: AbstractControl, falseControl?: AbstractControl) {
        mainControl.valueChanges.subscribe(change => {
          if(change) {
            if(trueControl) { trueControl.setValidators(Validators.required) }
            if(falseControl) {
              falseControl.clearValidators();
              falseControl.updateValueAndValidity();
            }
          } else {
            if(falseControl) { falseControl.setValidators(Validators.required) }
            if(trueControl) {
              trueControl.clearValidators();
              trueControl.updateValueAndValidity();
            }
          }
        });
      }
    }
