import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NotificationService } from '../../shared/services/notification.service';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private loaderService: LoaderService, private notificationService: NotificationService){}

	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const isApiCall = req.url[0] === '@';
		return next.handle(req).pipe(
			tap(() => {
				if (isApiCall) {
					this.loaderService.show();
				}
			}),
			catchError(error => {
				if (error.status === 403) {
					this.notificationService.createBasicNotification('Forbidden Access', 'Access for this data is forbidden', 'error')
				}
				return throwError(error);
			}),
			finalize(() => {
				if (isApiCall) {
					this.loaderService.hide();
				}
			}),
		);
	}
}
