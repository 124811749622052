export class ExperimentalFields {
	experimentalFieldsSeeds = false;
	experimentalFieldsPlants = false;
	experimentalFieldsFeedstuffs = false;
	experimentalFieldsLivestock = false;
	experimentalFieldsBees = false;
	experimentalFieldsAquaculture = false;
	experimentalFieldsAlgae = false;
	experimentalFieldsOther = false;
}

export class ExperimentalFacility {
	experimentalFacilityOpenFields = false;
	experimentalFacilityGreenhouses = false;
	experimentalFacilityGrowth = false;
	experimentalFacilityOther = false;

}

export class OpenFields {
	openFieldscultivableHa = false;
	openFieldsSeparated = false;
}

export class PlantsType {
	plantsTypeArableCrops = false;
	plantsTypeAnnualCrops = false;
	plantsTypeAromaticPlants = false;
	plantsTypePerennialCrops = false;
	plantsTypeOther = false;
}

export class PerennialCrops {
	perennialCropsImplantedPomeFruits = false;
	perennialCropsImplantedOliveTrees = false;
	perennialCropsImplantedCitrus = false;
	perennialCropsImplantedVines = false;
	perennialCropsImplantedOther = false;
}

export class GreenHouses {
	greenhousesCultivableMeters = false;
	greenhousesSeparatedGreenhouses = false;
}

export class GreenHouseMeters  {
	metersCultivableGreenhouseMin = false;
	metersCultivableGreenhouseMax = false;
	metersCultivableGreenhouseAverage = false;
}

export class Shape {
	shapeGable = false;
	shapeFlatArch = false;
	ShapeRaisedDome = false;
	ShapeSawtooth = false;
	ShapeTunnel = false;
	ShapeOther = false;
}

export class Structure {
	StructureSingleSpan = false;
	StructureMultiSpan = false;
	StructureShadeHouse = false;
	StructureScreenHouse = false;
	StructureCropTopStructure = false;
	StructureOther = false;
}

export class ProductionTechnology {
	TechnologyLowTech = false;
	TechnologyMediumTech = false;
	TechnologyHighTech = false;
}


export class Heating {
	HeatingBoiler = false;
	HeatingHotWaterTubes = false;
	HeatingWithSteamPipes = false;
	HeatingUnitHeaters = false;
	HeatingInfraredHeaters = false;
	HeatingSolar = false;
	HeatingNoSystem = false;
	HeatingOther = false;
}

export class Cooling {
	CoolingVentilation = false;
	CoolingEvaporation = false;
	CoolingShadeCloths = false;
	CoolingCirculation = false;
	CoolingNoSystem = false;
	CoolingOther = false;
}
export class Cultivation {
	CultivationSoil = '';
	CultivationOtherMedia = '';
	CultivationTypeOther = '';
}

export class Environmental {
	EnvironmentalTemperature = false;
	EnvironmentalRelativeHumidity = false;
	EnvironmentalLightIntensity = false;
	EnvironmentalQualityLight = false;
	EnvironmentalOther = false;
}

export class Application {
	ApplicationAgronomy = false;
	ApplicationBotany = false;
	ApplicationPlantPathology = false;
	ApplicationBiotechnology = false;
	ApplicationOther = false;
}

export class CultivationType {
	CultivationTypeAgronomy = false;
	CultivationTypeBotany = false;
	CultivationTypePlantPathology = false;
	CultivationTypePestControl = false;
	CultivationTypeOther = false;
}

export class DedicatedEquipment {
	DedicatedEquipmentWeatherStation = false;
	DedicatedEquipmentInternalTemperature = false;
	DedicatedEquipmentPhotosynthesis = false;
	DedicatedEquipmentRoot = false;
	DedicatedEquipmentLeafSpectroscopy = false;
	DedicatedEquipmentSensors = false;
	DedicatedEquipmentOther = false;
}
export class EquipmentMachinery {
	equipmentMachinerySoilCultivation = false;
	equipmentMachineryPlanting = false;
	equipmentMachineryFertilising = false;
	equipmentMachineryPestControl = false;
	equipmentMachineryIrrigation = false;
	equipmentMachinerySorting = false;
	equipmentMachineryHarvesting = false;
	equipmentMachineryLoading = false;
	equipmentMachineryPostHarvesting = false;
	equipmentMachineryPrecisionFarming = false;
	equipmentMachineryVerticalFarming = false;
	equipmentMachineryOther = false;
}

export class LivestockMachinery {
	livestockMachineryDryFeeder = false;
	livestockMachineryWetFeeder = false;
	livestockMachineryCattleCrush = false;
	livestockMachineryHeadlocks = false;
	livestockMachineryStablePartitions = false;
	livestockMachineryFreeStall = false;
	livestockMachineryGestationStall = false;
	livestockMachineryFreeStandingAreas = false;
	livestockMachineryRoundYards = false;
	livestockMachineryHeadRolls = false;
	livestockMachineryMilkingMachine = false;
	livestockMachineryEggCollectors = false;
	livestockMachineryOther = false;
}
export class EquipmentSitu {
	equipmentSituWeatherStation = false;
	equipmentSituInternalTemperature = false;
	equipmentSituSensors = false;
	equipmentSituOther = false;
}
export class MeasureEquipmentSitu {
	measureEquipmentSituWeatherStation = false;
	measureEquipmentSituInternalTemperature = false;
	measureEquipmentSituPh = false;
	measureEquipmentSituPe = false;
	measureEquipmentSituConductivity = false;
	measureEquipmentSituSensors = false;
	measureEquipmentSituOther = false;
}




