<form action="" class="mt-2" [formGroup]="questionForm" *ngIf="showForm">
	<div class="d-flex justify-content-between">
		<h1>Finances</h1>
		<div>
			<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
				<div>EDIT</div>
			</button>

			<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" [ngClass]="{'disabled': questionForm.invalid }">
				<div>SAVE</div>
			</button>
		</div>
	</div>
	<div class="mb-8">(data to be obtained from the accounting department)</div>

	<div class="title">Net Value of the facility:</div>
	<div class="description">(The Net Value - it is equal to the cost of acquisition/total value of all R&D equipment/ instruments purchased for the facility less the accumulated accounting depreciation, or in other words net value is the total value of the invoice without the depreciation accumulated so far. It is a single cumulative value for all the equipment composing the facilities (we’ll take care then to perform the relevant calculation considering the % dedicated to METROFOOD-RI). This value can also be obtained from the accounting department. In order to provide relevant and comparable financial data from all partners, please bear in mind that we need the net value as at 31 December 2020. You also have the option to provide the related costs whether in your local currency or in euro (if you keep the accounting in euro, as well) by checking the currency from the drop down list)</div>

	<div class="input-wrapper mt-3">
		<metro-form-input class="standard" type="text" unit="thousands"
		ph="Amount" formControlName="netBookValue"  [control]="questionForm.controls.netBookValue"
		></metro-form-input>

		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.netBookValue.disabled}" >
			<nz-select  class="select" formControlName="netBookValueCurr">
				<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
			</nz-select>
		</div>

	</div>

	<!-- <div class="title mt-12">% of annual maintenance costs in the net value of each facility:</div>
	<div class="description">similar to the previous question, we ask you to provide a rough estimate of how much maintenance costs per year. For example, if you estimate that maintenance costs are 5,000 on average per year and the net value is 100,000, then % is 5% (5,000 divided by 100,000).</div>
	<div class="input-wrapper mt-3">
		<metro-form-input class="standard" type="text"
		ph="Amount in %" formControlName="maintenancePercentageCosts"  [control]="questionForm.controls.maintenancePercentageCosts"
		></metro-form-input>

		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.maintenancePercentageCosts.disabled}" >%</div>
	</div>

	<div class="title mt-12">% of annual consumables costs in the net value of each facility:</div>
	<div class="description">Here, we ask you to provide a rough estimate of how much consumables cost per year. For example, if you estimate that consumables are 10,000 in average per year and the net value is 100,000, then % is 10% (10,000 divided by 100,000).</div>

	<div class="input-wrapper mt-3 mb-5">
		<metro-form-input class="standard" type="text" ph="Amount in %" formControlName="consumablePercentageCosts"
		[control]="questionForm.controls.consumablePercentageCosts"></metro-form-input>

		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.consumablePercentageCosts.disabled}" >%</div>
	</div> -->

	<nz-radio-group formControlName="maintenanceThanNet" class="mt-15">
		<label nz-radio [nzValue]="true" class=" mt-5 mb-5">The annual maintenance costs are HIGHER than Net value</label>
		<label nz-radio [nzValue]="false">The annual maintenance costs are LOWER than Net value</label>
	</nz-radio-group>

	<div class="title mt-3">{{maintenance ? 'Annual' : '% of Annual'}} maintenance costs in the net value of each facility:</div>
	<div class="description">similar to the previous question, we ask you to provide a rough estimate of how much maintenance costs per year.</div>
	<div class="description" *ngIf="!maintenance">
		For example, if you estimate that maintenance costs are 5,000 on average per year and the net value is 100,000, then % is 5% (5,000 divided by 100,000).
	</div>
	<div class="input-wrapper mt-3">
		<metro-form-input class="standard" type="text" [unit]="maintenance ? 'thousands' : null "
		[ph]="maintenance ? 'Amount' : 'Amount in %' " formControlName="maintenanceCosts"  [control]="questionForm.controls.maintenanceCosts"
		></metro-form-input>

		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.maintenanceCosts.disabled}">
			<div *ngIf="!maintenance">%</div>
			<nz-select  *ngIf="maintenance" class="select" formControlName="maintenanceCostsCurr">
				<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
			</nz-select>
		</div>

	</div>

	<nz-radio-group formControlName="consumablesThanNet" class="mt-15">
		<label nz-radio [nzValue]="true" class=" mt-5 mb-5">The annual consumables costs are HIGHER than Net value</label>
		<label nz-radio [nzValue]="false">The annual consumables costs are LOWER than Net value</label>
	</nz-radio-group>
	<div class="title mt-3">{{consumables ? 'Annual' : '% of Annual'}} consumables costs in the net value of each facility:</div>
	<div class="description">Here, we ask you to provide a rough estimate of how much consumables cost per year.
		<div *ngIf="!consumables">
			For example, if you estimate that consumables are 10,000 in average per year and the net value is 100,000, then % is 10% (10,000 divided by 100,000).
		</div>

	</div>
	<div class="input-wrapper mt-3 mb-5">
		<metro-form-input class="standard" type="text" [unit]="consumables ? 'thousands' : null "
		[ph]="consumables ? 'Amount' : 'Amount in %' " formControlName="consumablesCosts"  [control]="questionForm.controls.consumablesCosts"
		></metro-form-input>

		<div class="input-metrics" [ngClass]="{'editable': !questionForm.controls.consumablesCosts.disabled}">
			<div *ngIf="!consumables">%</div>
			<nz-select  *ngIf="consumables" class="select" formControlName="consumablesCostsCurr">
				<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
			</nz-select>
		</div>

	</div>


</form>

