import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Person } from '@metro-membership/data-access';
import { ResourceTypes } from '@metro-membership/data-access/enums';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { InstituteService } from '../../../../data/services/institute.service';

@Component({
	selector: 'metro-membership-resources-modal',
	templateUrl: './resources-modal.component.html',
	styleUrls: ['./resources-modal.component.less']
})
export class ResourcesModalComponent implements OnInit {
	@Input() isVisible = false;
	@Input() instituteId = '';
	@Input() person = null as Person;
	resourcesForm: FormGroup;
	radioValue = 'Software';
	resourceTypes = ResourceTypes
	constructor(
		private formBuilder: FormBuilder,
		private instituteService: InstituteService,
		private modal: NzModalRef
	) {}

	ngOnInit() {
		this.resourcesForm = this.formBuilder.group({
			name: [null, Validators.required],
			instituteId: this.instituteId,
			personId: [this.person?.id || null, Validators.required],
			person: [this.person|| null]
		});
	}

	handleOk(): void {
		this.isVisible = false;
		this.saveResource();
	}

	saveResource() {
		const formData = this.resourcesForm.getRawValue();
		this.resourcesForm.reset();
		this.modal.destroy(formData);
	}

	handlePersonChange(person: Person) {
		this.resourcesForm.get('person').setValue(person);
		this.resourcesForm.get('personId').setValue(person.id);
	}

	handleCancel(): void {
		this.modal.destroy();
	}

}
