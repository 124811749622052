import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';
import { SectorCategoriesTranslations } from '@metro-membership/data-access/enums';
import { debounceTime } from 'rxjs/operators';
import { sector, foods, amount_min, amount_max, amount_unit, amount_average } from './sector-kitchen';

@Component({
  selector: 'metro-membership-sector-kitchen',
  templateUrl: './sector-kitchen.component.html',
  styleUrls: ['./sector-kitchen.component.less']
})
export class SectorKitchenComponent implements OnInit {
	@Output() formChanges: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	sectorCategories = SectorCategoriesTranslations;
	@Input() facility = { } as Facility;
	formGroup: FormGroup;
	showForm = false;

  constructor(private formBuilder: FormBuilder) { }

	get sector() {
		return this.formGroup.get('sector') as FormArray;
	}

	get food() {
		return this.formGroup.get('food') as FormArray;
	}

	get amountUnit() {
		return this.formGroup.get('amountUnit') as FormArray;
	}

	get amountMin() {
		return this.formGroup.get('amountMin') as FormArray;
	}

	get amountMax() {
		return this.formGroup.get('amountMax') as FormArray;
	}

	get amountAverage() {
		return this.formGroup.get('amountAverage') as FormArray;
	}

  ngOnInit(): void {
		this.buildForms();
	}

	buildTableForm(categoryTable: any[]): FormGroup[] {
		return categoryTable.map(categories => {
			const currentSector = this.facility.sectors.find(sector => (sector.name === categories.name) )
			return this.formBuilder.group({
				id: currentSector?.id || null,
				name: categories.name || null,
				status: currentSector?.status || null,
				number: [{value: currentSector?.number, disabled: true}],
				isActive: currentSector?.isActive || null,
				comment:	currentSector?.comment || null,
				facilityId: this.facility.id,
			});
		});

	}
	buildForms() {

		this.formGroup = this.formBuilder.group({
			sector: this.formBuilder.array(this.buildTableForm(sector)),
			food: this.formBuilder.array(this.buildTableForm(foods)),
			amountUnit: this.formBuilder.array(this.buildTableForm(amount_unit)),
			amountMin: this.formBuilder.array(this.buildTableForm(amount_min)),
			amountMax: this.formBuilder.array(this.buildTableForm(amount_max)),
			amountAverage: this.formBuilder.array(this.buildTableForm(amount_average)),
		});

		this.showForm = true;
		this.formGroup.valueChanges
		.pipe(debounceTime(500))
		.subscribe(changes => {
			this.formChanges.next(changes);
		});
	}

}
