<metro-membership-breadcrumbs></metro-membership-breadcrumbs>

<form nz-form nzLayout="vertical" [formGroup]="searchForm" (ngSubmit)="formSubmit()" class="pt-4 pb-8 px-10 mb-14">
	<div nz-row nzGutter="30">
		<div nz-col nzSpan="8">
			<nz-form-item>
				<nz-form-label>Facility name</nz-form-label>
				<nz-form-control>
					<nz-input-group [nzSuffix]="nameClearTpl" nzAddOnBeforeIcon="icons:library">
						<input nz-input placeholder="Facility name" formControlName="name" />
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<ng-template #nameClearTpl>
				<i nz-icon class="ant-input-clear-icon" nzTheme="outline" nzType="close-circle" *ngIf="searchForm.get('name').value"
					 (click)="searchForm.get('name').reset()"></i>
			</ng-template>
		</div>

		<div nz-col nzSpan="8">
			<nz-form-item>
				<nz-form-label>Related institute</nz-form-label>
				<nz-form-control>
					<nz-input-group [nzSuffix]="instituteNameClearTpl" nzAddOnBeforeIcon="icons:library">
						<input nz-input placeholder="Related institute" formControlName="instituteName" />
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<ng-template #instituteNameClearTpl>
				<i nz-icon class="ant-input-clear-icon" nzTheme="outline" nzType="close-circle" *ngIf="searchForm.get('instituteName').value"
					 (click)="searchForm.get('instituteName').reset()"></i>
			</ng-template>
		</div>

		<div nz-col nzSpan="8">
			<nz-form-item>
				<nz-form-label>Country</nz-form-label>
				<nz-form-control>
					<nz-input-group [nzSuffix]="instituteCountryClearTpl" nzAddOnBeforeIcon="icons:map-marker">
						<input nz-input placeholder="Country" formControlName="instituteCountry" />
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<ng-template #instituteCountryClearTpl>
				<i nz-icon class="ant-input-clear-icon" nzTheme="outline" nzType="close-circle" *ngIf="searchForm.get('instituteCountry').value"
					 (click)="searchForm.get('instituteCountry').reset()"></i>
			</ng-template>
		</div>

		<div nz-col nzSpan="8">
			<label>Filter by type</label>
			<div class="mt-3">
				<nz-checkbox-wrapper (nzOnChange)="searchForm.get('type').setValue($event)">
					<div nz-row [nzGutter]="[24, 12]">
						<div nz-col nzSpan="12">
							<label nz-checkbox nzValue="rm" [nzChecked]="isChecked('type', 'rm')">
								RM facility
							</label>
						</div>
						<div nz-col nzSpan="12">
							<label nz-checkbox nzValue="analytical" [nzChecked]="isChecked('type', 'analytical')">
								Analytical lab
							</label>
						</div>
						<div nz-col nzSpan="12">
							<label nz-checkbox nzValue="primary" [nzChecked]="isChecked('type', 'primary')">
								Primary Production
							</label>
						</div>
						<div nz-col nzSpan="12">
							<label nz-checkbox nzValue="process_kitchen" [nzChecked]="isChecked('type', 'process_kitchen')">
								Processing and Kitchen lab
							</label>
						</div>
					</div>
				</nz-checkbox-wrapper>
			</div>
		</div>
	</div>

	<div nz-row class="mt-6">
		<div nz-col nzSpan="24" class="text-center">
			<button nz-button nzType="primary" class="my-2 mx-2">Find<i nz-icon nzType="search"></i></button>
			<button nz-button (click)="searchForm.reset({ type: [] })" type="button" class="my-2 mx-2">Clear All</button>
		</div>
	</div>
</form>

<metro-membership-paginated-table [headers]="tableHeaders" [data]="facilities | async" [params]="filterParams"
																	(paramsChange)="makeQuery($event)">
	<ng-template #trTemplate let-data>
		<tr>
			<td><a routerLink="/institutes/{{ data.institute.id }}/facilities/{{ data.id }}">{{ data.facilityName }}</a></td>
			<td><a routerLink="/institutes/{{ data.institute.id }}">{{ data.institute.shortName }}</a></td>
			<td>{{ data.institute.country }}</td>
			<td><metro-membership-facility-type [type]="data.type"></metro-membership-facility-type></td>
			<td>
				<span *ngIf="data.persons && data.persons.length > 0" nz-typography nzCopyable [nzContent]="data.persons[0].person?.email"></span>
			</td>
		</tr>
	</ng-template>
</metro-membership-paginated-table>
