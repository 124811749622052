import { HttpParams } from '@angular/common/http';
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	EventEmitter,
	OnDestroy,
	Input,
	ContentChild,
	Output,
	TemplateRef
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { PaginatedData } from '../../types/paginated-data';
import { PaginatedTableHeader } from './paginated-table-header';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
	selector: 'metro-membership-paginated-table',
	templateUrl: './paginated-table.component.html',
	styleUrls: ['./paginated-table.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatedTableComponent<T = unknown> implements OnInit, OnDestroy {
	@Input() data: PaginatedData<T>;
	@Input() headers: PaginatedTableHeader[];
	@Input() params: HttpParams;
	@Output() paramsChange: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();
	@ContentChild('trTemplate') trTemplate: TemplateRef<{ data: T }>;
	subscriptions: Subscription[] = [];
	paramsChangeSub: Subject<HttpParams> = new Subject<HttpParams>();

	ngOnInit() {
		this.subscriptions = [
			this.paramsChangeSub.pipe(
				distinctUntilChanged((x, y) => x.toString() === y.toString())
			).subscribe(params => this.paramsChange.next(params))
		];
	}

	changeQueryParams(queryParams: NzTableQueryParams) {
		let params = this.params;

		const currentSort = queryParams.sort.find(s => s.value);
		if (currentSort) {
			params = params.set('sort', currentSort.key + ',' + this.mapSortOrder(currentSort.value));
		}

		if (queryParams.pageIndex) {
			params = params.set('page', queryParams.pageIndex.toString());
		}

		if (queryParams.pageSize) {
			params = params.set('limit', queryParams.pageSize.toString());
		}

		this.paramsChangeSub.next(params);
	}

	private mapSortOrder(sortOrder: NzTableSortOrder) {
		return sortOrder === 'descend' ? 'DESC' : 'ASC';
	}

	ngOnDestroy() {
		this.paramsChangeSub.complete();
	}
}
