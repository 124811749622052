<div class="mb-3 d-flex justify-content-end" >
	<div class="mt-2 d-flex">
		<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
			<div>EDIT</div>
			<i nz-icon nzType="icons:edit" class="ml-4"></i>
		</button>
		<button class="ml-5 d-flex align-items-center primary" (click)="save()" *ngIf="isEditable">
			<div>SAVE</div>
		</button>

	</div>
</div>

<div *ngIf="showForms" [formGroup]="rmForm">

	<div class="relative mb-15">
		<h3>5.1 Ultracleaning and sterilization systems</h3>
		<div *ngFor="let item of storageHow | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'"
				>
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.2 Balances and calibrated masses</h3>
		<div *ngFor="let item of waterActivity | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.3 Homogenization, physical and physical-chemical transformation</h3>
		<div *ngFor="let item of homogenization | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.4 Sample dividing, packaging & labeling</h3>
		<div *ngFor="let item of sample | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.5 Instruments for stability testing</h3>
		<div *ngFor="let item of instruments | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.6 Dedicated water activity and particulate size testing</h3>
		<div *ngFor="let item of waterActivity | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)"  [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>



	<div class="relative mb-15">
		<h3>5.7 Storage</h3>
		<div *ngFor="let item of storageHow | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

<!--	<div class="relative mb-15">-->
<!--		<div class="description mb-5">Which and how many equipment are at the disposal of the facility?</div>-->
<!--		<div *ngFor="let item of howManyFacilityEquipment.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">-->
<!--			<label nz-checkbox (ngModelChange)="changeDisability(item.controls.number)" formControlName="isActive">-->
<!--				<div class="title">{{ item.get('product').value }}</div>-->
<!--			</label>-->
<!--			<metro-form-input class="standard mb-3" type="text" ph="Amount" formControlName="number"-->
<!--				[control]="item.controls.number">-->
<!--			</metro-form-input>-->
<!--		</div>-->
<!--	</div>-->

<!--	<div class="relative mb-15" *ngFor="let item of productionInstrumentation.controls; index as i">-->
<!--		<h3>5.8 Other instrumentation for production of RMs</h3>-->
<!--		<div class="description mb-5">If there is any other equipment at your disposal for the production of RMs, list it here</div>-->
<!--		<nz-textarea-count [nzMaxCharacterCount]="300" [formGroup]="item" class="w-100">-->
<!--			<textarea rows="4" formControlName="other" nz-input></textarea>-->
<!--		</nz-textarea-count>-->
<!--	</div>-->



</div>
