import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Institute, InstitutePlan } from '@metro-membership/data-access';
import { CountryCurrencyEnum } from '@metro-membership/data-access/enums';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InstituteService } from '../../data/services/institute.service';
import { CustomValidator } from '../../shared/validators/percentage.validator';

@Component({
  selector: 'metro-membership-institute-plan',
  templateUrl: './institute-plan.component.html',
  styleUrls: ['./institute-plan.component.less']
})
export class InstitutePlanComponent {
	institute: Institute;
	institutePlan: InstitutePlan;
	planForm: FormGroup;
	showForm = false;
	unSubscribe = new Subject();
	isEditable = false;
	currency = new Set<string>(Object.values(CountryCurrencyEnum));

	constructor(private formBuilder: FormBuilder,  private activatedRoute: ActivatedRoute,
							private instituteService: InstituteService) {
		this.activatedRoute.parent.params
			.pipe(takeUntil(this.unSubscribe))
			.subscribe(params => {
				this.getInstitute(params.instituteId)
			});
	}

	getInstitute(instituteId: string) {
		this.instituteService.fetchInstitute(instituteId).subscribe(institute => {
			this.institute = institute;
			this.buildForm();
			this.getInstitutePlan();
		});
	}

	buildForm() {
		this.planForm = this.formBuilder.group({
			instituteId: this.institute?.id,
			isEstimationOfToT: null,
			estimatedCost: ['', Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
				CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
			estimatedCostCurr: ['EUR'],
			averagePercentDedicated: ['', Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
				CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
			typeOfAccreditation: null,
			accreditationCost: [0, Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
				CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
			accreditationCostCurr: ['EUR'],
			eResources: this.formBuilder.array([])
		});
		this.planForm.disable();
		this.showForm = true;
	}

	addEResourceToForm() {
		const eResources = (this.planForm.controls.eResources as FormArray);
		eResources.push(this.createEResource());
		this.planForm.disabled && eResources.disable();
	}

	createEResource() {
		return this.formBuilder.group({
			id: null,
			description: [null, Validators.required],
			type: [null, Validators.required],
			purchaseValue: ['', Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
				CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
			purchaseValueCurr: ['EUR'],
			percentOfMetro: ['', Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
				CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
		});
	}

	getInstitutePlan() {
		this.instituteService.fetchInstitutePlan(this.institute?.id, new HttpParams({ fromObject: { join: ['eResources'] }})).toPromise().then(plan => {
			this.institutePlan = plan;
			this.setForm();
		});
	}

	get eResourcesControls(): AbstractControl[] {
		return (this.planForm.controls.eResources as FormArray).controls;
	}

	setForm() {
		if (this.institutePlan.eResources?.length !== (this.planForm.controls.eResources as FormArray).length) {
			const newControls = this.formBuilder.array([]);
			this.institutePlan.eResources?.forEach(() => newControls.push(this.createEResource()));
			this.planForm.controls.eResources = newControls;
			this.planForm.disabled && newControls.disable();
		}
		this.planForm.patchValue(this.institutePlan);
	}

	onSave() {
		if (this.institutePlan?.id) {
			this.editInstitutePlan();
		} else {
			this.addInstitutePlan();
		}
		this.isEditable = false;
	}

	editInstitutePlan() {
		const formData = this.planForm.getRawValue();
		this.instituteService.editInstitutePlan(formData, this.institutePlan.instituteId).subscribe(res => {
			this.planForm.disable();
			this.getInstitutePlan();
		});
	}

	addInstitutePlan() {
		const formData = this.planForm.getRawValue();
		delete formData.id;
		this.instituteService.addInstitutePlan(formData).subscribe(res => {
			this.planForm.disable();
			this.getInstitutePlan();
		});
	}

	onEdit() {
		this.planForm.enable();
		this.isEditable = true;
	}
}
