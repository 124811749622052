import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements OnDestroy {
	isLoaderVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);

	show() {
		this.isLoaderVisible.next(true);
	}

	hide() {
		this.isLoaderVisible.next(false);
	}

	ngOnDestroy() {
		this.isLoaderVisible.complete();
	}
}
