import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Institute, InstitutePersonRole, Person } from '@metro-membership/data-access';
import { PersonRole } from '@metro-membership/data-access/enums';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UserService } from '../../core/services/user.service';
import { FacilityService } from '../../data/services/facility.service';
import { InstituteService } from '../../data/services/institute.service';
import { PersonService } from '../../data/services/person.service';
import { ChoosePersonComponent } from '../../shared/components/choose-person/choose-person.component';
import { PersonModalComponent } from '../../shared/modals/person-modal/person-modal.component';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
	selector: 'metro-membership-node-view',
	templateUrl: './node-view.page.html',
	styleUrls: ['./node-view.page.less']
})
export class NodeViewPage implements OnInit, OnDestroy {
	editMode = false;
	showForm = false;
	showFinances = false;
	showQualities = false;
	showPrimary = false;
	showDeputy = false;

	unSubscribe = new Subject();
	formSubscribe: Subscription;
	instituteForm: FormGroup;

	deputyModal = false;
	institute: Institute = null;

	deputyInvestigator: InstitutePersonRole;
	primaryInvestigator: InstitutePersonRole;
	financesManagers: InstitutePersonRole[] = [];
	qualityManagers: InstitutePersonRole[] = [];
	resourceManagers: InstitutePersonRole[] = [];

	personRole = PersonRole;
	activeUserRoles = [];

	geocoder: any;
	center: any = null;

	constructor(
		private activatedRoute: ActivatedRoute,
		private instituteService: InstituteService,
		private personRoleService: PersonService,
		public facilityService: FacilityService,
		private modal: NzModalService,
		private userService: UserService,
		private fb: FormBuilder,
		private http: HttpClient,
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.activatedRoute.params
			.pipe(takeUntil(this.unSubscribe))
			.subscribe(params => {
				this.getInstitute(params.instituteId);
			});
	}

	ngOnDestroy() {
		this.unSubscribe.next();
		this.unSubscribe.complete();
	}

	getCurrentUserRole() {
		const user = this.userService.user.getValue();
		const instRole = user?.instituteRoles.filter(role => role.instituteId === this.institute.id);
		this.activeUserRoles = instRole?.map(instRole => instRole.role);

		this.setFinancesVisibility();
	}

	setFinancesVisibility() {
		this.showFinances = (
			this.activeUserRoles.indexOf(this.personRole.REPRESENTATIVE) > -1 ||
			this.activeUserRoles.indexOf(this.personRole.DEPUTY) > -1  ||
			this.activeUserRoles.indexOf(this.personRole.FINANCIAL_DATA_MANAGER) > -1  ||
			this.userService.keycloakRoles.indexOf('admin') > -1
		);

		this.showQualities = (
			this.activeUserRoles.indexOf(this.personRole.REPRESENTATIVE) > -1 ||
			this.activeUserRoles.indexOf(this.personRole.DEPUTY) > -1  ||
			this.activeUserRoles.indexOf(this.personRole.QUALITY_MANAGER) > -1  ||
			this.userService.keycloakRoles.indexOf('admin') > -1);

		this.showPrimary = (
			this.activeUserRoles.indexOf(this.personRole.REPRESENTATIVE) > -1 ||
			this.userService.keycloakRoles.indexOf('admin') > -1
		);

		this.showDeputy = (
			this.activeUserRoles.indexOf(this.personRole.REPRESENTATIVE) > -1 ||
			this.activeUserRoles.indexOf(this.personRole.DEPUTY) > -1  ||
			this.userService.keycloakRoles.indexOf('admin') > -1
		);
	}

	handlePersons() {
		this.deputyInvestigator = this.institute.persons.find(person => person.role === PersonRole.DEPUTY);
		this.primaryInvestigator = this.institute.persons.find(person => person.role === PersonRole.REPRESENTATIVE);
		this.financesManagers = this.institute.persons.filter(person => person.role === PersonRole.FINANCIAL_DATA_MANAGER);
		this.qualityManagers = this.institute.persons.filter(person => person.role === PersonRole.QUALITY_MANAGER);
		this.resourceManagers = this.institute.persons.filter(person => person.role === PersonRole.RESOURCE_MANAGER);
	}

	getInstitute(instituteId: string) {
		this.instituteForm.disable();

		const params = new HttpParams({
			fromObject: {
				join: ['persons', 'persons.person', 'departments', 'instituteStaff', 'resources']
			}
		});

		this.instituteService.fetchInstitute(instituteId, params).toPromise().then((instituteResponse) => {
			this.institute = instituteResponse;
			if (this.formSubscribe) {
				this.formSubscribe.unsubscribe();
			}
			this.handlePersons();
			this.setForms();
			this.getCurrentUserRole();
			this.showForm = true;
		});
	}

	get isEditable() {
		return this.instituteForm.enabled as boolean;
	}

	buildForm() {
		this.instituteForm = this.fb.group({
			address: '',
			zipCode: '',
			city: '',
			country: '',
			pic: '',
			shortName: ''
		});

		this.instituteForm.disable();
	}

	setForms() {
		this.instituteForm.setValue({
			address: this.institute.address,
			zipCode: this.institute.zipCode,
			city: this.institute.city,
			country: this.institute.country,
			pic: this.institute.pic,
			shortName: this.institute.shortName
		});
		this.center = {
			lat: this.institute.lat,
			lng: this.institute.lng
		};

		this.registerEvents();
	}

	onEdit() {
		this.editMode = true;
		this.instituteForm.enable();
	}

	edit() {
		const instituteForm = this.instituteForm.getRawValue();
		this.institute = { ...this.institute, ...instituteForm };

		this.instituteService.editInstitute(this.institute).toPromise().then(() => {
			this.instituteService.institutesUpdate$.next(true);
			this.instituteForm.disable();
			this.editMode = false;
		});
	}

	showDeputyModal() {
		const deputyModal: NzModalRef = this.modal.create({
			nzTitle: 'Search the list of users, that are not assigned to any organisation, to select the ones you want to add as staff of METROFOOD-RI Central Hub. If you cannot find the person on the list, you can also invite them to join the platform.',
			nzContent: PersonModalComponent
		})
		deputyModal.afterClose.subscribe((results: { person: Person } ) => {
			this.deputyRoleChange(results.person);
		})
	}

	registerEvents() {
		this.formSubscribe = this.instituteForm.get('address').valueChanges.pipe(
			takeUntil(this.unSubscribe),
			debounceTime(3000)
		).subscribe((change: string) => {
			if (change) {
				this.findLocation(change);
			}
		});
	}

	findLocation(address: string) {
		// if (!this.geocoder) this.geocoder = new google.maps.Geocoder()
		// this.geocoder.geocode({
		// 	'address': address
		// }, (results, status) => {
		// 	if (status == google.maps.GeocoderStatus.OK) {
		// 		this.institute.lat = results[0].geometry.location.lat();
		// 		this.institute.lng = results[0].geometry.location.lng();
		// 		this.center = {
		// 			lat: this.institute.lat,
		// 			lng: this.institute.lng
		// 		}

		// 	} else {
		// 		this.notificationService.createBasicNotification('Sorry', 'this search produced no results.', 'error')
		// 	}
		//})
	}

	deputyRoleChange(updatedPerson: Person) {
		const updatedRole = {
			instituteId: this.institute.id,
			personId: updatedPerson.id,
			role: PersonRole.DEPUTY
		} as InstitutePersonRole;
		if(this.deputyInvestigator) {
			this.personRoleService.updateInstitutePersonRole(updatedRole, this.deputyInvestigator.id).then(res => {
				this.getInstitute(this.institute.id.toString())
			})
		} else {
			this.createInstituteRole(updatedRole)
		}
	}

	showFinancialAssingModal() {
		const financialModal: NzModalRef = this.modal.create({
			nzTitle: 'Search the list of users, that are not assigned to any organisation, to select the ones you want to add as staff of METROFOOD-RI Central Hub. If you cannot find the person on the list, you can also invite them to join the platform.',
			nzContent: PersonModalComponent
		});

		financialModal.afterClose.subscribe((results: { person: Person } ) => {
			const role = {
				instituteId: this.institute.id,
				personId: results.person.id,
				role: PersonRole.FINANCIAL_DATA_MANAGER
			} as InstitutePersonRole;
			this.createInstituteRole(role)
		});
	}

	showResourceAssingModal() {
		const qualityModal: NzModalRef = this.modal.create({
			nzTitle: 'Search the list of users, that are not assigned to any organisation, to select the ones you want to add as staff of METROFOOD-RI Central Hub. If you cannot find the person on the list, you can also invite them to join the platform.',
			nzContent: PersonModalComponent
		});

		qualityModal.afterClose.subscribe((results: { person: Person } ) => {
			const role = {
				instituteId: this.institute.id,
				personId: results.person.id,
				role: PersonRole.RESOURCE_MANAGER
			} as InstitutePersonRole;
			this.createInstituteRole(role)
		});
	}

	showQualityAssignModal() {
		const qualityModal: NzModalRef = this.modal.create({
			nzTitle: 'Search the list of users, that are not assigned to any organisation, to select the ones you want to add as staff of METROFOOD-RI Central Hub. If you cannot find the person on the list, you can also invite them to join the platform.',
			nzContent: PersonModalComponent
		});

		qualityModal.afterClose.subscribe((results: { person: Person } ) => {
			const role = {
				instituteId: this.institute.id,
				personId: results.person.id,
				role: PersonRole.QUALITY_MANAGER
			} as InstitutePersonRole;
			this.createInstituteRole(role)
		});
	}

	createInstituteRole(role: InstitutePersonRole) {
		this.personRoleService.createInstituteRole(role).then(res => {
			this.getInstitute(this.institute.id.toString())
		});
	}

deleteInstituteRole(role: InstitutePersonRole) {
		this.personRoleService.deleteInstituteRole(role).then(response => {
			this.getInstitute(this.institute.id.toString())
		});
	}
}
