import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SearchPersonPage } from './search-person/search-person.page';
import { SearchInstitutePage } from './search-institute/search-institute.page';
import { SearchFacilityPage } from './search-facility/search-facility.page';
import { SearchRoutingModule } from './search-routing.module';

@NgModule({
  declarations: [
  	SearchPersonPage,
		SearchInstitutePage,
		SearchFacilityPage
	],
  imports: [
    SharedModule,
		SearchRoutingModule
  ]
})
export class SearchModule { }
