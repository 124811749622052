<div class="modal-content">
<nz-tabset nzType="card">
	<nz-tab [nzTitle]="'Resource details'">

			<div class="mt-1 mb-1 standard-text titillium-font">Resource Name</div>
			<form action="" [formGroup]="resourcesForm">
				<metro-form-input class="standard w-100" type="text" ph="Resource Name" formControlName="name"
				[control]="resourcesForm.controls.name"></metro-form-input>
			</form>
		</nz-tab>
		<nz-tab [nzTitle]="'Resource Person'">
			<div class="mb-2">
				Select a contact person responsible for the new e-resource from the list of users or add a new user in the system. An automatic e-mail will be sent to the person, informing them they should fill out details about the resource.
			</div>
			<metro-membership-choose-person
			(personChange)="handlePersonChange($event)"
			[chosenPerson] = "person"
			></metro-membership-choose-person>
		</nz-tab>
		</nz-tabset>
	</div>

<ng-template [nzModalFooter]>
	<div class="d-flex justify-content-end ">
		<button class="secondary" (click)="handleCancel()">Cancel</button>
		<button class="primary" (click)="handleOk()" [ngClass]="{'disabled': resourcesForm.invalid}">Save</button>
	</div>
</ng-template>
