<form action="" [formGroup]="facilityForm">
	<div class="mt-2 d-flex ">
		<input type="text" [ngClass]="{'editable': isEditable}" class="transparent transparent__title"
		formControlName="facilityName">
		<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
			<span>EDIT</span>
			<i nz-icon nzType="icons:edit" class="ml-4"></i>
		</button>
		<button class="ml-5 d-flex align-items-center primary" (click)="onAddSite()" *ngIf="isEditable">
			<span>ADD SITE</span>
		</button>
		<button class="ml-5 d-flex align-items-center primary" (click)="save()" *ngIf="isEditable">
			<span>SAVE</span>
		</button>
	</div>

	<div class="title mt-5 mb-5 d-flex align-items-center" >
		<span>
			Facility contact person:
		</span>
			<button class="ml-5 d-flex align-items-center secondary" (click)="showFacilityRepModal()" *ngIf="isEditable && activeUserRole === personRole.REPRESENTATIVE">
				<span>EDIT</span>
				<i nz-icon nzType="icons:edit" class="ml-4"></i>
		</button>
	</div>
	<ng-container *ngIf="primaryInvestigators.length === 0">
		none
	</ng-container>
	<div class="d-flex flex-wrap mb-10 ">
		<metro-membership-person-info class="mr-4" *ngFor="let primaryInvestigator of primaryInvestigators" [person]="primaryInvestigator?.person"></metro-membership-person-info>
	</div>


	<div class="title mt-5 mb-5 d-flex align-items-center" >
		<span>
			Facility Quality Manager:
		</span>
	</div>
	<ng-container *ngIf="qualityManagers.length === 0">
		none
	</ng-container>
	<div class="d-flex flex-column mb-10 ">
		<metro-membership-person-info class="mr-4" *ngFor="let qualityManager of qualityManagers" [person]="qualityManager?.person"></metro-membership-person-info>
	</div>

	<ng-container *ngIf="facility?.sites && facility?.sites.length > 0">
		<nz-collapse>
			<nz-collapse-panel *ngFor="let site of facility.sites; index as i" [nzHeader]=" 'Site ' +  (i + 1) + ' :  ' + (site.spec ? site.spec : '')  " [nzActive]="false"
			[nzShowArrow]="true" [nzExtra]="extraTpl">
				<ng-template #extraTpl >
					<i nz-icon nzType="edit" class="mr-5" (click)="onEditSite($event, site)"></i>
					<i nz-icon nzType="delete" (click)="onDeleteSite($event, site)"></i>
				</ng-template>
				<div class="d-flex justify-content-between">
				<div>
					<div class="title mb-5">Site contact person:</div>
					<div class="d-flex flex-column" >
						<metro-membership-person-info [person]="site?.sitePerson" *ngIf="site?.sitePerson"></metro-membership-person-info>
					</div>
				</div>

					<div class="d-flex" >
						<div class="mr-20">
							<div class="title">Address</div>
							<div>{{site.street}}</div>
							<div class="d-flex">
								<div>{{site.zipCode}}</div>
								<div>{{site.city}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="title mt-5" >
					Site details:
				</div>
				<div >Approx. m^2: {{site.square}}</div>
			</nz-collapse-panel>
		</nz-collapse>
	</ng-container>
</form>
