<div class="relative mt-2 mb-20">
	<nz-table class="simple"
						[nzFrontPagination]="false"
						[nzShowPagination]="total > perPage"
						(nzQueryParams)="handleQueryParams($event)"
						[nzTotal]="total"
						[nzPageSize]="perPage"
						[nzData]="resources">
		<thead>
		<tr>
			<th [nzSortFn]="true" nzColumnKey="name">Name</th>
			<th [nzSortFn]="true" nzColumnKey="type">Type</th>
			<th class="w-10 action-row">Actions</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let resource of resources" >
			<td  class="pointer">
<!--				<a [routerLink]="['resources', resource.id]">{{ resource.name }}</a>-->
				<a [routerLink]="['/','institutes', resource.instituteId, 'resources', resource.id]">{{ resource.name }}</a>
			</td>
			<td class="d-flex">
				<i nz-icon nzType="icons:resource-types/{{ resource.type | lowercase }}" class="mr-4"></i>
				{{ resource.type }}
			</td>
			<td class="action-row">
				<i nz-icon nzType="delete" (click)="onDeleteResource(resource.id)"></i>
			</td>
		</tr>

		</tbody>
		<button class="add-resource secondary" (click)="addResource()" *ngIf="showEditing">
			<span>Add Resource</span>
		</button>
	</nz-table>

</div>
