import { PersonRole } from '@metro-membership/data-access/enums';
import { AuthGrantManager } from '@metro-membership/util';

export class AuthRolesPresets {
	static async isManagerOfInstitute(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasInstituteRole(PersonRole.REPRESENTATIVE, PersonRole.DEPUTY, PersonRole.FINANCIAL_DATA_MANAGER);
	}

	static async  isFinancialManagerOfInstitute(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasInstituteRole(PersonRole.REPRESENTATIVE, PersonRole.DEPUTY, PersonRole.FINANCIAL_DATA_MANAGER);
	}

	static async isManagerOfFacilityOrInstitute(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasFacilityRole(PersonRole.REPRESENTATIVE, PersonRole.DEPUTY) ||
				await authGM.hasInstituteRole(PersonRole.REPRESENTATIVE, PersonRole.DEPUTY)
	}
	static async isFinancialManagerOfInstituteOrFacility(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasInstituteRole(PersonRole.FINANCIAL_DATA_MANAGER, PersonRole.DEPUTY, PersonRole.REPRESENTATIVE) ||
			await authGM.hasFacilityRole(PersonRole.FINANCIAL_DATA_MANAGER, PersonRole.REPRESENTATIVE);
	}

	static async isQualityManagerOfInstituteOrFacility(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasInstituteRole(PersonRole.QUALITY_MANAGER, PersonRole.REPRESENTATIVE, PersonRole.DEPUTY) ||
			await authGM.hasFacilityRole(PersonRole.QUALITY_MANAGER, PersonRole.REPRESENTATIVE);
	}

	static async isQualityManagerOfFacility(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasInstituteRole(PersonRole.QUALITY_MANAGER, PersonRole.REPRESENTATIVE, PersonRole.DEPUTY) ||
			await authGM.hasFacilityRole(PersonRole.REPRESENTATIVE, PersonRole.QUALITY_MANAGER);
	}

	static async isQualityManagerOfInstitute(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasInstituteRole(PersonRole.QUALITY_MANAGER, PersonRole.DEPUTY, PersonRole.REPRESENTATIVE);
	}

	static async isResourceManagerOfInstitute(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasInstituteRole(PersonRole.RESOURCE_MANAGER, PersonRole.DEPUTY, PersonRole.REPRESENTATIVE);
	}

	static async isResourceManagerOfInstituteOrResource(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasResourceRole(PersonRole.RESOURCE_MANAGER, PersonRole.REPRESENTATIVE) ||
			await authGM.hasInstituteRole(PersonRole.DEPUTY, PersonRole.REPRESENTATIVE, PersonRole.RESOURCE_MANAGER);
	}

	static async isResourceManagerOfInstituteOrAnyResource(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasResourceRole(PersonRole.RESOURCE_MANAGER, PersonRole.REPRESENTATIVE) ||
			await authGM.hasInstituteRole(PersonRole.DEPUTY, PersonRole.REPRESENTATIVE, PersonRole.RESOURCE_MANAGER);
	}

	static async  isServiceManager(authGM: AuthGrantManager): Promise<boolean> {
		return await authGM.hasInstituteRole(PersonRole.DEPUTY, PersonRole.REPRESENTATIVE) ||
		await authGM.hasFacilityRole(PersonRole.QUALITY_MANAGER, PersonRole.REPRESENTATIVE) ||
		await authGM.isServicesManager();
	}
}
