import { Component } from '@angular/core';
import { MenuPosition } from '../../shared/components/side-menu/menu-position';

@Component({
	selector: 'metro-membership-resources',
	templateUrl: './resources.page.html',
	styleUrls: ['./resources.page.less']
})
export class ResourcesPage {
	sideMenuPositions: MenuPosition[] = [
		{ text: 'General data ', url: `general`, subPositions: [
			{ text: 'Details', url: `details`, enabled: true, visibility: true },
			{ text: 'Additional', url: `additional`, enabled: true, visibility: true },
			{ text: 'Accessibility', url: `accessibility`, enabled: true, visibility: true },
		] },
	];

}
