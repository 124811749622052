

	<div class="modal-content">
		<metro-membership-choose-person
			(personChange)="handlePersonChange($event)"
	></metro-membership-choose-person>
	</div>

	<ng-template [nzModalFooter] >
		<button nz-button class="primary" (click)="handleOk()" [ngClass]="{'disabled': !person }">save</button>
		<button nz-button class="secondary" (click)="handleCancel()">cancel</button>
	</ng-template>
