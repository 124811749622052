<form action="" [formGroup]="resourcesForm" *ngIf="showForm">
	<div class="mt-2 d-flex justify-content-end">
		<input type="text" [ngClass]="{'editable': isEditable}" class="transparent transparent__title"
		formControlName="name">
		<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
			<span>EDIT</span>
			<i nz-icon nzType="icons:edit" class="ml-4"></i>
		</button>
		<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" [ngClass]="{'disabled': resourcesForm.invalid }">
			<span>SAVE</span>
		</button>
	</div>

	<div class="title mb-4 mt-8">E-resource type</div>
	<nz-radio-group formControlName="type">
		<label class="mb-4 d-flex" nz-radio [nzValue]="resourceTypes.SOFTWARE">
			<i nz-icon nzType="icons:resource-types/software" class="mx-4"></i>
			<span>Software</span>
		</label>
		<label class="mb-4 d-flex" nz-radio [nzValue]="resourceTypes.HARDWARE">
			<i nz-icon nzType="icons:resource-types/dataset" class="mx-4"></i>
			<span>Hardware</span>
		</label>
		<label class="mb-4 d-flex" nz-radio [nzValue]="resourceTypes.DATABASE">
			<i nz-icon nzType="icons:resource-types/dataset" class="mx-4"></i>
			<span>Database</span>
		</label>
		<label class="mb-4 d-flex" nz-radio [nzValue]="resourceTypes.WORKFLOW">
			<i nz-icon nzType="icons:resource-types/dataset" class="mx-4"></i>
			<span>Workflow</span>
		</label>
		<label class="mb-4 d-flex" nz-radio [nzValue]="resourceTypes.E_LEARNING_PLATFORM">
			<i nz-icon nzType="icons:resource-types/dataset" class="mx-4"></i>
			<span>E-learning platform</span>
		</label>
		<label class="mb-4 d-flex" nz-radio [nzValue]="resourceTypes.WIKI">
			<i nz-icon nzType="icons:resource-types/dataset" class="mx-4"></i>
			<span>Wiki</span>
		</label>
		<label class="mb-4 d-flex" nz-radio [nzValue]="resourceTypes.OTHER">
			<i nz-icon nzType="icons:resource-types/cloud" class="mx-4"></i>
			<span>Other</span>
		</label>
	</nz-radio-group>

	<metro-form-input *ngIf="resourcesForm.controls.type.value === resourceTypes.OTHER"
		class="standard mt-3 w-100" type="text" ph="Other" formControlName="typeComment"
		[control]="resourcesForm.controls.typeComment">
	</metro-form-input>

	<div class="title mt-5 mb-5 d-flex align-items-center" >
		<span>
			Contact person:
		</span>
			<button class="ml-5 d-flex align-items-center secondary" (click)="showPersonModal()"  *ngIf="isEditable && showEditing">
				<span>EDIT</span>
				<i nz-icon nzType="icons:edit" class="ml-4"></i>
		</button>
	</div>
	<ng-container *ngIf="!contactPerson">
		none
	</ng-container>
	<div class="d-flex flex-wrap mb-10 ">
		<metro-membership-person-info class="mr-4" [person]="contactPerson"></metro-membership-person-info>
	</div>
</form>
