import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Institute, InstituteFinances } from '@metro-membership/data-access';
import { CountryCurrencyEnum } from '@metro-membership/data-access/enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InstituteService } from '../../data/services/institute.service';
import { CustomValidator } from '../../shared/validators/percentage.validator';

@Component({
	selector: 'metro-membership-institute-finances',
	templateUrl: './institute-finances.page.html',
	styleUrls: ['./institute-finances.page.less']
})
@UntilDestroy({ arrayName: 'subscriptions' })
export class InstituteFinancesPage implements OnDestroy {
	subscriptions = [];
	questionForm: FormGroup;
	showForm = false;
	currency: Set<string> = new Set();
	currencyList: string[] = [];
	unSubscribe = new Subject();
	instituteId = '';
	institute: Institute;
	instituteFinances: InstituteFinances;

	constructor(
		private formBuilder: FormBuilder,
		private instituteService: InstituteService,
		private activatedRoute: ActivatedRoute
		) {

			this.currencyList = Object.values(CountryCurrencyEnum);
			for (const curr of this.currencyList) {
				this.currency.add(curr);
			}
			this.activatedRoute.parent.params
			.pipe(takeUntil(this.unSubscribe))
			.subscribe(params => {
				this.instituteId = params.instituteId;
				this.getInstitute();
				this.getQuestions();
			});
		}

		ngOnDestroy() {
			this.unSubscribe.next();
			this.unSubscribe.complete();
		}

		get isEditable() {
			return this.questionForm.enabled as boolean;
		}

		getQuestions() {
			this.buildForm();

			const params = new HttpParams({
				fromObject: {
					join: ['institute']
				}
			});

			this.instituteService.fetchInstituteFinances(this.instituteId, params).subscribe((questions: InstituteFinances) => {
				this.instituteFinances = questions;
				this.setForm();
			});
		}

		getInstitute() {
			this.instituteService.fetchInstitute(this.instituteId).subscribe(institute => {
				this.institute = institute;
			});
		}
		setForm() {
			this.questionForm.setValue({
				instituteId: this.instituteId,
				personnelScientistsCosts: this.instituteFinances?.personnelScientistsCosts || null,
				personnelLabCosts: this.instituteFinances?.personnelLabCosts || null,
				personnelTempStaffCosts: this.instituteFinances?.personnelTempStaffCosts || null,
				personnelManagmentCosts: this.instituteFinances?.personnelManagmentCosts || null,
				personnelAdminCosts: this.instituteFinances?.personnelAdminCosts || null,
				ItStaffCosts: this.instituteFinances?.ItStaffCosts || null,
				personnelAdminCostsCurr: this.instituteFinances?.personnelAdminCostsCurr ||'EUR',
				personnelManagmentCostsCurr: this.instituteFinances?.personnelManagmentCostsCurr || 'EUR',
				personnelScientistsCostsCurr: this.instituteFinances?.personnelScientistsCostsCurr || 'EUR',
				personnelLabCostsCurr: this.instituteFinances?.personnelLabCostsCurr || 'EUR',
				personnelTempStaffCostsCurr: this.instituteFinances?.personnelTempStaffCostsCurr || 'EUR',
				ItStaffCostsCurr: this.instituteFinances?.ItStaffCostsCurr || 'EUR'
			});
		}
		buildForm() {
			this.questionForm = this.formBuilder.group({
				instituteId: this.instituteId,
				personnelScientistsCosts: [this.instituteFinances?.personnelScientistsCosts || null,
					Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
					CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
				personnelLabCosts: [this.instituteFinances?.personnelLabCosts || null,
					Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
					CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
				personnelTempStaffCosts: [this.instituteFinances?.personnelTempStaffCosts || null,
					Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
					CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
				personnelManagmentCosts: [this.instituteFinances?.personnelManagmentCosts || null,
					Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
					CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
				personnelAdminCosts: [this.instituteFinances?.personnelAdminCosts || null,
					Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
					CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
				ItStaffCosts: [this.instituteFinances?.ItStaffCosts || null,
					Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
					CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
				personnelAdminCostsCurr: this.instituteFinances?.personnelAdminCostsCurr || 'EUR',
				personnelManagmentCostsCurr: this.instituteFinances?.personnelManagmentCostsCurr || 'EUR',
				personnelScientistsCostsCurr: this.instituteFinances?.personnelScientistsCostsCurr || 'EUR',
				personnelLabCostsCurr: this.instituteFinances?.personnelLabCostsCurr || 'EUR',
				personnelTempStaffCostsCurr: this.instituteFinances?.personnelTempStaffCostsCurr || 'EUR',
				ItStaffCostsCurr: this.instituteFinances?.ItStaffCostsCurr || 'EUR'
			});
			this.questionForm.disable();
			this.showForm = true;
		}

		onSave() {
			if (!this.questionForm.valid) {
				return true;
			}
			if (this.instituteFinances?.id) {
				this.editQuestions();
			} else {
				this.addQuestions();
			}
		}

		editQuestions() {
			const questionsBody = this.questionForm.getRawValue();
			this.instituteService.editInstituteFinances(questionsBody, this.instituteFinances.instituteId)
				.subscribe(() => {
				this.questionForm.disable();
				this.getQuestions();
			});
		}

		addQuestions() {
			const questionsBody = this.questionForm.getRawValue();
			this.instituteService.addInstituteFinances(questionsBody).subscribe(() => {
				this.questionForm.disable();
				this.getQuestions();
			});
		}

		onEdit() {
			this.questionForm.enable();
		}
	}
