import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InstituteResources, InstituteResourcesAccessibility } from '@metro-membership/data-access';
import { ResourceTypes } from '@metro-membership/data-access/enums';
import { Subject, Subscription } from 'rxjs';
import { InstituteService } from '../../data/services/institute.service';
import { setStandardControlsValidators } from '../../shared/utils/formHelper';

@Component({
  selector: 'metro-membership-resources-accessibility',
  templateUrl: './resources-accessibility.component.html',
  styleUrls: ['./resources-accessibility.component.less']
})
export class ResourcesAccessibilityComponent {
	isEditable = false;
	showForm = false;
	unSubscribe = new Subject();
	instituteId = '';
	subscriptions: Subscription[] = [];
	resourcesForm: FormGroup;
	resourceTypes = ResourceTypes;
	instituteResources: InstituteResources;
	instituteResourcesAccessibility: InstituteResourcesAccessibility;
	accessTerms: string[] = ['Fully open access (everyone can use the resource)',
	'Limited open access (for example registration required)',
	'Closed access (separate contract and/or payment required)' ]
  constructor(private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private instituteService: InstituteService) {
			this.subscriptions.push(
				activatedRoute.params.subscribe(params => {
					this.instituteId = params.instituteId;
					this.buildForm(params.resourceId);
					this.getResource(params.resourceId);
				}));
		}

		buildForm(instituteResource: string) {
			this.resourcesForm = this.formBuilder.group({
				id: null,
				resourceId: instituteResource,
				providedResource: null,
				accessTerm: [ null, Validators.required],
				licence: [ null, Validators.required],
				licenceConnected: null,
				onlineResource:[null, Validators.required],
				linkToResource: null,
			});
			this.showForm = true;
			this.validationChange();
			this.resourcesForm.disable();
		}

		getResource(resourceId: number) {
			const params = new HttpParams({
				fromObject: { join: ['accessibility'], instituteId: this.instituteId || null }
			});

			this.instituteService.fetchResource(resourceId, params).subscribe(resourceJson => {
				this.instituteResources = resourceJson;
				this.setForm();
			});
		}

		setForm() {
			this.resourcesForm.patchValue(this.instituteResources.accessibility);
			this.showForm = true;
		}

		onEdit() {
			this.resourcesForm.enable();
			this.isEditable = true;
		}

		onSave() {
			const params = new HttpParams({
				fromObject: {
					instituteId: this.instituteId || null
				}
			});
			const accessibility = this.resourcesForm.getRawValue() as InstituteResourcesAccessibility;
			this.instituteResources.accessibility = accessibility;
			this.instituteService.editResource(this.instituteResources, this.instituteResources.id, params).subscribe(res => {
				this.resourcesForm.disable();
				this.isEditable = false;
			})
		}

		validationChange() {
			const onlineResource = this.resourcesForm.get('onlineResource');
			const licence = this.resourcesForm.get('licence');

			const licenceConnected = this.resourcesForm.get('licenceConnected');
			const linkToResource = this.resourcesForm.get('linkToResource');

			setStandardControlsValidators(onlineResource,linkToResource);
			setStandardControlsValidators(licence,licenceConnected);
		}
}
