<form action="" [formGroup]="resourcesForm">
	<div class="mt-2 d-flex justify-content-end">
		<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
			<span>EDIT</span>
			<i nz-icon nzType="icons:edit" class="ml-4"></i>
		</button>
		<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" [ngClass]="{'disabled': resourcesForm.invalid }">
			<span>SAVE</span>
		</button>
	</div>

	<div class="w-100 d-flex relative">
		<div class="w-100">

			<metro-membership-form-text
				ph="E-resource description" formControlName="detailsDescription"
				label="E-resource description"
				[control]="resourcesForm.controls.detailsDescription">
			</metro-membership-form-text>

			<div class="title mb-4 mt-12">What is the current status of the resource?</div>

			<nz-radio-group formControlName="status" >

				<label class="mb-4 d-flex" nz-radio nzValue="concept">
					<span>Concept phase (the general idea for the resource was formulated)</span>
				</label>

				<label class="mb-4 d-flex" nz-radio nzValue="development">
					<span>Under development (work has started on creating the resource)</span>
				</label>

				<label class="mb-4 d-flex" nz-radio nzValue="testing">
					<span>Testing phase (the resource is almost complete and is being tested internally)</span>
				</label>

				<label class="mb-4 d-flex" nz-radio nzValue="operational">
					<span>Fully operational (the resource is complete and is in use, this option also applies if new features/data/etc. is still being added to the resource)</span>
				</label>

			</nz-radio-group>


			<metro-form-input
				class="standard w-100" type="text" ph="Date of the resource first creation" formControlName="firstCreation"
				label="Date of the resource first creation"
				[control]="resourcesForm.controls.firstCreation">
			</metro-form-input>

			<metro-form-input
				class="standard w-100" type="text" ph="Current resource version" formControlName="currentVersion"
				label="Current resource version"
				[control]="resourcesForm.controls.currentVersion">
			</metro-form-input>

			<metro-form-input
				class="standard w-100" type="text" ph="Date of the current version creation" formControlName="currentVersionCreation"
				label="Date of the current version creation"
				[control]="resourcesForm.controls.currentVersionCreation">
			</metro-form-input>

			<div class="title mb-4 mt-8">Are there further updates planned?</div>

			<nz-radio-group formControlName="updates">

				<label class="mb-4 d-flex" nz-radio [nzValue]="radioDecisions.YES">
					<span>Yes (please describe)</span>
				</label>

				<label class="mb-4 d-flex" nz-radio [nzValue]="radioDecisions.NO">
					<span>No</span>
				</label>

				<label class="mb-4 d-flex" nz-radio [nzValue]="radioDecisions.DONT_KNOW">
					<span>Don’t know</span>
				</label>

				<label class="mb-4 d-flex" nz-radio [nzValue]="radioDecisions.NOT_APPLY">
					<span>Does not apply</span>
				</label>

			</nz-radio-group>

			<metro-membership-form-text *ngIf="resourcesForm.controls.updates.value === radioDecisions.YES"
																	ph="Plans for update" formControlName="updatesComment"
																	label="Plans for update"
																	[control]="resourcesForm.controls.updatesComment">
			</metro-membership-form-text>

			<div class="title mt-8 mb-4">Is there a Maintenance/sustainability plan for resource?</div>

			<nz-radio-group formControlName="sustainabilityPlan">

				<label class="mb-4 d-flex" nz-radio [nzValue]="true">
					<span>Yes</span>
				</label>

				<label class="mb-4 d-flex" nz-radio [nzValue]="false">
					<span>No</span>
				</label>

				<label class="mb-4 d-flex" nz-radio [nzValue]="null">
					<span>Don’t know</span>
				</label>

			</nz-radio-group>

			<metro-membership-form-text *ngIf="resourcesForm.controls.sustainabilityPlan.value === true"
																	ph="Maintenance/sustainability plan for resource" formControlName="sustainabilityPlanComment"
																	label="Maintenance/sustainability plan for resource"
																	[control]="resourcesForm.controls.sustainabilityPlanComment">
			</metro-membership-form-text>

			<div class="mb-5">
				<div class="title mb-4 mt-8">Resource interface type</div>
				<nz-select
					class="w-50"
					formControlName="interfaceTypes"
					[nzMaxTagCount]="5"
					nzMode="multiple"
					nzPlaceHolder="Please select">
					<nz-option *ngFor="let type of interfaceTypes" [nzLabel]="type" [nzValue]="type"></nz-option>
				</nz-select>
			</div>

			<metro-form-input *ngIf="detectOtherOption(resourcesForm.controls.interfaceTypes)"
												class="standard mt-3 w-100" type="text" ph="Other interface type" formControlName="interfaceTypesComment"
												label="Other interface type"
												[control]="resourcesForm.controls.interfaceTypesComment">
			</metro-form-input>


			<div class="mb-5">
				<div class="title mb-4 mt-8">Technology used in resource</div>
				<nz-select
					class="w-50"
					formControlName="technologies"
					[nzMaxTagCount]="5"
					nzMode="multiple"
					nzPlaceHolder="Please select">
					<nz-option *ngFor="let technology of technologies" [nzLabel]="technology" [nzValue]="technology"></nz-option>
				</nz-select>

				<metro-form-input *ngIf="detectOtherOption(resourcesForm.controls.technologies)"
													class="standard mt-3 w-100" type="text" ph="Other technology used" formControlName="technologiesComment"
													label="Other technology used"
													[control]="resourcesForm.controls.technologiesComment">
				</metro-form-input>

			</div>
			<div class="mb-5">
				<div class="title mb-4 mt-8">Operating system</div>
				<nz-select
					class="w-50"
					formControlName="operatingSystems"
					[nzMaxTagCount]="5"
					nzMode="multiple"
					nzPlaceHolder="Please select">
					<nz-option *ngFor="let system of operatingSystems" [nzLabel]="system" [nzValue]="system"></nz-option>
				</nz-select>
			</div>

			<metro-form-input  *ngIf="detectOtherOption(resourcesForm.controls.operatingSystems)"
												 class="standard mt-3 w-100" type="text" ph="Other operating system" formControlName="operatingSystemsComment"
												 label="Other operating system"
												 [control]="resourcesForm.controls.operatingSystemsComment">
			</metro-form-input>

			<div class="title mt-5 mb-5">
				Is there a possibility for other organisations to self-host the resource or to install on own machines?
			</div>

			<nz-radio-group formControlName="selfHost">

				<label class="mb-4 d-flex" nz-radio [nzValue]="true">
					<span>Yes</span>
				</label>

				<label class="mb-4 d-flex" nz-radio [nzValue]="false">
					<span>No</span>
				</label>

				<label class="mb-4 d-flex" nz-radio [nzValue]="null">
					<span>Does not apply</span>
				</label>

			</nz-radio-group>

			<div class="title mt-5 mb-5">
				Main domain
			</div>

			<div class="mb-5 relative">
				<nz-select
					class="w-50"
					formControlName="domain"
					[nzMaxTagCount]="5"
					nzMode="multiple"
					nzPlaceHolder="Please select">
					<nz-option  nzLabel="Metro" nzValue="Metro"></nz-option>
					<nz-option  nzLabel="Food" nzValue="Food"></nz-option>
				</nz-select>
				<div *ngIf="resourcesForm.controls.domain.invalid" class="invalid-message">
					This field cannot be empty.
				</div>
			</div>

			<div class="title mt-5 mb-5">
				Target audience
			</div>

			<div class="mb-5 relative">
				<nz-select
					class="w-50"
					formControlName="targetAudience"
					[nzMaxTagCount]="5"
					nzMode="multiple"
					nzPlaceHolder="Please select">
					<nz-option  *ngFor="let audience of audiences" [nzLabel]="audience" [nzValue]="audience" ></nz-option>
				</nz-select>
				<div *ngIf="resourcesForm.controls.targetAudience.invalid" class="invalid-message">
					This field cannot be empty.
				</div>
			</div>

			<metro-form-input *ngIf="detectOtherOption(resourcesForm.controls.targetAudience)"
												class="standard mt-3 w-100" type="text" ph="Other target audience" formControlName="targetAudienceComment"
												label="Other target audience"
												[control]="resourcesForm.controls.targetAudienceComment">
			</metro-form-input>

			<div class="title mt-5 mb-5">
				Resource language
			</div>

			<div class="mb-5 relative">
				<nz-select
					class="w-50"
					formControlName="languageCode"
					[nzMaxTagCount]="5"
					nzMode="multiple"
					nzPlaceHolder="Please select">
					<nz-option  *ngFor="let lang of languages" [nzLabel]="lang.name" [nzValue]="lang.code" ></nz-option>
				</nz-select>
			</div>

			<div class="title mt-5 mb-5">
				Related topics
			</div>

			<div class="mb-5 relative">
				<div >Groups of topics based on core services:</div>
				<nz-select
					class="w-50"
					formControlName="servicesTopics"
					[nzMaxTagCount]="5"
					nzMode="multiple"
					nzPlaceHolder="Please select">
					<nz-option  *ngFor="let topic of servicesTopics" [nzLabel]="topic.label" [nzValue]="topic.value" ></nz-option>
				</nz-select>
			</div>

			<div class="mb-5 relative">
				<div >General topics:</div>
				<nz-select
					class="w-50"
					formControlName="generalTopics"
					[nzMaxTagCount]="5"
					nzMode="multiple"
					nzPlaceHolder="Please select">
					<nz-option  *ngFor="let topic of generalTopics" [nzLabel]="topic.label" [nzValue]="topic.value" ></nz-option>
				</nz-select>
			</div>

			<metro-form-input
				class="standard mt-3 w-100" type="text" ph="Link to more information" formControlName="link"
				label="Link to more information"
				[control]="resourcesForm.controls.link">
			</metro-form-input>

		</div>
	</div>
</form>
