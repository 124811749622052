import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Facility, FacilityFinances, FacilityHR, FacilityQuality, FacilityQualityServices, FacilityRi, FacilityServiceEntity, FacilitySite, FacilityUpgrade, FacilitySector } from '@metro-membership/data-access';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { BreadcrumbsService } from '../../shared/components/breadcrumbs/breadcrumbs.service';
import { PaginatedData } from '../../shared/types/paginated-data';

@Injectable({
	providedIn: 'root'
})
export class FacilityService {
	constructor(
		private http: HttpClient,
		private breadcrumbsService: BreadcrumbsService
	) { }

	fetchFacilities(params?: HttpParams): Observable<PaginatedData<Facility>> {
		return this.http.get<PaginatedData<Facility>>(`@facilities`, { params });
	}

	fetchFacility(facilityId: string, params?: HttpParams): Observable<Facility> {
		return this.http.get<Facility>(`@facilities/${facilityId}`, { params }).pipe(
			tap((facility: Facility) => this.breadcrumbsService.putBreadcrumbPart('facility', {
				id: facility.id,
				value: facility.facilityName
			}))
		);
	}

	addFacility(facilityBody: Facility): Observable<Facility> {
		return this.http.post<Facility>(`@facilities`, facilityBody);
	}

	editFacility(facilityBody: Facility): Observable<Facility>  {
		return this.http.patch<Facility>(`@facilities/${facilityBody.id}`, facilityBody);
	}

	replaceFacility(facilityBody: Facility) {
		return this.http.put<Facility>(`@facilities/${facilityBody.id}`, facilityBody);
	}

	deleteFacility(facilityId: number): Observable<any>  {
		return this.http.delete(`@facilities/${facilityId}`);
	}

	fetchFacilityFinances(facilityId: number, params?: HttpParams): Observable<FacilityFinances>  {
		return this.http.get<FacilityFinances>(`@facility-finances/${facilityId}`, { params });
	}

	addFacilityFinances(questions: FacilityFinances): Observable<FacilityFinances> {
		return this.http.post<FacilityFinances>(`@facility-finances`, questions);
	}

	editFacilityFinances(questionBody: FacilityFinances, id: number): Observable<FacilityFinances> {
		return this.http.patch<FacilityFinances>(`@facility-finances/${id}`, questionBody);
	}

	addFacilitySite(siteBody: FacilitySite): Observable<FacilitySite> {
		return this.http.post<FacilitySite>(`@facility-sites`, siteBody);
	}

	deleteFacilitySite(id: number): Observable<FacilitySite> {
		return this.http.delete<FacilitySite>(`@facility-sites/${id}`);
	}

	editFacilitySite(siteBody: FacilitySite, id: number): Observable<FacilitySite> {
		return this.http.patch<FacilitySite>(`@facility-sites/${id}`, siteBody);
	}

	addFacilityServices(services: FacilityServiceEntity): Observable<FacilityServiceEntity> {
		return this.http.post<FacilityServiceEntity>(`@facility-services`,services );
	}

	editFacilityServices(services: FacilityServiceEntity, id: number): Observable<FacilityServiceEntity> {
		return this.http.patch<FacilityServiceEntity>(`@facility-services/${id}`,services );
	}

	fetchFacilityServices(id: number): Observable<FacilityServiceEntity> {
		return this.http.get<FacilityServiceEntity>(`@facility-services/${id}`);
	}

	addMultipleFacilitySectors(sectorBody: FacilitySector[]): Observable<FacilitySector> {
		return this.http.post<FacilitySector>(`@facility-sector/bulk`, {
			bulk: sectorBody
		});
	}

	fetchFacilityRi(facilityId: number): Observable<FacilityRi> {
		return this.http.get<FacilityRi>(`@facility-ri/${facilityId}`);
	}

	editFacilityRi(riBody: FacilityRi, id: number): Observable<FacilityRi> {
		return this.http.patch<FacilityRi>(`@facility-ri/${id}`, riBody);
	}

	addFacilityRi(riBody: FacilityRi): Observable<FacilityRi> {
		return this.http.post<FacilityRi>(`@facility-ri`, riBody);
	}
	addFacilityHr(hrBody: FacilityHR): Observable<FacilityHR> {
		return this.http.post<FacilityHR>(`@facility-hr`, hrBody);
	}
	editFacilityHr(hrBody: FacilityHR, id: number): Observable<FacilityHR> {
		return this.http.patch<FacilityHR>(`@facility-hr/${id}`, hrBody);
	}

	fetchFacilityHr(id: number): Observable<FacilityHR> {
		return this.http.get<FacilityHR>(`@facility-hr/${id}`);
	}

	addFacilityUpgrade(upgradeBody: FacilityUpgrade): Observable<FacilityUpgrade> {
		return this.http.post<FacilityUpgrade>(`@facility-upgrade`, upgradeBody);
	}

	editFacilityUpgrade(upgradeBody: FacilityUpgrade, id: number): Observable<FacilityUpgrade> {
		return this.http.patch<FacilityUpgrade>(`@facility-upgrade/${id}`, upgradeBody);
	}

	fetchFacilityUpgrade(id: number) {
		return this.http.get<FacilityUpgrade>(`@facility-upgrade/${id}`);
	}
	fetchFacilityQuality(id: number, params: HttpParams): Observable<FacilityQuality>  {
		return this.http.get<FacilityQuality>(`@facility-quality/${id}`, {params});
	}
	addFacilityQuality(qualityBody: FacilityQuality): Observable<FacilityQuality> {
		return this.http.post<FacilityQuality>(`@facility-quality`, qualityBody);
	}
	editFacilityQuality(qualityBody: FacilityQuality, id: number): Observable<FacilityQuality> {
		return this.http.put<FacilityQuality>(`@facility-quality/${id}`, qualityBody);
	}

	addFacilityQualityServices(qualityServices: FacilityQualityServices) {
		return this.http.post<FacilityQualityServices>(`@facility-quality-services`, qualityServices);
	}

	editFacilityQualityServices(qualityServices: FacilityQualityServices, id: number): Observable<FacilityQualityServices> {
		return this.http.put<FacilityQualityServices>(`@facility-quality-services/${id}`, qualityServices);
	}
}
