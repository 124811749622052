import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'metro-membership-resources-hardware',
  templateUrl: './resources-hardware.component.html',
  styleUrls: ['./resources-hardware.component.less']
})
export class ResourcesHardwareComponent {
	@Input() resourcesForm: FormGroup;
}
