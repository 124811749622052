import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';
import { Subject } from 'rxjs';
import { FacilityService } from '../../data/services/facility.service';
import { PaginatedData } from '../../shared/types/paginated-data';
import { PaginatedTableHeader } from '../../shared/components/paginated-table/paginated-table-header';

@Component({
  selector: 'metro-membership-search-facility',
  templateUrl: './search-facility.page.html',
  styleUrls: ['./search-facility.page.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFacilityPage implements OnInit, OnDestroy {
	searchForm: FormGroup;
	tableHeaders: PaginatedTableHeader[] = [
		{ label: 'Facility name', columnKey: 'facilityName', sortFn: true },
		{ label: 'Institute name', columnKey: 'institute.shortName', width: '180px', sortFn: true },
		{ label: 'Country', columnKey: 'institute.country', width: '110px', sortFn: true },
		{ label: 'Type', columnKey: 'type', width: '185px', sortFn: true },
		{ label: 'Contact person email', width: '200px', columnKey: 'email' },
	];
	filterParams = new HttpParams({
		fromObject: {
			page: '1',
			limit: '10',
			join: ['institute', 'persons', 'persons.person']
		}
	});
	facilities: Subject<PaginatedData<Facility>> = new Subject<PaginatedData<Facility>>();

	constructor(private facilityService: FacilityService) {}

	ngOnInit() {
		this.searchForm = new FormGroup({
			name: new FormControl(null),
			instituteName: new FormControl(null),
			instituteCountry: new FormControl(null),
			type: new FormControl([])
		});
	}

	isChecked(formControlName: string, value: string): boolean {
		return this.searchForm.get(formControlName).value.findIndex(v => v === value) >= 0;
	}

	formSubmit() {
		const qp = this.mapFormToRequest(this.searchForm.getRawValue());
		if (Object.keys(qp)) {
			this.filterParams = this.filterParams.set('s', JSON.stringify(qp));
		} else {
			this.filterParams = this.filterParams.delete('s');
		}

		this.makeQuery(this.filterParams);
	}

	makeQuery(params: HttpParams) {
		this.facilityService.fetchFacilities(params).subscribe(facilities => this.facilities.next(facilities));
	}

	mapFormToRequest(fv: Record<string, any>): Record<string, any> {
		const qp = {};

		if (fv.name) {
			qp['facilityName'] = { $contL: fv.name }
		}

		if (fv.instituteName) {
			qp['institute.shortName'] = { $contL: fv.instituteName }
		}

		if (fv.instituteCountry) {
			qp['institute.country'] = { $contL: fv.instituteCountry }
		}

		if (fv.type && fv.type.length) {
			qp['type'] = { $in: fv.type }
		}

		return qp;
	}

	ngOnDestroy() {
		this.facilities.complete();
	}
}
