import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Facility, Person } from '@metro-membership/data-access';
import { FacilityTypeTranslations, PersonRole } from '@metro-membership/data-access/enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserService } from 'apps/front/src/app/core/services/user.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { FacilityService } from '../../../../data/services/facility.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { FacilityModalComponent } from '../../modals/facility-modal/facility-modal.component'
@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
	selector: 'metro-membership-facility-table',
	templateUrl: './facility-table.component.html',
	styleUrls: ['./facility-table.component.less']
})
export class FacilityTableComponent implements OnInit {
	facilities: Facility[] = [];
	instituteId = '';
	subscriptions = [];
	facilityTypes = FacilityTypeTranslations;
	pageIndex = 1;
	perPage = 5;
	offset = 0;
	total = 0;

	activeUserRoles = [];
	showEditing : boolean;

	facilityModal = false;

	sortOrder: NzTableSortOrder;
	sortColumn = '';
	facilityParams: HttpParams = new HttpParams();

	constructor(
		private confirmationService: ConfirmationService,
		private facilityService: FacilityService,
		private modal: NzModalService,
		private activatedRoute: ActivatedRoute,
		private userService : UserService

		) { }

		getCurrentUserRole() {
			const user  = this.userService.user.getValue() as Person;
			const instRole = user?.instituteRoles.filter(role => role.instituteId.toString() === this.instituteId);
			this.activeUserRoles = instRole?.map(instRole => instRole.role);
			this.setEditingVisible();
		}

		setEditingVisible()  {
			this.showEditing = (
				this.activeUserRoles.indexOf(PersonRole.REPRESENTATIVE) > -1 ||
				this.activeUserRoles.indexOf(PersonRole.DEPUTY) > -1  ||
				this.userService.keycloakRoles.indexOf('admin') > -1
			);
		}

		ngOnInit(): void {
			this.subscriptions.push(this.activatedRoute.params
				.subscribe(params => {
					this.instituteId = params.instituteId;
					const facilityParams = this.getParams();
					this.getFacilities(facilityParams);
					this.getCurrentUserRole();
				}));
			}

			addFacality() {
				this.showFacilityModal();
			}

			onDeleteFacility(facilityId: number) {
				this.confirmationService.showDeleteConfirm(
					'Delete Facility',
					'Do you really want to delete this Facility ?',
					'Delete',
					() => this.deleteFacility(facilityId)
				);
			}

			getParams(): HttpParams {
				return new HttpParams()
				.set('filter', `instituteId||$eq||${this.instituteId ? this.instituteId : '' }`)
				.set('limit', `${this.perPage}`)
				.set('offset', `${this.offset}`);
			}

			async deleteFacility(facilityId: number) {
				return this.facilityService.deleteFacility(facilityId).toPromise().then(() => this.getFacilities(this.getParams()))
			}

			handleQueryParams(event?: NzTableQueryParams) {
				let facilityParams = new HttpParams()
				.set('filter', `instituteId||$eq||${this.instituteId}`)
				.set('limit', `${this.perPage}`)
				.set('offset', `${ (event.pageIndex - 1) * this.perPage }`);

				const currentSort = event.sort.find(s => s.value);
				if(currentSort) {
					facilityParams = facilityParams.set('sort', `${currentSort.key},${this.mapSortOrder(currentSort.value)}`);
				}

				this.getFacilities(facilityParams);
			}

			private mapSortOrder(sortOrder: NzTableSortOrder) {
				return sortOrder === 'descend' ? 'DESC' : 'ASC';
			}

			getFacilities(params?: HttpParams) {
				this.facilityService.fetchFacilities(params).toPromise().then(facilitiesJson => {
					this.total = facilitiesJson.total;
					this.facilities = facilitiesJson.data;
				})
			}
		showFacilityModal() {
			const facilityModal: NzModalRef = this.modal.create({
				nzTitle: 'Select the type of physical facility you want to add',
				nzContent: FacilityModalComponent,
				nzComponentParams: {
					instituteId: this.instituteId
				},
			})
			facilityModal.afterClose.subscribe((results) => {
				if(results) {
					const facilityParams = this.getParams();
					this.facilityService.addFacility(results).subscribe(() => {
						this.pageIndex = 1;
						this.getFacilities(facilityParams);
					})
				}
			})
		}
}
