import { Component } from '@angular/core';
import { Person } from '@metro-membership/data-access';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NzModalRef } from 'ng-zorro-antd/modal';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
	selector: 'metro-membership-person-modal',
	templateUrl: './person-modal.component.html',
	styleUrls: ['./person-modal.component.less']
})
export class PersonModalComponent {
	person: Person;

	constructor(
		private modal: NzModalRef
	) {}

	handlePersonChange(person: Person) {
		this.person = person;
	}

	handleOk() {
		this.modal.destroy({ person : this.person });
	}

	handleCancel() {
		this.modal.destroy();
	}
}
