import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en-GB';
import { NgModule } from '@angular/core';
import { UiModule } from '@metro/ui';
import { en_GB, NZ_I18N } from 'ng-zorro-antd/i18n';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DataModule } from './data/data.module';
import { DefaultModule } from './default/default.module';
import { FacilityModule } from './facility/facility.module';
import { InstituteModule } from './institute/institute.module';
import { ResourcesModule } from './resources/resources.module';

registerLocaleData(en);

@NgModule({
	declarations: [AppComponent],
	imports: [
		CoreModule,
		AppRoutingModule,
		FacilityModule,
		ResourcesModule,
		InstituteModule,
		DefaultModule,
		DataModule,
		UiModule
	],
	providers: [{ provide: NZ_I18N, useValue: en_GB }],
	bootstrap: [AppComponent]
})
export class AppModule {}
