import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MenuPosition } from './menu-position';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'metro-membership-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.less'],
	// changeDetection: ChangeDetectionStrategy.OnPush // Review: Enable this
})
export class SideMenuComponent {
	@Input() sideMenuPositions: MenuPosition[];
	@Input() isInstitute = false;
	@Input() isExpanded = false;

	constructor(private router: Router) {}

	navigate(url: string) {
		const routeSegment = this.router.url.split('/')
		routeSegment.pop();


		if(!this.isInstitute) {
			this.router.navigate([ ...routeSegment, url ]);
		} else {
			this.router.navigate([ url ]);
		}
	}
}
