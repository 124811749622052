import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { InstituteRoutingModule } from './institute-routing.module';
import { InstitutesPage } from './institutes/institutes.page';
import { MyInstitutesPage } from './my-institutes/my-institutes.page';
import { NodeViewPage } from './node-view/node-view.page';
import { FacilityTableComponent } from './node-view/components/facility-table/facility-table.component';
import { ResourcesTableComponent } from './node-view/components/resources-table/resources-table.component';
import { ResourcesModalComponent } from './node-view/modals/resources-modal/resources-modal.component';
import { InstituteFinancesPage } from './institute-finances/institute-finances.page';
import { InstituteQualityPage } from './institute-quality/institute-quality.page';
import { InstituteEServicesComponent } from './institute-e-services/institute-e-services.component';
import { InstitutePlanComponent } from './institute-plan/institute-plan.component';

@NgModule({
	declarations: [
		InstitutesPage,
		MyInstitutesPage,
		NodeViewPage,
		FacilityTableComponent,
		ResourcesTableComponent,
		ResourcesModalComponent,
		InstituteFinancesPage,
		InstituteQualityPage,
		InstituteEServicesComponent,
		InstitutePlanComponent
	],
	imports: [
		SharedModule,
		InstituteRoutingModule
	]
})
export class InstituteModule { }
