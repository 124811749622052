import { Component,Input, OnInit,  } from '@angular/core';
import { FormBuilder,  FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';
import { EquipmentTranslations } from '@metro-membership/data-access/enums';
import { FacilityEquipmentService } from '../../../data/services/facility-equipment.service';
import { FacilityService } from '../../../data/services/facility.service';
import {
	AnalyticalInstrumentation,
	BiotechnologyBiosensors,
	BiotechnologyImmunoenzymatic,
	BiotechnologyMolecular,
	BiotechnologyOthers, CeDetectors, Chromatography,
	Devices, ElementalAnalysis, GcDetectors, IsotopicAnalysis, LcDetectors,
	Microbiology,
	Microscopes, MolecularAnalysis,
	NanoparticleAnalysis,
	Rheology,
	SensorAnalysis,
	SolidStructureAnalysis,
	SolidSurfaceAnalysis, SpeciationAnalysis,
	SurfaceAnalysis,
	ThermalAnalysis
} from './analytical';

@Component({
  selector: 'metro-membership-analytical',
  templateUrl: './analytical.component.html',
  styleUrls: ['./analytical.component.less']
})
export class AnalyticalComponent implements OnInit {
	@Input() facility = { } as Facility;
	analyticalFormsGroup: FormGroup;
	equipmentTranslations = EquipmentTranslations;

	devices: Devices = new Devices();
	microbiology: Microbiology = new Microbiology();
	biotechnologyOthers: BiotechnologyOthers = new BiotechnologyOthers();
	biotechnologyImmunoEnzymatic: BiotechnologyImmunoenzymatic = new BiotechnologyImmunoenzymatic();
	biotechnologyBiosensors: BiotechnologyBiosensors = new BiotechnologyBiosensors();
	biotechnologyMolecular: BiotechnologyMolecular = new BiotechnologyMolecular();
	sensorAnalysis: SensorAnalysis = new SensorAnalysis();
	thermalAnalysis: ThermalAnalysis = new ThermalAnalysis();
	rheology: Rheology = new Rheology();
	solidStructureAnalysis: SolidStructureAnalysis = new SolidStructureAnalysis();
	microscopes: Microscopes = new Microscopes();
	solidSurfaceAnalysis: SolidSurfaceAnalysis = new SolidSurfaceAnalysis();
	surfaceAnalysis: SurfaceAnalysis = new SurfaceAnalysis();
	nanoparticleAnalysis: NanoparticleAnalysis = new NanoparticleAnalysis();
	molecularAnalysis: MolecularAnalysis = new MolecularAnalysis();
	isotopicAnalysis: IsotopicAnalysis = new IsotopicAnalysis();
	speciationAnalysis: SpeciationAnalysis = new SpeciationAnalysis();
	elementalAnalysis: ElementalAnalysis = new ElementalAnalysis();
	gcDetectors: GcDetectors = new GcDetectors();
	lcDetectors: LcDetectors = new LcDetectors();
	ceDetectors: CeDetectors = new CeDetectors();
	chromatography: Chromatography = new Chromatography();
	analyticalInstrumentation: AnalyticalInstrumentation = new AnalyticalInstrumentation();

	showForms = false;
	editMode = false;

  constructor(private formBuilder: FormBuilder, private analyticalEqService: FacilityEquipmentService, private facilityService: FacilityService) {}

	ngOnInit(): void {
		this.buildForms();
	}

	changeDisability(control: string){
			console.log(control)
	}
	buildForms() {
		this.analyticalFormsGroup = this.analyticalEqService.analyticalForm(this.facility);
		this.analyticalFormsGroup.patchValue(this.facility.analyticalEquipment);
		this.showForms = true;
	}

	get isEditable(): boolean {
		return this.analyticalFormsGroup.enabled as boolean;
	}

	onEdit(): void {
		this.editMode = !this.editMode;
		this.analyticalFormsGroup.enable();
	}

	save(): void {
  	const formData = this.analyticalFormsGroup.getRawValue();
  	this.facility.analyticalEquipment = {...formData};
		this.facilityService.editFacility(this.facility).subscribe(res => {
			console.log(res)
		})
	}

}
