
<div class="modal-content">
	<div class="mt-1 mb-1 standard-text titillium-font">Facility Name</div>
	<form action="" [formGroup]="facilityForm">
		<metro-form-input class="standard w-100" type="text"
			ph="Facility Name" formControlName="facilityName" [control]="facilityForm.controls.facilityName"
		></metro-form-input>
		<nz-radio-group class="mt-10" formControlName="type">
			<label class="mb-4 d-flex" nz-radio nzValue="rm">
				<i nz-icon nzType="icons:facility-types/rm" class="mx-4"></i>
				<span>
				RM facility
			</span>
			</label>
			<label class="mb-4 d-flex" nz-radio nzValue="analytical">
				<i nz-icon nzType="icons:facility-types/analytical" class="mx-4"></i>
				<span>
				Analytical lab
			</span>
			</label>
			<label class="mb-4 d-flex" nz-radio nzValue="primary">
				<i nz-icon nzType="icons:facility-types/primary" class="mx-4"></i>
				<span>
				Primary production
			</span>
			</label>
			<label class="mb-4 d-flex" nz-radio nzValue="process_kitchen">
				<i nz-icon nzType="icons:facility-types/process_kitchen" class="mx-4"></i>
				<span>
				Processing and Kitchen lab
			</span>
			</label>
		</nz-radio-group>
	</form>
</div>

<ng-template [nzModalFooter]>
	<div class="d-flex justify-content-end ">
		<button class="secondary" (click)="handleCancel()">Cancel</button>
		<button class="primary" (click)="handleOk()" [ngClass]="{'disabled': facilityForm.invalid}">Save</button>
	</div>
</ng-template>
