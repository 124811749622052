import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InstituteResources, InstituteResourcesDetails } from '@metro-membership/data-access';
import { RadioDecisions, ResourceTypes } from '@metro-membership/data-access/enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject, Subscription } from 'rxjs';
import { InstituteService } from '../../data/services/institute.service';
import { ISOLanguages } from '../../shared/utils/languages';
import { generalTopics, servicesTopics } from './topics';

@Component({
  selector: 'metro-membership-resources-details',
  templateUrl: './resources-details.component.html',
  styleUrls: ['./resources-details.component.less']
})
@UntilDestroy({ arrayName: 'subscriptions' })
export class ResourcesDetailsComponent {
	unSubscribe = new Subject();
	instituteId = '';
	subscriptions: Subscription[] = [];
	resourcesForm: FormGroup;
	resourceTypes = ResourceTypes;
	radioDecisions = RadioDecisions;
	instituteResources: InstituteResources;
	instituteResourcesDetails: InstituteResourcesDetails;
	interfaceTypes: string[] = ['Command-line', 'Web UI', 'Desktop UI', 'SOAP WS', 'HTTP WS', 'API', 'QL', 'Other', 'Not applicable'];
	technologies: string[] = ['MySQL','SQL', 'PHP', 'IIS server', 'AngularJS', 'Java', 'Access', 'Oracle', 'Rshiny', 'SAS',
		'CMS (Drupal, WordPress, Joomla, TYPO-3,etc.)', 'Wikimedia', 'Other', 'Not applicable'];
	operatingSystems: string[] = ['Windows XP', 'Windows 7', 'Windows 10', 'LINUX operating system', 'Unix', 'iOS', 'Android',
		'Web browsers', 'Other', 'Not applicable'];
	audiences: string[] = ['Researchers','Policy makers','Industry','Consumers','Public health organisations', 'Students',
	'Other', 'Not applicable'];
	languages: { code: string, name: string }[] = ISOLanguages;
	generalTopics: { value: string, label: string }[] = generalTopics;
	servicesTopics: { value: string, label: string }[] = servicesTopics;

	constructor(
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private instituteService: InstituteService) {
		this.subscriptions.push(
		activatedRoute.params.subscribe(params => {
			this.instituteId = params.instituteId;
			this.getResource(params.resourceId)
			this.buildForm(params.resourceId);
		}));
	}
	get isEditable(): boolean {
		return this.resourcesForm.enabled;
	}

	onEdit() {
		this.resourcesForm.enable();
	}

	onSave() {
		const params = new HttpParams({
			fromObject: {
				instituteId: this.instituteId || null
			}
		});
		this.instituteResources.details = this.resourcesForm.getRawValue() as InstituteResourcesDetails;
		this.instituteService.editResource(this.instituteResources, this.instituteResources.id, params).subscribe(resourceJson => {
			this.resourcesForm.disable();
			this.resourcesForm.updateValueAndValidity();
		})
	}

	getResource(resourceId: number) {
		const params = new HttpParams({
			fromObject: {
				join: ['details'],
			 	instituteId: this.instituteId || null
			}
		});
		this.instituteService.fetchResource(resourceId, params).subscribe(resourceJson => {
			this.instituteResources = resourceJson;
			this.setForm();
		});
	}

	buildForm(resourceId: string) {
		this.resourcesForm = this.formBuilder.group({
			id: null,
			resourceId: resourceId,
			detailsDescription: null,
			typeComment: null,
			side: null,
			status: null,
			firstCreation: null,
			currentVersion: null,
			currentVersionCreation: null,
			updates: null,
			updatesComment: null,
			sustainabilityPlan: null,
			sustainabilityPlanComment: null,
			interfaceTypes: null,
			interfaceTypesComment: null,
			technologies: null,
			technologiesComment: null,
			operatingSystems: null,
			operatingSystemsComment: null,
			languageCode: null,
			generalTopics: null,
			servicesTopics: null,
			selfHost: null,
			domain: [null, Validators.required],
			domainComment: null,
			targetAudience: [null, Validators.required],
			targetAudienceComment: null,
			link: null
		});
		this.resourcesForm.disable();
		this.validationChange();
	}

	validationChange() {
		const targetAudience = this.resourcesForm.get('targetAudience');
		const targetAudienceComment = this.resourcesForm.get('targetAudienceComment');

		targetAudience.valueChanges.subscribe(changes => {
			if(this.detectOtherOption(targetAudience)) {
				targetAudienceComment.setValidators(Validators.required);
			} else {
				targetAudienceComment.clearValidators();
				targetAudienceComment.updateValueAndValidity();
			}
		})
	}

	setForm() {
		this.resourcesForm.patchValue(this.instituteResources.details);
	}

	detectOtherOption(control: AbstractControl): boolean {
		const value = control.value as string[];
		return value?.indexOf('Other') > -1 || false;
	}
}
