import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';
import { SectorCategories, SectorCategoriesTranslations } from '@metro-membership/data-access/enums';
import { debounceTime } from 'rxjs/operators';
import { sector,endProducts, useRM, amount } from './sector-primary';

@Component({
  selector: 'metro-membership-sector-production',
  templateUrl: './sector-production.component.html',
  styleUrls: ['./sector-production.component.less']
})
export class SectorProductionComponent implements OnInit {
	sectorCategories = SectorCategoriesTranslations;
	@Output() formChanges: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	@Input() facility = { } as Facility;
	formGroup: FormGroup;
	showForm = false;

  constructor(private formBuilder: FormBuilder) { }

	get sector() {
		return this.formGroup.get('sector') as FormArray;
	}

	get endProducts() {
		return this.formGroup.get('endProducts') as FormArray;
	}

	get useRm() {
		return this.formGroup.get('useRm') as FormArray;
	}

	get amount() {
		return this.formGroup.get('amount') as FormArray;
	}

  ngOnInit(): void {
		this.buildForms();
	}

	buildTableForm(categoryTable: any[]): FormGroup[] {
		return categoryTable.map(categories => {
			const currentSector = this.facility.sectors.find(sector => (sector.name === categories.name) )
			return this.formBuilder.group({
				id: currentSector?.id || null,
				name: categories.name || null,
				status: currentSector?.status || null,
				number: [{value: currentSector?.number, disabled: true}],
				isActive: currentSector?.isActive,
				comment:	currentSector?.comment || null,
				facilityId: this.facility.id,
			});
		});

	}
	buildForms() {

		this.formGroup = this.formBuilder.group({
			sector: this.formBuilder.array(this.buildTableForm(sector)),
			endProducts: this.formBuilder.array(this.buildTableForm(endProducts)),
			useRm: this.formBuilder.array(this.buildTableForm(useRM)),
			amount: this.formBuilder.array(this.buildTableForm(amount)),

		});

		this.showForm = true;
		this.formGroup.valueChanges
		.pipe(debounceTime(500))
		.subscribe(changes => {
			this.formChanges.next(changes);
		});
	}

}
