<div class="d-inline-block w-100">
	<div class="mt-1 mb-1 standard-text titillium-font">Assign user</div>
	<metro-form-input class="standard" type="text" ph="Name Surname"
		[control]="searchControl">	</metro-form-input>

	<div class="relative">
		<div class="users-wrapper">
			<div *ngFor="let person of persons">
				<label nz-checkbox (ngModelChange)="checkPerson($event, person)" [ngModel]="person.id === chosenPerson?.id"> {{person.name}} {{person.surname}}</label>
			</div>
		</div>
		<div class="invalid-message" *ngIf="!chosenPerson?.id">Person is required</div>
	</div>

	<div class="d-flex justify-content-end">
		<button (click)="showPersonForm = !showPersonForm"  class="secondary mt-7 mb-7">Add new user</button>
	</div>

	<form action="" *ngIf="showPersonForm" [formGroup]="personForm">
		<metro-form-input class="standard mt-4" type="text" ph="Name" formControlName="name"
		[control]="personForm.controls.name">	</metro-form-input>
		<metro-form-input class="standard mt-4" type="text" ph="Surname" formControlName="surname"
		[control]="personForm.controls.surname">	</metro-form-input>
		<metro-form-input class="standard mt-4" type="text" ph="Email" formControlName="email"
		[control]="personForm.controls.email">
	</metro-form-input>

		<div class="d-flex justify-content-end">
			<button (click)="savePerson()" class="primary mr-2" [ngClass]="{'disabled': personForm.invalid }">Save person and send e-mail</button>
		</div>
	</form>

</div>



