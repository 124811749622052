export enum FacilityType {
	Analytical_Lab = 'analytical',
	Primary_Production = 'primary',
	Processing_And_Kitchen_Lab = 'process_kitchen',
	RM_Facility = 'rm'
}
export enum FacilityTypeTranslations {
	RM = 'RM facility',
	PRIMARY = 'Primary production',
	ANALYTICAL = 'Analytical lab',
	PROCESS_KITCHEN = 'Processing and Kitchen lab'
}
