import { Component } from '@angular/core';
import { Institute } from '@metro-membership/data-access';
import { CountriesEnum } from '@metro-membership/data-access/enums';
import { InstituteService } from '../../data/services/institute.service';
import { MenuPosition } from '../../shared/components/side-menu/menu-position';

@Component({
	selector: 'metro-membership-institutes',
	templateUrl: './institutes.page.html',
	styleUrls: ['./institutes.page.less']
})
export class InstitutesPage {
	instituteCountries = new Set();
	sideMenuPositions: MenuPosition[] = [];
	countries = CountriesEnum;

	constructor(private instituteService: InstituteService) {
		this.getInstitutes();
	}

	setInstituteCountries(institutes: Institute[]) {
		for (const institute of institutes) {
			this.instituteCountries.add(institute.country);
		}
	}

	getInstitutes() {
		this.instituteService.fetchInstitutes().toPromise().then(instituteJson => {
			this.setInstituteCountries(instituteJson);
			// Review: Sorting can be done via database sort order, but more importantly, institutes should have "node" parent entity,
			// that describes which country institute belongs to
			this.instituteCountries.forEach((country: string) => {
				const instPerCountry = instituteJson.filter(institute => institute.country === country);
				const countryRep = instPerCountry.find(country => country.isNodeRep);
				const countryTitle = countryRep?.country ? countryRep?.country + ' - ' : null;
				const finalData = {
					text: countryTitle + countryRep?.shortName,
					url: '/institutes/' + countryRep?.id, enabled: true,
					country: country,
					visibility: true,
					subPositions: instPerCountry.map(institute => {
						const subPositionCountry = institute?.country ? institute?.country + ' - ' : null;
						return {
							text: institute.shortName,
							url: '/institutes/' + institute.id,
							country: subPositionCountry,
							enabled: true,
							visibility: true
						};
					}).sort((a, b) => (a.text > b.text) ? 1 : -1)
				};
				const instRepIdx = finalData.subPositions.findIndex(inst => inst.text === countryRep?.shortName);
				if (instRepIdx > -1 && finalData.subPositions.length > 1) {
					finalData.subPositions.splice(instRepIdx, 1);
				}
				this.sideMenuPositions.push(finalData);
			});
			this.sideMenuPositions = this.sideMenuPositions.sort((a, b) => (a.country > b.country) ? 1 : -1);
			const italianIndex = this.sideMenuPositions.findIndex(position => position.country === 'IT');
			const italianGroup = this.sideMenuPositions.splice(italianIndex, 1);
			this.sideMenuPositions.unshift(...italianGroup);
		});

	}
}
