import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'metro-membership-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
	constructor(public loaderService: LoaderService) {}
}
