import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LandingPage } from './landing/landing.page';
import { UnderDevelopmentPage } from './under-development/under-development.page';

@NgModule({
  declarations: [
  	LandingPage,
  	UnderDevelopmentPage
	],
	imports: [
		SharedModule,
		RouterModule
	],
	exports: [
		LandingPage,
		UnderDevelopmentPage
	]
})
export class DefaultModule { }
