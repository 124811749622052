import { PersonRole } from '@metro-membership/data-access/enums';
import { AuthGrantManagerRoles } from './auth-grant-manager-roles';

export abstract class AuthGrantManager {
	roles: AuthGrantManagerRoles = {
		application: [],
		node: [],
		institute: [],
		facility: [],
		resource: [],
	}
	facilityId: number;
	resourceId: number;
	instituteId: number;
	nodeId: number;
	personId: string;

	async hasFacilityRole(...roles: PersonRole[]): Promise<boolean> {

		if (this.facilityId) {
			return this.roles.facility.findIndex(r => r.facilityId === this.facilityId && roles.includes(r.role)) >= 0;
		}
		return false;
	}

	async hasResourceRole(...roles: PersonRole[]): Promise<boolean> {
		if (this.resourceId) {
			return this.roles.resource.findIndex(r => r.resourceId === this.resourceId && roles.includes(r.role)) >= 0;
		}
		return false;
	}

	// async hasInAnyResourceRole(): Promise<boolean> {
	// 	if (this.instituteId) {
	// 		const institute = this.roles.institute.find(role => role.instituteId === this.instituteId)?.institute;
	// 		console.log(institute)
	// 		const instResources  = institute?.resources?.filter(resource => resource.personRole.personId === this.personId);
	// 		return instResources.length > 0
	// 	}
	// 	return false;
	// }

	async hasInstituteRole(...roles: PersonRole[]): Promise<boolean> {
		if (this.instituteId || await this.resolveEntityTree('institute')) {
			return this.roles.institute.findIndex(r => r.instituteId === this.instituteId && roles.includes(r.role)) >= 0;
		}
		return false;
	}

	async hasNodeRole(...roles: PersonRole[]): Promise<boolean> {
		return false; // Todo: Fill when user object has nodeRoles
	}

	async hasAppRole(...roles: string[]): Promise<boolean> {
		return roles.some(role => this.roles.application.includes(role));
	}

	isSystemAdmin(): Promise<boolean> {
		return this.hasAppRole('admin');
	}

	isServicesManager(): Promise<boolean> {
		return this.hasAppRole('services_admin');
	}

	protected abstract async resolveEntityTree(level: 'node' | 'institute'): Promise<boolean>;
}
