export const sector = [
	{
		isActive: false,
		number: 0,
		name: 'FOOD_PROCESSING',
	},
	{
		isActive: false,
		number: 0,
		name: 'FOOD_STORAGE',
	},
	{
		isActive: false,
		number: 0,
		name: 'FOOD_PACKING',
	},
	{
		isActive: false,
		number: 0,
		name: 'FOOD_PREPARATION',
	},
	{
		isActive: false,
		number: 0,
		name: 'TREATMENT_REDUCTION',
	},
	{
		isActive: false,
		number: 0,
		name: 'PRODUCTION_AIDS_FOOD',
	},
	{
		isActive: false,
		number: 0,
		name: 'SECTOR_OTHER',
	}
];

export const amount_min = [
	{
		isActive: false,
		number:  0,
		name: 'PROCESSED_AMOUNT_MIN',
	},
	{
		isActive: false,
		number:  0,
		name: 'PRODUCTION_RATE_MIN',
	},
]

export const amount_max = [
	{
		isActive: false,
		number:  0,
		name: 'PRODUCTION_RATE_MAX',
	},
	{
		isActive: false,
		number:  0,
		name: 'PROCESSED_AMOUNT_MAX',
	},
]

export const amount_average = [
	{
		isActive: false,
		number:  0,
		name: 'PROCESSED_AMOUNT_AVERAGE',
	},
	{
		isActive: false,
		number:  0,
		name: 'PRODUCTION_RATE_AVERAGE',
	},
]
export const amount_unit = [
	{
		isActive: false,
		number:  0,
		name: 'PROCESSED_AMOUNT_MEASUREMENT_UNIT',
	},
	{
		isActive: false,
		number:  0,
		name: 'PRODUCTION_RATE_MEASUREMENT_UNIT',
	},
]

export const foods = [
	{
		isActive: false,
		number:  0,
		name: 'FRUTS',
	},
	{
		isActive: false,
		number:  0,
		name: 'VEGETABLES',
	},
	{
		isActive: false,
		number:  0,
		name: 'CEREALS',
	},
	{
		isActive: false,
		number: 0,
		name: 'LEGUMES',
	},
	{
		isActive: false,
		number: 0,
		name: 'MILK_DIARY_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'MEAT_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'FISH_SEAFOOD',
	},
	{
		isActive: false,
		number: 0,
		name: 'CONFECTIONARY',
	},
	{
		isActive: false,
		number: 0,
		name: 'READY_TO_EAT_MEAT',
	},
	{
		isActive: false,
		number: 0,
		name: 'BEVERAGES_VINEGARS',
	},
	{
		isActive: false,
		number: 0,
		name: 'FEEDSTUFF',
	},
	{
		isActive: false,
		number: 0,
		name: 'NON_FOOD_AGRICULTURAL',
	},
	{
		isActive: false,
		number: 0,
		name: 'FOOD_BY_PRODUCTS',
	},
	{
		isActive: false,
		number: 0,
		name: 'FOOD_WASTES',
	},
	{
		isActive: false,
		number: 0,
		name: 'FOODS_OTHER',
	}
];
