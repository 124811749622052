import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Facility, FacilityServiceEntity } from '@metro-membership/data-access';
import { ServiceStatuses } from '@metro-membership/data-access/enums';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacilityService } from '../../data/services/facility.service';

@Component({
	selector: 'metro-membership-facility-services',
	templateUrl: './facility-services.page.html',
	styleUrls: ['./facility-services.page.less']
})
export class FacilityServicesPage {
	unSubscribe = new Subject();
	facility: Facility;
	serviceFormArray: FormArray = this.formBuilder.array([]);

	facilityId = '';
	editMode = false;
	showForm = false;

	services: FacilityServiceEntity;
	constructor(
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private facilityService: FacilityService,
		private http: HttpClient) {
			this.activatedRoute.parent.params
			.pipe(takeUntil(this.unSubscribe))
			.subscribe(params => {
				this.facilityId = params.facilityId;
				this.getFacilities();
			});
		}
		get isEditable(): boolean {
			return this.serviceFormArray.enabled as boolean;
		}

		onSave(): void {
			if (this.facility?.services?.id) {
				this.facilityService.editFacilityServices(this.services, this.facility.id).subscribe(res => {});
			} else {
				this.facilityService.addFacilityServices(this.services).subscribe(res => {});
			}
		}

		onEdit(): void {
			this.editMode = !this.editMode;
		}

		getFacilities(): void {
			const filterPhrase = new HttpParams().set('join', 'services');
			this.facilityService.fetchFacility(this.facilityId, filterPhrase).subscribe(facilityJson => {
				this.facility = facilityJson;
			});
		}

		handleFormChanges(services: FacilityServiceEntity) {
			this.services = services;
		}
	}
