import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';
import { FacilityEquipmentService } from '../../../data/services/facility-equipment.service';
import { FacilityService } from '../../../data/services/facility.service';
import { Balance, Homogenization, Instruments, Sample, Sterilization, StorageHow, WaterActivity } from './rm_plants';

@Component({
  selector: 'metro-membership-rm-plants',
  templateUrl: './rm-plants.component.html',
  styleUrls: ['./rm-plants.component.less']
})
export class RmPlantsComponent implements OnInit {
	@Input() facility = { } as Facility;
	rmForm: FormGroup;

	storageHow: StorageHow = new StorageHow();
	waterActivity: WaterActivity = new WaterActivity();
	instruments: Instruments = new Instruments();
	sample: Sample = new Sample();
	homogenization: Homogenization = new Homogenization();
	balance: Balance = new Balance();
	sterilization: Sterilization = new Sterilization();

	showForms = false;
	editMode = false;

	constructor(private formBuilder: FormBuilder, private analyticalEqService: FacilityEquipmentService,
							private facilityService: FacilityService) {}

	ngOnInit(): void {
		this.buildForms();
	}

	changeDisability(control: string){
		console.log(control)
	}
	buildForms() {
		this.rmForm = this.analyticalEqService.rmForm(this.facility);
		this.rmForm.patchValue(this.facility.rmEquipment);
		this.showForms = true;
	}

	get isEditable(): boolean {
		return this.rmForm.enabled as boolean;
	}

	onEdit(): void {
		this.editMode = !this.editMode;
		this.rmForm.enable();
	}

	save(): void {
		const formData = this.rmForm.getRawValue();
		this.facility.rmEquipment = {...formData};
		this.facilityService.editFacility(this.facility).subscribe(res => {
			console.log(res)
		})
	}
}
