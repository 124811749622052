import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRolesPresets } from '@metro-membership/util';
import { RoleGuard } from '../core/guards/role.guard';
import { InstituteEServicesComponent } from './institute-e-services/institute-e-services.component';
import { InstituteFinancesPage } from './institute-finances/institute-finances.page';
import { InstitutePlanComponent } from './institute-plan/institute-plan.component';
import { InstituteQualityPage } from './institute-quality/institute-quality.page';
import { InstitutesPage } from './institutes/institutes.page';
import { MyInstitutesPage } from './my-institutes/my-institutes.page';
import { NodeViewPage } from './node-view/node-view.page';

export const instituteRoutes: Routes = [
	{
		path: 'my/:instituteId',
		component: MyInstitutesPage,
		data: { breadcrumb: ':institute' },
		children: [
			{
				path: '', component: NodeViewPage, data: { breadcrumb: ':institute', }
			},
			{
				path: 'finances', component: InstituteFinancesPage, canActivate: [RoleGuard], data: { breadcrumb: 'Finances',
				appRoles: AuthRolesPresets.isFinancialManagerOfInstitute }
			},
			{
				path: 'quality', component: InstituteQualityPage, canActivate: [RoleGuard], data: { breadcrumb: 'Quality',
				appRoles: AuthRolesPresets.isQualityManagerOfInstitute }
			},
			{
				path: 'e-services', component: InstituteEServicesComponent, canActivate: [RoleGuard], data: { breadcrumb: 'IT facilities',
				appRoles: AuthRolesPresets.isManagerOfInstitute }
			},
		]
	},
	{
		path: ':instituteId',
		component: InstitutesPage,
		data: { breadcrumb: ':institute' },
		children: [
			{
				path: '',
				component: NodeViewPage,
			},
			{
				path: 'finances',
				component: InstituteFinancesPage,
				canActivate: [RoleGuard],
				data: { breadcrumb: 'Finances',
				appRoles: AuthRolesPresets.isFinancialManagerOfInstitute
			}
			},
			{
				path: 'quality',
				component: InstituteQualityPage,
				data: { breadcrumb: 'Quality',
				appRoles: AuthRolesPresets.isQualityManagerOfInstitute }
			},
			{
				path: 'e-services', component: InstituteEServicesComponent, data: { breadcrumb: 'IT facilities',
				appRoles: AuthRolesPresets.isManagerOfInstitute }
			},
			{
				path: 'plans', component: InstitutePlanComponent, data: { breadcrumb: 'Plans' }
			},
		]
	},
];

@NgModule({
	imports: [RouterModule.forChild(instituteRoutes)],
	exports: [RouterModule]
})
export class InstituteRoutingModule {}
