export enum InstituteQualityQuestions {
	PERSON_QUALITY_MANAGER = 'PERSON_QUALITY_MANAGER',
	INSTITUTE_CERTIFICATED = 'INSTITUTE_CERTIFICATED',
	CERTYFICATION_ASSOCIATED_COST = 'CERTYFICATION_ASSOCIATED_COST',
	EXTERNAL_USERS_SERVICES = 'EXTERNAL_USERS_SERVICES',
	EXTERNAL_USERS_SATISFACTION = 'EXTERNAL_USERS_SATISFACTION',
	HANDLING_CUSTOMER_COMPLAINTS = 'HANDLING_CUSTOMER_COMPLAINTS',
	STANDARD_OPERATING_PROCEDURE = 'STANDARD_OPERATING_PROCEDURE',
	RESEARCH_INFRASTRUCTURES = 'RESEARCH_INFRASTRUCTURES',
	QUALITY_RESEARCH_DOCS = 'QUALITY_RESEARCH_DOCS',
	SOP_RESEARCH = 'SOP_RESEARCH',
	SOP_RECORD = 'SOP_RECORD',

	AUDI_TRAIL = 'AUDI_TRAIL',
	QMS_IMPLEMENTATIONS = 'QMS_IMPLEMENTATIONS'
}

export enum InstituteQualityAnswers {
	OTHER = 'OTHER',
	MANAGEMENT_TEAM_MEMBER = 'MANAGEMENT_TEAM_MEMBER',
	ISO_9000_9001 = 'ISO_9000_9001',
	ISO_14001 = 'ISO_14001',

	CERTIFICATION_GENERAL_COST = 'CERTIFICATION_GENERAL_COST',
	CERTIFICATION_FACILITY_BUDGET = 'CERTIFICATION_FACILITY_BUDGET',

	CLIENT_SATISFACTION = 'CLIENT_SATISFACTION',
	STAKEHOLDER_MEETING = 'STAKEHOLDER_MEETING',

	INTERNAL_PROJECT_MANAGEMENT_SYSTEM = 'INTERNAL_PROJECT_MANAGEMENT_SYSTEM',
	OPERATIONAL_PROCEDURE_DESIGN = 'OPERATIONAL_PROCEDURE_DESIGN',
	ANNUAL_REPORT_OF_RESEARCH = 'ANNUAL_REPORT_OF_RESEARCH',
	ANNUAL_REPORT_OF_FELLOWSHIPS = 'ANNUAL_REPORT_OF_FELLOWSHIPS',

	SOP_POLICY = 'SOP_POLICY',
	SOP_CONFIDENTIALITY = 'SOP_CONFIDENTIALITY',
	SOP_CONFERENCES = 'SOP_CONFERENCES',
	SOP_SCIENTIFIC_PAPERS = 'SOP_SCIENTIFIC_PAPERS',
	SOP_TV = 'SOP_TV',
	SOP_SCIENTIFIC_VISITS = 'SOP_SCIENTIFIC_VISITS',
	SOP_PRESENTATION_PARTNERS = 'SOP_PRESENTATION_PARTNERS',
	SOP_OPEN_ACCESS = 'SOP_OPEN_ACCESS',
	SOP_PLANS_COOPERATION_AGRO_FOOD = 'SOP_PLANS_COOPERATION_AGRO_FOOD',
	SOP_MEETING = 'SOP_MEETING',
	SOP_WEB_CONFERENCE = 'SOP_WEB_CONFERENCE',
	SOP_WORKSHOPS_SEMINARY = 'SOP_WORKSHOPS_SEMINARY',
	SOP_HOME_PAGE_SITE = 'SOP_HOME_PAGE_SITE',
	SOP_TECHNICAL_SCIENTIFIC_REPORTS = 'SOP_TECHNICAL_SCIENTIFIC_REPORTS',
	SOP_PATENT = 'SOP_PATENT'

}

export enum InstituteQualityTranslations {
	OTHER = 'Other (please specify)',
	MANAGEMENT_TEAM_MEMBER = 'Member of the management team reporting to the director',
}
