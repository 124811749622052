<div class="d-flex justify-content-between">
	<h1>Sectors</h1>
	<div>

		<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" >
			<div>SAVE</div>
		</button>
	</div>
</div>

<metro-membership-sector-analytical
	*ngIf="facility && facility.type === 'analytical' "
	(formChanges)="handleFormChanges($event)" [facility]="facility"
></metro-membership-sector-analytical>


<metro-membership-sector-kitchen
	*ngIf="facility && facility.type === 'process_kitchen' "
	(formChanges)="handleFormChanges($event)" [facility]="facility"
></metro-membership-sector-kitchen>

<metro-membership-sector-rm	*ngIf="facility && facility.type === 'rm' "
	(formChanges)="handleFormChanges($event)" [facility]="facility"
></metro-membership-sector-rm>


<metro-membership-sector-production
*ngIf="facility && facility.type === 'primary' "
	(formChanges)="handleFormChanges($event)" [facility]="facility"
></metro-membership-sector-production>
