import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthRolesFn } from '@metro-membership/util';
import { KeycloakService } from '@metro/ui-core';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {
	constructor(
		private userService: UserService,
		private keycloak: KeycloakService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const userRoles = this.keycloak.getUserRoles();
		if (userRoles.indexOf('admin') >= 0) {
			return true;
		}

		const rolesFn: AuthRolesFn = route.data.appRoles;
		if (!rolesFn) {
			throw new Error('Role guard defined, but no appRoles function to handle role-checking');
		}

		const authGM = this.userService.getCurrentAuthGrantManager(route);
		return rolesFn(authGM);
	}
}
