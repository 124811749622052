<div class="mb-3 d-flex justify-content-end" >
	<div class="mt-2 d-flex">
		<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
			<div>EDIT</div>
			<i nz-icon nzType="icons:edit" class="ml-4"></i>
		</button>
		<button class="ml-5 d-flex align-items-center primary" (click)="save()" *ngIf="isEditable">
			<div>SAVE</div>
		</button>

	</div>
</div>



<div *ngIf="showForms" [formGroup]="analyticalFormsGroup">

	<div class="relative mb-15">
		<h3>5.1 Analytical instrumentation</h3>
		<div *ngFor="let item of analyticalInstrumentation | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">	{{ equipmentTranslations[item.key] }} </div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.1 Chromatography, separation processes and detectors for organic analysis</h3>
		<div *ngFor="let item of chromatography | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{equipmentTranslations[item.key]}}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>CE detectors</h3>
		<div *ngFor="let item of ceDetectors | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)"  [formControlName]="item.key">
				<div class="title">{{equipmentTranslations[item.key]}}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount"
												[formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>LC detectors</h3>
		<div *ngFor="let item of lcDetectors | keyvalue; index as i " class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{equipmentTranslations[item.key]}}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount"
												[formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>GC detectors</h3>
		<div *ngFor="let item of gcDetectors | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount"	[formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.2 Elemental analysis</h3>
		<div *ngFor="let item of elementalAnalysis | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.3 Speciation analysis</h3>
		<div *ngFor="let item of speciationAnalysis | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key  }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.4 Isotopic analysis</h3>
		<div *ngFor="let item of isotopicAnalysis | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.5 Molecular spectroscopy</h3>
		<div *ngFor="let item of molecularAnalysis | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.7 Surface analysis</h3>
		<div *ngFor="let item of surfaceAnalysis | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.8 Solid state (surface) analysis</h3>
		<div *ngFor="let item of solidSurfaceAnalysis | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)"  [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Microscopes</h3>
		<div class="description">Which of these devices are present in the lab?</div>
		<div *ngFor="let item of microscopes | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.9 Solid state (structure) analysis</h3>
		<div *ngFor="let item of solidStructureAnalysis | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.10 Thermal analysis/thermalproperties</h3>
		<div *ngFor="let item of thermalAnalysis | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.11 Rheology</h3>
		<div *ngFor="let item of rheology | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.12 Sensory analysis</h3>
		<div *ngFor="let item of sensorAnalysis | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.13 BiotechnologyMolecular based techniques</h3>
		<div *ngFor="let item of biotechnologyMolecular | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.13 BiotechnologyImmunoenzymatic techniques</h3>
		<div *ngFor="let item of biotechnologyImmunoEnzymatic | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.13 BiotechnologyOthers</h3>
		<div *ngFor="let item of biotechnologyOthers | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.1.14 Microbiology</h3>
		<div *ngFor="let item of microbiology | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>5.2 Dedicated devices for sample preparation, sample storage and pre-treatment</h3>
		<div *ngFor="let item of devices | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ equipmentTranslations[item.key] }}</div>
			</label>
		</div>
	</div>


</div>
