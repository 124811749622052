<div class="d-flex justify-content-between">
	<h1 class="mt-2 mb-5">{{institute?.shortName}} - IT facilities </h1>
	<div>
		<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable"
						[ngClass]="{'disabled': planForm.invalid}">
			<span>SAVE</span>
			<i nz-icon nzType="icons:edit" class="ml-4"></i>
		</button>

		<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
			<span>EDIT</span>
		</button>
	</div>
</div>
<div>
	<form action="" [formGroup]="planForm" *ngIf="showForm">
		<h2 class="mt-7 mb-7">Plans for investments for the e-resources: <br />Note: referring <span class="text-underline">only to the e-RI</span></h2>

		<div class="p-4 mb-4 bg-gray" *ngFor="let resource of eResourcesControls">
			<div>
				<label class="input-label">Description of the e-resource</label>
				<metro-form-input class="standard" type="text"
													[formControl]="resource.get('description')" [control]="resource.get('description')"
				></metro-form-input>
			</div>

			<div class="mb-4">
				<label class="input-label mb-2">Type of the e-resource</label>
				<nz-radio-group [formControl]="resource.get('type')" class="d-flex justify-content-between">
					<label nz-radio nzValue="hardware">hardware (e.g. laptops)</label>
					<label nz-radio nzValue="software">software (e.g. ms office licence)</label>
					<label nz-radio nzValue="other">other (e.g.)</label>
				</nz-radio-group>
			</div>

			<div>
				<div nz-row nzJustify="space-between">
					<div nz-col nzSpan="9">
						<label class="input-label">Purchase value</label>
						<div class="input-wrapper">
							<metro-form-input class="standard" type="text" unit="thousands"
																ph="Amount" [formControl]="resource.get('purchaseValue')" [control]="resource.get('purchaseValue')"
							></metro-form-input>
							<div class="input-metrics" [ngClass]="{'editable': !resource.get('purchaseValue').disabled}" >
								<nz-select  class="select" [formControl]="resource.get('purchaseValueCurr')">
									<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
								</nz-select>
							</div>
						</div>
					</div>

					<div nz-col nzSpan="9">
						<label class="input-label">% for METROFOOD-RI</label>
						<div class="input-wrapper">
							<metro-form-input class="standard" type="text" unit="thousands" ph="Amount"
																[formControl]="resource.get('percentOfMetro')" [control]="resource.get('percentOfMetro')"
							></metro-form-input>
							<div class="input-metrics" [ngClass]="{'editable': !resource.get('percentOfMetro').disabled}" >
								<div>%</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<button nz-button class="secondary" (click)="addEResourceToForm()" [disabled]="planForm.disabled">+ Add another e-resource</button>

		<h2 class="mt-7 mb-7">New investments foreseen in physical facilities</h2>

		<div>add investments on new equipment or upgrading for the physical facilities most likely (> 90%)  to be bought once the ERIC will be set up (next 3 years):</div>

		<nz-radio-group formControlName="isEstimationOfToT">
			<label nz-radio [nzValue]="true" class="my-3">
				The estimated costs will be declared
			</label>

			<div class="px-9" [hidden]="planForm.controls.isEstimationOfToT.value !== true">
				<p class="text-primary note-text">
					The Net Value is equal to the cost of acquisition/total value of all IT facilities purchased less the accumulated accounting depreciation/amortisation, or in other words, net value is the total value of the invoice without the depreciation accumulated so far
				</p>

				<div nz-row nzJustify="space-between">
					<div nz-col nzSpan="9">
						<label class="input-label">TOT Net value</label>
						<div class="input-wrapper">
							<metro-form-input class="standard" type="text" unit="thousands"
																ph="Amount" formControlName="estimatedCost" [control]="planForm.controls.estimatedCost"
							></metro-form-input>
							<div class="input-metrics" [ngClass]="{'editable': !planForm.controls.estimatedCost.disabled}" >
								<nz-select  class="select" formControlName="estimatedCostCurr">
									<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
								</nz-select>
							</div>
						</div>
					</div>

					<div nz-col nzSpan="9">
						<label class="input-label">Average % dedicated to METROFOOD-RI</label>
						<div class="input-wrapper">
							<metro-form-input class="standard" type="text" unit="thousands" ph="Amount"
																formControlName="averagePercentDedicated" [control]="planForm.controls.averagePercentDedicated"
							></metro-form-input>
							<div class="input-metrics" [ngClass]="{'editable': !planForm.controls.averagePercentDedicated.disabled}" >
								<div>%</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<label nz-radio [nzValue]="false">
				The estimation of the TOT value DEDICATED TO METROFOOD-RI (as of 31 December 2020) will be declared
			</label>

			<div class="mt-5 px-9" [hidden]="planForm.controls.isEstimationOfToT.value !== false">
				<label class="input-label">Estimated cost of the TOT value DEDICATED TO METROFOOD-RI</label>
				<div class="input-wrapper">
					<metro-form-input class="standard" type="text" unit="thousands" ph="Amount"
														formControlName="estimatedCost" [control]="planForm.controls.estimatedCost"
					></metro-form-input>
					<div class="input-metrics" [ngClass]="{'editable': !planForm.controls.estimatedCost.disabled}" >
						<nz-select  class="select" formControlName="estimatedCostCurr">
							<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
						</nz-select>
					</div>
				</div>
			</div>
		</nz-radio-group>

		<h2 class="mt-7 mb-7">Accreditation costs (if the case):</h2>

		<div>
			<label class="input-label">Type of accreditation</label>
			<metro-form-input class="standard" type="text"
												formControlName="typeOfAccreditation"  [control]="planForm.controls.typeOfAccreditation"
			></metro-form-input>
		</div>

		<div>
			<label class="input-label">Accreditation cost</label>
			<div class="input-wrapper">
				<metro-form-input class="standard" type="text" unit="thousands" ph="Amount"
													formControlName="accreditationCost" [control]="planForm.controls.accreditationCost"
				></metro-form-input>
				<div class="input-metrics" [ngClass]="{'editable': !planForm.controls.accreditationCost.disabled}" >
					<nz-select  class="select" formControlName="accreditationCostCurr">
						<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
					</nz-select>
				</div>
			</div>
		</div>
	</form>

</div>

