<form action="" class="mt-2" [formGroup]="questionForm" *ngIf="showForm">
	<div class="d-flex justify-content-between">
		<h1>{{facility?.facilityName }} - Facility Quality data</h1>
		<div>
			<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
				<div>EDIT</div>
			</button>

			<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" [ngClass]="{'disabled': questionForm.invalid }">
				<div>SAVE</div>
			</button>
		</div>
	</div>

	<div class="relative mb-15">
		<h4 class="mb-5">1. Is within your facility a person appointed as responsible for the quality management system (such a person is often called quality assurance manager, quality coordinator etc.)?</h4>
		<div>
				<nz-radio-group class="relative" formControlName="qualityManagerSystemQuestionStatus" >
					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>
					<div *ngIf="questionForm.controls.qualityManagerSystemQuestionStatus.invalid" class="invalid-message">
						this field is required
					</div>
				</nz-radio-group>
			<div *ngIf="questionForm.controls.qualityManagerSystemQuestionStatus.value">
				<h4 class="mt-5 mb-5">what is his/her position in the organization?</h4>

				<nz-radio-group class="relative" class="d-flex flex-column" formControlName="qualityManagerSystemQuestion">

					<label nz-radio nzValue="MANAGEMENT_INSTITUTE">The same as for the institution</label>
					<label nz-radio nzValue="MANAGEMENT_FACILITY">Facility quality manager</label>
					<label nz-radio nzValue="MANAGEMENT_OTHER">OTHER</label>

					<metro-form-input *ngIf="questionForm.controls.qualityManagerSystemQuestion.value === 'MANAGEMENT_OTHER'"
					class="standard w-100 mt-5" type="text" ph="Position name" formControlName="qualityManagerSystemQuestionComment"
					[control]="questionForm.controls.qualityManagerSystemQuestionComment">
				</metro-form-input>

			</nz-radio-group>

		</div>
		<div *ngIf="questionForm.controls.qualityManagerSystemQuestionStatus.value === false">
			<h4 class="mt-5 mb-5">will someone be recruited in the near future (by 2023)?</h4>
			<nz-radio-group class="relative" formControlName="qualityManagerSystemQuestionSubStatus" class="d-flex">

				<label nz-radio [nzValue]="true">YES</label>
				<label nz-radio [nzValue]="false">NO</label>

				<div *ngIf="questionForm.controls.qualityManagerSystemQuestionSubStatus.invalid" class="invalid-message">
					this field is required
				</div>

			</nz-radio-group>
		</div>
	</div>
</div>

<div class="relative mb-15">
	<h4 class="mb-5">2. Is your facility accredited ?</h4>
	<div>
		<nz-radio-group class="relative" formControlName="accreditationStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
			<div *ngIf="questionForm.controls.accreditationStatus.invalid" class="invalid-message">
				this field is required
			</div>
		</nz-radio-group>
		<div *ngIf="questionForm.controls.accreditationStatus.value">
			<h4 class="mt-5 mb-5">what is the scope of the accreditation?</h4>

			<div class="d-flex justify-content-between flex-column">

				<label nz-checkbox *ngFor="let element of accreditation" [formControlName]="element.formKey">
				<h6>{{element.label}}</h6>
				</label>

				<metro-form-input *ngIf="questionForm.controls.accreditationOther.value"
				class="standard w-100 mt-5" type="text" ph="Other" formControlName="accreditationOtherComment"
				[control]="questionForm.controls.accreditationOtherComment">
			</metro-form-input>
		</div>
	</div>
	<div *ngIf="questionForm.controls.accreditationStatus.value">
		<h4 class="mt-5">Are sustainable foods within the scope ?</h4>
		<nz-radio-group class="relative" formControlName="sustainableFood" class="d-flex">

			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>

			<div *ngIf="questionForm.controls.sustainableFood.invalid" class="invalid-message">
				this field is required
			</div>

		</nz-radio-group>
	</div>
	<div *ngIf="questionForm.controls.accreditationStatus.value === false">
		<h4 class="mt-5">Is it planned in the near future (by 2023)?</h4>
		<nz-radio-group class="relative" formControlName="accreditationSubStatus" class="d-flex">

			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>

			<div *ngIf="questionForm.controls.accreditationSubStatus.invalid" class="invalid-message">
				this field is required
			</div>

		</nz-radio-group>
	</div>
</div>
</div>
<ng-container *ngIf="questionForm.controls.accreditationStatus.value">

	<div class="relative mb-15">
		<h4 class="mb-5">3. Which guidance documents or standards are followed?</h4>
		<div class="d-flex justify-content-between flex-column">

			<label nz-checkbox *ngFor="let element of guidance" [formControlName]="element.formKey">
			<h6>{{element.label}}</h6>
			</label>

			<metro-form-input *ngIf="questionForm.controls.guidanceOther.value"
			class="standard w-100 mt-5" type="text" ph="Other" formControlName="guidanceOtherComment"
			[control]="questionForm.controls.guidanceOtherComment">
		</metro-form-input>
	</div>
	</div>

	<div class="relative mb-15">
		<h4 class="mb-5">4. You are accredited as (pick the ones that apply):</h4>
		<div class="d-flex justify-content-between flex-column">
			<label nz-checkbox formControlName="accreditedCalibration">
			<h6>Calibration laboratory</h6>
			</label>
			<label nz-checkbox formControlName="accreditedTestingLab">
			<h6>Testing laboratory</h6>
			</label>

			<label nz-checkbox formControlName="accreditedRM">
			<h6>Reference Material (RM) Producer</h6>
			</label>
			<label nz-checkbox formControlName="accreditedProficiencyTest">
			<h6>Proficiency Test (PT) Provider</h6>
			</label>

			<label nz-checkbox formControlName="accreditedOther">
			<h6>Other (please specify)</h6>
			</label>

			<metro-form-input *ngIf="questionForm.controls.accreditedOther.value"
			class="standard w-100 mt-5" type="text" ph="Other" formControlName="accreditedOtherComment"
			[control]="questionForm.controls.accreditedOtherComment">
		</metro-form-input>
	</div>
	</div>

	<div class="relative mb-15" *ngIf="!(facility?.type === facilityTypes.RM_Facility)">
		<h4 class="mb-5">5. In which potential services provided by your facility are you accredited?</h4>
		<div  *ngIf="facilityServices && ( facility?.type === facilityTypes.Primary_Production ||
		facility?.type === facilityTypes.Processing_And_Kitchen_Lab)">
			<nz-textarea-count [nzMaxCharacterCount]="300">
				<textarea rows="4" formControlName="serviceAccreditationStatus" nz-input></textarea>
			</nz-textarea-count>
		</div>
		<div *ngIf="facilityServices && facility?.type === facilityTypes.Analytical_Lab" class="d-flex flex-column mt-2">
			<ng-container [formGroup]="facilityServicesForm" *ngIf="facilityServicesForm">
				<ng-container *ngFor="let item of facilityServicesArray; let i = index" >
					<label nz-checkbox [formControlName]="item">
					<h6>{{ analyticalServicesTranslations[item] }}</h6>
					</label>
				</ng-container>
			</ng-container>
		</div>
	</div>
</ng-container>

<div class="relative mb-15">
	<h4 class="mb-5">6.	Is your facility certified?</h4>
	<div>
		<nz-radio-group class="relative" formControlName="certificationStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
			<div *ngIf="questionForm.controls.certificationStatus.invalid" class="invalid-message">
				this field is required
			</div>
		</nz-radio-group>
		<div *ngIf="questionForm.controls.certificationStatus.value">
			<h4 class="mt-5">What is the scope of the certification?</h4>

			<div class="d-flex justify-content-between flex-column">

				<label nz-checkbox formControlName="certificationISO9000">
				<h6>ISO 9000/9001</h6>
				</label>

				<label nz-checkbox formControlName="certificationISO14001">
				<h6>ISO 14001</h6>
				</label>

				<label nz-checkbox formControlName="certificationionOther">
				<h6>Other (please specify in the comment box below)</h6>
				</label>

				<metro-form-input *ngIf="questionForm.controls.certificationionOther.value"
				class="standard w-100 mt-5" type="text" ph="Other" formControlName="certificationOtherComment"
				[control]="questionForm.controls.certificationOtherComment">
			</metro-form-input>
		</div>
	</div>

	<div *ngIf="questionForm.controls.certificationStatus.value === false">
		<h4 class="mt-5">Is it planned in the near future (by 2023)?</h4>
		<nz-radio-group class="relative" formControlName="certificationSubStatus" class="d-flex">

			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>

		</nz-radio-group>
	</div>
</div>
</div>

<div class="relative mb-15">
	<h4 class="mb-5">7. Does your facility carry out any quality control activities along the food sampling chain (from sample collection to sample storage)?</h4>
	<div>
		<nz-radio-group class="relative" formControlName="qualityControlActivities" >
			<label nz-radio [nzValue]="'YES'">YES</label>
			<label nz-radio [nzValue]="'NO'">NO</label>
			<label nz-radio [nzValue]="'NO_APPLICABLE'">Not applicable</label>
			<div *ngIf="questionForm.controls.qualityControlActivities.invalid" class="invalid-message">
				this field is required
			</div>
		</nz-radio-group>
	</div>

</div>

<div class="relative mb-15">
	<h4 class="mb-5">8. Does your facility have Standard Operating Procedure (SOPs) and/or Work Instructions (WI) regarding the utilization and conditions of use of equipment & installations?</h4>
	<div>
		<nz-radio-group class="relative" formControlName="SOPStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
			<div *ngIf="questionForm.controls.SOPStatus.invalid" class="invalid-message">
				this field is required
			</div>
		</nz-radio-group>
		<div *ngIf="questionForm.controls.SOPStatus.value">
			<h4 class="mt-5">please specify the title of SOP/WI</h4>

			<div class="d-flex justify-content-between flex-column">

				<label nz-checkbox *ngFor="let element of sop" [formControlName]="element.formKey">
				<h6>{{element.label}}</h6>
				</label>

				<metro-form-input *ngIf="questionForm.controls.SOPOther.value"
				class="standard w-100 mt-5" type="text" ph="Other" formControlName="SOPOtherComment"
				[control]="questionForm.controls.SOPOtherComment">
			</metro-form-input>
		</div>
	</div>

	<div *ngIf="questionForm.controls.SOPStatus.value === false">
		<h4 class="mt-5">Is it planned in the near future (by 2023)?</h4>
		<nz-radio-group class="relative" formControlName="SOPSubStatus" class="d-flex">

			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>

			<div *ngIf="questionForm.controls.SOPSubStatus.invalid" class="invalid-message">
				this field is required
			</div>

		</nz-radio-group>
	</div>
</div>
</div>

<div class="relative mb-15">
	<h4 class="mb-5">9. Does your facility follow any quality assurance plan for research and development and non-routine analysis?</h4>
	<div>
		<nz-radio-group class="relative" formControlName="qualityAssuranceStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
			<div *ngIf="questionForm.controls.qualityAssuranceStatus.invalid" class="invalid-message">
				this field is required
			</div>
		</nz-radio-group>
		<div *ngIf="questionForm.controls.qualityAssuranceStatus.value">
			<h4 class="mt-5">please identify the standard operating procedures (SOPs).</h4>

			<div class="d-flex justify-content-between flex-column">

				<label nz-checkbox *ngFor="let element of qualityAssurance" [formControlName]="element.formKey">
					<h6>{{element.label}}</h6>
				</label>

				<metro-form-input *ngIf="questionForm.controls.qualityAssuranceOther.value"
				class="standard w-100 mt-5" type="text" ph="Other" formControlName="qualityAssuranceOtherComment"
				[control]="questionForm.controls.qualityAssuranceOtherComment">
			</metro-form-input>
		</div>
	</div>

	<div *ngIf="questionForm.controls.qualityAssuranceStatus.value === false">
		<h4 class="mt-5">Is it planned in the near future (by 2023)?</h4>
		<nz-radio-group class="relative" formControlName="qualityAssuranceSubStatus" class="d-flex">

			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>

			<div *ngIf="questionForm.controls.qualityAssuranceSubStatus.invalid" class="invalid-message">
				this field is required
			</div>

		</nz-radio-group>
	</div>
</div>
</div>

<div class="relative mb-15">
	<h4>10.	Does your facility provide specific training to temporary personnel (e.g. students, guest workers, fellows, research assistants) before allowing them to share equipment?</h4>
	<div>
		<nz-radio-group class="relative" formControlName="personnelTrainingStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
			<div *ngIf="questionForm.controls.personnelTrainingStatus.invalid" class="invalid-message">
				this field is required
			</div>
		</nz-radio-group>
		<div *ngIf="questionForm.controls.personnelTrainingStatus.value">
			<h4 class="mt-5">please specify these training aspects.</h4>

			<div class="d-flex justify-content-between flex-column">

				<label nz-checkbox *ngFor="let element of personnelQualities" [formControlName]="element.formKey">
				<h6>{{element.label}}</h6>
				</label>

				<metro-form-input *ngIf="questionForm.controls.personnelTrainingOther.value"
				class="standard w-100 mt-5" type="text" ph="Other" formControlName="personnelTrainingOtherComment"
				[control]="questionForm.controls.personnelTrainingOtherComment">
			</metro-form-input>
		</div>
	</div>

</div>
</div>

<div class="relative mb-15">
	<h4>11.	Is your research activity linked to other activities (such as teaching or service provisions to costumers)?</h4>
	<div>
		<nz-radio-group class="relative" formControlName="linkedResearchStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
			<div *ngIf="questionForm.controls.linkedResearchStatus.invalid" class="invalid-message">
				this field is required
			</div>
		</nz-radio-group>
		<div *ngIf="questionForm.controls.linkedResearchStatus.value">
			<h4 class="mt-5">please specify.</h4>

			<div class="d-flex justify-content-between flex-column">

				<label nz-checkbox formControlName="linkedResearchTeaching">
				<h6>Teaching</h6>
				</label>

				<label nz-checkbox formControlName="linkedResearchCostumers">
				<h6>Service provisions to costumers</h6>
				</label>

				<label nz-checkbox formControlName="linkedResearchStatusOther">
				<h6>Other (please specify in the comment box below)</h6>
				</label>

				<metro-form-input *ngIf="questionForm.controls.linkedResearchStatus.value"
				class="standard w-100 mt-5" type="text" ph="Other" formControlName="linkedResearchOtherComment"
				[control]="questionForm.controls.linkedResearchOtherComment">
			</metro-form-input>
		</div>
	</div>
</div>
</div>

<div class="relative mb-15">
	<h4>12. Can you recommend Quality Management System (QMS) aspects that are not covered by this questionnaire but could be usefully implemented in METROFOOD-RI, in the current or future situation?</h4>
	<div>
		<nz-radio-group class="relative" formControlName="qmsStatus" >
			<label nz-radio [nzValue]="true">YES</label>
			<label nz-radio [nzValue]="false">NO</label>
			<div *ngIf="questionForm.controls.qmsStatus.invalid" class="invalid-message">
				this field is required
			</div>
		</nz-radio-group>
		<div *ngIf="questionForm.controls.qmsStatus.value" class="relative">
			<h4 class="mt-5">please specify these requirements aspects briefly.</h4>
			<metro-form-input *ngIf="questionForm.controls.qmsStatus.value"
			class="standard w-100 mt-5" type="text" ph="Other" formControlName="qms"
			[control]="questionForm.controls.qms">
		</metro-form-input>
		<div *ngIf="questionForm.controls.qms.invalid" class="invalid-message">
			this field is required
		</div>
	</div>
</div>
</div>
</form>
