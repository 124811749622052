import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Facility, FacilityServiceEntity } from '@metro-membership/data-access';
import { ServiceStatuses } from '@metro-membership/data-access/enums';
import { debounceTime } from 'rxjs/operators';
import { FacilityService } from '../../../data/services/facility.service';
import { analyticalServices } from './analytical';
@Component({
  selector: 'metro-membership-analytical-service',
  templateUrl: './analytical-services.component.html',
  styleUrls: ['./analytical-services.component.less']
})
export class AnalyticalServiceComponent implements OnInit {
	@Output() formChanges: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	@Input() facility = { } as Facility;
	serviceForm: FormGroup;
	showForm = false;
	facilityServices: FacilityServiceEntity;
	analyticalServices = analyticalServices;
  constructor(private formBuilder: FormBuilder, private facilityService: FacilityService) {}

	ngOnInit(): void {
		this.buildForm();
		this.fetchServices();
	}

	fetchServices() {
		this.facilityService.fetchFacilityServices(this.facility.id).subscribe(res => {
			this.facilityServices = res;
			this.setForm();
		})
	}
	setForm() {
		this.serviceForm.patchValue({
			facilityId: this.facilityServices?.facilityId || null,
			samplePreparationStorage: this.facilityServices?.samplePreparationStorage || null,
			foodCompositionCharacterization: this.facilityServices?.foodCompositionCharacterization || null,
			inorganicContaminant: this.facilityServices?.inorganicContaminant || null,
			organicContaminants: this.facilityServices?.organicContaminants || null,
			chemicalBiologicalMarkersProfiles: this.facilityServices?.chemicalBiologicalMarkersProfiles || null,
			microbiologicalAnalysis: this.facilityServices?.microbiologicalAnalysis || null,
			allergenTesting: this.facilityServices?.allergenTesting || null,
			geneticAnalyses: this.facilityServices?.geneticAnalyses || null,
			characterizationFCMMigrationTests: this.facilityServices?.characterizationFCMMigrationTests || null,
			analysisFoodAdditivesSupplements: this.facilityServices?.analysisFoodAdditivesSupplements || null,
			developmentSensorsDevices: this.facilityServices?.developmentSensorsDevices || null,
			agroecoSystemCharacterisation: this.facilityServices?.agroecoSystemCharacterisation || null,
			testing: this.facilityServices?.testing || null,
		});
	}
	buildForm() {
		this.serviceForm = this.formBuilder.group({
			facilityId: this.facility.id,
			samplePreparationStorage: '',
			foodCompositionCharacterization: '',
			inorganicContaminant: '',
			organicContaminants: '',
			chemicalBiologicalMarkersProfiles: '',
			microbiologicalAnalysis: '',
			allergenTesting: '',
			geneticAnalyses: '',
			characterizationFCMMigrationTests: '',
			analysisFoodAdditivesSupplements: '',
			developmentSensorsDevices: '',
			agroecoSystemCharacterisation: '',
			testing: '',
		});
		this.showForm = true;

		this.serviceForm.valueChanges
		.pipe(debounceTime(500))
		.subscribe(changes => {
			this.formChanges.next(changes);
		});
	}


}
