<form action="" [formGroup]="resourcesForm">

	<metro-form-input
		class="standard mt-3 w-100" type="text" ph="What is the size of the database?" formControlName="databaseSize"
		label="What is the size of the database?"
		[control]="resourcesForm.controls.databaseSize">
	</metro-form-input>

	<div class="title mb-4">
		Does the dataset contain confidential information?
	</div>

	<nz-radio-group formControlName="datasetConfidentialInformation">

		<label class="mb-4 d-flex" nz-radio [nzValue]="true">
			<span>YES</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="false">
			<span>NO</span>
		</label>

	</nz-radio-group>

	<metro-membership-form-text *ngIf="resourcesForm.controls.datasetConfidentialInformation.value"
		ph="Describe the confidential information" formControlName="datasetConfidentialInformationComment"
		label="Describe the confidential information"
		[control]="resourcesForm.controls.datasetConfidentialInformationComment">
	</metro-membership-form-text>

	<div class="title mb-4">
		The database general structure
	</div>

	<nz-radio-group formControlName="databaseStructure">

		<label class="mb-4 d-flex" nz-radio [nzValue]="'RELATIONAL'">
			<span>Relational database (SQL, Oracle, postgreSQL, Db2, etc.)</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'EXCEL'">
			<span>Excel</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'ACCESS'">
			<span>Access</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'WORD'">
			<span>Word</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'OTHER'">
			<span>Other</span>
		</label>

	</nz-radio-group>

	<metro-membership-form-text *ngIf="resourcesForm.controls.databaseStructure.value === 'OTHER' "
		ph="Describe the structure" formControlName="databaseStructureComment"
		label="Describe the structure"
		[control]="resourcesForm.controls.databaseStructureComment">
	</metro-membership-form-text>

	<metro-membership-form-text
		ph="Ontologies used" formControlName="databaseOntologies"
		label="Ontologies used"
		[control]="resourcesForm.controls.databaseOntologies">
	</metro-membership-form-text>

	<div class="title mb-4">
		Can a sample of the data be provided?
	</div>

	<nz-radio-group formControlName="dataSampleProvided">

		<label class="mb-4 d-flex" nz-radio [nzValue]="true">
			<span>YES</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="false">
			<span>NO</span>
		</label>

	</nz-radio-group>

	<div class="title mt-4 mb-4">
		In what formats can the data be imported?
	</div>

	<div class="mb-5 relative">
		<nz-select
			class="w-50"
			formControlName="databaseImportedFormat"
			[nzMaxTagCount]="5"
			nzMode="multiple"
			nzPlaceHolder="Please select">
			<nz-option *ngFor="let format of dataFormat" [nzLabel]="format" [nzValue]="format"></nz-option>
		</nz-select>
	</div>

	<metro-form-input *ngIf="detectOtherOption(resourcesForm.controls.databaseImportedFormat)"
										class="standard mt-3 w-100" type="text" ph="Please specify other data export format" formControlName="databaseImportedFormatComment"
										label="Please specify other data export format"
										[control]="resourcesForm.controls.databaseImportedFormatComment">
	</metro-form-input>

	<div class="title mt-4 mb-4">
		In what format can the data be exported?
	</div>

	<div class="mb-5 relative">
		<nz-select
			class="w-50"
			formControlName="databaseExportedFormat"
			[nzMaxTagCount]="5"
			nzMode="multiple"
			nzPlaceHolder="Please select">
			<nz-option *ngFor="let format of dataFormat" [nzLabel]="format" [nzValue]="format"></nz-option>
		</nz-select>
	</div>

	<metro-form-input *ngIf="detectOtherOption(resourcesForm.controls.databaseExportedFormat)"
										class="standard mt-3 w-100" type="text" ph="Please specify other data export format" formControlName="databaseExportedFormatComment"
										label="Please specify other data export format"
										[control]="resourcesForm.controls.databaseExportedFormatComment">
	</metro-form-input>

</form>
