<div class="person d-flex" *ngIf="person" >
	<button class="person__delete" (click)="deleteRole()" *ngIf="showDelete">x</button>
	<div class="person__preview" [class.mr-40]="biggerSpacing">
		<nz-avatar [nzSize]="48" nzIcon="user"></nz-avatar>
	</div>

	<div>
		<div class="person__name">
			<ng-container *ngIf="person?.name">{{person.name}} </ng-container>
			<ng-container *ngIf="person?.surname">{{person.surname}} </ng-container>
		</div>
		<div class="person__email" *ngIf="person?.email">{{person.email}}</div>

		<div class="person__body" *ngIf="person?.body"><em>{{person.body}} - {{person?.technicalCommittee}}</em></div>

		<div class="person__phone" *ngIf="person?.phone">{{person.phone}}</div>
	</div>
</div>
