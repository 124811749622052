import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthRolesPresets } from '@metro-membership/util';
import { ConfigService } from '../../core/services/config.service';
import { UserService } from '../../core/services/user.service';
import { FacilityService } from '../../data/services/facility.service';
import { MenuPosition } from '../../shared/components/side-menu/menu-position';
import { Facility } from '@metro-membership/data-access';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
	selector: 'metro-membership-facility',
	templateUrl: './facility.page.html',
	styleUrls: ['./facility.page.less']
})
@UntilDestroy({ arrayName: 'subscriptions' })
export class FacilityPage {
	sideMenuPositions: MenuPosition[] = [];
	unSubscribe = new Subject();
	instituteId = '';
	subscriptions: Subscription[] = [];
	constructor(
		private activatedRoute: ActivatedRoute,
		private userService: UserService,
		private facilityService: FacilityService
	) {
		this.subscriptions.push(
		activatedRoute.parent.params.subscribe(params => {
			this.instituteId = params.instituteId;
		}),
		activatedRoute.params.subscribe(params => {
			this.facilityService.fetchFacility(params.facilityId).toPromise().then((facilityResponse) =>
			this.setMenuPositions(facilityResponse));
		})
		)
	}

	setMenuPositions(facility: Facility) {
		const isAdmin =	this.userService.keycloakRoles.indexOf('admin') > -1;
		const isServiceAdmin =	this.userService.keycloakRoles.indexOf('services_admin') > -1;
		this.subscriptions.push(
		this.userService.user.subscribe(async () => {
			const grantManager = this.userService.getCurrentAuthGrantManager(this.activatedRoute.snapshot);
			this.sideMenuPositions = [
				{
					text: 'Facility details', url: `details`, subPositions: [
						{ text: 'People', url: `people`, enabled: true, visibility: true },
						{ text: 'Sector', url: `sector`, enabled: true, visibility: true },
						{
							text: 'Finances',
							url: `finances`,
							enabled: await AuthRolesPresets.isFinancialManagerOfInstituteOrFacility(grantManager) || isAdmin,
							visibility: true
						},
						{ text: 'HR', url: `hr`, enabled: await AuthRolesPresets.isManagerOfFacilityOrInstitute(grantManager)
							|| isAdmin, visibility: true },
						{ text: 'Equipment', url: `equipment`, enabled: true, visibility: true },
						{ text: 'Plans for upgrade', url: `plans`, enabled:
							await AuthRolesPresets.isManagerOfFacilityOrInstitute(grantManager) || isAdmin, visibility: true },
						{ text: '% to RI', url: `ri`, enabled: await AuthRolesPresets.isManagerOfFacilityOrInstitute(grantManager)
							|| isAdmin, visibility: true },
						{ text: 'Quality', url: `quality`,
							enabled: await AuthRolesPresets.isQualityManagerOfFacility(grantManager) || isAdmin,
							visibility: true
						}
					]
				}
			];
			if(facility.type === 'rm' || facility.type === 'analytical') {
				this.sideMenuPositions[0].subPositions.push({ text: 'Services', url: `services`,
				enabled: await AuthRolesPresets.isServiceManager(grantManager) || isAdmin || isServiceAdmin,
				visibility: true  })
			}
		})
		)
	}

}
