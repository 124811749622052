import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacilityPersonRole, InstitutePersonRole, Person } from '@metro-membership/data-access';
import { KeycloakService } from '@metro/ui-core';
import { EmailBody } from '../../../../../../libs/data-access/src/lib/models/email';

@Injectable({
	providedIn: 'root'
})
export class PersonService {
	constructor(private http: HttpClient, private keycloak: KeycloakService) { }

	fetchPersons(params?: HttpParams) {
		return this.http.get<Person[]>(`@persons`, { params });
	}

	fetchPerson(id: string, params?: HttpParams) {
		return this.http.get<Person>(`@persons/${id}`, { params });
	}

	createPerson(formData: any) {
		return this.http.post<Person>(`@persons`, formData);
	}

	updatePerson(person: Person) {
		return this.http.patch<Person>(`@persons/${person.id}`, person);
	}

	sendRegistrationEmail(email: EmailBody) {
		return this.http.post<any>(`@email`, email );
	}

	updateInstitutePersonRole(personRole: InstitutePersonRole, id: number) {
		return this.http.patch(`@institute-person-role/${id}`, personRole).toPromise();
	}

	createInstituteRole(personRole: InstitutePersonRole) {
		return this.http.post(`@institute-person-role`, personRole).toPromise();
	}

	deleteInstituteRole(personRole: InstitutePersonRole) {
		return this.http.delete(`@institute-person-role/${personRole.id}`).toPromise();
	}

	updateIFacilityPersonRole(personRole: FacilityPersonRole, id: number) {
		return this.http.patch(`@facility-person-role/${id}`, personRole).toPromise();
	}

	createFacilityRole(personRole: FacilityPersonRole) {
		return this.http.post(`@facility-person-role`, personRole).toPromise();
	}
}
