export class Sterilization {
	sterilizationPTFE = false;
	sterilizationAutoclaveSterilizer = false;
	sterilizationRadiosterilization = false;
	sterilizationSubBoiling = false;
	sterilizationOther = false;
}

export class Balance {
	balancesTechnical = false;
	balancesAnalytical = false;
	balancesMicroBalances = false;
	balancesUltraMicro = false;
	balancesCalibrationMasses = false;
}

export class Homogenization {
	homogenizationCentrifuge = false;
	homogenizationFiltration = false;
	homogenizationGrinder = false;
	homogenizationMixer = false;
	homogenizationShaker = false;
	homogenizationSiever = false;
	homogenizationUltrasoundBath = false;
	homogenizationRotaryEvaporator = false;
	homogenizationOven = false;
	homogenizationFreeze = false;
	homogenizationOther = false;
	// homogenizedMin = '';
	// homogenizedMax = '';
}

export class Sample {
	sampleDosingValve = false;
	sampleFeeder = false;
	sampleVialFillingMachine = false;
	sampleSealingMachine = false;
	sampleNitrogenSealer = false;
	sampleStopperingMachine = false;
	samplePelletPresser = false;
	sampleVacuumChamber = false;
	sampleLyophilizedStoppering = false;
	sampleLabelPrinter = false;
	sampleAutomaticLabeler = false;
	sampleOther = false;
}

export class Instruments {
	instrumentsOven = false;
	instrumentsFridge7 = false;
	instrumentsMinusFreezer20 = false;
	instrumentsMinus40 = false;
	instrumentsFreezerMinus80 = false;
	instrumentsClimaticChamber = false;
	instrumentsCycleTemperature = false;
	instrumentsTemperatureHumidity = false;
	instrumentsDesiccator = false;
	instrumentsSolarBox = false;
	instrumentsOther = false;
}

export class WaterActivity {
	waterActivity = false;
	waterActivityTotalContent = false;
	waterActivityParticleSize = false;
}

export class StorageHow {
	storageHowRoomTemperature = false;
	storageHowRefrigeratedTemperature = false;
	storageHowUnderVacuum = false;
	storageHowInertGas = false;
	storageHowSterilized = false;
	storageHowInDark = false;
	storageHowGlassContainers = false;
	storageHowPlasticContainers = false;
	storageHowOther = false;
}
