import { Component, OnInit } from '@angular/core';
import { KeycloakService } from '@metro/ui-core';
import { version } from '../../../../package.json';

@Component({
  selector: 'metro-membership-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
	appVersion = version;
	username: string;

	constructor(private keycloak: KeycloakService) {
	}

	ngOnInit() {
		this.username = this.keycloak.getUsername();
	}
}
