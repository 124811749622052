import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BreadcrumbOption } from 'ng-zorro-antd/breadcrumb';
import { merge, Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { BreadcrumbsService } from './breadcrumbs.service';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'metro-membership-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit {
	subscriptions: Subscription[];
	breadcrumbs: BreadcrumbOption[];

	constructor(
		private breadcrumbsService: BreadcrumbsService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private ngZone: NgZone
	) {}

	ngOnInit() {
		this.subscriptions = [
			this.observeRouteAndBreadcrumbs()
		];
	}

	navigate(url: string, e: MouseEvent): void {
		e.preventDefault();
		this.ngZone.run(() => this.router.navigateByUrl(url));
	}

	private observeRouteAndBreadcrumbs(): Subscription {
		return merge(
			this.router.events.pipe(
				filter(e => e instanceof NavigationEnd),
				startWith(true)
			),
			this.breadcrumbsService.breadcrumbParts
		).subscribe(() => {
			this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs(this.activatedRoute.root);
			this.cdr.markForCheck();
		});
	}
}
