import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Institute } from '@metro-membership/data-access';
import { Subject } from 'rxjs';
import { InstituteService } from '../../data/services/institute.service';
import { PaginatedData } from '../../shared/types/paginated-data';
import { PaginatedTableHeader } from '../../shared/components/paginated-table/paginated-table-header';

@Component({
  selector: 'metro-membership-search-institute',
  templateUrl: './search-institute.page.html',
  styleUrls: ['./search-institute.page.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInstitutePage implements OnInit, OnDestroy {
	searchForm: FormGroup;
	tableHeaders: PaginatedTableHeader[] = [
		{ label: 'Institute name', columnKey: 'shortName', sortFn: true },
		{ label: 'Country', columnKey: 'country', width: '110px', sortFn: true },
		{ label: 'Primary Investigator e-mail', columnKey: 'personEmail', width: '280px', sortFn: true },
		{ label: 'Website', width: '230px', columnKey: 'url' },
	];
	filterParams = new HttpParams({
		fromObject: {
			page: '1',
			limit: '10'
		}
	});
	institutes: Subject<PaginatedData<Institute>> = new Subject<PaginatedData<Institute>>();

	constructor(private instituteService: InstituteService) {}

	ngOnInit() {
		this.searchForm = new FormGroup({
			name: new FormControl(null),
			country: new FormControl(null),
			with: new FormControl([]),
			type: new FormControl([]),
			isNodeRep: new FormControl(false)
		});
	}

	isChecked(formControlName: string, value: string): boolean {
		return this.searchForm.get(formControlName).value.findIndex(v => v === value) >= 0;
	}

	formSubmit() {
		const qp = this.mapFormToRequest(this.searchForm.getRawValue());
		if (Object.keys(qp)) {
			this.filterParams = this.filterParams.set('s', JSON.stringify(qp));
		} else {
			this.filterParams = this.filterParams.delete('s');
		}

		this.makeQuery(this.filterParams);
	}

	makeQuery(params: HttpParams) {
		this.instituteService.fetchInstitutes(params).subscribe(institutes => this.institutes.next(institutes));
	}

	mapFormToRequest(fv: Record<string, any>): Record<string, any> {
		const qp = {};

		if (fv.name) {
			qp['shortName'] = { $contL: fv.name }
		}

		if (fv.country) {
			qp['country'] = { $contL: fv.country }
		}

		if (fv.with) {
			// Todo: Add this once db is standardized
		}

		if (fv.type && fv.type.length) {
			qp['type'] = { $in: fv.type }
		}

		if (fv.isNodeRep) {
			qp['isNodeRep'] = { $eq: true }
		}

		return qp;
	}

	ngOnDestroy() {
		this.institutes.complete();
	}
}
