<div class="relative mb-15">
	<nz-table
		[nzFrontPagination]="false"
		[nzShowPagination]="total > perPage"
		(nzQueryParams)="handleQueryParams($event)"
		[nzTotal]="total"
		[(nzPageIndex)] ="pageIndex"
		[nzPageSize]="perPage"
		[nzData]="facilities">
		<thead>
		<tr>
			<th [nzSortFn]="true" nzColumnKey="facilityName">Name</th>
			<th [nzSortFn]="true" nzColumnKey="type">Type</th>
			<th>Side</th>
			<th class="action-row">Actions</th>
		</tr>
		</thead>
		<tbody>
		<ng-container *ngFor="let facility of facilities">
			<tr class="pointer" *ngIf="facility.facilityName && facility.facilityName.length > 0">
				<td>
					<a [routerLink]="['/','institutes', facility.instituteId, 'facilities', facility.id]">{{ facility.facilityName }}</a>
				</td>
				<td>
					<metro-membership-facility-type [type]="facility.type"></metro-membership-facility-type>
				</td>
				<td>
					{{ facility?.type === 'analytical' || facility?.type === 'rm' ? 'METRO' : 'FOOD' }}
				</td>
				<td class="action-row">
					<i *ngIf="showEditing" nz-icon nzType="delete" (click)="onDeleteFacility(facility.id)"></i>
				</td>
			</tr>
		</ng-container>
		</tbody>
		<button class="add-facility secondary" (click)="addFacality()" *ngIf="showEditing">
			<span>Add Facility</span>
		</button>
	</nz-table>
</div>

