<ng-container *ngIf="showForm" >
	<nz-table class="mb-15"
	[nzFrontPagination]="false"
	[nzData]="formGroup.controls">
	<thead>
		<tr>
			<th nzWidth="40%" nzAlign="center"></th>
			<th nzWidth="60%" nzAlign="center">
				<tr style="display: block;">
					<th style="display: inline-block; width: 25%;" nzAlign="center">YES</th>
					<th style="display: inline-block; width: 25%;" nzAlign="center">Potentially</th>
					<th style="display: inline-block; width: 25%;" nzAlign="center">Planned 1-5 years</th>
					<th style="display: inline-block; width: 25%;" nzAlign="center">NO</th>
				</tr>
			</th>
		</tr>
	</thead>
	<tbody>

		<tr class="pointer" *ngFor="let item of sector.controls; index as i" [formGroup]="item">

			<td nzWidth="40%">
				{{sectorCategories[item.value.name]}}
			</td>
			<td nzWidth="60%" nzAlign="center">
				<nz-radio-group formControlName="status" style="width: 100%;" >
					<td nzAlign="center" style="display: inline-block; width: 25%;">
						<label nz-radio nzValue="YES"></label>
					</td>
					<td nzAlign="center" style="display: inline-block; width: 25%;"	>
						<label nz-radio nzValue="POTENTIALLY"></label>
					</td>
					<td nzAlign="center" style="display: inline-block; width: 25%;"	>
						<label nz-radio nzValue="PLANNED"></label>
					</td>
					<td nzAlign="center" style="display: inline-block; width: 25%;"	>
						<label nz-radio nzValue="NO"></label>
					</td>
				</nz-radio-group>
			</td>
		</tr>
	</tbody>

	</nz-table>


	<div class="relative mb-15">
		<h3>Other (please specify here)</h3>
		<div [formGroup]="sector.controls[sector.controls.length - 1]" class="d-flex justify-content-between">
			<nz-textarea-count [nzMaxCharacterCount]="300" class="w-100" >
				<textarea rows="4" formControlName="comment" nz-input></textarea>
			</nz-textarea-count>
		</div>
	</div>

	<h3>What type of end product (food or non-food agricultural product) you work with?</h3>
	<nz-table
	[nzFrontPagination]="false"
	[nzData]="formGroup.controls">
	<thead>
		<tr>
			<th nzWidth="40%" nzAlign="center"></th>
			<th nzWidth="60%" nzAlign="center">
				<tr style="display: block;">
					<th style="display: inline-block; width: 25%;" nzAlign="center">YES</th>
					<th style="display: inline-block; width: 25%;" nzAlign="center">Potentially</th>
					<th style="display: inline-block; width: 25%;" nzAlign="center">Planned 1-5 years</th>
					<th style="display: inline-block; width: 25%;" nzAlign="center">NO</th>
				</tr>
			</th>
		</tr>
	</thead>
	<tbody>

		<tr class="pointer" *ngFor="let item of endProducts.controls; index as i" [formGroup]="item">

			<td nzWidth="40%">

				{{sectorCategories[item.value.name] }}
			</td>
			<td nzWidth="60%" nzAlign="center">
				<nz-radio-group formControlName="status" style="width: 100%;" >
					<td nzAlign="center" style="display: inline-block; width: 25%;">
						<label nz-radio nzValue="YES"></label>
					</td>
					<td nzAlign="center" style="display: inline-block; width: 25%;"	>
						<label nz-radio nzValue="POTENTIALLY"></label>
					</td>
					<td nzAlign="center" style="display: inline-block; width: 25%;"	>
						<label nz-radio nzValue="PLANNED"></label>
					</td>
					<td nzAlign="center" style="display: inline-block; width: 25%;"	>
						<label nz-radio nzValue="NO"></label>
					</td>
				</nz-radio-group>
			</td>
		</tr>
	</tbody>

	</nz-table>

	<div class="relative mb-15">
		<h3>Other (please specify here)</h3>
		<div [formGroup]="endProducts.controls[endProducts.controls.length - 1]" class="d-flex justify-content-between">
			<nz-textarea-count [nzMaxCharacterCount]="300" class="w-100" >
				<textarea rows="4" formControlName="comment" nz-input></textarea>
			</nz-textarea-count>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Is it possible to use the experimental facility to grow raw materials for RM production?</h3>
		<div *ngFor="let item of useRm.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
			<nz-radio-group formControlName="isActive" >

					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>

			</nz-radio-group>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>What is the amount that can be produced/production rate?</h3>
		<div *ngFor="let item of amount.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
			<metro-form-input class="standard mb-3 w-100" type="text" ph="Text" formControlName="comment"
				[control]="item.controls.comment">
			</metro-form-input>
		</div>
	</div>


</ng-container>
