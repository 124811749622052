import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InstitutePersonRole, InstituteResources, Person } from '@metro-membership/data-access';
import { PersonRole, ResourceTypes } from '@metro-membership/data-access/enums';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subject, Subscription } from 'rxjs';
import { EmailBody } from '../../../../../../libs/data-access/src/lib/models/email';
import { UserService } from '../../core/services/user.service';
import { InstituteService } from '../../data/services/institute.service';
import { PersonService } from '../../data/services/person.service';
import { PersonModalComponent } from '../../shared/modals/person-modal/person-modal.component';

@Component({
  selector: 'metro-membership-resources-general',
  templateUrl: './resources-general.component.html',
  styleUrls: ['./resources-general.component.less']
})
export class ResourcesGeneralComponent {
	isEditable = false;
	unSubscribe = new Subject();
	instituteId = '';
	subscriptions: Subscription[] = [];
	resourcesForm: FormGroup;
	resourceTypes = ResourceTypes;
	instituteResource: InstituteResources;
	contactPerson: Person;

	activeUserRoles = [];
	showForm = false;
	showEditing = false;
	constructor(
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private instituteService: InstituteService,
		private personService: PersonService,
		private modal: NzModalService,
		private userService: UserService) {
		this.subscriptions.push(
		activatedRoute.params.subscribe(params => {
			this.instituteId = params.instituteId;
			this.buildForm();
			this.getResource(params.resourceId);
		}));
	}

	buildForm() {
		this.resourcesForm = this.formBuilder.group({
			typeComment: null,
			name: [null, Validators.required],
			type: [null],
			side: null,
			instituteId: null,
			personId: null,
		});
		this.showForm = true;
		this.resourcesForm.disable();
		this.validationChange();
	}

	onEdit() {
		this.resourcesForm.enable();
		this.isEditable = true;
	}

	validationChange() {
		const type = this.resourcesForm.get('type');
		const typeComment = this.resourcesForm.get('typeComment');
		type.valueChanges.subscribe(typeChange => {
			if(typeChange === this.resourceTypes.OTHER) {
				typeComment.setValidators(Validators.required);
			} else {
				typeComment.clearValidators();
				typeComment.updateValueAndValidity();
			}
		});

	}

	getCurrentUserRole() {
		const user = this.userService.user.getValue();
		const isAdmin =	this.userService.keycloakRoles.indexOf('admin') > -1;
		const instRole = user?.instituteRoles.filter(role => role.instituteId === this.instituteResource.institute.id);
		this.activeUserRoles = instRole?.map(instRole => instRole.role);
		this.showEditing = this.activeUserRoles.indexOf(PersonRole.REPRESENTATIVE) > -1 || isAdmin;

	}

	showPersonModal() {
		const qualityModal: NzModalRef = this.modal.create({
			nzTitle: `Search the list of users, that are not assigned to any organisation, to select the ones you want to add as staff of
				METROFOOD-RI Central Hub. If you cannot find the person on the list, you can also invite them to join the platform.`,
			nzContent: PersonModalComponent
		});

		qualityModal.afterClose.subscribe((results: { person: Person } ) => {
			this.setPerson(results.person);
		});
	}

	sendAssignEmail(email: EmailBody) {
		this.instituteService.sendResourceContactAssignEmail(email).subscribe(() => ({}) );
	}

	setPerson(person: Person) {
		this.resourcesForm.get('personId').setValue(person.id);
		this.contactPerson = person;
	}

	onSave() {
		const currentPerson = this.instituteResource?.personRole?.person;
		const formData = this.resourcesForm.getRawValue();
		if(formData.personId ) {
			formData.personRole = { ...this.instituteResource?.personRole, personId: formData.personId, role: PersonRole.REPRESENTATIVE };
			delete formData.personRole?.person;
		}

		const params = new HttpParams({
			fromObject: {
				instituteId: this.instituteId || null
			}
		});

		this.instituteService.editResource(formData, this.instituteResource.id, params).subscribe((resourceJson) => {
			this.resourcesForm.disable();
			this.isEditable = false;

			if(currentPerson?.id !== formData.personId) {
				const instRole: InstitutePersonRole = { role: PersonRole.RESOURCE_MANAGER, instituteId: parseInt(this.instituteId), personId: formData.personId, id: null };
				this.personService.createInstituteRole(instRole).then(roleJson => roleJson);
				this.sendAssignEmail({
					resource: resourceJson.name,
					url: 'https://membership.metrofood-services.eu/',
					email: this.contactPerson.email,
					name:this.contactPerson.name
				})
			}
		})
	}

	getResource(resourceId: number) {
		const params = new HttpParams({
			fromObject: { join: ['personRole','personRole.person', 'institute'], instituteId: this.instituteId || null }
		});

		this.instituteService.fetchResource(resourceId, params).subscribe(resourceJson => {
			this.instituteResource = resourceJson;
			this.setForm();
			this.getCurrentUserRole();
			this.contactPerson = resourceJson?.personRole?.person;
		});
	}

	setForm() {
		this.resourcesForm.patchValue(this.instituteResource);
	}

}
