import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';
import { SectorCategories, SectorCategoriesTranslations } from '@metro-membership/data-access/enums';
import { debounceTime } from 'rxjs/operators';
import { procInterRms, additivesSpecify, environmental, beverages, foodsAnimal, prepareFoods,  agricultural, matrixes,  pureSubst, sector, feedStuffsSpecify, foodsPlant, fcm } from './sector-rm';

@Component({
  selector: 'metro-membership-sector-rm',
  templateUrl: './sector-rm.component.html',
  styleUrls: ['./sector-rm.component.less']
})
export class SectorRmComponent implements OnInit {
	@Output() formChanges: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	@Input() facility = { } as Facility;
	formGroup: FormGroup;
	showForm = false;
	sectorCategories = SectorCategoriesTranslations;
  constructor(private formBuilder: FormBuilder) { }

	get sector() {
		return this.formGroup.get('sector') as FormArray;
	}
	get pureSubst() {
		return this.formGroup.get('pureSubst') as FormArray;
	}
	get procInterRms() {
		return this.formGroup.get('procInterRms') as FormArray;
	}
	get matrixes() {
		return this.formGroup.get('matrixes') as FormArray;
	}
	get additivesSpecify() {
		return this.formGroup.get('additivesSpecify') as FormArray;
	}
	get feedStuffsSpecify() {
		return this.formGroup.get('feedStuffsSpecify') as FormArray;
	}
	get foodsAnimal() {
		return this.formGroup.get('foodsAnimal') as FormArray;
	}
	get foodsPlant () {
		return this.formGroup.get('foodsPlant') as FormArray;
	}
	get beverages() {
		return this.formGroup.get('beverages') as FormArray;
	}
	get fcm() {
		return this.formGroup.get('fcm') as FormArray;
	}
	get agricultural() {
		return this.formGroup.get('agricultural') as FormArray;
	}
	get environmental() {
		return this.formGroup.get('environmental') as FormArray;
	}
	get prepareFoods() {
		return this.formGroup.get('prepareFoods') as FormArray;
	}
  ngOnInit(): void {
		this.buildForms();
	}

	buildTableForm(categoryTable: any[]): FormGroup[] {
		return categoryTable.map(categories => {
			const currentSector = this.facility.sectors.find(sector => sector.name === categories.name )
			return this.formBuilder.group({
				id: currentSector?.id || null,
				name: categories.name || null,
				status: currentSector?.status || null,
				number: [{value: currentSector?.number, disabled: true}],
				isActive: currentSector?.isActive || null,
				comment: currentSector?.comment || null,
				facilityId: this.facility.id,
			});
		});

	}
	buildForms() {

		this.formGroup = this.formBuilder.group({
			sector: this.formBuilder.array(this.buildTableForm(sector)),
			pureSubst: this.formBuilder.array(this.buildTableForm(pureSubst)),
			procInterRms: this.formBuilder.array(this.buildTableForm(procInterRms)),
			matrixes: this.formBuilder.array(this.buildTableForm(matrixes)),
			additivesSpecify: this.formBuilder.array(this.buildTableForm(additivesSpecify)),
			feedStuffsSpecify: this.formBuilder.array(this.buildTableForm(feedStuffsSpecify)),
			foodsAnimal: this.formBuilder.array(this.buildTableForm(foodsAnimal)),
			foodsPlant: this.formBuilder.array(this.buildTableForm(foodsPlant)),
			beverages: this.formBuilder.array(this.buildTableForm(beverages)),
			fcm: this.formBuilder.array(this.buildTableForm(fcm)),
			agricultural: this.formBuilder.array(this.buildTableForm(agricultural)),
			environmental: this.formBuilder.array(this.buildTableForm(environmental)),
			prepareFoods: this.formBuilder.array(this.buildTableForm(prepareFoods)),
		});

		this.showForm = true;
		this.formGroup.valueChanges
		.pipe(debounceTime(500))
		.subscribe(changes => {
			this.formChanges.next(changes);
		});
	}
	changeDisability(control: FormControl){
		if(control.disabled){
			control.enable();
		} else {
			control.disable();
		}
	}

}
