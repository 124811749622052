<div class="top-menu">
	<div class="container overflow-visible">
		<ul nz-menu nzMode="horizontal">

			<li nz-menu-item [nzMatchRouterExact]="true" [nzMatchRouter]="true">
				<a [routerLink]="['/']">Home</a>
			</li>

			<li nz-menu-item [nzMatchRouter]="true">
				<a [routerLink]="['institutes', 1]">Partners</a>
			</li>

			<li nz-menu-item [nzMatchRouter]="true">
				<a [routerLink]="['my', firstInstituteId]">My institute</a>
			</li>

			<li nz-submenu nzMenuClassName="top-menu-submenu" [nzTitle]="titleTpl" [class.ant-menu-submenu-selected]="searchType | async">
				<ul>
					<li nz-menu-item [nzMatchRouterExact]="true" [nzMatchRouter]="true">
						<a [routerLink]="['search', 'person']">Search person</a>
					</li>
					<li nz-menu-item [nzMatchRouterExact]="true" [nzMatchRouter]="true">
						<a [routerLink]="['search', 'institute']">Search institute</a>
					</li>
					<li nz-menu-item [nzMatchRouterExact]="true" [nzMatchRouter]="true">
						<a [routerLink]="['search', 'facility']">Search facility</a>
					</li>
					<li nz-menu-item [nzMatchRouterExact]="true" [nzMatchRouter]="true">
						<a [routerLink]="['search', 'e-resource']">Search e-resource</a>
					</li>
				</ul>
			</li>

		</ul>
	</div>
</div>

<ng-template #titleTpl>
	<span>Search {{ searchType | async }}</span><i nz-icon nzType="down"></i>
</ng-template>
