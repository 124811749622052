import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Facility } from '@metro-membership/data-access';
import { NzModalService } from 'ng-zorro-antd/modal';
import { forkJoin, merge, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FacilityService } from '../../data/services/facility.service';

@Component({
	selector: 'metro-membership-facility-equipment',
	templateUrl: './facility-equipment.page.html',
	styleUrls: ['./facility-equipment.page.less']
})
export class FacilityEquipmentPage {
	unSubscribe = new Subject();
	facility: Facility;
	facilityForm: FormGroup;

	facilityId = '';
	editMode = false;
	showForm = false;

	getEquipment(categoryGroup: FormGroup) {
		return categoryGroup.get('equipment') as FormArray;
	}

	constructor(
		private activatedRoute: ActivatedRoute,
		private facilityService: FacilityService,
		private formBuilder: FormBuilder,
		private http: HttpClient
	) {
		this.activatedRoute.parent.params
		.pipe(takeUntil(this.unSubscribe))
		.subscribe(params => {
			this.facilityId = params.facilityId;
			this.getFacilities();
		});
	}

	get isEditable(): boolean {
		return this.facilityForm.enabled as boolean;
	}

	onEdit(): void {
		this.editMode = !this.editMode;
		this.facilityForm.enable();
	}


	getFacilities() {
		const params = new HttpParams({
			fromObject: { join: ['analyticalEquipment', 'rmEquipment'] }
		});

		this.facilityService.fetchFacility(this.facilityId, params).subscribe(facilityJson => {
			this.facility = facilityJson;
		});
	}


}
