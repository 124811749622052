import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Institute, InstituteEService } from '@metro-membership/data-access';
import { CountryCurrencyEnum } from '@metro-membership/data-access/enums';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InstituteService } from '../../data/services/institute.service';
import { CustomValidator } from '../../shared/validators/percentage.validator';

@Component({
  selector: 'metro-membership-institute-e-services',
  templateUrl: './institute-e-services.component.html',
  styleUrls: ['./institute-e-services.component.less']
})
export class InstituteEServicesComponent {
	institute: Institute;
	instituteEServices: InstituteEService;
	eServicesForm: FormGroup;
	showForm = false;
	unSubscribe = new Subject();
	isEditable = false;
	currency = new Set<string>(Object.values(CountryCurrencyEnum));

	constructor(private formBuilder: FormBuilder,  private activatedRoute: ActivatedRoute,
		private instituteService: InstituteService) {
		this.activatedRoute.parent.params
		.pipe(takeUntil(this.unSubscribe))
		.subscribe(params => {
			this.getInstitute(params.instituteId)
		});
	}

	getInstitute(instituteId: string) {
		this.instituteService.fetchInstitute(instituteId).subscribe(institute => {
			this.institute = institute;
			this.buildForm();
			this.getInstituteEServices();
		});
	}

	buildForm() {
		this.eServicesForm = this.formBuilder.group({
			instituteId: this.institute?.id,
			needs: null,
			itDivisions: null,
			itDivisionsComment: null,
			externalSupport: null,
			externalSupportComment: null,
			managementPlanComment: null,
			managementPlan: null,
			workingItMin: ['', Validators.compose([CustomValidator.digitsValidator(),
				CustomValidator.fractionsValidator(), Validators.required])],
			workingItMax: ['', Validators.compose([CustomValidator.digitsValidator(),
				CustomValidator.fractionsValidator(), Validators.required])],
			workingItMinFTE: ['', Validators.compose([CustomValidator.digitsValidator(),
				CustomValidator.fractionsValidator(), Validators.required])],
			workingItMaxFTE: ['', Validators.compose([CustomValidator.digitsValidator(),
				CustomValidator.fractionsValidator(), Validators.required])],
			metroFoodFTEIt:[ '', Validators.compose([CustomValidator.digitsValidator(),
				CustomValidator.fractionsValidator(), Validators.required])],
			preExistingTotNetIsEstimate: null,
			preExistingTOTNet: ['', Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
				CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])],
			preExistingTOTNetCurr: ['EUR'],
			preExistingAveragePercent: ['', Validators.compose([ Validators.required, CustomValidator.digitsValidator ,
				CustomValidator.dotValidator(), CustomValidator.fractionsValidator()])]
		});
		this.eServicesForm.disable();
		this.showForm = true;
	}

	getInstituteEServices() {
		this.instituteService.fetchInstituteEServices(this.institute?.id).toPromise().then((services: InstituteEService) => {
			this.instituteEServices = services;
			this.setForm();
		});
	}

	setForm() {
		this.eServicesForm.patchValue(this.instituteEServices);
	}

	onSave() {
		if (this.instituteEServices?.id) {
			this.editInstituteService();
		} else {
			this.addInstituteService();
		}
		this.isEditable = false;
	}

	editInstituteService() {
		const formData = this.eServicesForm.getRawValue();
		this.instituteService.editInstituteEServices(formData, this.instituteEServices.instituteId).subscribe(res => {
			this.eServicesForm.disable();
			this.getInstituteEServices();
		});
	}

	addInstituteService() {
		const formData = this.eServicesForm.getRawValue();
		delete formData.id;
		this.instituteService.addInstituteEServices(formData).subscribe(res => {
			this.eServicesForm.disable();
			this.getInstituteEServices();
		});
	}

	onEdit() {
		this.eServicesForm.enable();
		this.isEditable = true;
	}
}
