
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {	FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';
import { FacilityEquipmentService } from '../../../data/services/facility-equipment.service';
import {
	Application,
	Cooling, Cultivation, CultivationType, DedicatedEquipment, Environmental, EquipmentMachinery, EquipmentSitu,
	ExperimentalFacility,
	ExperimentalFields, GreenHouseMeters,
	GreenHouses, Heating, LivestockMachinery, MeasureEquipmentSitu,
	OpenFields,
	PerennialCrops,
	PlantsType, ProductionTechnology, Shape, Structure
} from './production';


@Component({
	selector: 'metro-membership-production',
	templateUrl: './production.component.html',
	styleUrls: ['./production.component.less']
})
export class ProductionComponent {
	@Input() facility = { } as Facility;
	productionForm: FormGroup;

	experimentalFields: ExperimentalFields = new ExperimentalFields();
	experimentalFacility: ExperimentalFacility = new ExperimentalFacility();
	openFields : OpenFields = new OpenFields();
	plantsType: PlantsType = new PlantsType();
	perennialCrops: PerennialCrops = new PerennialCrops();
	greenHouses: GreenHouses = new GreenHouses();
	greenHouseMeters: GreenHouseMeters = new GreenHouseMeters();
	shape: Shape = new Shape();
	structure: Structure = new Structure();
	productionTechnology: ProductionTechnology = new ProductionTechnology();
	heating: Heating = new Heating();
	cooling: Cooling = new Cooling();
	cultivation: Cultivation = new Cultivation();
	environmental: Environmental = new Environmental();
	application: Application = new Application();
	cultivationType: CultivationType = new CultivationType();
	dedicatedEquipment: DedicatedEquipment = new DedicatedEquipment();
	equipmentMachinery: EquipmentMachinery = new EquipmentMachinery();
	livestockMachinery: LivestockMachinery = new LivestockMachinery();
	equipmentSitu: EquipmentSitu = new EquipmentSitu();
	measureEquipmentSitu: MeasureEquipmentSitu = new MeasureEquipmentSitu();
	showForms = false;

	constructor(private formBuilder: FormBuilder, private equipmentService: FacilityEquipmentService ) {
		this.buildForm();
	}

	buildForm() {
		this.productionForm = this.equipmentService.primaryProduction();
	}

	changeDisability(control: string){

	}

}
