<form action="" class="mt-2" [formGroup]="riForm" *ngIf="showForm">
	<div class="d-flex justify-content-between">
		<h1>% to RI</h1>
		<div>
			<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
				<div>EDIT</div>

			</button>

			<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" [ngClass]="{'disabled': riForm.invalid }">
				<div>SAVE</div>
			</button>
		</div>
	</div>


	<div class="input-wrapper mt-5">
		<h4 class="mb-2">What is the % (as time machine - mean value for the whole facility) that you plan to involve in METROFOOD-RI (research activities related to METROFOOD-RI and related services)?</h4>
		<metro-form-input class="standard" type="text"
			ph="Amount" formControlName="timeMachine"  [control]="riForm.controls.timeMachine"
		></metro-form-input>
	</div>

	<div class="input-wrapper mt-5">
		<h4 class="mb-2">If needed, you can specify an additional time machine (%) for specific equipment included in the main facility</h4>
		<metro-form-input class="standard" type="text"
			ph="Amount" formControlName="addTimeMachine" [control]="riForm.controls.addTimeMachine"
		></metro-form-input>
	</div>


</form>

