
<form action="" [formGroup]="formGroup">
	<nz-table *ngIf="showForm"
	[nzFrontPagination]="false"
	[nzData]="true">
	<thead>
		<tr>
			<th nzWidth="40%" nzAlign="center"></th>
			<th nzWidth="60%" nzAlign="center">
				<tr style="display: block;">
					<th style="display: inline-block; width: 25%;" nzAlign="center">YES</th>
					<th style="display: inline-block; width: 25%;" nzAlign="center">YES after minor upgrades</th>
					<th style="display: inline-block; width: 25%;" nzAlign="center">YES after major upgrades</th>
					<th style="display: inline-block; width: 25%;" nzAlign="center">NO</th>
				</tr>
			</th>
		</tr>
	</thead>
	<tbody>

		<tr class="pointer" *ngFor="let item of serviceDetails; index as i">

			<td nzWidth="40%">
				{{item.label}}
			</td>
			<td nzWidth="60%" nzAlign="center">
				<nz-radio-group [formControlName]="item.formKey" style="width: 100%;" >
					<td nzAlign="center" style="display: inline-block; width: 25%;">
						<label nz-radio nzValue="YES"></label>
					</td>
					<td nzAlign="center" style="display: inline-block; width: 25%;"	>
						<label nz-radio nzValue="YES_MINOR"></label>
					</td>
					<td nzAlign="center" style="display: inline-block; width: 25%;"	>
						<label nz-radio nzValue="YES_MAJOR"></label>
					</td>
					<td nzAlign="center" style="display: inline-block; width: 25%;"	>
						<label nz-radio nzValue="NO"></label>
					</td>
				</nz-radio-group>
			</td>

		</tr>

	</tbody>

	</nz-table>

	<div class="relative mb-15">
		<h3>In which tasks/services the facility can be involved?</h3>
		<div class="d-flex justify-content-between" *ngFor="let item of tasksServices">
			<label nz-checkbox [formControlName]="item.formKey">
				<div class="title">{{item.label}}</div>
			</label>
		</div>
	</div>

	<div class="relative">
		<h3>What kind of stability test can you perform?</h3>
		<div *ngFor="let item of stability; index as i" class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.formKey">
				<div class="title">{{ item.label }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<div class="d-flex justify-content-between">
			<textarea nz-input formControlName="stabilityOtherTestComment"></textarea>
		</div>
	</div>


	<div class="relative mb-15">
		<h3>Do you have Analytical Lab. facilities that can be involved in RM characterisation?</h3>
		<div class="d-flex justify-content-between">
			<nz-radio-group formControlName="ALInvolvedInRmCharacterisation" >

					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>

			</nz-radio-group>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Do you have Analytical Lab. facilities for homogeneity studies?</h3>
		<div class="d-flex justify-content-between">
			<nz-radio-group formControlName="ALForHomogeneityStudies" >

					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>

			</nz-radio-group>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Specify the analytical technique</h3>
		<div class="d-flex justify-content-between">
			<textarea nz-input formControlName="analyticalTechnique"></textarea>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Do you have written procedures for RM certification?</h3>
		<div class="d-flex justify-content-between">
			<nz-radio-group formControlName="proceduresRmCertification" >

					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>

			</nz-radio-group>
		</div>
	</div>

	<div class="relative mb-15" *ngIf="formGroup.controls.proceduresRmCertification.value">
		<h3>If you have written procedures for RM certification, here you can include an explanatory text or upload files</h3>
		<div class="d-flex justify-content-between">
			<textarea nz-input formControlName="proceduresRmCertificationComment"></textarea>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Do you have written procedures for RM preparation?</h3>
		<div class="d-flex justify-content-between">
			<nz-radio-group formControlName="proceduresRmPreparation" >

					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>

			</nz-radio-group>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>If you have written procedures for RM preparation, here you can include an explanatory text or upload files</h3>
		<div class="d-flex justify-content-between">
			<textarea nz-input formControlName="proceduresRmPreparationComment"></textarea>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>In which physical form are you able to prepare RMs?</h3>
		<div *ngFor="let item of prepareRm; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.formKey">
				<div class="title">{{ item.label }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Solid RMs are obtained (select what applies):</h3>
		<div class="d-flex justify-content-between">
			<label nz-checkbox formControlName="solidRmObtainedWithoutDehydration">
				<div class="title">by applying a dehydration step</div>
			</label>
		</div>

		<div class="d-flex justify-content-between">
			<label nz-checkbox formControlName="solidRmObtainedWithDehydration">
				<div class="title">without a dehydration step</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>If you prepare dehydrated RMs (e.g. lyophilized RMs), when do you perform homogenization?</h3>
		<div class="d-flex justify-content-between">
			<label nz-checkbox formControlName="beforeDehydration">
				<div class="title">before the dehydratation step (e.g. before lyophilization)</div>
			</label>
		</div>

		<div class="d-flex justify-content-between">
			<label nz-checkbox formControlName="afterDehydration">
				<div class="title">after the dehydratation step (e.g. after lyophilization)</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Which is the production scale of your plant?</h3>
		<div class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(formGroup.controls.productionScalePlantPerYearComment)" formControlName="productionScalePlantPerYear">
				<div class="title">n° of lots per year</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" formControlName="productionScalePlantPerYearComment"
				[control]="formGroup.controls.productionScalePlantPerYearComment">
			</metro-form-input>
		</div>

		<div class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(formGroup.controls.productionScalePlantAliquotsComment)" formControlName="productionScalePlantAliquots">
				<div class="title">n° of aliquots per lot</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" formControlName="productionScalePlantAliquotsComment"
				[control]="formGroup.controls.productionScalePlantAliquotsComment">
			</metro-form-input>
		</div>

		<div class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(formGroup.controls.productionScalePlantAliquotsDimensionComment)" formControlName="productionScalePlantAliquotsDimension">
				<div class="title">aliquot dimension (g)</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" formControlName="productionScalePlantAliquotsDimensionComment"
				[control]="formGroup.controls.productionScalePlantAliquotsDimensionComment" >
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Do you have dedicated production lines?</h3>
		<div class="d-flex justify-content-between">
			<nz-radio-group formControlName="dedicatedProductionLines" >

					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>

			</nz-radio-group>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>n° of production lines</h3>
		<div class="d-flex justify-content-between">
			<metro-form-input class="standard mb-3" type="text" ph="Amount" formControlName="linesSpecific2"
			[control]="formGroup.controls.linesSpecific2">
		</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Are these production lines specific for different types of matrixes?</h3>
		<div class="d-flex justify-content-between">
			<nz-radio-group formControlName="linesSpecificMatrixTypes" >

					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>

			</nz-radio-group>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Please specify</h3>
		<div class="d-flex justify-content-between">
			<label nz-checkbox formControlName="liquidMaterials">
				<div class="title">Liquid materials</div>
			</label>
		</div>

		<div class="d-flex justify-content-between">
			<label nz-checkbox formControlName="solidDryPowders">
				<div class="title">Solid - dry-powders</div>
			</label>
		</div>

		<div class="d-flex justify-content-between">
			<label nz-checkbox formControlName="solidDehydrated">
				<div class="title">Solid - dehydrated</div>
			</label>
		</div>
	</div>


</form>
