import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRolesPresets } from '@metro-membership/util';
import { RoleGuard } from '../core/guards/role.guard';
import { ResourcesAccessibilityComponent } from './resources-accessibility/resources-accessibility.component';
import { ResourcesAdditionalComponent } from './resources-additional/resources-additional.component';
import { ResourcesDetailsComponent } from './resources-details/resources-details.component';
import { ResourcesGeneralComponent } from './resources-general/resources-general.component';
import { ResourcesPage } from './resources/resources.page';

export const resourcesRoutes: Routes = [
	{
		path: 'resources/:resourceId', component: ResourcesPage, canActivate: [RoleGuard],
		data: {  breadcrumb: ':resource', appRoles: AuthRolesPresets.isResourceManagerOfInstituteOrResource }, children: [
			{ path:'general', component: ResourcesGeneralComponent, data: { breadcrumb: '' }   },
			{ path:'details', component: ResourcesDetailsComponent, data: { breadcrumb: 'Details' } },
			{ path:'additional', component: ResourcesAdditionalComponent, data: { breadcrumb: 'Additional' } },
			{ path:'accessibility', component: ResourcesAccessibilityComponent, data: { breadcrumb: 'Accessibility' } },
			{ path: '', pathMatch: 'full', redirectTo: 'general' },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(resourcesRoutes)],
	exports: [RouterModule]
})
export class ResourcesRoutingModule {}
