<div [formGroup]="productionForm">
	<div class="relative mb-15">
		<h3>Experimental fields/farms for primary production</h3>
		<div *ngFor="let item of experimentalFields | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>What type of experimental facility do you use to produce seeds, plants and/or feedstuffs?</h3>
		<div *ngFor="let item of experimentalFacility | keyvalue ; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>For open fields</h3>
		<div *ngFor="let item of openFields | keyvalue; index as i"  class="d-flex justify-content-between">
			<span>{{ item.key }}</span>
			<metro-form-input class="standard mb-3 w-60" type="text" ph="Amount" [formControlName]="item.key">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Type of soil (type and classification system used)</h3>
		<div class="d-flex justify-content-between">
			<metro-form-input class="standard mb-3 w-100" type="text" ph="Amount" formControlName="soilType">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Dedicated irrigation system</h3>
		<div class="d-flex justify-content-between">
			<nz-radio-group formControlName="irrigationSystem">

					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>

			</nz-radio-group>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Type of plants and dedicated ha</h3>
		<div *ngFor="let item of plantsType | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Specify what and how many perennial crops are implanted</h3>
		<div *ngFor="let item of perennialCrops | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>For greenhouses</h3>
		<div *ngFor="let item of greenHouses | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Square meters cultivable per greenhouse</h3>
		<div *ngFor="let item of greenHouseMeters | keyvalue; index as i"  class="d-flex justify-content-between">
			<span>{{ item.key }}</span>
			<metro-form-input class="standard mb-3 w-60" type="text" ph="Amount" [formControlName]="item.key">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Shape</h3>
		<div *ngFor="let item of shape  | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Structure</h3>
		<div *ngFor="let item of structure | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Technology level</h3>
		<div *ngFor="let item of productionTechnology  | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Heating systems</h3>
		<div *ngFor="let item of heating | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Cooling system</h3>
		<div *ngFor="let item of cooling | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Square meters cultivable per greenhouse</h3>
		<div *ngFor="let item of cultivation | keyvalue; index as i" class="d-flex justify-content-between">
			<span>{{ item.key }}</span>
			<metro-form-input class="standard mb-3 w-60" type="text" ph="Amount" [formControlName]="item.key">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Environmental control</h3>
		<div *ngFor="let item of environmental | keyvalue; index as i"  class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Application</h3>
		<div *ngFor="let item of application | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>For growth chambers</h3>
		<div class="d-flex justify-content-between">
			<span>Total square meters (sqm)</span>
			<metro-form-input class="standard mb-3 w-60" type="text" ph="Amount" formControlName="growthChambersTotalMeters"></metro-form-input>
		</div>
		<div class="d-flex justify-content-between">
			<span>n° of separated growth chambers</span>
			<metro-form-input class="standard mb-3 w-60" type="text" ph="Amount" formControlName="growthChambersSeparated"></metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Cultivation type</h3>
		<div *ngFor="let item of application | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Dedicated measurement equipment in situ</h3>
		<div *ngFor="let item of dedicatedEquipment | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<div class="title">{{ item.key }}</div>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Specific equipment: Agriculture machinery</h3>
		<div *ngFor="let item of equipmentMachinery | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<span class="title">{{ item.key }}</span>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Specific equipment: Livestock machinery</h3>
		<div *ngFor="let item of livestockMachinery | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<span class="title">{{ item.key }}</span>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Dedicated equipment in situ</h3>
		<div *ngFor="let item of equipmentSitu | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<span class="title">{{ item.key }}</span>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<div class="relative mb-15">
		<h3>Dedicated measurement equipment in situ</h3>
		<div *ngFor="let item of measureEquipmentSitu | keyvalue; index as i" class="d-flex justify-content-between">
			<label nz-checkbox (ngModelChange)="changeDisability(item.key)" [formControlName]="item.key">
				<span class="title">{{ item.key }}</span>
			</label>
			<metro-form-input class="standard mb-3" type="text" ph="Amount" [formControlName]="item.key + 'Comment'">
			</metro-form-input>
		</div>
	</div>

	<metro-membership-form-text class="standard mt-3"
															label="Specify the species"
															ph="Please specify the species" formControlName="species">
	</metro-membership-form-text>



</div>

