<div class="d-flex justify-content-between">
	<h1 class="mt-2 mb-5">{{institute?.shortName}} - IT facilities </h1>
	<div>
			<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable"
			[ngClass]="{'disabled': eServicesForm.invalid}">
				<span>SAVE</span>
				<i nz-icon nzType="icons:edit" class="ml-4"></i>
			</button>

			<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
				<span>EDIT</span>
			</button>
	</div>
</div>
<div>
	<form action="" [formGroup]="eServicesForm" *ngIf="showForm">
		<h2>IT staff</h2>
		<div class="relative mb-15">
			<h3 class="mb-3">Do you have and IT department/division in your organisation?</h3>

				<nz-radio-group formControlName="itDivisions" >
					<label nz-radio [nzValue]="true">YES</label>
					<label nz-radio [nzValue]="false">NO</label>
				</nz-radio-group>

				<metro-form-input *ngIf="eServicesForm.controls.itDivisions.value"
				label="Provide the name for dept/div"
				class="standard mt-3 w-100" type="text" ph="Provide the name for dept/div" formControlName="itDivisionsComment"
				[control]="eServicesForm.controls.itDivisionsComment">
			</metro-form-input>

	</div>

	<div class="relative mb-15">
		<h3 class="mb-3">Do you use any external to your organisation IT support or maintenance services?</h3>

			<nz-radio-group formControlName="externalSupport" class="mb-3" >
				<label nz-radio [nzValue]="true">YES</label>
				<label nz-radio [nzValue]="false">NO</label>
			</nz-radio-group>

			<metro-membership-form-text *ngIf="eServicesForm.controls.externalSupport.value" class="standard w-100"
			label ="Please describe the external IT support or maintenance services."
			ph="Please describe the external IT support or maintenance services." formControlName="externalSupportComment"
			[control]="eServicesForm.controls.externalSupportComment"></metro-membership-form-text>

</div>

		<div class="relative mb-15">
			<nz-table
				[nzFrontPagination]="false"
				[nzData]="true">
				<thead>
				<tr>
					<th></th>
					<th>n° min</th>
					<th>n° max</th>
					<th>FTE min</th>
					<th>FTE max</th>
				</tr>
				</thead>
				<tbody>
					<tr>
						<td class="w-28">
							IT Staff working in the institute
						</td>
						<td class="w-18">
							<div class="mb-2">
								<metro-form-input class="standard" type="text"
									ph="Number" formControlName="workingItMin"  [control]="eServicesForm.controls.workingItMin">
								</metro-form-input>
							</div>
						</td>
						<td class="w-18">
							<div class="mb-2">
								<metro-form-input class="standard" type="text"
									ph="Number" formControlName="workingItMax"  [control]="eServicesForm.controls.workingItMax"
							></metro-form-input>
							</div>
						</td>
						<td class="w-18">
							<div class="mb-2">
								<metro-form-input class="standard" type="text"
									ph="Number" formControlName="workingItMinFTE"  [control]="eServicesForm.controls.workingItMinFTE"
							></metro-form-input>
							</div>
						</td>
						<td class="w-18">
							<div class="mb-2">
								<metro-form-input class="standard" type="text"
									ph="Number" formControlName="workingItMaxFTE"  [control]="eServicesForm.controls.workingItMaxFTE"
							></metro-form-input>
							</div>
						</td>
					</tr>
				</tbody>
			</nz-table>
		</div>

		<div class="mt-15">FTEs dedicated to METROFOOD-RI of IT Staff</div>

		<metro-form-input class="standard" type="text"
			ph="Number" formControlName="metroFoodFTEIt"  [control]="eServicesForm.controls.metroFoodFTEIt"
	></metro-form-input>

		<h2 class="mt-15 mb-7">Data Management Plan</h2>

		<nz-radio-group formControlName="managementPlan" class="mb-3" >
			<label nz-radio [nzValue]="'TRUE'">YES</label>
			<label nz-radio [nzValue]="'PLANNED'">Planned in the next 3 years</label>
			<label nz-radio [nzValue]="'FALSE'">NO</label>
			<label nz-radio [nzValue]="">I don't know</label>
		</nz-radio-group>

		<metro-membership-form-text class="standard mt-3" *ngIf="eServicesForm.controls.managementPlan.value === 'TRUE' "
		label="Please describe briefly the DMP"
		ph="Please describe briefly the DMP" formControlName="managementPlanComment"
		[control]="eServicesForm.controls.managementPlanComment">
	</metro-membership-form-text>

		<h2 class="mt-7 mb-7">E-needs</h2>

		<div>Indicate if you have identified any e-needs in your institute, that are related to the METROFOOD-RI operations and can be addressed by the e-RI. These can be for example datasets, data repositories, software ideas you do not have the ability to develop and maintain yourselves, servers needed to host and run software, etc.</div>

		<metro-membership-form-text class="standard mt-3"
			ph="Please describe e-needs in your institute" formControlName="needs"
			[control]="eServicesForm.controls.needs">
		</metro-membership-form-text>

		<h2 class="mt-7 mb-7">Net value of pre-existing e-resources</h2>

		<div>Net value of pre-existing e-resources (e.g., Hardware, Data Base/Data collection, Calculation/Data Processing, Modeling/Data integration, e-learning platforms) dedicated to METROFOOD-RI Note: referring <span class="text-underline">only to the e-RI</span></div>

		<nz-radio-group formControlName="preExistingTotNetIsEstimate">
			<label nz-radio [nzValue]="true" class="my-3">
				The TOT Net value as of 31 December 2020 will be declared
			</label>

			<div class="px-9" [hidden]="eServicesForm.controls.preExistingTotNetIsEstimate.value !== true">
				<p class="text-primary note-text">
					The Net Value is equal to the cost of acquisition/total value of all IT facilities purchased less the accumulated accounting depreciation/amortisation, or in other words, net value is the total value of the invoice without the depreciation accumulated so far
				</p>

				<div nz-row nzJustify="space-between">
					<div nz-col nzSpan="9">
						<label class="input-label">TOT Net value</label>
						<div class="input-wrapper">
							<metro-form-input class="standard" type="text" unit="thousands"
																ph="Amount" formControlName="preExistingTOTNet" [control]="eServicesForm.controls.preExistingTOTNet"
							></metro-form-input>
							<div class="input-metrics" [ngClass]="{'editable': !eServicesForm.controls.preExistingTOTNet.disabled}" >
								<nz-select  class="select" formControlName="preExistingTOTNetCurr">
									<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
								</nz-select>
							</div>
						</div>
					</div>

					<div nz-col nzSpan="9">
						<label class="input-label">Average % dedicated to METROFOOD-RI</label>
						<div class="input-wrapper">
							<metro-form-input class="standard" type="text" unit="thousands" ph="Amount"
																formControlName="preExistingAveragePercent" [control]="eServicesForm.controls.preExistingAveragePercent"
							></metro-form-input>
							<div class="input-metrics" [ngClass]="{'editable': !eServicesForm.controls.preExistingAveragePercent.disabled}" >
								<div>%</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<label nz-radio [nzValue]="false">
				The estimation of the TOT value DEDICATED TO METROFOOD-RI (as of 31 December 2020) will be declared
			</label>

			<div class="mt-5 px-9" [hidden]="eServicesForm.controls.preExistingTotNetIsEstimate.value !== false">
				<label class="input-label">Estimation of the TOT value DEDICATED TO  METROFOOD-RI (as of 31 December 2020)</label>
				<div class="input-wrapper">
					<metro-form-input class="standard" type="text" unit="thousands" ph="Amount"
														formControlName="preExistingTOTNet" [control]="eServicesForm.controls.preExistingTOTNet"
					></metro-form-input>
					<div class="input-metrics" [ngClass]="{'editable': !eServicesForm.controls.preExistingTOTNet.disabled}" >
						<nz-select  class="select" formControlName="preExistingTOTNetCurr">
							<nz-option *ngFor="let curr of currency" [nzValue]="curr" [nzLabel]="curr"></nz-option>
						</nz-select>
					</div>
				</div>
			</div>
		</nz-radio-group>

	</form>

</div>

