import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Facility, FacilityHR } from '@metro-membership/data-access';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacilityService } from '../../data/services/facility.service';
import { CustomValidator } from '../../shared/validators/percentage.validator';
import { HttpParams } from '@angular/common/http';
@Component({
	selector: 'metro-membership-facility-hr',
	templateUrl: './facility-hr.page.html',
	styleUrls: ['./facility-hr.page.less']
})
export class FacilityHrPage {
	facilityId: string;
	facilityHr: FacilityHR;
	hrForm: FormGroup;
	showForm = false;
	unSubscribe = new Subject();
	isEditable = false;
	constructor(private formBuilder: FormBuilder, private facilityService: FacilityService, private activatedRoute: ActivatedRoute) {
		this.activatedRoute.parent.params
		.pipe(takeUntil(this.unSubscribe))
		.subscribe(params => {
			this.facilityId = params.facilityId;
			this.buildForm();
			this.getfacilityHr();
		});
	}
	setForm()  {
		this.hrForm.setValue({
			facilityId: this.facilityHr?.facilityId || '',
			workingScientistsMin: this.facilityHr?.workingScientistsMin || null,
			workingScientistsMax: this.facilityHr?.workingScientistsMax  || null,
			workingScientistsMinFTE: this.facilityHr?.workingScientistsMinFTE || null,
			workingScientistsMaxFTE: this.facilityHr?.workingScientistsMaxFTE  || null,
			workingLabTechniciansMinFTE: this.facilityHr?.workingLabTechniciansMinFTE || null,
			workingLabTechniciansMaxFTE: this.facilityHr?.workingLabTechniciansMaxFTE || null,
			workingLabTechniciansMin: this.facilityHr?.workingLabTechniciansMin || null,
			workingLabTechniciansMax: this.facilityHr?.workingLabTechniciansMax || null,
			workinTempStaffMin: this.facilityHr?.workinTempStaffMin || null,
			workinTempStaffMax: this.facilityHr?.workinTempStaffMax || null,
			workinTempStaffMinFTE: this.facilityHr?.workinTempStaffMinFTE || null,
			workinTempStaffMaxFTE: this.facilityHr?.workinTempStaffMaxFTE || null,
			metroFoodFTEScientists: this.facilityHr?.metroFoodFTEScientists || null,
			metroFoodFTELabTechnicans: this.facilityHr?.metroFoodFTELabTechnicans || null,
			metroFoodFTTempStaff: this.facilityHr?.metroFoodFTTempStaff || null,
		})
	}
	buildForm() {
		this.hrForm = this.formBuilder.group({
			facilityId: [this.facilityId, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()])],

			workingScientistsMin: [null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],
			workingScientistsMax: [ null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],

			workingScientistsMinFTE: [ null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],
			workingScientistsMaxFTE: [ null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],

			workingLabTechniciansMinFTE: [ null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],
			workingLabTechniciansMaxFTE: [ null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],

			workingLabTechniciansMin: [ null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],
			workingLabTechniciansMax: [ null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],

			workinTempStaffMin: [ null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],
			workinTempStaffMax: [ null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],

			workinTempStaffMinFTE: [null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],
			workinTempStaffMaxFTE: [null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],

			metroFoodFTEScientists: [null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],
			metroFoodFTELabTechnicans: [null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],
			metroFoodFTTempStaff: [null, Validators.compose([CustomValidator.digitsValidator(), CustomValidator.fractionsValidator()]) ],
		});

		this.hrForm.disable();
		this.showForm = true;

	}

	getfacilityHr() {
		const facilityId = parseInt(this.facilityId, 10)
		this.facilityService.fetchFacilityHr(facilityId).toPromise().then((hr: FacilityHR) => {
			this.facilityHr = hr;
			this.setForm();
		});
	}

	onSave() {
		if (this.facilityHr?.id) {
			this.editfacilityHr();
		} else {
			this.addfacilityHr();
		}
		this.isEditable = false;
	}

	editfacilityHr() {
		const formData = this.hrForm.getRawValue();
		this.facilityService.editFacilityHr(formData, this.facilityHr.facilityId).subscribe(res => {
			this.hrForm.disable();
			this.getfacilityHr();
		});
	}

	addfacilityHr() {
		const formData = this.hrForm.getRawValue() as FacilityHR;
		delete formData.id;
		this.facilityService.addFacilityHr(formData).subscribe(res => {
			this.hrForm.disable();
			this.getfacilityHr();
		});
	}

	onEdit() {
		this.hrForm.enable();
		this.isEditable = true;
	}

}
