import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ResourcesPage } from './resources/resources.page';
import { ResourcesRoutingModule } from './resources-routing.module';
import { ResourcesGeneralComponent } from './resources-general/resources-general.component';
import { ResourcesDetailsComponent } from './resources-details/resources-details.component';
import { ResourcesAdditionalComponent } from './resources-additional/resources-additional.component';
import { ResourcesDatabaseComponent } from './resources-additional/resources-database/resources-database.component';
import { ResourcesHardwareComponent } from './resources-additional/resources-hardware/resources-hardware.component';
import { ResourcesSoftwareComponent } from './resources-additional/resources-software/resources-software.component';
import { ResourcesAccessibilityComponent } from './resources-accessibility/resources-accessibility.component';

@NgModule({
	declarations: [
		ResourcesPage,
		ResourcesGeneralComponent,
		ResourcesDetailsComponent,
		ResourcesAdditionalComponent,
		ResourcesSoftwareComponent,
		ResourcesHardwareComponent,
		ResourcesDatabaseComponent,
		ResourcesAccessibilityComponent
	],
	imports: [
		SharedModule,
		ResourcesRoutingModule
	]
})
export class ResourcesModule { }
