import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Facility, FacilityPersonRole, FacilitySite, Person } from '@metro-membership/data-access';
import { PersonRole } from '@metro-membership/data-access/enums';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../core/services/user.service';
import { FacilityService } from '../../data/services/facility.service';
import { ConfirmationService } from '../../shared/services/confirmation.service';
import { FacilitySiteComponent } from './site-modal/site-modal.component';

@Component({
	selector: 'metro-membership-facility-details',
	templateUrl: './facility-details.page.html',
	styleUrls: ['./facility-details.page.less']
})
export class FacilityDetailsPage {
	unSubscribe = new Subject();
	facilityForm: FormGroup;
	facility: Facility;
	facilityContactPerson: Person;
	primaryInvestigators: FacilityPersonRole[] = [];
	qualityManagers: FacilityPersonRole[] = [];
	editMode = false;
	activeUserRole = '';
	personRole = PersonRole;

	constructor(private activatedRoute: ActivatedRoute,
		private facilityService: FacilityService,
		private formBuilder: FormBuilder,
		private modal: NzModalService,
		private userService: UserService,
		private confirmationService: ConfirmationService) {

			this.buildForm();
			this.facilityForm.disable();
			this.activatedRoute.parent.params
			.pipe(takeUntil(this.unSubscribe))
			.subscribe(params => {
				this.getFacilities(params.facilityId);
			});
		}
		get sites(): FormArray {
			return this.facilityForm.get('sites') as FormArray;
		}
		buildForm(): void {
			this.facilityForm = this.formBuilder.group({
				facilityName: '',
			});
		}
		setForm(): void {
			this.facilityForm.setValue({
				facilityName: this.facility.facilityName,
			});
			this.facilityForm.disable();
		}

		get isEditable(): boolean {
			return this.facilityForm.enabled as boolean;
		}

		handlePersons():void {
			this.primaryInvestigators = this.facility.persons.filter(person => person.role === PersonRole.REPRESENTATIVE);
			this.qualityManagers = this.facility.persons.filter( person => person.role === PersonRole.QUALITY_MANAGER)
		}

		save(): void {
			const facilityFormData = this.facilityForm.getRawValue();
			const facilityBody =  { ...this.facility, facilityName: facilityFormData.facilityName,
				sites: facilityFormData.sites } as Facility;

			this.facilityService.editFacility(facilityBody).subscribe(facilityBody => {
				this.facilityForm.disable();
			})
		}

		getFacilities(facilityId: string): void {
			const params: HttpParams = new HttpParams({
				fromObject: {
					join: ['persons','persons.person', 'sites','sites.sitePerson', 'institute']
				}
			})

			this.facilityService.fetchFacility(facilityId, params).subscribe(facilitiesJson => {
				this.activatedRoute.snapshot.data = { breadcrumb: facilitiesJson.facilityName };
				this.facility = facilitiesJson;
				this.setForm();
				this.handlePersons();
				this.getCurrentUserRole();
			});
		}
		getCurrentUserRole(): void {
			const user = this.userService.user.getValue();
			const instRole = user?.instituteRoles.find(role => role.instituteId === this.facility.institute.id);
			this.activeUserRole = instRole?.role;
		}
		onEdit(): void {
			this.editMode = !this.editMode;
			this.facilityForm.enable();
		}
		onAddSite() {
			const modal = this.modal.create({
				nzTitle: 'Add facility site',
				nzContent: FacilitySiteComponent,
				nzComponentParams: {
					facility: this.facility
				},
			});

			modal.afterClose.subscribe((result: {data : FacilitySite}) =>{
				if(result?.data) this.addSite(result.data);
			})
		}
		onEditSite(event: any, site: FacilitySite) {
			event.stopPropagation();
			const modal = this.modal.create({
				nzTitle: 'Edit facility site',
				nzContent: FacilitySiteComponent,
				nzComponentParams: {
					facility: this.facility,
					site: site
				},
			});

			modal.afterClose.subscribe((result: {data : FacilitySite}) =>{
				if(result?.data) this.editSite(result.data);
			})
		}
		onDeleteSite(event: any ,facilitySite: FacilitySite) {
			event.stopPropagation();
			this.confirmationService.showDeleteConfirm(
				'Delete Site',
				'Do you really want to delete this site ?',
				'Delete',
				() => this.deleteSite(facilitySite.id)
				);
			}
			deleteSite(id: number) {
				this.facilityService.deleteFacilitySite(id).subscribe(siteResponse => {
					this.getFacilities(this.facility.id.toString());
				})
			}
			addSite(facilitySite: FacilitySite) {
				delete facilitySite.id
				this.facilityService.addFacilitySite(facilitySite).subscribe(siteResponse => {
					this.getFacilities(this.facility.id.toString());
				});
			}
			editSite(facilitySite: FacilitySite) {
				this.facilityService.editFacilitySite(facilitySite, facilitySite.id ).subscribe(siteResponse => {
					this.getFacilities(this.facility.id.toString());
				});
			}
		}
