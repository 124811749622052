<form action="" [formGroup]="instituteForm" *ngIf="showForm">
	<!-- <h2 class="text-primary">{{ institute.shortName }}</h2> -->
	<div class="mt-4 d-flex">
		<input type="text" [ngClass]="{'editable': isEditable}" class="transparent transparent__title" formControlName="shortName">
		<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
			<span>EDIT</span>
			<i nz-icon nzType="icons:edit" class="ml-4"></i>
		</button>

		<button class="ml-5 d-flex align-items-center primary" (click)="edit()" *ngIf="isEditable">
			<span>SAVE</span>
		</button>
	</div>

	<div class="my-15">
		<div nz-row>
			<div nz-col nzSpan="8">
				<div>
					<h3 class="mb-4">Primary Investigator</h3>
					<metro-membership-person-info *ngIf="primaryInvestigator" [person] ="primaryInvestigator.person"></metro-membership-person-info>
				</div>
			</div>

			<div *ngIf="institute" nz-col nzSpan="8">
				<div class="mr-10 adress">
					<h3 class="mb-4">Address</h3>
					<ng-container *ngIf="isEditable">
						<input class="transparent editable" formControlName="address"
									[style.width.ch]="this.instituteForm.get('address').value.length + 1">
						<div class="d-flex">
							<input class="transparent editable" formControlName="zipCode" [style.width.ch]="this.instituteForm.get('zipCode').value.length  + 1">
							<input class="transparent editable" formControlName="city" [style.width.ch]="this.instituteForm.get('city').value.length  + 1" >,
							<input class="transparent editable" formControlName="country" [style.width.ch]="this.instituteForm.get('country').value.length  + 1">
						</div>
						<div class="d-flex">
							PIC no. <input class="transparent editable" formControlName="pic" [style.width.ch]=" this.instituteForm.get('pic').value?.length  + 1">
						</div>
					</ng-container>
					<ng-container *ngIf="!isEditable">
						<div>
							{{this.instituteForm.get('address').value}}
						</div>
						<div class="d-flex">
							<div>
								{{this.instituteForm.get('zipCode').value}}, &nbsp;
							</div>
							<div>
								{{this.instituteForm.get('city').value}}, &nbsp;
							</div>
							<div>
								{{this.instituteForm.get('country').value}}, &nbsp;
							</div>
						</div>
						<div>
							PIC no. &nbsp;	{{this.instituteForm.get('pic').value}}
						</div>
					</ng-container>
				</div>
			</div>
			<div class="map-layout" nz-col nzSpan="8">
				<metro-membership-map-layout [center]="center"></metro-membership-map-layout>
			</div>
		</div>
	</div>

	<div class="person-wrapper mt-8">
		<div class="mt-10 mb-4 d-flex">
			<h3>Deputy Investigator</h3>
			<button class="ml-5 d-flex align-items-center secondary" (click)="showDeputyModal()" *ngIf="isEditable && showPrimary">
				<span>EDIT</span>
				<i nz-icon nzType="icons:edit" class="ml-4"></i>
			</button>
		</div>
		<metro-membership-person-info *ngIf="deputyInvestigator" [person] ="deputyInvestigator.person"></metro-membership-person-info>

		<div *ngIf="!deputyInvestigator">
			None
		</div>
	</div>

	<div class="mt-8 person-wrapper">
		<div class="title mb-5 d-flex align-items-center">

			<span>Finances Data Managers:</span>

			<button class="ml-5 d-flex align-items-center secondary" (click)="showFinancialAssingModal()" *ngIf="isEditable && showDeputy">
				<span>ADD</span>
			</button>

		</div>
		<div class="d-flex flex-wrap"  *ngIf="financesManagers && financesManagers.length > 0">
			<metro-membership-person-info [deleteFcn]="deleteInstituteRole.bind(this)" class="mb-6 mr-10" *ngFor="let manager of financesManagers"
			[role]="manager"
			[showDelete] = "showDeputy && isEditable"
			[person] ="manager.person"></metro-membership-person-info>
		</div>
		<div *ngIf="financesManagers && financesManagers.length === 0">
			None
		</div>

	</div>

<!--	<div class="mt-8 person-wrapper">-->
<!--		<div class="title mb-5 d-flex align-items-center">-->

<!--			<span>Quality Managers:</span>-->
<!--			<button class="ml-5 d-flex align-items-center secondary"  (click)="showQualityAssignModal()" *ngIf="isEditable && showDeputy">-->
<!--				<span>ADD</span>-->
<!--			</button>-->

<!--		</div>-->

<!--		<div class="d-flex flex-wrap" *ngIf="qualityManagers && qualityManagers.length > 0">-->
<!--			<metro-membership-person-info [deleteFcn]="delteInstituteRole.bind(this)"  class="mb-6 mr-10" *ngFor="let manager of qualityManagers"-->
<!--			[role]="manager"-->
<!--			[showDelete] = "showDeputy && isEditable"-->
<!--			[person] ="manager.person"></metro-membership-person-info>-->
<!--		</div>-->
<!--		<div *ngIf="qualityManagers && qualityManagers.length === 0">-->
<!--			None-->
<!--		</div>-->

<!--	</div>-->

	<div class="mt-8 person-wrapper">
		<div class="title mb-5 d-flex align-items-center">

			<span>E-resources Managers:</span>

			<button class="ml-5 d-flex align-items-center secondary" (click)="showResourceAssingModal()" *ngIf="isEditable && showDeputy">
				<span>ADD</span>
			</button>
		</div>

		<div class="d-flex flex-wrap" *ngIf="resourceManagers && resourceManagers.length > 0">
			<metro-membership-person-info  class="mb-6 mr-10" *ngFor="let manager of resourceManagers"
																		 [deleteFcn]="deleteInstituteRole.bind(this)"
																		 [showDelete] = "showDeputy && isEditable"
																		[role]="manager" [person] ="manager.person"></metro-membership-person-info>
		</div>
		<div *ngIf="resourceManagers && resourceManagers.length === 0">
			None
		</div>

	</div>

	<div nz-row class="mt-5 mb-16 px-16 sub-views-buttons" nzJustify="space-between">
		<div nz-col nzFlex="0 0 200px">
			<button nz-button [routerLink]="['finances']" *ngIf="showFinances">Finances</button>
		</div>

		<div nz-col nzFlex="0 0 200px">
			<button nz-button [routerLink]="['e-services']" *ngIf="showDeputy">E-resources</button>
		</div>

		<div nz-col nzFlex="0 0 200px">
			<button nz-button [routerLink]="['plans']">Plans</button>
		</div>
	</div>

	<div *ngIf="institute?.departments">
		<h3 class="mb-4">Involved Departments and Divisions</h3>
		<div class="mb-4" *ngFor="let dep of institute.departments">
			<ng-container *ngIf="dep.shortName"><strong>{{dep.shortName}}</strong> - </ng-container>
			{{dep.fullName}}
		</div>
	</div>

	<h3 class="mt-16">Involved Staff</h3>
	<div class="my-5" *ngIf="institute?.instituteStaff">
		<div nz-row [nzGutter]="[24, 24]">
			<div *ngFor="let staff of institute.instituteStaff" nz-col nzSpan="12">
				<metro-membership-person-info *ngIf="staff?.name || staff?.surname || staff?.email"  [person]="staff" [biggerSpacing]="true"></metro-membership-person-info>
			</div>
		</div>
	</div>
	<a routerLink="/under-development">
		more...
	</a>

	<div class="mt-16">
		<h3>Physical facilities</h3>
		<metro-membership-facility-table *ngIf="institute"></metro-membership-facility-table>
	</div>

	<div class="mt-6">
		<h3>E-resources</h3>
		<metro-membership-resources-table *ngIf="institute"></metro-membership-resources-table>
	</div>
</form>
