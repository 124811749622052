export const serviceDetails = [
	{formKey: 'preparationOfRm', label: 'Preparation of RMs'},
	{formKey: 'storage', label: 'Storage'},
	{formKey: 'managementInterlaboratoryStudies', label: 'Management of interlaboratory studies'}
]

export const tasksServices = [
	{formKey: 'facilityInvolvedFeasibilityStudies', label: 'R&amp;D, feasibility studies'},
	{formKey: 'facilityInvolvedProduction', label: 'RM small-scale production'},
	{formKey: 'facilityInvolvedBigScaleProduction', label: 'RM big-scale production'},
	{formKey: 'facilityInvolvedStability', label: 'Stability studies'},
	{formKey: 'facilityInvolvedOther', label: 'Other'},
]

export const stability = [
	{formKey: 'stabilityTestLuminous', label: 'Luminous test'},
	{formKey: 'stabilityTestThermal', label: 'Thermal test'},
	{formKey: 'stabilityHumidityTest', label: 'Humidity test'},
	{formKey: 'stabilityOtherTest', label: 'Other'},

]


export const prepareRm = [
	{formKey: 'prepareRmSolid', label: 'Solid (dry powder or dehydrated)'},
	{formKey: 'prepareRmLiquid', label: 'Liquid'},
	{formKey: 'prepareRmGas', label: 'Gas'},
]

