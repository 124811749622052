export const generalTopics = [
	{ value: 'FOOD_STANDARDIZATION', label: 'Food standardization and harmonisation' },
	{ value: 'FOOD_QUALITY', label: 'Food quality' },
	{ value: 'FOOD_SAFETY', label: 'Food safety' },
	{ value: 'FUTURE_ANALYSIS', label: 'Future food analysis' },
	{ value: 'FUTURE_AGRICULTURE', label: 'Future agriculture' },
	{ value: 'FOOD_PREPARATION', label: 'Food preparation and storage' },
	{ value: 'DATA_MANAGEMENT', label: 'Data management' },
	{ value: 'E_LEARNING', label: 'e-learning' },
];


export const servicesTopics = [
	{ value: 'RM_PLANTS', label: 'METRO - RM Plants' },
	{ value: 'ANALYTICAL', label: 'METRO - Analytical labs' },
	{ value: 'PRIMARY', label: 'FOOD - Primary production' },
	{ value: 'KITCHEN_LABS', label: 'FOOD - Processing and Kitchen labs' },
	{ value: 'E_SERVICES', label: 'e-services' },
]
