import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	Institute,
	InstituteEService,
	InstituteFinances,
	InstitutePlan,
	InstituteQuality,
	InstituteResources
} from '@metro-membership/data-access';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EmailBody } from '../../../../../../libs/data-access/src/lib/models/email';
import { BreadcrumbsService } from '../../shared/components/breadcrumbs/breadcrumbs.service';
import { PaginatedData } from '../../shared/types/paginated-data';

@Injectable({
	providedIn: 'root'
})

export class InstituteService {
	institutes: Institute[] = []; // Review: This seems like a data-store pattern, but data-store might be too much here, maybe something like ngx-cacheable that is simple and does exactly this - a cache :)?
	institutesUpdate$: Subject<boolean> = new Subject();

	constructor(
		private http: HttpClient,
		private breadcrumbsService: BreadcrumbsService
	) { }

	fetchInstitutes(params: HttpParams): Observable<PaginatedData<Institute>>
	fetchInstitutes(): Observable<Institute[]>
	fetchInstitutes(params?: HttpParams) {
		if (!params) {
			return this.http.get<Institute[]>(`@institutes`).pipe(
				tap((institutes: Institute[]) => this.institutes = institutes)
			);
		} else {
			return this.http.get<PaginatedData<Institute>>(`@institutes`, { params });
		}
	}

	fetchInstitute(id: string, params?: HttpParams) {
		return this.http.get<Institute>(`@institutes/${id}`, { params }).pipe(
			// Review: Think if we want to update breadcrumb on every institute fetch or if you need to control it yourself (via function parameter)
			tap((institute: Institute) => this.breadcrumbsService.putBreadcrumbPart('institute', {
				id: institute.id,
				value: institute.shortName
			}))
		);
	}

	editInstitute(institute: Institute) {
		return this.http.patch<Institute>(`@institutes/${institute.id}`, institute);
	}

	replaceInstitute(institute: Institute) {
		return this.http.put<Institute>(`@institutes/${institute.id}`, institute);
	}

	fetchResources(params?: HttpParams) {
		return this.http.get<PaginatedData<InstituteResources>>(`@institute-resources`, { params });
	}

	fetchResource(resourceId: number, params?: HttpParams) {
		return this.http.get<InstituteResources>(`@institute-resources/${resourceId}`, { params } )
			.pipe(
				tap((resource: InstituteResources) => this.breadcrumbsService.putBreadcrumbPart('resource', {
					id: resource.id,
					value: resource.name
				}))
			);
	}

	addResource(resourceBody: InstituteResources) {
		return this.http.post<InstituteResources>(`@institute-resources`, resourceBody);
	}

	editResource(resourceBody: InstituteResources, id: number, params?: HttpParams) {
		return this.http.put<InstituteResources>(`@institute-resources/${id}`, resourceBody, { params });
	}

	deleteResource(resourceId: number, params?:HttpParams) {
		return this.http.delete(`@institute-resources/${resourceId}`, {params});
	}

	sendResourceContactAssignEmail(email: EmailBody) {
		return this.http.post<any>(`@institute-resources/reassign`, email );
	}

	fetchInstituteFinances(id:string, params?: HttpParams): Observable<InstituteFinances> {
		return this.http.get<InstituteFinances>(`@institute-finances/${id}`, { params });
	}

	fetchInstituteQualities(params?: HttpParams): Observable<InstituteQuality[]> {
		return this.http.get<InstituteQuality[]>(`@institute-quality`, { params });
	}

	addInstituteFinances(questions: InstituteFinances): Observable<InstituteFinances> {
		return this.http.post<InstituteFinances>(`@institute-finances/`, questions);
	}

	editInstituteFinances(questionBody: InstituteFinances, id: number): Observable<InstituteFinances> {
		return this.http.patch<InstituteFinances>(`@institute-finances/${id}`, questionBody);
	}

	addInstituteQuality(qualityForm: InstituteQuality): Observable<InstituteQuality> {
		return this.http.post<InstituteQuality>(`@institute-quality/`, qualityForm);
	}

	editInstituteQuality(qualityForm: InstituteQuality, instituteId: number): Observable<InstituteQuality> {
		return this.http.patch<InstituteQuality>(`@institute-quality/${instituteId}`, qualityForm);
	}

	fetchInstituteEServices(id: number, params?: HttpParams) : Observable<InstituteEService>{
		return this.http.get<InstituteEService>(`@institute-e-services/${id}`, { params });
	}

	addInstituteEServices(qualityForm: InstituteEService): Observable<InstituteEService> {
		return this.http.post<InstituteEService>(`@institute-e-services/`, qualityForm);
	}

	editInstituteEServices(qualityForm: InstituteEService, instituteId: number): Observable<InstituteEService> {
		return this.http.patch<InstituteEService>(`@institute-e-services/${instituteId}`, qualityForm);
	}

	fetchInstitutePlan(id: number, params?: HttpParams) : Observable<InstitutePlan>{
		return this.http.get<InstitutePlan>(`@institute-plan/${id}`, { params });
	}

	addInstitutePlan(planForm: InstitutePlan): Observable<InstitutePlan> {
		return this.http.post<InstitutePlan>(`@institute-plan/`, planForm);
	}

	editInstitutePlan(planForm: InstitutePlan, instituteId: number): Observable<InstitutePlan> {
		return this.http.patch<InstitutePlan>(`@institute-plan/${instituteId}`, planForm);
	}
}
