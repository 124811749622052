import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Facility, FacilityUpgrade } from '@metro-membership/data-access';
import { FacilityType, FacilityTypeTranslations } from '@metro-membership/data-access/enums';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacilityService } from '../../data/services/facility.service';
import { CustomValidator } from '../../shared/validators/percentage.validator';

@Component({
  selector: 'metro-membership-facility-upgrade-plans',
  templateUrl: './facility-upgrade-plans.page.html',
  styleUrls: ['./facility-upgrade-plans.page.less']
})
export class FacilityUpgradePlansPage {
	facilityType = FacilityType;
	facilityTypeTranslations = FacilityTypeTranslations;

	upgradeForm: FormGroup;
	unSubscribe = new Subject();
	facility: Facility;
	facilityUpgrade: FacilityUpgrade;
	facilityId = '';
	showForm = false;
	constructor(private fb: FormBuilder,
		private facilityService: FacilityService,
		private activatedRoute: ActivatedRoute) {
		this.activatedRoute.parent.params
		.pipe(takeUntil(this.unSubscribe))
		.subscribe(params => {
			this.facilityId = params.facilityId;
			this.buildForm();
			this.getFacility();
			this.getUpgrades();
		});
	}

	get isEditable() {
		return this.upgradeForm.enabled as boolean;
	}
	onEdit() {
		this.upgradeForm.enable();
	}
	getFacility() {
		this.facilityService.fetchFacility(this.facilityId).subscribe(facilityJson => {
			this.facility = facilityJson;
		});
	}
	onSave() {
		if (!this.upgradeForm.valid) {
			return true;
		}
		if (this.facilityUpgrade?.id) {
			this.editFacilityUpgrade();
		} else {
			this.addFacilityUpgrade();
		}
	}

	addFacilityUpgrade() {
		const formBody = this.upgradeForm.getRawValue() as FacilityUpgrade;
		this.facilityService.addFacilityUpgrade(formBody).subscribe(upgradeResponse => {
			this.facilityUpgrade = upgradeResponse;
			this.upgradeForm.disable();
		})
	}
	editFacilityUpgrade() {
		const formBody = this.upgradeForm.getRawValue() as FacilityUpgrade;
		this.facilityService.editFacilityUpgrade(formBody, this.facility.id).subscribe(upgradeResponse => {
			this.facilityUpgrade = upgradeResponse;
			this.upgradeForm.disable();
		});

	}
	getUpgrades() {
		const facilityId = parseInt(this.facilityId,0)
		this.facilityService.fetchFacilityUpgrade(facilityId).toPromise().then(facilitiesJson => {
			this.facilityUpgrade = facilitiesJson;
			this.setForm();
		});
	}

	setForm() {
		this.upgradeForm.patchValue({
			renewal: [this.facilityUpgrade?.renewal || false],
			newEquip: [this.facilityUpgrade?.newEquip || null],
			newLine: [this.facilityUpgrade?.newLine || false],
			newLine2: [this.facilityUpgrade?.newLine2 || null],
			newRealisation: [this.facilityUpgrade?.newRealisation || false],
			NewProcessingLine: [this.facilityUpgrade?.NewProcessingLine || null],
			NewKLRealisation: [this.facilityUpgrade?.NewKLRealisation || false],
			newKL: [this.facilityUpgrade?.newKL || null],
			newFacility: [this.facilityUpgrade?.newFacility || null],
		})
	}

	buildForm() {
		this.upgradeForm = this.fb.group({
			facilityId: this.facilityId,
			renewal: null,
			newEquip: null,
			newLine: null,
			newLine2: null,
			newRealisation: null,
			NewProcessingLine: null,
			NewKLRealisation: null,
			newKL:null,
			newFacility: null,
		});
		this.upgradeForm.disable();
		this.showForm = true;
	}
}
