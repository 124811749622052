import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Facility, Person } from '@metro-membership/data-access';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { FacilityService } from '../../data/services/facility.service';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
	selector: 'metro-membership-facility-people',
	templateUrl: './facility-people.page.html',
	styleUrls: ['./facility-people.page.less']
})
export class FacilityPeoplePage {
	subscriptions: Subscription[];
	facility: Facility;
	persons: Person[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private facilityService: FacilityService
	) {
		this.subscriptions = [
			this.activatedRoute.parent.params.subscribe(params => this.getFacilities(params.facilityId))
		]
	}

	getFacilities(facilityId: string) {
		const params = new HttpParams({
			fromObject: {
				filter: `id||eq||${facilityId}`,
				join: ['persons', 'persons.person', 'sites']
			}
		});
		this.facilityService.fetchFacilities(params).toPromise().then(facilitiesJson => {
			this.persons = facilitiesJson[0].persons;
		});
	}
}
