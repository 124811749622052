export const personnelQualities = [
	{ formKey: 'personnelTrainingQuality', label: 'Training about quality' },
	{ formKey: 'personnelTrainingSecurity', label: 'Training about security' },
	{ formKey: 'personnelTrainingTechnical', label: 'Technical training for analytical methods' },
	{ formKey: 'personnelTrainingUpdate', label: 'Upgrade course for agricultural machinery' },
	{ formKey: 'personnelTrainingInstruments', label: 'Training about use of instruments' },
	{ formKey: 'personnelTrainingTempSuper', label: 'Temporary supervised work' },
	{ formKey: 'personnelTrainingPerSuper', label: 'Permanent supervised work' },
	{ formKey: 'personnelTrainingQuiz', label: 'Quiz in relation to safety at work place' },
	{ formKey: 'personnelTrainingOther', label: 'Other (please specify in the comment box below)' },
]


export const qualityAssurance = [
	{ formKey: 'qualityAssuranceManual', label: 'Quality manual' },
	{ formKey: 'qualityAssuranceProcedure', label: 'Analytical procedure' },
	{ formKey: 'qualityAssuranceValidation', label: 'Method validation procedure' },
	{ formKey: 'qualityAssuranceControl', label: 'Quality control procedure' },
	{ formKey: 'qualityAssuranceQualification', label: 'Staff qualification procedure' },
	{ formKey: 'qualityAssuranceTraining', label: 'Staff training' },
	{ formKey: 'qualityAssuranceSuperVision', label: 'Staff supervision' },
	{ formKey: 'qualityAssuranceRingTests', label: 'Participation in International Ring Tests' },
	{ formKey: 'qualityAssuranceCertRm', label: 'Analysis of Certified Reference Materials' },
	{ formKey: 'qualityAssuranceAnalyticalStand', label: 'Use of certified analytical standards' },
	{ formKey: 'qualityAssuranceBlanks', label: 'Blanks' },
	{ formKey: 'qualityAssuranceSurrogates', label: 'Multiple Internal Standards (surrogates)' },
	{ formKey: 'qualityAssuranceInstrumental', label: 'Linearity of instrumental response' },
	{ formKey: 'qualityAssuranceIndependentStand', label: 'Calibration curve check using independent standards' },
	{ formKey: 'qualityAssuranceRepeatability', label: 'Measurement of repeatability (inter day and intra day) ' },
	{ formKey: 'qualityAssuranceRecovery', label: 'Measurement of recovery' },
	{ formKey: 'qualityAssuranceCalibration', label: 'Instrument calibration' },
	{ formKey: 'qualityAssuranceOther', label: 'Other (please specify in the comment box below)' },
]

export const sop = [
	{ formKey: 'SOPResearchLabs', label: 'Safety manual for the staff of research laboratories' },
	{ formKey: 'SOPManagementLabEq', label: 'Management of laboratory equipment' },
	{ formKey: 'SOPCalibrationProcedure', label: 'Instrument calibration procedure' },
	{ formKey: 'SOPIntroductionLabEq', label: 'Introduction of a laboratory equipment' },
	{ formKey: 'SOPWorkInstruction', label: 'Work instruction for preparation of standard solutions' },
	{ formKey: 'SOPOther', label: 'Other (please specify in the comment box below)' },
]

export const guidance = [
	{ formKey: 'guidanceEurachemTraceability', label: 'Eurachem (traceability in chemical measurements)' },
	{ formKey: 'guidanceEurachemQuantify', label: 'Eurachem (quantifying uncertainty in analytical measurement)' },
	{ formKey: 'guidanceISO30', label: 'ISO guide 30 (terms and definitions used in connection with reference materials)' },
	{ formKey: 'guidanceISO31', label: 'ISO guide 31 (contents of certificates of reference materials)' },
	{ formKey: 'guidanceISO32', label: 'ISO guide 32 (calibration of chemical analysis and use of certificate reference materials)' },
	{ formKey: 'guidanceISO33', label: 'ISO guide 33 (Use of certificate reference materials)' },
	{ formKey: 'guidanceISO34', label: 'ISO guide 34 (quality system guidelines for the production of reference materials)' },
	{ formKey: 'guidanceISO35', label: 'ISO guide 35 (Certification of reference materials – general and statistic principles)' },
	{ formKey: 'guidanceISO80', label: 'ISO guide 80 (Guidance for the in-house preparation of quality control materials (QCMs))' },
	{ formKey: 'guidanceOther', label: 'Other (please specify)' },
]

export const accreditation = [
	{ formKey: 'accreditationISO15189', label: 'ISO 15189' },
	{ formKey: 'accreditationISO17020', label: 'ISO/IEC 17025' },
	{ formKey: 'accreditationISO17043', label: 'ISO/IEC 17043' },
	{ formKey: 'accreditationISO17065', label: 'ISO/IEC 17065' },
	{ formKey: 'accreditationISO17034', label: 'ISO 17034' },
	{ formKey: 'accreditationOther', label: 'Other (please specify in the comment box below)' },
]
