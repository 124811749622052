<nz-form-item>
	<nz-form-control class="input-field">
		<label class="mb-2">{{ label }}</label>

			<textarea rows="4"
			nz-input
			#input
			class="{{ class }}"
			[formControl]="control"
			(change)="onChange($event, input.value)"
			(focusout)="handleFocusOut()"
			[value]="control.value"
			placeholder="{{ ph }}"
			[disabled]="control.disabled"
			></textarea>

			<div *ngIf="!control.valid" class="invalid-message">{{ getErrorMessage() }}</div>
	</nz-form-control>
</nz-form-item>
