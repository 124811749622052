import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserService } from '../../services/user.service';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'metro-membership-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopMenuComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[];
	firstInstituteId = null;
	searchType: BehaviorSubject<string> = new BehaviorSubject<string>('');

	constructor(
		private router: Router,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.subscriptions = [
			this.userService.user.pipe(
				filter(user => !!user)
			).subscribe(user => {
				// Review: this probably needs some rethinking and also we should decide if My-institute works based on app-roles or institution-roles
				const institute = user.instituteRoles[0]?.instituteId.toString();
				const facility = user.facilityRoles[0]?.facility.instituteId.toString();
				this.firstInstituteId = institute || facility;
				if(!this.firstInstituteId) this.firstInstituteId = 'unassigned';
			}),

			this.router.events.pipe(
				filter(event => event instanceof NavigationEnd)
			).subscribe((event: NavigationEnd) => {
				const parts = event.url.split('/search/');
				const isUpdatingType = parts.length > 1 && ['person', 'institute', 'facility', 'e-resource'].includes(parts[1]);
				this.searchType.next(isUpdatingType ? ' ' + parts[1] : '');
			})
		];
	}

	ngOnDestroy() {
		this.searchType.complete();
	}
}
