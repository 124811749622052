import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'metro-membership-resources-database',
  templateUrl: './resources-database.component.html',
  styleUrls: ['./resources-database.component.less']
})
export class ResourcesDatabaseComponent {
	dataFormat: string[] = ['XML', 'ZIP', 'HTML', 'CSV', 'TXT', 'SQL', 'SCII', 'JSON', 'Other', 'Not applicable']
	@Input() resourcesForm: FormGroup;

	detectOtherOption(control: AbstractControl): boolean {
		const value = control.value as string[];
		return value?.indexOf('Other') > -1 || false;
	}
}
