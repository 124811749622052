import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import * as olProj from 'ol/proj';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import View from 'ol/View';


@Component({
  selector: 'metro-membership-map-layout',
  templateUrl: './map-layout.component.html',
  styleUrls: ['./map-layout.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapLayoutComponent implements OnInit, OnChanges {
  @Input() center: { lat: number, lng: number } = null;
  map: Map = null;
  vectorSource: VectorSource;
  vectorLayer: VectorLayer;
  marker: Feature;

  constructor(private cdr: ChangeDetectorRef,
    private ngZone: NgZone) {

    }

    ngOnInit(): void {
      this.createMarkerLayer();
      this.createMap();
    }

    ngOnChanges(change: SimpleChanges) {
      this.center.lng = parseFloat(change.center?.currentValue?.lng);
      this.center.lat = parseFloat(change.center?.currentValue?.lat);

      if(this.map)  {
        this.createMarkerLayer();

        this.map.getView().setCenter(olProj.transform([this.center.lng, this.center.lat], 'EPSG:4326', 'EPSG:3857'));
        this.map.addLayer(this.vectorLayer)
        this.map.getView().setZoom(15);

      }
    }

    createMap() {
      this.map = new Map({
        target: 'map',
        layers: [
          new TileLayer({
            source: new OSM()
          }),
          this.vectorLayer
        ],
        view: new View({
          center: olProj.fromLonLat([this.center.lng, this.center.lat]),
          zoom: 15
        })
      });
    }

    createMarkerLayer() {
      this.marker = new Feature({
        geometry: new Point(fromLonLat([this.center.lng, this.center.lat]), )
      });
      const mysvg = './../../../assets/icons/map-marker.svg'
      // mysvg.src = 'data:image/svg+xml,' + encodeURIComponent(marker);

      const style = new Style({
        image: new Icon({
          src: mysvg,
					size: [52, 52],
					color: '#FFF'
        })
      });

      this.marker.setStyle(style);

      this.vectorSource = new VectorSource({
        features: [this.marker]
      });

      this.vectorLayer =  new VectorLayer({
        source: this.vectorSource
      });
    }
  }
