import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
	constructor(private modal: NzModalService) {}

	showDeleteConfirm(title: string, content: string, okText: string, okFn): void {
		this.modal.confirm({
			nzTitle: title,
			nzContent: content,
			nzOkText: okText,
			nzOkType: 'primary',
			nzOnOk: okFn,
			nzCancelText: 'Cancel',
			nzOnCancel: () => {}
		});
	}
}
