import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnderDevelopmentPage } from '../default/under-development/under-development.page';
import { SearchFacilityPage } from './search-facility/search-facility.page';
import { SearchInstitutePage } from './search-institute/search-institute.page';

export const searchRoutes: Routes = [
	{
		path: 'person',
		component: UnderDevelopmentPage,
		data: { breadcrumb: 'Search person' }
	},
	{
		path: 'institute',
		component: UnderDevelopmentPage,
		data: { breadcrumb: 'Search institute' }
	},
	{
		path: 'facility',
		component: UnderDevelopmentPage,
		data: { breadcrumb: 'Search facility' }
	},
	{
		path: 'e-resource',
		component: UnderDevelopmentPage,
		data: { breadcrumb: 'Search e-resource' }
	},
];

@NgModule({
	imports: [RouterModule.forChild(searchRoutes)],
	exports: [RouterModule]
})
export class SearchRoutingModule {}
