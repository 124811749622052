
export enum EquipmentTranslations {
	instrumentationChromatography = 'Chromatography, separation processes and detectors for organic analysis',
	instrumentationElementalAnalysis = 'Elemental analysis',
	instrumentationSpeciationAnalysis = 'Speciation analysis',
	instrumentationIsotopicAnalysis = 'Isotopic analysis',
	instrumentationMolecularSpectroscopy = 'Molecular spectroscopy',
	instrumentationNanoparticleAnalysis = 'Nanoparticle analysis',
	instrumentationSurfaceAnalysis = 'Surface analysis',
	instrumentationSolidSurfaceStateAnalysis = 'Solid-state (surface) analysis',
	instrumentationSolidStructureStateAnalysis = 'Solid-state (structure) analysis',
	instrumentationThermalAnalysis = 'Thermal analysis / Thermal properties',
	instrumentationRheology = 'Rheology',
	instrumentationSensoryAnalysis = 'Sensory analysis',
	instrumentationBiotechnology = 'Biotechnology',
	instrumentationMicrobiology = 'Microbiology',
	chromatographyGPC = 'Gel permeation chromatography (GPC)',
	chromatographyTLC = 'Thin layer chromatography (TLC)',
	chromatographyPyGC = 'Pyrolysis gas chromatography (PyGC)',
	chromatographyGPCLALLS = 'Gel permeation chromatography - Scattering method (GPC-LALLS)',
	chromatographyCE = 'Capillary electrophoresis (CE)',
	chromatographyLC = 'Liquid chromatography (LC)',
	chromatographyGC ='Gas chromatography (GC)',
	chromatographyIC = 'Ionic chromatography (IC)',
	chromatographySFC = 'Supercritical fluid chromatography (SFC)',
	chromatographyOther = 'Other (please specify)',
	ceDetectorsAerosolDetector = 'Aerosol-based detector ',
	ceDetectorsCDD = 'Circular dichroism detector (CDD)',
	ceDetectorsECD = 'Electrochemical detector (ECD)',
	ceDetectorsFLD = 'Fluorescence detector (FLD)',
	ceDetectorsMS = 'Single quadrupole mass-spectrometry detector (MS)',
	ceDetectorsMSMS = 'Triple quadrupole mass-spectrometry detector (MS/MS)',
	ceDetectorsRID = 'Refractive index detector (RID)',
	ceDetectorsUV = 'UV-Vis',
	ceDetectorsMSn = 'Ion trap mass spectrometer (MSn)',
	ceDetectorsHRMS = 'High resolution mass spectrometer (HRMS)',
	ceDetectorsOther = 'Other (please specify)',
	lcDetectorsCDD = 'Circular dichroism detector (CDD)\n',
	lcDetectorsECD = 'Electrochemical detector (ECD)',
	lcDetectorsFLD = 'Fluorescence detector (FLD)',
	lcDetectorsELSD = 'Evaporative light scattering detector (ELSD)',
	lcDetectorsDAD = 'Diode array detector (DAD)',
	lcDetectorsMS = 'Single quadrupole mass-spectrometry detector (MS)',
	lcDetectorsMSMS = 'Triple quadrupole mass-spectrometry detector (MS/MS)',
	lcDetectorsOrbitrap = 'Orbitrap',
	lcDetectorsTOF = 'TOF',
	lcDetectorsQTOF = 'QTOF',
	lcDetectorsRID = 'Refractive index detector (RID)',
	lcDetectorsUV = 'UV-Vis',
	lcDetectorsMSn = 'Ion trap mass spectrometer (MSn)',
	lcDetectorsHRMS = 'High resolution mass spectrometer (HRMS)',
	lcDetectorsConductivity = 'Conductivity',
	lcDetectorsRadioFlow = 'Radio flow',
	lcDetectorsOther = 'Other (please specify)',
	gcDetectorsDID = 'Discharge ionization detector (DID)',
	gcDetectorsELCD = 'Electrolytic conductivity detector (ELCD)',
	gcDetectorsECD = 'Electron capture detector (ECD)',
	gcDetectorsFID = 'Flame ionisation detector (FID)',
	gcDetectorsFPD = 'Flame photometric detector (FPD)',
	gcDetectorsHID = 'Helium ionisation detector (HID)',
	gcDetectorsPID = 'Photo-ionisation detector (PID)',
	gcDetectorsPDD = 'Pulsed discharge ionisation detector (PDD)',
	gcDetectorsTID = 'Termoionic ionisation detector (TID)',
	gcDetectorsMS = 'Single quadrupole mass-spectrometry detector (MS)',
	gcDetectorsMSMS = 'Triple quadrupole mass-spectrometry detector (MS/MS)',
	gcDetectorsMSn = 'Ion trap mass spectrometer (MSn)\n',
	gcDetectorsHRMS = 'High resolution mass spectrometer (HRMS)',
	gcDetectorsTCD = 'Thermal conductivity (TCD)\n',
	gcDetectorsOther = 'Other (please specify)',
	elementalFAAS = 'Flame atomic absorption spectrometer (F-AAS)',
	elementalETAAS = 'Electrothermal atomic absorption spectrometer (ET-AAS)',
	elementalCVAAS = 'Cold vapor atomic absorption spectrometer (CV-AAS)',
	elementalHGAAS = 'Hydride generation atomic absorption spectrometer (HG-AAS)',
	elementalAMA = 'Advanced mercury analyzer (AMA)',
	elementalICPAES = 'Inductively coupled plasma - atomic emission spectrometer (ICP-AES)',
	elementalLAICPAES = 'Laser Ablation - Inductively coupled plasma - atomic emission spectrometer (LA-ICP-AES)',
	elementalICPMS = 'Inductively coupled plasma - mass spectrometer (ICP-MS)',
	elementalLAICPMS = 'Laser Ablation - Inductively coupled plasma - mass spectrometer (LA-ICP-MS)',
	elementalICPMSMS = 'Triple/multiple quadrupole Inductively coupled plasma - mass spectrometer (ICP-MS/MS)',
	elementalHRICPMS = 'High Resolution - Inductively coupled plasma - mass spectrometer (HR-ICP-MS)',
	elementalXRF = 'X-Ray fluorescence (XRF)',
	elementalMicroXRF = 'micro - X-Ray fluorescence (micro-XRF)\n',
	elementalTXRF = 'Total reflection - X-Ray fluorescence (TXRF)',
	elementalNAA = 'Neutron activation analysis (NAA)',
	elementalOther = 'Other (please specify)',
	speciationHPLCICPAES = 'HPLC-Inductively coupled plasma atomic emission spectrometer (HPLC-ICP-AES)',
	speciationHPLCICPMS = 'HPLC-Inductively coupled plasma - mass spectrometer (HPLC-ICP-MS)',
	speciationHPLCAFS = 'HPLC-Atomic fluorescence spectrometer (HPLC-AFS)',
	speciationLCMS = 'LC-Mass spectrometer (LC-MS)',
	speciationGCICPMS = 'GC-Inductively coupled plasma - mass spectrometer (GC-ICP-MS)',
	speciationGCMS = 'GC-Mass spectrometer (GC-MS)',
	speciationCEICPMS = 'CE-Inductively coupled plasma - mass spectrometer (CE-ICP-MS)',
	speciationElectrochemistryTechniques = 'Electrochemistry techniques',
	speciationOther = 'Other (please specify)',
	isotopicIRMS = 'Isotope ratio mass spectrometer (IRMS)',
	isotopicMCICPMS = 'Multicollector Inductively coupled plasma mass spectrometer (MC-ICP-MS)',
	isotopicTandemMassSpectrometer = 'Tandem-Mass spectrometer',
	isotopicTIMS = 'Thermal ionization mass spectrometer (TIMS)',
	isotopicNMR = 'Nuclear magnetic resonance (NMR)',
	isotopicNAA = 'Neutron activation analysis (NAA)',
	isotopicOther = 'Other (please specify)',
	molecularUVVis = 'UV-Vis spectroscopy',
	molecularIR = 'Infrared spectroscopy (IR)',
	molecularFTIR = 'Fourier transform Infrared spectroscopy (FT-IR)\n',
	molecularRamanSpectroscopy = 'Raman spectroscopy',
	molecularFluorescenceSpectroscopy = 'Fluorescence spectroscopy\n',
	molecularEPR = 'Electro spin resonance (EPR)',
	molecularNMR = 'Nuclear magnetic resonance (NMR)',
	molecularOther = 'Other (please specify)',
	nanoparticleA4FMALLS = 'Asimmetric flow filed fractionation - multi-angle laser light scattering (A4F-MALLS)',
	nanoparticleA4FICPMS = 'Asimmetric flow filed fractionation - inductively coupled plasma mass spectrometer (A4F-ICP-MS)',
	nanoparticleSPICPMS = 'Single particle - inductively coupled plasma mass spectrometer (SP-ICP-MS)',
	nanoparticleDLS = 'Dynamic light scattering (DLS)',
	nanoparticleElectronMicroscopeTechniques = 'Electron microscope techniques (SEM, TEM, etc.)',
	nanoparticleBET = 'Nitrogen absorption analysis (BET)',
	nanoparticleELS = 'Electrophoretic light scattering (ELS)',
	nanoparticleOther = 'Other (please specify)\n',
	surfaceAESSAM = 'Auger electron spectrometer (AES/SAM)',
	surfaceAPFIM = 'Atom probe field ion microscope (APFIM)',
	surfaceAPMAXMA = 'Electron probe X-ray micro-analyzer (APMA/XMA)',
	surfaceFESEMEDX = 'Field emission scanning electron microscope (FE-SEM-EDX)',
	surfaceSIMS = 'Secondary ion mass spectrometer (SIMS)',
	surfaceTDSMS = 'Thermal desorption mass spectrometer (TDS-MS)',
	surfaceTOFSIMS = 'Time-of-flight secondary ion mass spectrometer (TOF-SIMS)',
	surfaceXPSESCA = 'X-ray photoelectron spectrometer (XPS-ESCA)',
	surfaceLowLevelAlphaParticle = 'Low level alpha particle measuring instrument ',
	surfaceOther = 'Other (please specify)',
	solidSurfaceAFM = 'Atomic force microscope (AFM)',
	solidSurfaceSEM = 'Scanning electron microscope (SEM)',
	solidSurfaceTEM = 'Transmission electron microscope (TEM)',
	solidSurfaceImageAnalysisSystem =  'Image analysis system ',
	solidSurfaceOther =  'Other (please specify)',
	microscopesCM =  'Confocal microscope (CM)',
	microscopesFM =  'Fluorescence microscopi (FM)',
	microscopesOM =  'Optical microscope (OM)',
	solidESR =  'Electro spin resonance analyser (ESR)',
	solidNMR =  'Nuclear magnetic resonance (NMR)',
	solidRamanSpectrometer =  'Raman spectrometer',
	solidXRD =  'X-ray diffraction analyser (XRD)',
	solidMicroCT =  'Micro-computed tomography (micro-CT)\n',
	solidPRTMS =  'Proton transfer ion trap mass spectrometer (PRT-MS)',
	solidOther =  'Other (please specify)',
	thermalDEA =  'Dielectric thermal analyser (DEA)',
	thermalDIL =  'Dilatometer (DIL)',
	thermalDMA =  'Dynamic mechanical analyser (DMA)',
	thermalDSC =  'Differential scanning calorimeter (DSC)',
	thermalDTA =  'Differential thermal analyser (DTA)',
	thermalEGA =  'Evolved gas analyser (EGA)',
	thermalLFA =  'Laser flash analyser (LFA)',
	thermalTGA =  'Thermogravimetric analyser (TGA)',
	thermalTMA =  'Thermomechanical analyser (TMA)\n',
	thermalOther =  'Other (please specify)',
	rheologyUtube =  'U-tube viscosimeters\n',
	rheologyFallingSphere =  'Falling sphere viscosimeters',
	rheologyRotationalViscosimeters =  'Rotational viscosimeters',
	rheologyRheometers =  'Rheometers\n',
	rheologyOther =  'Other (please specify)\n',
	sensoryElectronicNose =  'Electronic nose (e-Nose)',
	sensoryElectronicTongue =  'Electronic tongue (e-Tongue)',
	sensoryGCO =  'Gas chromatography-olfactometry (GC-O)',
	sensoryElectronicVisualAnalyser =  'Electronic visual analyser',
	sensoryPanelTests =  'Panel tests',
	sensoryOther =  'Other (please specify)',
	biotechnologyMolecularPCR =  'PCR\n',
	biotechnologyMolecularRTPCR =  'Real time - PCR (RT-PCR)',
	biotechnologyMolecularAPPCR =  'Arbitratily-primed - PCR (AP-PCR)',
	biotechnologyMolecularREPPCR =  'Repetitive element - PCR (rep-PCR)',
	biotechnologyMolecularSequencer =  'Sequencer (genome, RNA, metagenome)\n',
	biotechnologyMolecularDNAMicroarray =  'DNA microarray-based technology',
	biotechnologyMolecularDGGE = 'Denaturing gradient gel electrophoresis (DGGE)',
	biotechnologyMolecularRFLP = 'Restriction fragment length polymorphism (RFLP)',
	biotechnologyMolecularRibotyping = 'Ribotyping',
	biotechnologyMolecularRAPD = 'Random amplified polymorphic DNA (RAPD)',
	biotechnologyMolecularPFGE = 'Pulsed-field gel electrophoresis (PFGE)\n',
	biotechnologyMolecularMEE = 'Multilocus enzyme electrophoresis (MEE)',
	biotechnologyMolecularMLST = 'Multilocus sequence typing (MLST)\n',
	biotechnologyMolecularElectrophoreticTest = 'Electrophoretic test',
	biotechnologyMolecularOther = 'Other (please specify)',
	biotechnologyBiosensorsOpticalBiosensors = 'Optical biosensors',
	biotechnologyBiosensorsPiezoelectricBiosensors = 'Piezoelectric biosensors',
	biotechnologyBiosensorsImmunosensors = 'Immunosensors',
	biotechnologyBiosensorsElecthrochemicalBiosensors = 'Electhrochemical biosensors',
	biotechnologyBiosensorsOther = 'Other (please specify)',
	biotechnologyImmunoenzymaticELISA = 'ELISA',
	biotechnologyImmunoenzymaticELFA = 'ELFA',
	biotechnologyImmunoenzymaticOther = 'Other (please specify)',
	biotechnologyOthersMicrobialVolatile = 'Microbial volatile organic compound detection technique',
	biotechnologyOthersBioanalyser = 'Bioanalyser',
	biotechnologyOthersOther = 'Other (please specify)',
	microbiologyAutoclaveSteriliser = 'Autoclave steriliser',
	microbiologyAntimicrobialSusceptibilityTest = 'Antimicrobial susceptibility test',
	microbiologyGramStrainer = '(Automated) gram strainer',
	microbiologyLiquidMediaPreparation = '(Automated) liquid media preparation',
	microbiologyPetriDishFiller = '(Automated) Petri dish filler / agar plate pourer',
	microbiologyColonyCounters = 'Colony counters',
	microbiologyCellCultureMedia = 'Cell culture media',
	microbiologyCO2Incubator = 'CO2 incubator / Cell culture incubator\n',
	microbiologyCompoundMicroscope = 'Compound microscope / Compound light microscope',
	microbiologyMicrobialIdentificationSystem = 'Microbial identification system',
	microbiologyFlowCitometry = 'Flow citometry',
	microbiologyGrowthChambers = 'Growth chambers',
	microbiologyGerminationChambers = 'Germination chambers',
	microbiologyOther = 'Other (please specify)',
	devicesTechnicalBalance = 'Technical balance',
	devicesAnalyticalBalance = 'Analytical balance',
	devicesOvenMuffleFurnace = 'Oven / muffle furnace',
	devicesFridge4 = 'Fridge (+ 4 °C)',
	devicesFreezerMinus20 = 'Freezer (- 20 °C)',
	devicesMinus80 = 'Freezer (- 80 °C)',
	dedicatedCentrifugeUltracentrifuge = 'Centrifuge / ultracentrifuge',
	devicesHeatingBath = 'Heating bath',
	devicesUltrasoundBath = 'Ultrasound bath',
	devicesIncubator = 'Incubator',
	devicesMillerGrinder = 'Miller / Grinder',
	devicesSiever = 'Siever',
	devicesMixer = 'Mixer',
	devicesHomogeniser = 'Homogeniser',
	devicesLyophilizer = 'Lyophilizer',
	devicesSprayDrier = 'Spray-drier',
	devicesRotaryEvaporator = 'Rotary evaporator',
	devicesSPME = 'Solid phase micro extraction (SPME)',
	devicesASE = 'Accelerated solvent extraction (ASE)',
	devicesMicrowaveDigestionSystem = 'Microwave digestion system',
	devicesMicrowaveExtractionSystem = 'Microwave extraction system',
	devicesUltrawaveDigestionSystem = 'Ultrawave digestion system',
	deviceLaserAblationSystem = 'Laser ablation system',
	devicesOther = 'Other (please specify)',
}
