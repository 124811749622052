<div class="landing-wrapper">
	<div class="my-15 text-center">
		<img src="assets/images/logo.svg" alt="">
		<p class="mt-5">Welcome to the METROFOOD-RI members app!</p>
	</div>

	<div class="landing-buttons mb-15">
		<button [routerLink]="['search', 'institute']">
			<i nz-icon nzType="icons:button-institute"></i>
			<span class="ml-4">Search Institute</span>
		</button>

		<button [routerLink]="['search', 'person']">
			<i nz-icon nzType="icons:button-user"></i>
			<span class="ml-4">Search Person</span>
		</button>

		<button [routerLink]="['search', 'facility']">
			<i nz-icon nzType="icons:button-facility"></i>
			<span class="ml-4">Search Facility</span>
		</button>

		<button [routerLink]="['search', 'e-resource']">
			<i nz-icon nzType="icons:button-facility"></i>
			<span class="ml-4">Search Resource</span>
		</button>
	</div>
</div>
