
<form action="" class="mt-2" >
	<div class="d-flex justify-content-between">
		<h1>Services</h1>
		<div>
			<!-- <button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
				<div>EDIT</div>

			</button> -->

			<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" >
				<div>SAVE</div>
			</button>
		</div>
	</div>
	<div>In which service(s) does your facility can be involved (planned 1 - 5 years)</div>
	<metro-membership-analytical-service *ngIf="facility && facility.type === 'analytical' "
	(formChanges)="handleFormChanges($event)" [facility]="facility"></metro-membership-analytical-service>

<metro-membership-rm-services *ngIf="facility && facility.type === 'rm' " (formChanges)="handleFormChanges($event)" [facility]="facility"></metro-membership-rm-services>
</form>
