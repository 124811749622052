import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Person } from '@metro-membership/data-access';
import { KeycloakService } from '@metro/ui-core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PersonService } from '../../data/services/person.service';
import { FrontendAuthGrantManager } from '../helpers/frontend-auth-grant-manager';

@Injectable({
	providedIn: 'root'
})
export class UserService implements OnDestroy {
	user: BehaviorSubject<Person> = new BehaviorSubject(null);
	keycloakRoles: string[] = []; // Todo: Rework this into something easier to manage

	constructor(
		private http: HttpClient,
		private keycloak: KeycloakService,
		private personService: PersonService
	) {}

	getAndStoreUser(keycloakId: string, email: string): Observable<Person> {
		return this.getUserById(keycloakId).pipe(
			catchError((err: any, caught: any) => {
				return throwError(err);
			}),
			tap(user => {

				this.user.next(user);
			})
		);
	}

	getUserById(id: string): Observable<Person> {
		const params: HttpParams = new HttpParams({
			fromObject: {
				filter: `keycloakId||eq||${id}`,
				join: ['instituteRoles', 'resourcesRoles','facilityRoles', 'facilityRoles.facility'],
				limit: '1'
			}
		});
		return this.fetchUser(params);
	}

	fetchUser(params: HttpParams): Observable<Person> {
		return this.http.get<Person[]>(`@persons/`, { params }).pipe(
			map(persons => persons.length ? persons[0] : null)
		);
	}

	getCurrentAuthGrantManager(
		route?: ActivatedRouteSnapshot,
		idOverrides: { facilityId?: number, instituteId?: number, nodeId?: number, resourceId?:number } = {}
	): FrontendAuthGrantManager {
		const authGM = new FrontendAuthGrantManager();
		authGM.roles.application = this.keycloak.getKeycloakInstance().tokenParsed.realm_access?.roles || [];
		authGM.roles.institute = this.user.getValue()?.instituteRoles || [];
		authGM.roles.facility = this.user.getValue()?.facilityRoles || [];
		authGM.roles.resource = this.user.getValue()?.resourcesRoles || [];
		if (route) {
			const facilityId = idOverrides.facilityId || route.paramMap.get('facilityId');
			if (facilityId) {
				authGM.facilityId = parseInt(facilityId as string, 10);
			}

			const resourceId = idOverrides.resourceId || route.paramMap.get('resourceId');

			if (resourceId) {

				authGM.resourceId = parseInt(resourceId as string, 10);
			}

			const instituteId = idOverrides.instituteId || route.paramMap.get('instituteId');
			if (instituteId) {
				authGM.instituteId = parseInt(instituteId as string, 10);
			}

			const nodeId = idOverrides.nodeId || route.paramMap.get('nodeId');
			if (nodeId) {
				authGM.nodeId = parseInt(nodeId as string, 10);
			}
		}

		return authGM;
	}

	ngOnDestroy() {
		this.user.complete();
	}
}
