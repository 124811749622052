<nz-table *ngIf="showForm"
[nzFrontPagination]="false"
[nzData]="formGroup.controls">
<thead>
	<tr>
		<th nzWidth="40%" nzAlign="center"></th>
		<th nzWidth="60%" nzAlign="center">
			<tr style="display: block;">
				<th style="display: inline-block; width: 25%;" nzAlign="center">YES</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">Potentially</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">Planned 1-5 years</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">NO</th>
			</tr>
		</th>
	</tr>
</thead>
<tbody>

	<tr class="pointer" *ngFor="let item of sector.controls; index as i" [formGroup]="item">

		<td nzWidth="40%">
			{{ sectorCategories[item.value.name]}}
		</td>
		<td nzWidth="60%" nzAlign="center">
			<nz-radio-group formControlName="status" style="width: 100%;" >
				<td nzAlign="center" style="display: inline-block; width: 25%;">
					<label nz-radio nzValue="YES"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="POTENTIALLY"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="PLANNED"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="NO"></label>
				</td>
			</nz-radio-group>
		</td>
	</tr>
</tbody>

</nz-table>

<div class="relative mb-15">
	<h3>Other (please specify here)</h3>
	<div [formGroup]="sector.controls[sector.controls.length - 1]" class="d-flex justify-content-between">
		<nz-textarea-count [nzMaxCharacterCount]="300" class="w-100" >
			<textarea rows="4" formControlName="comment" nz-input></textarea>
		</nz-textarea-count>
	</div>
</div>

<div class="relative mb-15">
	<h3>Which pure substances for calibration?</h3>
	<div *ngFor="let item of pureSubst.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>
	</div>
</div>

<div class="relative mb-15">
	<h3>Please describe the Process Intermediate-RMs</h3>
	<div *ngFor="let item of procInterRms.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">

		<nz-textarea-count [nzMaxCharacterCount]="300" [formGroup]="item" class="w-100">
			<textarea rows="4" formControlName="comment" nz-input></textarea>
		</nz-textarea-count>

	</div>
</div>

<div class="relative mb-15">
	<h3>Indicate the matrixes you work with</h3>
	<div *ngFor="let item of matrixes.controls; index as i" [formGroup]="item" class="d-flex justify-content-between align-items-center">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>
		<metro-form-input *ngIf="i === matrixes.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
		[control]="item.controls.comment">
	</metro-form-input>
</div>
</div>

<div class="relative mb-15">
	<h3>For additives and supplements please specify here</h3>
	<div *ngFor="let item of additivesSpecify.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">

		<nz-textarea-count [nzMaxCharacterCount]="300" [formGroup]="item" class="w-100">
			<textarea rows="4" formControlName="comment" nz-input></textarea>
		</nz-textarea-count>

	</div>
</div>

<div class="relative mb-15">
	<h3>For feedstuffs please specify here</h3>
	<div *ngFor="let item of feedStuffsSpecify.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">

		<nz-textarea-count [nzMaxCharacterCount]="300" class="w-100" [formGroup]="item">
			<textarea rows="4" formControlName="comment" nz-input></textarea>
		</nz-textarea-count>

	</div>
</div>

<div class="relative mb-15">
	<h3>For foods of animal origin, indicate the sub-categories you work with</h3>
	<div *ngFor="let item of foodsAnimal.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === foodsAnimal.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
			[control]="item.controls.comment">
		</metro-form-input>
</div>
</div>

<div class="relative mb-15">
	<h3>For foods of plant origin, indicate the sub-categories you work with</h3>
	<div *ngFor="let item of foodsPlant.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === foodsPlant.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
		[control]="item.controls.comment">
	</metro-form-input>
</div>
</div>

<div class="relative mb-15">
	<h3>For beverages and vinegars, indicate the sub-categories you work with</h3>
	<div *ngFor="let item of beverages.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === beverages.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
		[control]="item.controls.comment">
	</metro-form-input>
</div>
</div>

<div class="relative mb-15">
	<h3>For food contact materials, indicate the sub-categories you work with</h3>
	<div *ngFor="let item of fcm.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>
		<metro-form-input *ngIf="i === fcm.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
		[control]="item.controls.comment">
	</metro-form-input>
</div>
</div>

<div class="relative mb-15">
	<h3>For agricultural non-food products, indicate the sub-categories you work with</h3>
	<div *ngFor="let item of agricultural.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>

		<metro-form-input *ngIf="i === agricultural.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
		[control]="item.controls.comment">
	</metro-form-input>
</div>
</div>

<div class="relative mb-15">
	<h3>For environmental matrices, indicate the sub-categories you work with</h3>
	<div *ngFor="let item of environmental.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>
		<metro-form-input *ngIf="i === environmental.controls.length - 1" class="standard ml-5 w-100" type="text" ph="Other" formControlName="comment"
		[control]="item.controls.comment">
	</metro-form-input>
	</div>
</div>

<div class="relative mb-15">
	<h3>For prepared (ready-to-eat), indicate the sub-categories you work with</h3>
	<div *ngFor="let item of prepareFoods.controls; index as i" [formGroup]="item" class="d-flex justify-content-between">
		<label nz-checkbox formControlName="isActive">
			<div class="title">{{ sectorCategories[item.get('name').value] }}</div>
		</label>
	</div>
</div>
