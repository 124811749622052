<form action="" [formGroup]="resourcesForm">

	<div class="title mb-4">Type of software</div>

	<nz-radio-group formControlName="softwareType">

		<label class="mb-4 d-flex" nz-radio [nzValue]="'WEB'">
			<span>Web app</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'DESKTOP'">
			<span>Desktop app</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'MOBILE'">
			<span>Mobile app</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'OTHER'">
			<span>Other</span>
		</label>

	</nz-radio-group>

	<metro-form-input *ngIf="resourcesForm.controls.softwareType.value === 'OTHER'"
										class="standard mt-3 w-100" type="text" ph="Please specify other software type " formControlName="softwareTypeComment"
										[control]="resourcesForm.controls.softwareTypeComment">
	</metro-form-input>

	<metro-membership-form-text
		ph="Ontologies used" formControlName="softwareOntologies"
		label="Ontologies used"
		[control]="resourcesForm.controls.softwareOntologies">
	</metro-membership-form-text>

	<div class="title mt-4 mb-4">
		In what formats can the data be imported?
	</div>

	<div class="mb-5 relative">
		<nz-select
			class="w-50"
			formControlName="softwareImportFormat"
			[nzMaxTagCount]="5"
			nzMode="multiple"
			nzPlaceHolder="Please select">
			<nz-option *ngFor="let format of dataFormat" [nzLabel]="format" [nzValue]="format"></nz-option>
		</nz-select>
	</div>

	<metro-form-input *ngIf="detectOtherOption(resourcesForm.controls.softwareImportFormat)"
										class="standard mt-3 w-100" type="text" ph="Please specify other data import format"
										formControlName="softwareImportFormatComment"
										label="Please specify other data import format"
										[control]="resourcesForm.controls.softwareImportFormatComment">
	</metro-form-input>

	<div class="title mt-4 mb-4">
		In what format can the data be exported?
	</div>

	<div class="mb-5 relative">
		<nz-select
			class="w-50"
			formControlName="softwareExportFormat"
			[nzMaxTagCount]="5"
			nzMode="multiple"
			nzPlaceHolder="Please select">
			<nz-option *ngFor="let format of dataFormat" [nzLabel]="format" [nzValue]="format"></nz-option>
		</nz-select>
	</div>

	<metro-form-input *ngIf="detectOtherOption(resourcesForm.controls.softwareExportFormat)"
										class="standard mt-3 w-100" type="text" ph="Please specify other data export format"
										formControlName="softwareExportFormatComment"
										label="Please specify other data export format"
										[control]="resourcesForm.controls.softwareExportFormatComment">
	</metro-form-input>


</form>
