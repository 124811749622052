<div class="side-menu" >
	<ul nz-menu nzMode="inline">
		<ng-container *ngFor="let position of sideMenuPositions" >
			<li [nzOpen]="isExpanded" nz-submenu *ngIf="position.text" [nzTitle]="position.text" (nzOpenChange)="navigate(position.url)">
				<ul class="submenu">
					<li nz-menu-item class="d-flex align-items-center" *ngFor="let subPosition of position.subPositions"
						[ngClass]="{ 'disabled': !subPosition.enabled }" routerLinkActive="active" [routerLink]=" [ subPosition.url ] ">
						<i nz-icon nzType="icons:lock" *ngIf="!subPosition.enabled"></i>
						<span>
							{{subPosition.text}}
						</span>
					</li>
				</ul>
			</li>
			<ng-container *ngIf="!position.text">
				<li nz-menu-item class="d-flex align-items-center" *ngFor="let subPosition of position.subPositions"
					[ngClass]="{ 'disabled': !subPosition.enabled }" routerLinkActive="active" [routerLink]=" [ subPosition.url ] ">
					<i nz-icon nzType="icons:lock" *ngIf="!subPosition.enabled"></i>
					<span>
						{{ subPosition.country + subPosition.text }}
					</span>
				</li>
			</ng-container>
		</ng-container>
	</ul>
</div>
