export const sector = [
	{
		isActive: false,
		number:0 ,
		name: 'FOOD_SAFETY',
	},
	{
		isActive: false,
		number:0 ,
		name: 'FOOD_QUALITY',
	},
	{
		isActive: false,
		number:0 ,
		name: 'FOOD_AUTHENTICITY',
	},
	{
		isActive: false,
		number:0 ,
		name: 'NUTRITION',
	},
	{
		isActive: false,
		number:0 ,
		name: 'AGROECOSYSTEM_CHAR',
	},
	{
		isActive: false,
		number:0 ,
		name: 'CHARACTERISATION_MATERIAL',
	},
	{
		isActive: false,
		number:0 ,
		name: 'SECTOR_OTHER',
	}
];

export const safety = [
	{
		isActive: false,
		number:0 ,
		name: 'TOXIC_ELEMENT',
	},
	{
		isActive: false,
		number:0 ,
		name: 'MYCOTOXINS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PLANT_ALKALOIDS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'SAFETY_OTHER_TOXINS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PESTICIDES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'VETERINARY_DRUG',
	},
	{
		isActive: false,
		number:0 ,
		name: 'VOC',
	},
	{
		isActive: false,
		number:0 ,
		name: 'POPS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PHENOLS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'MELAMINE',
	},
	{
		isActive: false,
		number:0 ,
		name: 'NITRATES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PRECHLORATES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'ALLERGENS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'ADDITIVES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'GMO',
	},
	{
		isActive: false,
		number:0 ,
		name: 'AMINES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'BIOCIDES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'HORMONES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'HYDROCARBONS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'HALOGENATED_CONTAMINANTS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'MOH',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PROCESS_CONTAMINANTS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'ENVIRONMENTAL_CONTAMINANTS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'FOOD_CONTACT_MATERIAL_MIGRATION',
	},
	{
		isActive: false,
		number:0 ,
		name: 'NANOPARTICLES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'MICROPLASTICS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PATHOGENIC_MICRO',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PARASITES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PRIONS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'VIRUSES',
	},
	{
		isActive: false,
		number:0 ,
		name: 'RADIOACTIVITY',
	},
	{
		isActive: false,
		number:0 ,
		name: 'SAFETY_OTHER',
	}

];

export const elements = [
	{
		isActive: false,
		number:0 ,
		name: 'TOTAL_CONTENT',
	},
	{
		isActive: false,
		number:0 ,
		name: 'BIOAVAILABILITY',
	},
	{
		isActive: false,
		number:0 ,
		name: 'SPECIATION',
	},
	{
		isActive: false,
		number:0 ,
		name: 'TOXIC_ELEMENTS_OTHER',
	}
];

export const otherToxins = [
	{
		isActive: false,
		number:0 ,
		name: 'OTHER_TOXINES',
	},
];

export const hydrocarbons = [
	{
		isActive: false,
		number:0 ,
		name: 'HYDROCARBONS_OTHER',
	},
];

export const processContam = [
	{
		isActive: false,
		number:0 ,
		name: 'ACRYLAMIDE',
	},
	{
		isActive: false,
		number:0 ,
		name: 'FURANS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'MCDP_ESTERS',
	},
	{
		isActive: false,
		number:0 ,
		name: 'PROCESS_CONTAM_OTHER',
	},
];

export const environmContam = [
	{	name: 'PAHS' },
	{
		isActive: false,
		number:0 ,
		name: 'PCB'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PBDE_BFR'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PCDD_PCDF'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PFAS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'CHLORINATED_PARAFFINS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'ENVIRONM_CONTAM_OTHER'
	},
];

export const additivesSpecify = [
	{
		isActive: false,
		number: 0 ,
		name: 'ADDITIVES_SUPPLEMENTS'
	},
];

export const quality = [
	{
		isActive: false,
		number:0 ,
		name: 'ORGANOLEPTIC_PROPERTIES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PHYSICAL_CHEMICAL_ANALYSIS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'BIOACTIVE_COMPOUNDS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'MICROBIOLOGICAL_ANALYSIS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'IN_VITRO_BIOACTIVITY'
	},
	{
		isActive: false,
		number:0 ,
		name: 'SENSORY_ANALYSIS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'QUALITY_OTHER'
	},
];

export const authenticity = [
	{
		isActive: false,
		number:0 ,
		name: 'ISOTOPES_LIGHT_ELEMENTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'ISOTOPES_HEAVY_ELEMENTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'RARE_EARTH_ELEMENTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'ELEMENTAL_PROFILES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'OMNICS_ANALYSE'
	},
	{
		isActive: false,
		number:0 ,
		name: 'GENETIC_MARKERS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'ADULTERATION'
	},
	{
		isActive: false,
		number:0 ,
		name: 'AUTHENTICITY_OTHER'
	},
];

export const omics = [
	{
		isActive: false,
		number:0 ,
		name: 'PROTEOMICS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'LIPIDOICS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'METABOLOMICA'
	},
	{
		isActive: false,
		number:0 ,
		name: 'GENOMICS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'MULTI_OMNICS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'OMNICS_OTHER'
	}
];

export const nutrition = [
	{
		isActive: false,
		number:0 ,
		name: 'FIBER'
	},
	{
		isActive: false,
		number:0 ,
		name: 'LIPIDS_CONTENT'
	},
	{
		isActive: false,
		number:0 ,
		name: 'FATTY_ACIDS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'DRY_MATTER'
	},
	{
		isActive: false,
		number:0 ,
		name: 'VITAMINS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'CARBOHYDRATES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'SUGARS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PROTEINS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'WATER'
	},
	{
		isActive: false,
		number: 0 ,
		name: 'MINERALS'
	},
	{
		isActive: false,
		number: 0 ,
		name: 'NUTRITION_OTHER'
	}
];

export const agroeco = [
	{
		isActive: false,
		number:0 ,
		name: 'SURFACE_GROUNDWATER'
	},
	{
		isActive: false,
		number:0 ,
		name: 'SOILS_SEDIMENTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'BIOAVAILABILITY_STUDIES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'AIR_POLLUTION'
	},
	{
		isActive: false,
		number:0 ,
		name: 'WET_DRY_DEPOSITIONS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'BIOINDICATORS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'AGRO_ECO_OTHER'
	}
];

export const minerals = [
	{
		isActive: false,
		number:0 ,
		name: 'METALLIC_MATERIALS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'CERAMIC_MATERIALS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PLASTIC_MATERIALS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'GLASS_MATERIALS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PAPER_MATERIALS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'RECYCLED_MATERIALS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'WOOD_PLANTS_MATERIALS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'MATERIALS_CHAR_OTHER'
	}
];

export const matrixes = [
	{
		isActive: false,
		number:0 ,
		name: 'FOOD_OF_ANIMALS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'FOOD_OF_PLANTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'FOOD_PREPARED_PRODUCTS'
	},
	{
		isActive: false,
		number: 0 ,
		name: 'BEVERAGES_VINEGARS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'ADDITIVES_SUPPLEMENTS_MATRIX'
	},
	{
		isActive: false,
		number:0 ,
		name: 'MATRIX_OTHER'
	}
];

export const plants = [
	{
		isActive: false,
		number:0 ,
		name: 'CEREALS_PRODUCTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'FRUIT_VEGETABLES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'OLIVES_OILS_FATS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'SUGAR_PLANTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'HERBS_SALT'
	},
	{
		isActive: false,
		number:0 ,
		name: 'COCA_COFFE'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PLANTS_OTHER'
	}
];

export const beverages = [
	{
		isActive: false,
		number:0 ,
		name: 'DRINK_WATER'
	},
	{
		isActive: false,
		number:0 ,
		name: 'NON_ALCOHOLIC_BEVERAGES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'WINE'
	},
	{
		isActive: false,
		number:0 ,
		name: 'BEER_CIDER'
	},
	{
		isActive: false,
		number:0 ,
		name: 'ALCOHOLIC_BEVERAGES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'FRUIT_VEGETABLE_JUICES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'VINEGARS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'BEVERAGES_OTHER'
	}
]

export const preparedFoods = [
	{
		isActive: false,
		number:0 ,
		name: 'CANNED_FRUITS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'BREAD_BAKERY'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PASTA'
	},
	{
		isActive: false,
		number:0 ,
		name: 'SAUCES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PREPARED_DISHES'
	},
	{
		isActive: false,
		number:0 ,
		name: 'INSTANT_FOODS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'TOTAL_DIET'
	},
	{
		isActive: false,
		number:0 ,
		name: 'PREPARED_FOOD_OTHER'
	}
]

export const foodAnimal = [
	{
		isActive: false,
		number:0 ,
		name: 'MEATS_POLUTRY_PRODUCTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'FISH_PRODUCTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'MILK_DIARY_PRODUCTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'HONEY_BEE_PRODUCTS'
	},
	{
		isActive: false,
		number:0 ,
		name: 'FATS_ANIMAL'
	}
]

export const other = [
	{
		isActive: false,
		number:0 ,
		name: 'OTHER'
	},
]
