import { HttpClient, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Facility } from '@metro-membership/data-access';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FacilitySector } from 'libs/data-access/src/lib/entities/facility/facility-sector';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacilityService } from '../../data/services/facility.service';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
	selector: 'metro-membership-facility-sector',
	templateUrl: './facility-sector.page.html',
	styleUrls: ['./facility-sector.page.less']
})
export class FacilitySectorPage {
	unSubscribe = new Subject();
	facility: Facility;

	serviceFormArray: FormArray = this.formBuilder.array([]);

	facilityId = '';
	editMode = false;
	showForm = false;

	sectors: FacilitySector[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private facilityService: FacilityService,
		private http: HttpClient) {
			this.activatedRoute.parent.params
			.pipe(takeUntil(this.unSubscribe))
			.subscribe(params => {
				this.facilityId = params.facilityId;
				this.getFacilities();
			});
		}
		get isEditable(): boolean {
			return this.serviceFormArray.enabled as boolean;
		}

		onSave(): void {

			if (this.facility?.sectors?.length === 0) {
				this.facilityService.addMultipleFacilitySectors(this.sectors).subscribe(res => {});
			} else {
				this.facility.sectors = this.sectors.sort((a, b) => (a.id > b.id) ? 1 : -1);
				this.facilityService.replaceFacility(this.facility).subscribe(res => {});
			}
		}

		onEdit(): void {
			this.editMode = !this.editMode;
		}

		getFacilities(): void {
			const filterPhrase = new HttpParams().set('join', 'sectors');
			this.facilityService.fetchFacility(this.facilityId, filterPhrase).subscribe(facilityJson => {
				this.facility = facilityJson;
			});
		}

		handleFormChanges(sectorsObj: any[]) {
			const values = [];
			for (const key in sectorsObj) {
				if (Object.prototype.hasOwnProperty.call(sectorsObj, key)) {
					values.push(...sectorsObj[key]);
				}
			}
			this.sectors = values;
		}
}
