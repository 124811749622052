import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPage } from './default/landing/landing.page';
import { UnderDevelopmentPage } from './default/under-development/under-development.page';
import { FacilityModule } from './facility/facility.module';
import { InstituteModule } from './institute/institute.module';
import { ResourcesModule } from './resources/resources.module';
import { SearchModule } from './search/search.module';
import { NotFoundPage } from './shared/components/not-found/not-found-page';

export const appRoutes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: LandingPage
	},
	{
		path: 'under-development',
		component: UnderDevelopmentPage
	},
	{
		path: 'search',
		loadChildren: () => SearchModule
	},
	{
		path: 'institutes',
		loadChildren: () => InstituteModule
	},
	{
		path: 'institutes/:instituteId',
		loadChildren: () => FacilityModule,
		data: { breadcrumb: ':institute' }
	},
	{
		path: 'institutes/:instituteId',
		loadChildren: () => ResourcesModule,
		data: { breadcrumb: ':institute' }
	},
	{
		path: 'my-institutes/unassigned', component: NotFoundPage,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { paramsInheritanceStrategy: 'always' })],
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {}
