
<!-- <form action="" *ngIf="showForm">

	<metro-membership-kitchen-labs-equipment [kitchenForm]="facilityForm.get('kitchen')" *ngIf="facility && facility.type === 'process_kitchen' "
	(kitchenForm)="handleFormChanges($event)" [facility]="facility"
	></metro-membership-kitchen-labs-equipment>



	<metro-membership-analytical [rmFormsGroup]="facilityForm.get('analytical')" *ngIf="facility && facility.type === 'analytical' "
	(formChanges)="handleFormChanges($event)" [facility]="facility"></metro-membership-analytical>

	<metro-membership-production [rmFormsGroup]="facilityForm.get('primary')" *ngIf="facility && facility.type === 'primary'"
	(formChanges)="handleFormChanges($event)" [facility]="facility"
	>
	</metro-membership-production>
</form>
 -->
<metro-membership-production  *ngIf="facility && facility.type === 'primary'"  [facility]="facility"
>
</metro-membership-production>
<metro-membership-analytical *ngIf="facility && facility.type === 'analytical' " [facility]="facility"></metro-membership-analytical>

<metro-membership-rm-plants *ngIf="facility && facility.type === 'rm' " [facility]="facility"></metro-membership-rm-plants>
