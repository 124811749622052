<form action="" [formGroup]="resourcesForm">

	<div class="title">Type of hardware</div>

	<nz-radio-group formControlName="hardwareType">

		<label class="mb-4 d-flex" nz-radio [nzValue]="'SERVER'">
			<span>Server room</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'HPC'">
			<span>HPC (hight performance computer)</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'HTC'">
			<span>HTC (high throughput computer)</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'OTHER'">
			<span>Other(please specify) </span>
		</label>

	</nz-radio-group>

	<metro-form-input *ngIf="resourcesForm.controls.hardwareType.value === 'OTHER'"
		class="standard mt-3 w-100" type="text" ph="Please specify other hardware type" formControlName="hardwareTypeComment"
		label="Please specify other hardware type"
		[control]="resourcesForm.controls.hardwareTypeComment">
	</metro-form-input>

	<metro-form-input *ngIf="resourcesForm.controls.hardwareType.value === 'SERVER'"
		class="standard mt-3 w-100" type="number" ph="Approximate number of servers" formControlName="hardwareServersNumber"
		label="Approximate number of servers"
		[control]="resourcesForm.controls.hardwareServersNumber">
	</metro-form-input>

	<metro-membership-form-text
		ph="Description of the hardware" formControlName="hardwareDescription"
		label="Description of the hardware"
		[control]="resourcesForm.controls.hardwareDescription">
	</metro-membership-form-text>






</form>
