
<nz-form-item>
	<nz-form-control class="input-field">
		<label *ngIf="label"> {{ label }} </label>
		<input [ngClass]="{'pr-27': unit}"
			nz-input
			class="{{ class }}"
			#input
			[formControl]="control"
			(change)="onChange($event, input.value)"
			(focusout)="handleFocusOut()"
			[value]="control.value"
			type="{{ type }}"
			placeholder="{{ ph }}"
			[disabled]="control.disabled"
		/>
		<div class="unit" *ngIf="unit" >{{unit}}</div>
		<div *ngIf="!control.valid" class="invalid-message">{{ getErrorMessage() }}</div>
	</nz-form-control>
</nz-form-item>
