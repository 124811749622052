import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Facility } from '@metro-membership/data-access';
import { SectorCategories, SectorCategoriesTranslations } from '@metro-membership/data-access/enums';
import { debounceTime } from 'rxjs/operators';
import { additivesSpecify, other ,agroeco, authenticity, beverages, elements, environmContam, foodAnimal, hydrocarbons, matrixes, minerals, nutrition, omics, otherToxins, plants, preparedFoods, processContam, quality, safety, sector } from "./analytical";
@Component({
  selector: 'metro-membership-sector-analytical',
  templateUrl: './sector-analytical.component.html',
  styleUrls: ['./sector-analytical.component.less']
})
export class SectorAnalyticalComponent implements OnInit {
	@Output() formChanges: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	sectorCategories = SectorCategoriesTranslations;
	@Input() facility = { } as Facility;
	formGroup: FormGroup;
	showForm = false;

  constructor(private formBuilder: FormBuilder) { }

	get sector() {
		return this.formGroup.get('sector') as FormArray;
	}
	get safety() {
		return this.formGroup.get('safety') as FormArray;
	}
	get elements() {
		return this.formGroup.get('elements') as FormArray;
	}
	get otherToxins() {
		return this.formGroup.get('otherToxins') as FormArray;
	}
	get hydrocarbons() {
		return this.formGroup.get('hydrocarbons') as FormArray;
	}

	get processContam() {
		return this.formGroup.get('processContam') as FormArray;
	}

	get environmContam() {
		return this.formGroup.get('environmContam') as FormArray;
	}

	get additivesSpecify() {
		return this.formGroup.get('additivesSpecify') as FormArray;
	}

	get quality() {
		return this.formGroup.get('quality') as FormArray;
	}

	get authenticity() {
		return this.formGroup.get('authenticity') as FormArray;
	}

	get omics() {
		return this.formGroup.get('omics') as FormArray;
	}

	get nutrition() {
		return this.formGroup.get('nutrition') as FormArray;
	}

	get agroeco() {
		return this.formGroup.get('agroeco') as FormArray;
	}

	get minerals() {
		return this.formGroup.get('minerals') as FormArray;
	}

	get matrixes() {
		return this.formGroup.get('matrixes') as FormArray;
	}

	get plants() {
		return this.formGroup.get('plants') as FormArray;
	}

	get beverages() {
		return this.formGroup.get('beverages') as FormArray;
	}

	get preparedFoods() {
		return this.formGroup.get('preparedFoods') as FormArray;
	}

	get foodAnimal() {
		return this.formGroup.get('foodAnimal') as FormArray;
	}

	get other() {
		return this.formGroup.get('other') as FormArray;
	}

  ngOnInit(): void {
		this.buildForms();
	}

	buildTableForm(categoryTable: any[]): FormGroup[] {
		return categoryTable.map(categories => {
			const currentSector = this.facility.sectors.find(sector => sector.name === categories.name)
			return this.formBuilder.group({
				id: currentSector?.id || null,
				name: categories.name || null,
				status: currentSector?.status || null,
				number: [{value: currentSector?.number, disabled: true}],
				isActive: currentSector?.isActive || null,
				facilityId: this.facility.id,
				comment: currentSector?.comment|| null,
			});
		});

	}
	buildForms() {

		this.formGroup = this.formBuilder.group({
			sector: this.formBuilder.array(this.buildTableForm(sector)),
			safety: this.formBuilder.array(this.buildTableForm(safety)),
			elements: this.formBuilder.array(this.buildTableForm(elements)),
			otherToxins: this.formBuilder.array(this.buildTableForm(otherToxins)),
			hydrocarbons: this.formBuilder.array(this.buildTableForm(hydrocarbons)),
			processContam: this.formBuilder.array(this.buildTableForm(processContam)),
			environmContam: this.formBuilder.array(this.buildTableForm(environmContam)),
			additivesSpecify: this.formBuilder.array(this.buildTableForm(additivesSpecify)),
			quality: this.formBuilder.array(this.buildTableForm(quality)),
			authenticity: this.formBuilder.array(this.buildTableForm(authenticity)),
			omics: this.formBuilder.array(this.buildTableForm(omics)),
			nutrition: this.formBuilder.array(this.buildTableForm(nutrition)),
			agroeco: this.formBuilder.array(this.buildTableForm(agroeco)),
			minerals: this.formBuilder.array(this.buildTableForm(minerals)),
			matrixes: this.formBuilder.array(this.buildTableForm(matrixes)),
			plants: this.formBuilder.array(this.buildTableForm(plants)),
			beverages: this.formBuilder.array(this.buildTableForm(beverages)),
			preparedFoods: this.formBuilder.array(this.buildTableForm(preparedFoods)),
			foodAnimal: this.formBuilder.array(this.buildTableForm(foodAnimal)),
			other: this.formBuilder.array(this.buildTableForm(other)),
		});

		this.showForm = true;
		this.formGroup.valueChanges
		.pipe(debounceTime(500))
		.subscribe(changes => {
			this.formChanges.next(changes);
		});
	}


}
