import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'metro-membership-resources-software',
  templateUrl: './resources-software.component.html',
  styleUrls: ['./resources-software.component.less']
})
export class ResourcesSoftwareComponent {
	@Input() resourcesForm: FormGroup;
	dataFormat: string[] = ['XML', 'ZIP', 'HTML', 'CSV', 'TXT', 'SQL', 'SCII', 'JSON', 'Other', 'Not applicable']
	detectOtherOption(control: AbstractControl): boolean {
		const value = control.value as string[];
		return value?.indexOf('Other') > -1 || false;
	}
}
