
<nz-table *ngIf="showForm"
[formGroup]="serviceForm"
[nzFrontPagination]="false"
[nzData]="analyticalServices">
<thead>
	<tr>
		<th nzWidth="40%" nzAlign="center"></th>
		<th nzWidth="60%" nzAlign="center">
			<tr style="display: block;">
				<th style="display: inline-block; width: 25%;" nzAlign="center">YES</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">YES after minor upgrades</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">YES after major upgrades</th>
				<th style="display: inline-block; width: 25%;" nzAlign="center">NO</th>
			</tr>
		</th>
	</tr>
</thead>
<tbody>

	<tr class="pointer" *ngFor="let item of analyticalServices; index as i">

		<td nzWidth="40%">
			{{item.label}}
		</td>
		<td nzWidth="60%" nzAlign="center">
			<nz-radio-group [formControlName]="item.formKey" style="width: 100%;" >
				<td nzAlign="center" style="display: inline-block; width: 25%;">
					<label nz-radio nzValue="YES"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="YES_MINOR"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="YES_MAJOR"></label>
				</td>
				<td nzAlign="center" style="display: inline-block; width: 25%;"	>
					<label nz-radio nzValue="NO"></label>
				</td>
			</nz-radio-group>
		</td>

	</tr>

</tbody>

</nz-table>
