import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UploadService {
	constructor(private http: HttpClient) {}
	uploadFile(uploadForm: any) {
		return this.http.post(`@upload/`, uploadForm, { reportProgress: true, responseType: 'json', observe: 'events' });
	}
	fetchFileByName(fileName: string) {
		return this.http.get(`@upload/${fileName}`, {  observe: 'response', responseType: 'arraybuffer'} );
	}
}
