<form action="" [formGroup]="resourcesForm" *ngIf="showForm">

<div class="mt-2 d-flex justify-content-end">
	<button class="ml-5 d-flex align-items-center secondary" (click)="onEdit()" *ngIf="!isEditable">
		<span>EDIT</span>
		<i nz-icon nzType="icons:edit" class="ml-4"></i>
	</button>
	<button class="ml-5 d-flex align-items-center primary" (click)="onSave()" *ngIf="isEditable" [ngClass]="{'disabled': resourcesForm.invalid }">
		<span>SAVE</span>
	</button>
</div>


	<div class="title mb-4">Can the resource be provided as a resource or service by METROFOOD-RI?</div>

	<nz-radio-group formControlName="providedResource">

		<label class="mb-4 d-flex" nz-radio [nzValue]="'WEB'">
			<span>Yes</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'DESKTOP'">
			<span>Yes, under certain conditions</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'MOBILE'">
			<span>No</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="'OTHER'">
			<span>Don’t know</span>
		</label>

	</nz-radio-group>

	<div class="title mb-4 mt-8">Terms of access to the resource.</div>

	<div class="mb-4 relative">
		<nz-select
			class="w-50"
			formControlName="accessTerm"
			nzPlaceHolder="Please select">
			<nz-option  *ngFor="let term of accessTerms" [nzLabel]="term" [nzValue]="term" ></nz-option>
		</nz-select>
		<div *ngIf="resourcesForm.controls.accessTerm.invalid" class="invalid-message">
			This field cannot be empty.
		</div>
	</div>

	<div class="title mb-4 mt-12">Is there a licence connected to this resource?</div>

	<nz-radio-group formControlName="licence" class="relative">

		<label class="mb-4 d-flex" nz-radio [nzValue]="true">
			<span>Yes</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="false">
			<span>No</span>
		</label>

		<div *ngIf="resourcesForm.controls.licence.invalid" class="invalid-message">
			This field cannot be empty.
		</div>

	</nz-radio-group>

	<metro-membership-form-text *ngIf="resourcesForm.controls.licence.value === true"
		ph="Licence connected to resource" formControlName="licenceConnected"
		label="Licence connected to resource"
		[control]="resourcesForm.controls.licenceConnected">
	</metro-membership-form-text>

	<div class="title mt-8 mb-4">Is the resource available online?</div>

	<nz-radio-group formControlName="onlineResource" class="relative">

		<label class="mb-4 d-flex" nz-radio [nzValue]="true">
			<span>Yes</span>
		</label>

		<label class="mb-4 d-flex" nz-radio [nzValue]="false">
			<span>No</span>
		</label>

		<div *ngIf="resourcesForm.controls.onlineResource.invalid" class="invalid-message">
			This field cannot be empty.
		</div>

	</nz-radio-group>

	<metro-form-input *ngIf="resourcesForm.controls.onlineResource.value === true"
		class="standard w-100" type="text" ph="Link to resource online" formControlName="linkToResource"
		label="Link to resource online"
		[control]="resourcesForm.controls.linkToResource">
	</metro-form-input>

</form>
