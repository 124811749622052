import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InstituteResources, Person } from '@metro-membership/data-access';
import { PersonRole } from '@metro-membership/data-access/enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { EmailBody } from '../../../../../../../../libs/data-access/src/lib/models/email';
import { UserService } from '../../../../core/services/user.service';
import { InstituteService } from '../../../../data/services/institute.service';
import { PersonService } from '../../../../data/services/person.service';
import { ConfirmationService } from '../../../../shared/services/confirmation.service';
import { ResourcesModalComponent } from '../../modals/resources-modal/resources-modal.component';
@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
	selector: 'metro-membership-resources-table',
	templateUrl: './resources-table.component.html',
	styleUrls: ['./resources-table.component.less']
})
export class ResourcesTableComponent implements OnInit {
	resources: InstituteResources[] = [];
	instituteId = '';
	subscriptions = [];
	perPage = 5;
	offset = 0;
	total = 0;
	pageIndex = 0;

	activeUserRoles = [];
	showEditing : boolean;

	resourceModal = false;

	sortOrder: NzTableSortOrder;
	sortColumn = '';
	resourcesParams: HttpParams = new HttpParams();

	constructor(
		private confirmationService: ConfirmationService,
		private personService: PersonService,
		private instituteService: InstituteService,
		private modal: NzModalService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private userService: UserService
	) { }

	ngOnInit(): void {
		this.subscriptions.push(
			this.activatedRoute.params
			.subscribe(params => {
				this.instituteId = params.instituteId;
				this.getCurrentUserRole();
				this.getResources(this.getParams());
			})
		);
	}

	getCurrentUserRole() {
		const user  = this.userService.user.getValue() as Person;
		const instRole = user?.instituteRoles.filter(role => role.instituteId.toString() === this.instituteId);
		this.activeUserRoles = instRole?.map(instRole => instRole.role);
		this.setEditingVisible();
	}

	setEditingVisible()  {
		this.showEditing = (
			this.activeUserRoles.indexOf(PersonRole.REPRESENTATIVE) > -1 ||
			this.activeUserRoles.indexOf(PersonRole.DEPUTY) > -1  ||
			this.activeUserRoles.indexOf(PersonRole.RESOURCE_MANAGER) > -1 ||
			this.userService.keycloakRoles.indexOf('admin') > -1
		);
	}

	getParams(): HttpParams {
		return new HttpParams()
			.set('filter', `instituteId||$eq||${this.instituteId ? this.instituteId : ''}`)
			.set('join','persons')
			.set('limit', `${this.perPage}`)
			.set('offset', `${this.offset}`);
	}

	getResources(params?: HttpParams) {
		this.subscriptions.push(
			this.instituteService.fetchResources(params).subscribe(resourcesJson => {
			this.total = resourcesJson.total;
			this.resources = resourcesJson.data;
		})
		);
	}

	getResourceIco(ico: string): string {
		return `resource-${ico.toLowerCase()}`;
	}

	onDeleteResource(resourceId: number) {
		this.confirmationService.showDeleteConfirm(
			'Delete Resource',
			'Do you really want to delete this resource ?',
			'Delete',
			() => this.deleteResource(resourceId)
		);
	}

	async deleteResource(resourceId: number) {
		const params = new HttpParams({
			fromObject: {
				instituteId: this.instituteId || null
			}
		});
		return this.instituteService.deleteResource(resourceId, params).toPromise().then(() => {
			this.getResources(this.getParams());
		});
	}

	addResource() {
		const resourceModal: NzModalRef = this.modal.create({
			nzTitle: 'Select the type of E-resource you want to add',
			nzContent: ResourcesModalComponent,
			nzComponentParams: {
				instituteId: this.instituteId,
			},
		});

		resourceModal.afterClose.subscribe((results: InstituteResources) => {
			if(results) {
				const resourceParams = this.getParams();

				this.instituteService.addResource(results).subscribe(resourceJson => {
					this.pageIndex = 1;
					this.getResources(resourceParams);
					// this.sendAssignEmail({
					// 	resource: resourceJson.name,
					// 	url: 'https://membership.staging.metrofood-services.eu/',
					// 	email: results.personRole.person.email,
					// 	name:results.personRole.person.name
					// })
				});
			}
		});
	}
	sendAssignEmail(email: EmailBody) {
		this.instituteService.sendResourceContactAssignEmail(email).subscribe(emailJson => ({}));
	}
	handleQueryParams(event: NzTableQueryParams) {
		let resourceParams = new HttpParams()
			.set('filter', `instituteId||$eq||${this.instituteId}`)
			.set('limit', `${this.perPage}`)
			.set('offset', `${(event.pageIndex - 1) * this.perPage}`);

		const currentSort = event.sort.find(s => s.value);
		if (currentSort) {
			resourceParams = resourceParams.set('sort', `${currentSort.key},${this.mapSortOrder(currentSort.value)}`);
		}
		this.getResources(resourceParams);
	}

	private mapSortOrder(sortOrder: NzTableSortOrder) {
		return sortOrder === 'descend' ? 'DESC' : 'ASC';
	}
}
