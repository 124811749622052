import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FacilityType } from '@metro-membership/data-access/enums';

@Component({
  selector: 'metro-membership-facility-type',
  templateUrl: './facility-type.component.html',
  styleUrls: ['./facility-type.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityTypeComponent {
	@Input() type: FacilityType;

	getFacilityTypeName() {
		switch(this.type) {
			case FacilityType.Analytical_Lab:
				return 'Analytical lab';
			case FacilityType.Primary_Production:
				return 'Primary Production';
			case FacilityType.Processing_And_Kitchen_Lab:
				return 'Processing and kitchen lab';
			case FacilityType.RM_Facility:
				return 'RM Facility';
		}
	}
}
